import React from "react";
import LearnMoreSVG from "../../components/templates/Svg/Landingpagesvgs/LearnMoreSVG";

const ThirdSectionContextBox = ({
     children = <></>,
     title = "",
     body = "",
     learnMoreLink = "",
}) => {
     return (
          <div className=" w-[280px] h-[300px]  flex flex-col justify-between flex-shrink-0">
               <div className="  ps-4 pt-4 pb-2">
                    <div className=" w-[60px] h-[60px] bg-mainRectangeGredient2 rounded-[50%]  flex flex-col justify-center items-center">
                         {children}
                    </div>
               </div>
               <div className=" w-full h-[20px]  mt-2 mb-2  flex flex-row justify-start items-center gap-4 ">
                    <div className=" h-full w-[4px] bg-mainRectangeGredient2  "></div>
                    <h1 className=" font-[600] font-poppins text-[#111013] text-[19px]">
                         {title}
                    </h1>
               </div>

               <div className=" flex-1  overflow-hidden ">
                    <div className=" w-full h-full ps-4 font-poppins text-[16px] text-[#9497A1] ">
                         {body}
                    </div>
               </div>

               <div className=" w-full h-fit ps-4 pb-4 pt-4">
                    <a
                         href={learnMoreLink} rel="noreferrer"
                         target="_blank"
                         className=" text-[#E91050] text-[18px] font-inter font-[500]"
                    >
                         <div className=" flex flex-row items-center gap-2">
                              Learn More
                              <LearnMoreSVG />
                         </div>
                    </a>
               </div>
          </div>
     );
};

export default ThirdSectionContextBox;
