import React from "react";

const NavBarLink = ({ children }) => {
     return (
          <div className=" w-fit h-full flex flex-col justify-center">
               {children}
          </div>
     );
};

export default NavBarLink;
