import React from "react";

const ReviewSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="30"
               height="30"
               viewBox="0 0 30 30"
               fill="none"
          >
               <path
                    d="M16.875 24.375H24.375V26.25H16.875V24.375ZM16.875 20.625H28.125V22.5H16.875V20.625ZM16.875 16.875H28.125V18.75H16.875V16.875Z"
                    fill="white"
               />
               <path
                    d="M19.2649 10.5159L15.0002 1.875L10.7355 10.5159L1.2002 11.9016L8.1002 18.6281L6.47082 28.125L13.1252 24.6272V22.5084L8.96176 24.6975L9.94801 18.945L10.1149 17.9728L9.40895 17.2856L5.22957 13.2103L11.0055 12.3713L11.9805 12.2297L12.4174 11.3456L15.0002 6.11156L17.583 11.3456L18.0199 12.2297L18.9949 12.3713L25.983 13.3884L26.2502 11.5312L19.2649 10.5159Z"
                    fill="white"
               />
          </svg>
     );
};

export default ReviewSVG;
