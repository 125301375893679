import React from "react";
import ContactFormBackButton from "./ContactFormBackButton";
import ContactFormHeaderEmailSection from "./ContactFormHeaderEmailSection";

const ContactFormHeader = () => {
     return (
          <div className=" w-full h-[100px]  flex sm:flex-row flex-col  justify-between ps-4 pe-4">
               <ContactFormBackButton />
               <ContactFormHeaderEmailSection />
               <div></div>
          </div>
     );
};

export default ContactFormHeader;
