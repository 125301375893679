import React from "react";
import LeftArrowSVG from "../../../templates/Svg/ContactForm/LeftArrowSVG";
import ButtonComponent from "../../../../basicultils/ButtonComponent";

const ContactFormPageScrollSection = () => {
     return (
          <div className=" w-fit h-fit flex flex-row gap-4 text-[12px] font-montserrat">
               <ButtonComponent
                    className="w-fit h-fit"
                    isLoading={false}
                    onClick={() => {}}
               >
                    <LeftArrowSVG />
               </ButtonComponent>
               <div className=" w-fit h-fit flex flex-row gap-2">
                    <ButtonComponent
                         onClick={() => {}}
                         isLoading={false}
                         className="  border-b-2 border-[#000] min-w-[12px] flex flex-row justify-center"
                    >
                         1
                    </ButtonComponent>
                    <div>Of</div>
                    <div>14</div>
               </div>
               <ButtonComponent
                    className="w-fit h-fit rotate-180"
                    isLoading={false}
                    onClick={() => {}}
               >
                    <LeftArrowSVG />
               </ButtonComponent>
          </div>
     );
};

export default ContactFormPageScrollSection;
