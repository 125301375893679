import React from "react";

import HeadLineText from "./HeadLineText";

import EnterEmailInput from "./EnterEmailInput";
import eyecatcher from "../../assest/landingpageimages/firstsectioneyecatcher.png";

const headingText =
     "Collect and manage reviews from all your social media platforms into one intuitive dashboard to improve customer loyalty.";

const FirstSection = () => {
     return (
          <div className=" w-full md:h-[90vh] h-fit  flex  md:flex-row flex-col md:gap-0  justify-between md:mt-[3rem] mt-[1rem]  ">
               <div className=" md:w-[50%] w-full h-full flex flex-col items-end pt-12 gap-[4rem] bg-transparent md:p-0 p-6">
                    <HeadLineText />

                    <div className=" xl:w-[70%] md:w-[90%] w-full h-fit text-[16px] font-poppins font-[400] text-[#4B5162] ">
                         {headingText}
                    </div>

                    <div className=" w-full h-fit md:flex hidden  flex-row justify-end ">
                         <EnterEmailInput />
                    </div>
               </div>

               <div className="  md:w-[50%] w-full h-full overflow-visible relative  flex flex-row justify-center items-center ">
                    <img
                         src={eyecatcher}
                         alt="feature of ranblitz"
                         className=" object-cover  "
                    />
                    <div className=" absolute lg:w-[260px] md:w-[200px] w-[150px] lg:h-[260px] md:h-[200px] h-[150px] border-2 rounded-[50%] border-[#F17A01] md:bottom-[-5%] bottom-[-5%] right-8"></div>
               </div>
               <div className=" w-full h-fit  md:hidden block ps-8 pe-8 mt-[3rem]  ">
                    <EnterEmailInput />
               </div>
          </div>
     );
};

export default FirstSection;
