import React from "react";
import { useParams } from "react-router-dom";
import { schedulerPageSocialMediaListing } from "../../../config/socialmediaConfig";
import IndivSchedulerSocialMediaCom from "../../Utils/SocialMediaListingTemplates/IndivSchedulerSocialMediaCom";

const SchedulerListingOtherSocialMedia = () => {
     const parmas = useParams();
     const socialmedianame = parmas.socialmedianame;
     let array = schedulerPageSocialMediaListing.filter(
          (item) => item.link !== socialmedianame
     );

     return (
          <div className=" w-full flex flex-col gap-[1rem] mb-[1rem]">
               <h1 className=" text-[#172B70] font-bold text-[20px] font-montserrat ps-6 ">
                    Navigate to Other Scheduling Platforms:
               </h1>

               <div className=" w-full h-fit flex flex-row flex-wrap justify-start gap-[1rem] ps-6">
                    {array.map((media) => {
                         return <IndivSchedulerSocialMediaCom media={media} />;
                    })}
               </div>
          </div>
     );
};

export default SchedulerListingOtherSocialMedia;
