import React from "react";
import SocialMediaLogo from "../../../basicultils/SocialMediaLogo";

import ButtonComponent from "../../../basicultils/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../config/mainConfig";

const EmptyMentionSection = ({ content = {}, loginDetails = {} }) => {
     const navigate = useNavigate();

     const mainText = `You don’t have any ${content?.name} mentions`;
     const buttonText = `add your ${content?.name} Account`;

     let searchType = content?.searchType;
     // console.log(searchType)

     return (
          <div className=" w-full h-full flex flex-col justify-center gap-[0.5rem]">
               <div className="w-full h-min flex flex-row justify-center ">
                    <SocialMediaLogo
                         maincss="lg:w-[50px] lg:h-[50px] w-[35px] h-[35px] "
                         content={content}
                    />
               </div>
               <h1 className=" font-montserrat lg:text-[16px] text-[12px] capitalize font-[600] text-center">
                    {mainText}
               </h1>
               <div className=" w-full flex flex-row justify-center">
                    {!loginDetails?.isAccounted && (
                         <ButtonComponent
                              onClick={() => {
                                   let searchParmas = "";
                                   if (searchType) {
                                        searchParmas = "?type=" + searchType;
                                   }
                                   navigate(
                                        routes.dynamcisocialmediaaddaccount +
                                             searchParmas
                                   );
                              }}
                              className={
                                   "lg:w-[65%] md:w-[70%] w-[85%] h-[35px] ps-2 pe-2 pt-2 pb-2 capitalize border-2 bg-[#FFEAEA] rounded-sm  "
                              }
                              isLoading={false}
                         >
                              <p className="lg:text-[12px] text-[10px] font-[500] font-montserrat">
                                   {buttonText}
                              </p>
                         </ButtonComponent>
                    )}
               </div>
          </div>
     );
};

export default EmptyMentionSection;
