import React from "react";
import InputComponent from "../../basicultils/InputComponent";
import ButtonComponent from "../../basicultils/ButtonComponent";
const EnterEmailInput = () => {
     return (
          <div className=" xl:w-[70%] md:w-[90%] w-full h-fit relative">
               <InputComponent
                    type="text"
                    placeholder="Enter Something"
                    value=""
                    className=" w-full md:h-[66px] h-[50px] font-inter font-[500] md:text-[16px] text-[14px]  ps-3 rounded-[6px] border-2 "
                    onChange={() => {}}
               />

               <div className=" w-fit h-full flex flex-col justify-center items-center absolute bottom-0 right-2">
                    <ButtonComponent
                         onClick={() => {}}
                         className=" md:w-[136px] w-[100px] md:h-[50px]  h-[40px] bg-mainRectangeGredient rounded-[6px] font-poppins  md:text-[14px] text-[12px] text-[#fff] font-[600]"
                    >
                         Get a Demo
                    </ButtonComponent>
               </div>
          </div>
     );
};

export default EnterEmailInput;
