import React from "react";

const GoogleSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="15"
               height="15"
               viewBox="0 0 15 15"
               fill="none"
          >
               <g clip-path="url(#clip0_203_3028)">
                    <path
                         d="M3.29164 6.10313C3.58407 5.2184 4.14843 4.44863 4.90421 3.90362C5.66 3.35862 6.5686 3.06621 7.50039 3.06813C8.55664 3.06813 9.51164 3.44313 10.2616 4.05688L12.4441 1.875C11.1141 0.715625 9.40976 0 7.50039 0C4.54414 0 1.99914 1.68625 0.775391 4.15625L3.29164 6.10313Z"
                         fill="#EA4335"
                    />
                    <path
                         d="M10.0253 11.2586C9.34407 11.698 8.47906 11.9323 7.50031 11.9323C6.57223 11.9342 5.66707 11.6442 4.91296 11.1032C4.15886 10.5622 3.59403 9.79772 3.29844 8.91797L0.773438 10.8348C1.39297 12.0888 2.35173 13.1438 3.54083 13.8802C4.72992 14.6165 6.10169 15.0047 7.50031 15.0005C9.33344 15.0005 11.0847 14.3486 12.3966 13.1255L10.0259 11.2586H10.0253Z"
                         fill="#34A853"
                    />
                    <path
                         d="M12.3962 13.1255C13.7681 11.8455 14.6587 9.94047 14.6587 7.50047C14.6587 7.05672 14.5906 6.57984 14.4887 6.13672H7.5V9.03484H11.5225C11.3244 10.0092 10.7912 10.7636 10.0256 11.2586L12.3962 13.1255Z"
                         fill="#4A90E2"
                    />
                    <path
                         d="M3.2982 8.9175C3.14529 8.46057 3.06762 7.98184 3.0682 7.5C3.0682 7.01125 3.14632 6.54188 3.29132 6.10313L0.775073 4.15625C0.260309 5.19543 -0.00504996 6.34032 7.27841e-05 7.5C7.27841e-05 8.7 0.278198 9.83125 0.773198 10.8344L3.2982 8.9175Z"
                         fill="#FBBC05"
                    />
               </g>
               <defs>
                    <clipPath id="clip0_203_3028">
                         <rect width="15" height="15" fill="white" />
                    </clipPath>
               </defs>
          </svg>
     );
};

export default GoogleSVG;
