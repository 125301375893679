import { useSelector } from "react-redux";

// UseGetAccountDetails Custom Hook is used to get the auth status and auth obj of social media.

// This hook can return the auth status and auth obj of the currently selected social media on review dashboard.
// Similarly, this hook can also return the auth status and auth obj of the currently selected social media on scheduler dashboard. ( Simple )

// Additional, you can pass the social media name and check about the auth status and auth obj of that passed social media.

export const useGetAccountDetails_getDetailsFrom_modes = {
     review: "Review",
     schedule: "Schedule",
     custom: "Custom",
};

// This Hook return the array.
// Index 0 of the array has the "getDetailsFrom" function

// Array [0] .
// The getDetailsFrom function accepts two arguments :

// Argument One : pass the mode -> There are three mode that you can use.
// In above, you can import that obj (useGetAccountDetails_getDetailsFrom_modes) in above and pass the mode you want.

// review mode -> to get the auth status and auth obj of the currently selected social media
// scheduler mode -> to get the auth status and auth obj of the currently selected social media
// custom mode -> to get the auth status and auth obj of the passed social media name -> ( argument two )

// Argument two : only for custom mode -> pass the social media name

const UseGetAccountDetails = () => {
     const { selectedSocialMedia } = useSelector((state) => {
          return state.dynamicsocialmedia;
     });

     const scheduler = useSelector((state) => {
          return state.scheduler;
     });

     const masterAccounts = useSelector((state) => {
          return state?.master?.account;
     });

     const getDetailsFrom = (mode, passedSocialMedia = "") => {
          let obj = null;
          switch (mode) {
               // using switch case statement for handling modes :
               // Review mode ->
               case useGetAccountDetails_getDetailsFrom_modes.review: {
                    obj = masterAccounts[selectedSocialMedia.link];
                    break;
               }
               // Scheduler mode
               case useGetAccountDetails_getDetailsFrom_modes.schedule: {
                    console.log(mode);
                    obj = masterAccounts[scheduler?.selectedSocialMedia?.link];
                    break;
               }
               // Custom mode
               case useGetAccountDetails_getDetailsFrom_modes.custom: {
                    obj = masterAccounts[passedSocialMedia];
                    break;
               }
               default: {
                    console.log(mode);
                    break;
               }
          }

          if (!obj) return null;
          return obj;
     };

     //let obj = masterAccounts[selectedSocialMedia.link];

     return [getDetailsFrom];
};

export default UseGetAccountDetails;
