import React from "react";

const WorkSpace = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="25"
               height="25"
               viewBox="0 0 25 25"
               fill="none"
          >
               <path
                    d="M12.5 13.2812V19.5312H4.6875V13.2812H12.5ZM12.5 11.7188H4.6875C4.2731 11.7188 3.87567 11.8834 3.58265 12.1764C3.28962 12.4694 3.125 12.8668 3.125 13.2812V19.5312C3.125 19.9457 3.28962 20.3431 3.58265 20.6361C3.87567 20.9291 4.2731 21.0938 4.6875 21.0938H12.5C12.9144 21.0938 13.3118 20.9291 13.6049 20.6361C13.8979 20.3431 14.0625 19.9457 14.0625 19.5312V13.2812C14.0625 12.8668 13.8979 12.4694 13.6049 12.1764C13.3118 11.8834 12.9144 11.7188 12.5 11.7188ZM21.0938 4.6875V8.59375H13.2812V4.6875H21.0938ZM21.0938 3.125H13.2812C12.8668 3.125 12.4694 3.28962 12.1764 3.58265C11.8834 3.87567 11.7188 4.2731 11.7188 4.6875V8.59375C11.7188 9.00815 11.8834 9.40558 12.1764 9.6986C12.4694 9.99163 12.8668 10.1562 13.2812 10.1562H21.0938C21.5082 10.1562 21.9056 9.99163 22.1986 9.6986C22.4916 9.40558 22.6562 9.00815 22.6562 8.59375V4.6875C22.6562 4.2731 22.4916 3.87567 22.1986 3.58265C21.9056 3.28962 21.5082 3.125 21.0938 3.125ZM21.0938 13.2812V17.1875H17.1875V13.2812H21.0938ZM21.0938 11.7188H17.1875C16.7731 11.7188 16.3757 11.8834 16.0826 12.1764C15.7896 12.4694 15.625 12.8668 15.625 13.2812V17.1875C15.625 17.6019 15.7896 17.9993 16.0826 18.2924C16.3757 18.5854 16.7731 18.75 17.1875 18.75H21.0938C21.5082 18.75 21.9056 18.5854 22.1986 18.2924C22.4916 17.9993 22.6562 17.6019 22.6562 17.1875V13.2812C22.6562 12.8668 22.4916 12.4694 22.1986 12.1764C21.9056 11.8834 21.5082 11.7188 21.0938 11.7188ZM8.59375 4.6875V8.59375H4.6875V4.6875H8.59375ZM8.59375 3.125H4.6875C4.2731 3.125 3.87567 3.28962 3.58265 3.58265C3.28962 3.87567 3.125 4.2731 3.125 4.6875V8.59375C3.125 9.00815 3.28962 9.40558 3.58265 9.6986C3.87567 9.99163 4.2731 10.1562 4.6875 10.1562H8.59375C9.00815 10.1562 9.40558 9.99163 9.6986 9.6986C9.99163 9.40558 10.1562 9.00815 10.1562 8.59375V4.6875C10.1562 4.2731 9.99163 3.87567 9.6986 3.58265C9.40558 3.28962 9.00815 3.125 8.59375 3.125Z"
                    fill="white"
               />
          </svg>
     );
};

export default WorkSpace;
