import React from "react";
import NavBarLandingPage from "../../landingPage/NavBar/NavBarLandingPage";
import TenthSection from "../../landingPage/TenthSectionLandingpage/TenthSection";

const TermsofService = () => {
     return (
          <>
               <NavBarLandingPage />
               <div className=" w-full h-fit md:p-8  p-4">
                    <h1 className=" text-[20px] font-[500] mb-2">
                         Terms of Service
                    </h1>
                    <p className=" mt-2 ">Last updated: February 28, 2024</p>
                    <p className=" mt-2 mb-4  ">
                         Please read these terms of service carefully before
                         using Our Service.
                    </p>
                    <h1 className=" text-[20px] font-[500] mb-2">
                         Interpretation and Definitions
                    </h1>
                    <p className=" font-[500] mb-2">Interpretation</p>
                    <p>
                         The words of which the initial letter is capitalized
                         have meanings defined under the following conditions.
                         The following definitions shall have the same meaning
                         regardless of whether they appear in singular or in
                         plural.
                    </p>
                    <p className=" font-[500] mb-2 mt-4">Definitions</p>
                    <p>For the purposes of these terms of service:</p>
                    <ul className=" list-disc md:ps-8 ps-4 ">
                         <li className=" mt-2 ">
                              <p>
                                   Affiliate means an entity that controls, is
                                   controlled by or is under common control with
                                   a party, where "control" means ownership of
                                   50% or more of the shares, equity interest or
                                   other securities entitled to vote for
                                   election of directors or other managing
                                   authority.
                              </p>
                         </li>
                         <li className=" mt-2 ">
                              <p>Country refers to: India</p>
                         </li>
                         <li className=" mt-2 ">
                              <p>
                                   Company (referred to as either "the Company",
                                   "We", "Us" or "Our" in this Agreement) refers
                                   to Ranblitz Global Private Limited,
                                   Chhatarpur, Madhya Pradesh.
                              </p>
                         </li>
                         <li className=" mt-2 ">
                              <p>
                                   Device means any device that can access the
                                   Service such as a computer, a cellphone or a
                                   digital tablet.
                              </p>
                         </li>
                         <li className=" mt-2 ">
                              <p>Service refers to the Website.</p>
                         </li>
                         <li className=" mt-2 ">
                              <p>
                                   Terms of service (also referred as "Terms")
                                   mean these Terms of service that form the
                                   entire agreement between You and the Company
                                   regarding the use of the Service.{" "}
                              </p>
                         </li>
                         <li className=" mt-2 ">
                              <p>
                                   Third-party Social Media Service means any
                                   services or content (including data,
                                   information, products or services) provided
                                   by a third-party that may be displayed,
                                   included or made available by the Service.
                              </p>
                         </li>
                         <li className=" mt-2 ">
                              <p>
                                   Website refers to Ranblitz, accessible from
                                   https://ranblitz.com/
                              </p>
                         </li>
                         <li className=" mt-2 ">
                              <p>
                                   You means the individual accessing or using
                                   the Service, or the company, or other legal
                                   entity on behalf of which such individual is
                                   accessing or using the Service, as
                                   applicable.
                              </p>
                         </li>
                    </ul>
                    <h1 className=" font-[500] text-[20px] mb-2 mt-4">
                         Acknowledgment
                    </h1>
                    <p className=" mb-2">
                         These are the Terms of service governing the use of
                         this Service and the agreement that operates between
                         You and the Company. These Terms of service set out the
                         rights and obligations of all users regarding the use
                         of the Service.
                    </p>
                    <p className=" mb-2">
                         Your access to and use of the Service is conditioned on
                         Your acceptance of and compliance with these Terms of
                         service. These Terms of service apply to all visitors,
                         users and others who access or use the Service.
                    </p>
                    <p className=" mb-2">
                         By accessing or using the Service You agree to be bound
                         by these Terms of service. If You disagree with any
                         part of these Terms of service then You may not access
                         the Service.
                    </p>
                    <p className=" mb-2">
                         You represent that you are over the age of 18. The
                         Company does not permit those under 18 to use the
                         Service.
                    </p>
                    <p className=" mb-2">
                         Your access to and use of the Service is also
                         conditioned on Your acceptance of and compliance with
                         the Privacy Policy of the Company. Our Privacy Policy
                         describes Our policies and procedures on the
                         collection, use and disclosure of Your personal
                         information when You use the Application or the Website
                         and tells You about Your privacy rights and how the law
                         protects You. Please read Our Privacy Policy carefully
                         before using Our Service.
                    </p>
                    <h1 className=" font-[500] text-[20px] mb-2 mt-4">
                         Links to Other Websites
                    </h1>
                    <p className=" mb-2">
                         Our Service may contain links to third-party web sites
                         or services that are not owned or controlled by the
                         Company.
                    </p>
                    <p className=" mb-2">
                         The Company has no control over, and assumes no
                         responsibility for, the content, privacy policies, or
                         practices of any third party web sites or services. You
                         further acknowledge and agree that the Company shall
                         not be responsible or liable, directly or indirectly,
                         for any damage or loss caused or alleged to be caused
                         by or in connection with the use of or reliance on any
                         such content, goods or services available on or through
                         any such web sites or services.
                    </p>
                    <p className=" mb-2">
                         We strongly advise You to read the terms of service and
                         privacy policies of any third-party web sites or
                         services that You visit.
                    </p>
                    <h1 className=" font-[500] text-[20px] mb-2 mt-4">
                         Termination
                    </h1>
                    <p className=" mb-2">
                         We may terminate or suspend Your access immediately,
                         without prior notice or liability, for any reason
                         whatsoever, including without limitation if You breach
                         these Terms of service.
                    </p>
                    <p className=" mb-2">
                         Upon termination, Your right to use the Service will
                         cease immediately.
                    </p>
                    <h1 className=" font-[500] text-[20px] mb-2 mt-4">
                         Limitation of Liability
                    </h1>
                    <p className=" mb-2">
                         Notwithstanding any damages that You might incur, the
                         entire liability of the Company and any of its
                         suppliers under any provision of this Terms and Your
                         exclusive remedy for all of the foregoing shall be
                         limited to the amount actually paid by You through the
                         Service or 100 USD if You haven't purchased anything
                         through the Service.
                    </p>
                    <p className=" mb-2">
                         To the maximum extent permitted by applicable law, in
                         no event shall the Company or its suppliers be liable
                         for any special, incidental, indirect, or consequential
                         damages whatsoever (including, but not limited to,
                         damages for loss of profits, loss of data or other
                         information, for business interruption, for personal
                         injury, loss of privacy arising out of or in any way
                         related to the use of or inability to use the Service,
                         third-party software and/or third-party hardware used
                         with the Service, or otherwise in connection with any
                         provision of this Terms), even if the Company or any
                         supplier has been advised of the possibility of such
                         damages and even if the remedy fails of its essential
                         purpose.
                    </p>
                    <p className=" mb-2">
                         Some states do not allow the exclusion of implied
                         warranties or limitation of liability for incidental or
                         consequential damages, which means that some of the
                         above limitations may not apply. In these states, each
                         party's liability will be limited to the greatest
                         extent permitted by law.
                    </p>
                    <h1 className=" font-[500] text-[20px] mb-2 mt-4">
                         "AS IS" and "AS AVAILABLE" Disclaimer
                    </h1>
                    <p className=" mb-2">
                         The Service is provided to You "AS IS" and "AS
                         AVAILABLE" and with all faults and defects without
                         warranty of any kind. To the maximum extent permitted
                         under applicable law, the Company, on its own behalf
                         and on behalf of its Affiliates and its and their
                         respective licensors and service providers, expressly
                         disclaims all warranties, whether express, implied,
                         statutory or otherwise, with respect to the Service,
                         including all implied warranties of merchantability,
                         fitness for a particular purpose, title and
                         non-infringement, and warranties that may arise out of
                         course of dealing, course of performance, usage or
                         trade practice. Without limitation to the foregoing,
                         the Company provides no warranty or undertaking, and
                         makes no representation of any kind that the Service
                         will meet Your requirements, achieve any intended
                         results, be compatible or work with any other software,
                         applications, systems or services, operate without
                         interruption, meet any performance or reliability
                         standards or be error free or that any errors or
                         defects can or will be corrected.
                    </p>
                    <p className=" mb-2">
                         Without limiting the foregoing, neither the Company nor
                         any of the company's provider makes any representation
                         or warranty of any kind, express or implied: (i) as to
                         the operation or availability of the Service, or the
                         information, content, and materials or products
                         included thereon; (ii) that the Service will be
                         uninterrupted or error-free; (iii) as to the accuracy,
                         reliability, or currency of any information or content
                         provided through the Service; or (iv) that the Service,
                         its servers, the content, or e-mails sent from or on
                         behalf of the Company are free of viruses, scripts,
                         trojan horses, worms, malware, timebombs or other
                         harmful components.
                    </p>
                    <p className=" mb-2">
                         Some jurisdictions do not allow the exclusion of
                         certain types of warranties or limitations on
                         applicable statutory rights of a consumer, so some or
                         all of the above exclusions and limitations may not
                         apply to You. But in such a case the exclusions and
                         limitations set forth in this section shall be applied
                         to the greatest extent enforceable under applicable
                         law.
                    </p>
                    <h1 className=" font-[500] text-[20px] mb-2 mt-4">
                         Governing Law
                    </h1>
                    <p className=" mb-2">
                         The laws of the Country, excluding its conflicts of law
                         rules, shall govern this Terms and Your use of the
                         Service. Your use of the Application may also be
                         subject to other local, state, national, or
                         international laws.
                    </p>
                    <h1 className=" font-[500] text-[20px] mb-2 mt-4">
                         Disputes Resolution
                    </h1>
                    <p className=" mb-2">
                         If You have any concern or dispute about the Service,
                         You agree to first try to resolve the dispute
                         informally by contacting the Company.
                    </p>
                    <h1 className=" font-[500] text-[20px] mb-2 mt-4">
                         Severability and Waiver
                    </h1>
                    <p className=" mb-2 font-[500]">Severability</p>
                    <p className=" mb-2">
                         If any provision of these Terms is held to be
                         unenforceable or invalid, such provision will be
                         changed and interpreted to accomplish the objectives of
                         such provision to the greatest extent possible under
                         applicable law and the remaining provisions will
                         continue in full force and effect.
                    </p>
                    <p className=" mb-2 font-[500]">Waiver</p>
                    <p className=" mb-2">
                         Except as provided herein, the failure to exercise a
                         right or to require performance of an obligation under
                         these Terms shall not affect a party's ability to
                         exercise such right or require such performance at any
                         time thereafter nor shall the waiver of a breach
                         constitute a waiver of any subsequent breach.
                    </p>
                    <h1 className=" font-[500] text-[20px] mb-2 mt-4">
                         Translation Interpretation
                    </h1>
                    <p className=" mb-2">
                         These Terms of service may have been translated if We
                         have made them available to You on our Service. You
                         agree that the original English text shall prevail in
                         the case of a dispute.
                    </p>
                    <h1 className=" font-[500] text-[20px] mb-2 mt-4">
                         Changes to These Terms of service
                    </h1>
                    <p className=" mb-2">
                         We reserve the right, at Our sole discretion, to modify
                         or replace these Terms at any time. If a revision is
                         material We will make reasonable efforts to provide at
                         least 30 days' notice prior to any new terms taking
                         effect. What constitutes a material change will be
                         determined at Our sole discretion.
                    </p>{" "}
                    <p className=" mb-2">
                         By continuing to access or use Our Service after those
                         revisions become effective, You agree to be bound by
                         the revised terms. If You do not agree to the new
                         terms, in whole or in part, please stop using the
                         website and the Service.
                    </p>
                    <h1 className=" font-[500] text-[20px] mb-2 mt-4">
                         Contact Us
                    </h1>
                    <p className=" mb-2">
                         If you have any questions about these Terms of service,
                         You can contact us:
                    </p>
                    <ul className=" list-disc md:ps-8 ps-4 ">
                         <li className=" mt-2 ">
                              <p>By email: dev@ranblitz.com</p>
                         </li>
                    </ul>
               </div>
               <TenthSection />
          </>
     );
};

export default TermsofService;
