import React from "react";

const ProfileInformationLayout = ({ title, children }) => {
     return (
          <div className=" md:w-[396px] w-full h-[178px] flex flex-col  rounded-[8px] border-2 border-[#E1F2FF]">
               <div className=" w-full h-[54px] border-b-2 border-[#ECECEC] flex flex-col justify-center ps-4">
                    <h1 className=" text-[16px] font-montserrat text-[#17173A] font-bold">
                         {title}
                    </h1>
               </div>

               <div className="  flex-1">{children}</div>
          </div>
     );
};

export default ProfileInformationLayout;
