import React from "react";

const TickAndXmark = ({ value = false }) => {
     return (
          <div
               className={` w-[32px] h-[32px] flex flex-row justify-center items-center  rounded-[50%] ${
                    value ? "bg-[#FFE6E6]" : " bg-[#F7F8F9]"
               } `}
          >
               {value ? (
                    <svg
                         width="16"
                         height="16"
                         viewBox="0 0 16 16"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                         <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.8162 4.20701C14.0701 4.47369 14.0597 4.89568 13.793 5.14954L6.08929 12.4829C5.95773 12.6081 5.78078 12.6742 5.59933 12.666C5.41788 12.6577 5.24766 12.5758 5.12803 12.4391L2.16506 9.05451C1.92254 8.77747 1.95052 8.35629 2.22755 8.11377C2.50459 7.87125 2.92577 7.89923 3.16829 8.17626L5.67342 11.0379L12.8737 4.1838C13.1404 3.92994 13.5624 3.94033 13.8162 4.20701Z"
                              fill="url(#paint0_linear_2158_7666)"
                         />
                         <defs>
                              <linearGradient
                                   id="paint0_linear_2158_7666"
                                   x1="8"
                                   y1="4"
                                   x2="8"
                                   y2="12.6667"
                                   gradientUnits="userSpaceOnUse"
                              >
                                   <stop stop-color="#F17A01" />
                                   <stop offset="1" stop-color="#E80757" />
                              </linearGradient>
                         </defs>
                    </svg>
               ) : (
                    <svg
                         width="10"
                         height="10"
                         viewBox="0 0 10 10"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                    >
                         <path
                              d="M9.24304 9.24304C9.50339 8.98269 9.50339 8.56058 9.24304 8.30023L5.94367 5.00086L9.24395 1.70057C9.5043 1.44022 9.5043 1.01811 9.24395 0.757764C8.9836 0.497414 8.56149 0.497414 8.30114 0.757763L5.00086 4.05805L1.70057 0.757762C1.44022 0.497413 1.01811 0.497413 0.757762 0.757762C0.497413 1.01811 0.497413 1.44022 0.757762 1.70057L4.05805 5.00086L0.758667 8.30024C0.498318 8.56058 0.498318 8.9827 0.758668 9.24304C1.01902 9.50339 1.44113 9.50339 1.70148 9.24305L5.00086 5.94367L8.30023 9.24304C8.56058 9.50339 8.98269 9.50339 9.24304 9.24304Z"
                              fill="#191D23"
                         />
                    </svg>
               )}
          </div>
     );
};

export default TickAndXmark;
