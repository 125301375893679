import React from "react";

const CheckOff = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="20"
               height="20"
               viewBox="0 0 20 20"
               fill="none"
          >
               <rect
                    x="0.75"
                    y="0.75"
                    width="18.5"
                    height="18.5"
                    rx="2.25"
                    stroke="#A5ADD7"
                    stroke-width="1.5"
               />
          </svg>
     );
};

export default CheckOff;
