import React from "react";

const SchedulerWeekHeaderRow = ({
     day = "Sunday 07/10",
     task = "1 Task(s)",
}) => {
     return (
          <div
               className=" w-[235px] h-[86px] border-2 flex flex-row justify-center items-center
"
          >
               <div className=" w-full h-fit flex flex-col items-center justify-center font-poppins ">
                    <h1 className="text-[18px] font-[400]">{day}</h1>
                    <p className="text-[12px]">{task}</p>
               </div>
          </div>
     );
};

const SchedulerWeekGridViewHeader = () => {
     return (
          <div className=" w-full flex flex-row ">
               <div className=" w-[235px] h-[86px] border-2"></div>
               <SchedulerWeekHeaderRow day="Sunday 07/10" task="1 Task(s)" />
               <SchedulerWeekHeaderRow day="Monday 07/10" task="1 Task(s)" />

               <SchedulerWeekHeaderRow day="Tuesday 07/11" task="1 Task(s)" />
               <SchedulerWeekHeaderRow day="Wednesday 07/12" task="1 Task(s)" />

               <SchedulerWeekHeaderRow day="Thursday 07/13" task="1 Task(s)" />
               <SchedulerWeekHeaderRow day="Friday 07/14" task="1 Task(s)" />

               <SchedulerWeekHeaderRow day="Saturday 07/15" task="1 Task(s)" />
               {/* <div className=" w-[285px] h-[86px] border-2"></div> */}
          </div>
     );
};

export default SchedulerWeekGridViewHeader;
