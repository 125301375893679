import React from "react";

const DeleteSVG = () => {
     return (
          <div>
               <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="16"
                    viewBox="0 0 17 16"
                    fill="none"
               >
                    <path
                         d="M4.13891 12.6667C4.13891 13.4 4.75963 14 5.51829 14H11.0358C11.7945 14 12.4152 13.4 12.4152 12.6667V4.66667H4.13891V12.6667ZM5.51829 6H11.0358V12.6667H5.51829V6ZM10.691 2.66667L10.0013 2H6.55282L5.86313 2.66667H3.44922V4H13.1049V2.66667H10.691Z"
                         fill="#B9C8DA"
                    />
               </svg>
          </div>
     );
};

export default DeleteSVG;
