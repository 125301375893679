import React from "react";
import ButtonComponent from "../../../../basicultils/ButtonComponent";

const FillButtonMention = ({
     onClick = () => {},
     isLoading = () => {},
     children,
}) => {
     return (
          <ButtonComponent
               className="w-[35%] h-[30px] rounded-[5px] text-ignoreButtonTextColor capitalize text-[11px] font-[500] font-montserrat bg-[#E1E7ED] "
               onClick={onClick}
               isLoading={false}
          >
               {children}
          </ButtonComponent>
     );
};

export default FillButtonMention;
