import React from "react";

const IndivWallContentCard = ({ children, isDummy = false }) => {
     return (
          <div
               className={` xl:w-[30%] lg:w-[45%] w-[90%] lg:h-[300px] h-[250px] flex flex-col ${
                    isDummy
                         ? ""
                         : " border-[#E1F2FF] border-2 rounded-[4px] shadow-wallBoxShadow"
               } `}
          >
               {children}
          </div>
     );
};

export default IndivWallContentCard;
