import React from "react";

// Layout and UI
import VideoContentLeftIndivRow from "./VideoContentLeftIndivRow";
import EmptyMentionLayout from "../../../UI/EmptyMentionLayout";

// Hooks
import { useNavigate } from "react-router-dom";

// Config
import { routes } from "../../../../../config/mainConfig";

const VideoContentListing = ({ content = [] }) => {
     const navigate = useNavigate();

     return (
          <div
               className={` w-full h-full flex flex-col flex-shrink-0 items-center  ${
                    content?.length === 0
                         ? "h-full"
                         : "h-fit gap-[0.5rem] mt-[0.5rem] pb-[3rem]"
               } `}
          >
               {content?.length === 0 ? (
                    <EmptyMentionLayout
                         mainText="You don't have a video"
                         buttonText="Create Video"
                         onClick={() => {
                              // Navigating add video page
                              navigate(routes.videoforms);
                         }}
                    />
               ) : (
                    content.map((item, index) => {
                         return (
                              <VideoContentLeftIndivRow
                                   content={item}
                                   index={index}
                              />
                         );
                    })
               )}
          </div>
     );
};

export default VideoContentListing;
