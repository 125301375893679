import React from "react";
import IndivContactLeftPage from "./IndivContactLeftPage";

// Layout and UI
import EmptyMentionLayout from "../../../UI/EmptyMentionLayout";

// Hooks
import { useNavigate } from "react-router-dom";

// Config
import { routes } from "../../../../../config/mainConfig";

const ContactLeftListingPage = () => {
     const navigate = useNavigate();

     return (
          <div className=" w-full h-full flex flex-col justify-center flex-shrink-0 items-center gap-[0.5rem] mt-[0.5rem] pb-[3rem] overflow-auto">
               {/* <IndivContactLeftPage />
               <IndivContactLeftPage />
               <IndivContactLeftPage />
               <IndivContactLeftPage />
               <IndivContactLeftPage />
               <IndivContactLeftPage />
               <IndivContactLeftPage />
               <IndivContactLeftPage />
               <IndivContactLeftPage />
               <IndivContactLeftPage />
               <IndivContactLeftPage /> */}

               <EmptyMentionLayout
                    mainText="You don't have forms"
                    buttonText="Connect Forms"
                    onClick={() => {
                         navigate(routes.leadformsdocs);
                    }}
               />
          </div>
     );
};

export default ContactLeftListingPage;
