import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { Toaster } from "react-hot-toast";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
     <GoogleOAuthProvider clientId="909947155378-q2k3p7rnhrmc4vs4svdth7t94b26q9rr.apps.googleusercontent.com">
          <React.StrictMode>
               <Provider store={store}>
                    <Toaster />
                    <App />
               </Provider>
          </React.StrictMode>
     </GoogleOAuthProvider>
);
