import React from "react";
import WallHeaderSection from "./WallHeaderSection";

import EmptyWallContent from "./EmptyWallContent";
import ListWallContent from "./ListWallContent";
import { useSelector } from "react-redux";

const WallLayout = ({ isBigScreen = false }) => {
     const { pinnedReviewList } = useSelector((state) => {
          return state.dynamicsocialmedia;
     });

     return (
          <div className=" w-full h-full flex flex-col ">
               <WallHeaderSection isBigScreen={isBigScreen} />
               <div className="flex-1 overflow-hidden">
                    {pinnedReviewList.length > 0 ? (
                         <ListWallContent content={pinnedReviewList} />
                    ) : (
                         <EmptyWallContent
                              h1="Your Wall is Empty."
                              p={"Add something now!"}
                         />
                    )}
               </div>
          </div>
     );
};

export default WallLayout;
