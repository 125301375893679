import React from "react";
import ButtonComponent from "../../../../basicultils/ButtonComponent";
import EditSVG from "../../../templates/Svg/ContactForm/EditSVG";
import DeleteSVG from "../../../templates/Svg/DeleteSVG";
import { useDispatch } from "react-redux";
import { contactFormModes } from "../../../../config/ContactFormConfig";
import { contactFormSliceActions } from "../../../../store/contactFormSlice";

const EditAndSaveButton = () => {
     const dispatch = useDispatch();
     const editButtonClickHandler = () => {
          dispatch(
               contactFormSliceActions.updateModeForm(
                    contactFormModes.form.edit
               )
          );
     };
     const deleteButtonClickHandler = () => {
          dispatch(
               contactFormSliceActions.updateModeDelete(
                    contactFormModes.delete.deleting
               )
          );
     };
     return (
          <div className=" w-full h-[35px] flex flex-row justify-evenly">
               <div></div>
               <div></div>
               <div className=" flex flex-row gap-4">
                    <ButtonComponent
                         className=" w-[61px] h-[23px] border-[2px] rounded-[2px] border-mentionButtoncolor "
                         onClick={editButtonClickHandler}
                    >
                         <div className=" w-full h-full flex flex-row justify-evenly items-center text-mentionButtoncolor text-[10px] font-montserrat ">
                              <EditSVG />
                              Edit
                         </div>
                    </ButtonComponent>
                    <ButtonComponent
                         className=" w-[72px] h-[23px] border-[2px] rounded-[2px] border-mentionButtoncolor "
                         onClick={deleteButtonClickHandler}
                    >
                         <div className=" w-full h-full flex flex-row justify-evenly items-center text-mentionButtoncolor text-[10px] font-montserrat ">
                              <DeleteSVG />
                              Delete
                         </div>
                    </ButtonComponent>
               </div>
          </div>
     );
};

export default EditAndSaveButton;
