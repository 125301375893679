import React from "react";

// UI
import ButtonComponent from "../../../../../basicultils/ButtonComponent";
import { useSelector } from "react-redux";

let formAPI_endPoint = "https://apps.ranblitz.com:9093/api/v1/forms/create";

const FormsContactDocsLayout = () => {
     let { auth } = useSelector((state) => {
          return state.master;
     });
     return (
          <div className=" w-full h-full p-4 overflow-auto">
               <h1 className=" text-[20px] font-montserrat font-bold text-[#172B70]">
                    Steps to Connect your Website with Ranblitz Account for
                    Forms
               </h1>{" "}
               <div className=" w-fit flex flex-col gap-[1rem] mt-[2rem]">
                    <div className=" font-montserrat text-[14px] text-[#2F407C]">
                         Endpoint URL: {"   "}
                         {formAPI_endPoint}
                    </div>
                    <div className=" font-montserrat text-[14px] text-[#2F407C] ">
                         Method: POST
                    </div>
                    <div className=" font-montserrat text-[14px] text-[#2F407C] ">
                         Authorization: {auth?.access}
                    </div>
                    <div className=" font-montserrat text-[14px] text-[#2F407C] ">
                         Content-Type: application/json
                    </div>
                    <div className=" font-montserrat text-[14px] text-[#2F407C] ">
                         Body: JSON object with form details
                    </div>
               </div>
               <h1 className=" text-[20px] font-montserrat font-bold text-[#172B70] mt-[3rem]">
                    Authentication Setup :
               </h1>
               <p className=" font-montserrat text-[14px] text-[#2F407C] mt-[2rem]">
                    Obtain an API key to authenticate requests. The API key
                    should be included in the Authorization header of each
                    request.
               </p>
               <div className=" font-montserrat text-[14px] font-[700] text-[#2F407C]  mt-[2rem]">
                    Request Formation:
               </div>
               <div className=" mt-[2.5rem] w-[500px] h-fit rounded-[8px] border-2 p-4 bg-[#2E3B43] relative">
                    <ButtonComponent
                         onClick={() => {
                              navigator.clipboard
                                   .writeText(`curl --location 'http://13.51.197.126:8080/api/v1/users/login' \
                      --header 'Content-Type: application/json' \
                      --data '{
                          "username": "username",
                          "password": "password"
                      }'`);
                         }}
                         className=" right-3 bottom-3 absolute w-[100px] h-[30px] rounded-[10px] bg-mainRectangeGredient text-[14px] text-[#fff] font-[600]  "
                    >
                         Copy
                    </ButtonComponent>

                    <div className=" w-full h-fit flex flex-col gap-[0.75rem]">
                         <div className=" flex flex-row gap-[1rem] font-[600] text-[#fff]">
                              <span>Curl</span>
                              <span className="text-[#A5A6F6] font-[600]">
                                   --Request POST \
                              </span>
                         </div>

                         <div className=" ps-[2rem] flex flex-row gap-[1rem]">
                              <h1 className="text-[#A5A6F6] font-[600]">
                                   --location
                              </h1>
                              <h4 className="font-[600] text-[#fff]">
                                   'http://13.51.197.126:8080/api/v1/users/login'
                              </h4>
                         </div>
                         <div className=" ps-[4rem] flex flex-row gap-[1rem]">
                              <h1 className="text-[#A5A6F6] font-[600]">
                                   --header
                              </h1>
                              <h4 className="font-[600] text-[#FFCA00]">
                                   'Content-Type: application/json'
                              </h4>
                         </div>

                         <div className=" ps-[4rem] flex flex-row gap-[1rem]">
                              <h1 className="text-[#A5A6F6] font-[600]">
                                   {"  --data {` "}
                              </h1>
                         </div>
                         <div className=" ps-[8rem] flex flex-row gap-[1rem]">
                              <h4 className="font-[600] text-[#FFCA00]">
                                   "username": "username",
                              </h4>
                         </div>
                         <div className=" ps-[8rem] flex flex-row gap-[1rem]">
                              <h4 className="font-[600] text-[#FFCA00]">
                                   {` "password": "password" '} `}
                              </h4>
                         </div>
                    </div>
               </div>
               {/* paragrap  */}
               <p className=" font-montserrat text-[14px] text-[#2F407C] mt-[2rem]">
                    Prepare a POST request to the specified endpoint with the
                    necessary headers and a JSON payload containing form
                    details.
               </p>
               <div className=" font-montserrat text-[14px] font-[700] text-[#2F407C]  mt-[2rem]">
                    {`Form Object Structure: <br></br>
                    such as ->`}
               </div>
               <div className=" w-fit flex flex-col gap-[0.5rem] mt-[2rem]">
                    <div className=" font-montserrat text-[14px] text-[#2F407C]">
                         name (string): Name of the form
                    </div>
                    <div className=" font-montserrat text-[14px] text-[#2F407C] ">
                         email (string): Email address associated with the form
                    </div>
                    <div className=" font-montserrat text-[14px] text-[#2F407C] ">
                         phone (string): Phone number associated with the form
                    </div>
               </div>
               {/* fdfsadf */}
               <div className=" font-montserrat text-[14px] font-[700] text-[#2F407C]  mt-[2rem]">
                    Form Type:
               </div>
               <p className=" font-montserrat text-[14px] text-[#2F407C] mt-[2rem]">
                    Specify the type of form being created. In the provided
                    example, it is set to "contact".
               </p>
               {/* fdfsadf */}
               <div className=" font-montserrat text-[14px] font-[700] text-[#2F407C]  mt-[2rem]">
                    Sending the Request:
               </div>
               <p className=" font-montserrat text-[14px] text-[#2F407C] mt-[2rem]">
                    Use an appropriate method to send the request to the API
                    endpoint. In the provided example, a cURL command is used.
               </p>
               {/* fdfsadf */}
               <div className=" font-montserrat text-[14px] font-[700] text-[#2F407C]  mt-[2rem]">
                    Response Handling:
               </div>
               <p className=" font-montserrat text-[14px] text-[#2F407C] mt-[2rem]">
                    Handle the response returned by the API endpoint. Ensure
                    proper error handling and validation of the response to
                    confirm successful form creation.
               </p>
               <div className=" font-montserrat text-[14px] font-[700] text-[#2F407C]  mt-[2rem]">
                    {" Sample Code (CURL): "}
                    <br></br> bash Copy code
               </div>
               {/* curl --location 'http://localhost:8082/api/v1/forms/create' \
--header 'Authorization: [Your API Key]' \
--header 'Content-Type: application/json' \
--data-raw '{
    "froms_object": {
        "name": "name",
        "email": "email",
        "phone": "mobile no"
    },
    "froms_type": "contact"
}' */}
               <div className=" mt-[2.5rem] w-[600px] h-fit rounded-[8px] border-2 p-4 bg-[#2E3B43] relative">
                    <ButtonComponent
                         onClick={() => {
                              navigator.clipboard
                                   .writeText(` curl --location '${formAPI_endPoint}' \
                                   --header 'Authorization: ${auth.access}' \
                                   --header 'Content-Type: application/json' \
                                   --data-raw '{
                                       "froms_object": {
                                           "name": "name",
                                           "email": "email",
                                           "phone": "mobile no"
                                       },
                                       "froms_type": "contact"
                                   }'`);
                         }}
                         className=" right-3 bottom-3 absolute w-[100px] h-[30px] rounded-[10px] bg-mainRectangeGredient text-[14px] text-[#fff] font-[600]  "
                    >
                         Copy
                    </ButtonComponent>

                    <div className=" w-full h-fit flex flex-col gap-[0.75rem]">
                         <div className=" flex flex-row gap-[1rem] font-[600] text-[#fff]">
                              <span>Curl</span>
                              <span className="text-[#A5A6F6] font-[600]">
                                   --Request POST \
                              </span>
                         </div>

                         <div className=" ps-[2rem] flex flex-row gap-[1rem]">
                              <h1 className="text-[#A5A6F6] font-[600]">
                                   --location
                              </h1>
                              <h4 className="font-[600] text-[#fff]">
                                   '{formAPI_endPoint}'
                              </h4>
                         </div>
                         <div className=" ps-[4rem] flex flex-row gap-[1rem]">
                              <h1 className="text-[#A5A6F6] font-[600]">
                                   --header
                              </h1>
                              <h4 className="font-[600] text-[#FFCA00]">
                                   'Authorization: [Your API Key]'
                              </h4>
                         </div>
                         <div className=" ps-[4rem] flex flex-row gap-[1rem]">
                              <h1 className="text-[#A5A6F6] font-[600]">
                                   --header
                              </h1>
                              <h4 className="font-[600] text-[#FFCA00]">
                                   'Content-Type: application/json' \
                              </h4>
                         </div>

                         <div className=" ps-[4rem] flex flex-row gap-[1rem]">
                              <h1 className="text-[#A5A6F6] font-[600]">
                                   {" --data-raw '{ "}
                              </h1>
                         </div>
                         <div className=" ps-[8rem] flex flex-row gap-[1rem]">
                              <h1 className="text-[#A5A6F6] font-[600]">
                                   {`   "froms_object": { `}
                              </h1>
                              <h4 className="font-[600] text-[#FFCA00]">
                                   "username": "username",
                              </h4>
                         </div>
                         <div className=" ps-[16rem] flex flex-row gap-[1rem]">
                              <h4 className="font-[600] text-[#FFCA00]">
                                   {`  "email": "email",`}
                              </h4>
                         </div>
                         <div className=" ps-[16rem] flex flex-row gap-[1rem]">
                              <h4 className="font-[600] text-[#FFCA00]">
                                   {`   "phone": "mobile no" },`}
                              </h4>
                         </div>

                         <div className=" ps-[8rem] flex flex-row gap-[1rem]">
                              <h1 className="text-[#A5A6F6] font-[600]">
                                   {`       "froms_type": `}
                              </h1>
                              <h4 className="font-[600] text-[#FFCA00]">
                                   "contact"
                              </h4>
                         </div>
                         <div className=" ps-[8rem] flex flex-row gap-[1rem]">
                              <h1 className="text-[#A5A6F6] font-[600]">
                                   {` '} `}
                              </h1>
                         </div>
                    </div>
               </div>
               <div className=" font-montserrat text-[14px] text-[#2F407C] mt-[2.5rem] ">
                    Note: Replace [Your API Key] with your actual API key
                    obtained during authentication.
               </div>
          </div>
     );
};

export default FormsContactDocsLayout;
