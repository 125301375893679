import { RouterProvider, createBrowserRouter } from "react-router-dom";

import DashboardTemplate from "./components/templates/dashboard/DashboardTemplate";
import { routes } from "./config/mainConfig";
import Home from "./Pages/Home";
import SocialMediaListingPage from "./Pages/SocialMediaListingPage";
import DynamicSocialMediaPage from "./Pages/DynmaicSocialMedia/DynamicSocialMediaPage";
import OutletComPage from "./Pages/OutletComPage";
import DashboardPage from "./Pages/DashboardPage";
import LeadForms from "./Pages/LeadForms/LeadForms";
import Forms from "./Pages/LeadForms/Forms";
import ContactForm from "./Pages/LeadForms/ContactForm";
import SchedulerGridView from "./Pages/Scheduler/SchedulerGridView";
import SchedulerListView from "./Pages/Scheduler/SchedulerListView";
import SchedulerAddPost from "./Pages/Scheduler/SchedulerAddPost";
import Profile from "./Pages/Profile";
import DynamicSocialMediaAddAcount from "./Pages/DynmaicSocialMedia/DynamicSocialMediaAddAcount";
import SignInPage from "./Pages/Authentication/SignInPage";
import SignUpPage from "./Pages/Authentication/SignUpPage";
import PrivacyPolicy from "./Pages/Privacy/PrivacyPolicy";
import TermsofService from "./Pages/Privacy/TermsofService";
import Scheduler from "./Pages/Scheduler/Scheduler";
import SchedulerDynamicSocialMediaPage from "./Pages/Scheduler/SchedulerDynamicSocialMediaPage";
import SchedulerAddAccountPage from "./Pages/Scheduler/SchedulerAddAccountPage";
import VideoFormsLayout from "./Pages/VideoForms/VideoFormsLayout";
import VideoPage from "./Pages/VideoForms/VideoPage";
import VideoFormsAdd from "./Pages/VideoForms/VideoFormsAdd";
import VideoDocsPage from "./Pages/VideoForms/VideoDocsPage";
import LeadFormDocsPage from "./Pages/LeadForms/LeadFormDocsPage";

const router = createBrowserRouter([
     {
          path: routes.home,
          element: <Home />,
     },
     {
          path: routes.signin,
          element: <SignInPage />,
     },
     {
          path: routes.signup,
          element: <SignUpPage />,
     },
     {
          path: routes.privacypolicy,
          element: <PrivacyPolicy />,
     },
     {
          path: routes.termsofservice,
          element: <TermsofService />,
     },
     {
          path: routes.onlydashboard,
          element: <DashboardTemplate />,
          children: [
               {
                    path: routes.socialmediastat,
                    element: <DashboardPage />,
               },
               {
                    path: routes.profile,
                    element: <Profile />,
               },
               {
                    path: routes.socialmedialist,
                    element: <OutletComPage />,
                    children: [
                         {
                              index: true,
                              element: <SocialMediaListingPage />,
                         },
                         {
                              path: routes.dynamcisocialmedia,
                              element: <DynamicSocialMediaPage />,

                              children: [
                                   {
                                        path: "addaccount",
                                        element: (
                                             <DynamicSocialMediaAddAcount />
                                        ),
                                   },
                              ],
                         },
                    ],
               },

               {
                    path: routes.leadvideo,
                    element: <VideoFormsLayout />,
                    children: [
                         {
                              index: true,
                              element: <VideoPage />,
                         },

                         {
                              path: routes.videoforms,
                              element: <VideoFormsAdd />,
                         },

                         {
                              path: routes.leadvideodocs,
                              element: <VideoDocsPage />,
                         },
                    ],
               },
               {
                    path: routes.leadforms,
                    element: <LeadForms />,
                    children: [
                         {
                              index: true,
                              element: <Forms />,
                         },

                         {
                              path: routes.contactforms,
                              element: <ContactForm />,
                         },
                         {
                              path: "docs",
                              element: <LeadFormDocsPage />,
                         },
                    ],
               },

               {
                    path: routes.scheduler,
                    element: <OutletComPage />,
                    children: [
                         {
                              index: true,
                              element: <Scheduler />,
                         },
                         {
                              path: routes.schedulerdynamicsocialmedia,
                              element: <SchedulerDynamicSocialMediaPage />,

                              children: [
                                   {
                                        path: routes.schedulersocialmediagrid,
                                        element: <SchedulerGridView />,
                                   },
                                   {
                                        path: routes.schedulersocialmedialist,
                                        element: <SchedulerListView />,
                                   },
                                   {
                                        path: routes.schedulersocialmediaaddpost,
                                        element: <SchedulerAddPost />,
                                   },
                                   {
                                        path: routes.schedulersocialmediaaddaccount,
                                        element: <SchedulerAddAccountPage />,
                                   },
                              ],
                         },
                         {
                              path: routes.schedulerview,
                              element: <Scheduler />,
                              children: [
                                   {
                                        path: routes.schedulergridview,
                                        element: <SchedulerGridView />,
                                   },
                                   {
                                        path: routes.schedulerlistview,
                                        element: <SchedulerListView />,
                                   },
                              ],
                         },
                         {
                              path: routes.scheduleraddpost,
                              element: <SchedulerAddPost />,
                         },
                    ],
               },

               {
                    path: routes.accountsetting,
                    element: <Profile />,
               },
          ],
     },
]);

function App() {
     return <RouterProvider router={router} />;
}

export default App;
