import React from "react";
import SchedulerInputComponents from "./FormsComponents/SchedulerInputComponents";
import SchedulerDragAndBrowse from "./FormsComponents/SchedulerDragAndBrowse";
import SchedulerButton from "./FormsComponents/SchedulerButton";

const SchedulerForm = () => {
     return (
          <>
               {/* Desktop Screen Layout */}
               <div className=" lg:block hidden w-full h-full overflow-hidden  mt-[50px] ">
                    <div className=" w-full h-full overflow-auto justify-evenly flex flex-row  ">
                         <div className="w-[40%] h-full flex flex-col gap-6 ">
                              <SchedulerInputComponents />
                              {/* <div className=" w-full h-[100px] border-2"></div> */}
                              <SchedulerButton />
                         </div>
                         <div className="w-[40%] h-full ">
                              <SchedulerDragAndBrowse />
                         </div>
                    </div>
               </div>

               {/* Tab Screen layout */}
               <div className=" lg:hidden w-full h-full overflow-hidden mt-[50px]">
                    <div className=" w-full h-full overflow-auto flex flex-col items-center gap-8">
                         <div className=" w-[90%] h-fit">
                              <SchedulerInputComponents />
                         </div>
                         <div className=" w-[85%]  h-fit flex flex-row justify-start ">
                              <div className=" max-w-[588px] w-full h-fit">
                                   <SchedulerDragAndBrowse />
                              </div>
                         </div>

                         <div className=" w-[90%]  h-fit text-center justify-start flex flex-row ">
                              <div className=" max-w-[588px] w-full h-fit">
                                   <SchedulerButton />
                              </div>
                         </div>
                    </div>
               </div>
          </>
     );

     // return (
     // <div className=" w-full h-full overflow-hidden mt-[50px]">
     //      <div className=" w-full h-full overflow-auto flex flex-col items-center gap-8">
     //           <div className=" w-[90%] h-fit">
     //                <SchedulerInputComponents />
     //           </div>
     //           <div className=" w-[85%] h-fit flex flex-row justify-center ">
     //                <SchedulerDragAndBrowse />
     //           </div>

     //           <div className=" w-[90%] h-fit text-center ">
     //                <SchedulerButton />
     //           </div>

     //           <div className=" w-full h-[150px]"></div>
     //           <div className=" w-full h-[150px]"></div>
     //           <div className=" w-full h-[150px]"></div>
     //      </div>
     // </div>;
     // );
};

export default SchedulerForm;
