import React from "react";
import ProfileLayout from "../components/profileComponents/ProfileLayout";

const Profile = () => {
     return (
          <div className=" w-full h-full overflow-auto">
               <ProfileLayout />;
          </div>
     );
};

export default Profile;
