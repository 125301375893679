import React from "react";
import ModalComponent from "../../../../../basicultils/ModalComponent";
import ButtonComponent from "../../../../../basicultils/ButtonComponent";
import ContactBodyDeleteCard from "./utils/ContactBodyDeleteCard";

import DeleteLogoComponent from "./utils/DeleteLogoComponent";
import { contactFormSliceActions } from "../../../../../store/contactFormSlice";
import { contactFormModes } from "../../../../../config/ContactFormConfig";
import { useDispatch } from "react-redux";

const ContactBodyDeleteComponet = () => {
     const dispatch = useDispatch();
     return (
          <ModalComponent
               onClick={() => {
                    console.log("model");
                    // dispatch(
                    //      contactFormSliceActions.updateModeDelete(
                    //           contactFormModes.delete.none
                    //      )
                    // );
               }}
          >
               <ContactBodyDeleteCard>
                    <div className=" w-full h-full flex flex-col justify-evenly z-[10000] cursor-default ">
                         <DeleteLogoComponent />
                         <h1 className="  text-center text-[16px] text-deleteContactTextColor font-lato ">
                              Are you sure you want to delete ?
                         </h1>
                         <div className=" w-full flex flex-row justify-center gap-4">
                              <ButtonComponent
                                   isLoading={false}
                                   onClick={() => {
                                        dispatch(
                                             contactFormSliceActions.updateModeDelete(
                                                  contactFormModes.delete.none
                                             )
                                        );
                                   }}
                                   className="w-[125px] h-[40px] rounded-[4px] border-2 border-mentionButtoncolor text-[14px]  font-montserrat text-mentionButtoncolor"
                              >
                                   Cancel
                              </ButtonComponent>
                              <ButtonComponent
                                   isLoading={false}
                                   onClick={() => {
                                        console.log("deleete");
                                        dispatch(
                                             contactFormSliceActions.updateModeDelete(
                                                  contactFormModes.delete
                                                       .successfullyDeleted
                                             )
                                        );
                                   }}
                                   className="  w-[125px] h-[40px] rounded-[4px] bg-mainRectangeGredient font-montserrat text-[14px] text-[#fff]"
                              >
                                   Delete
                              </ButtonComponent>
                         </div>
                    </div>
               </ContactBodyDeleteCard>
          </ModalComponent>
     );
};

export default ContactBodyDeleteComponet;
