import ReviewNav from "../components/templates/Svg/Review/ReviewNav";
import CalenderSVG from "../components/templates/Svg/Scheduler/CalenderSVG";
import { routes } from "./mainConfig";
import FourCircleSvg from "../components/templates/Svg/FourCircleSvg";
import SocialNavSVG from "../components/templates/Svg/SocialNavSVG";
import LeadFormsNavigationSVG from "../components/templates/Svg/LeadFormsNavigationSVG";
import { RiSettings5Fill } from "react-icons/ri";
import { socialmedia } from "./socialmediaConfig";
import VideoSVG from "../components/templates/Svg/VideoSVG";

export const SideNavBar = {
     review: {
          name: "Reveiws",
          Logo: (props) => {
               return <ReviewNav.js {...props} />;
          },
     },

     scheduler: {
          name: "Scheduler",
          Logo: (props) => {
               return <CalenderSVG {...props} />;
          },
     },
};

export const NavBarLinkAssests = {
     dashboard: {
          text: "Profile",
          link: routes.profile,
          activeLink: routes.profile,
          logo: ({ color = "White" }) => {
               return <FourCircleSvg color={color} />;
          },
     },
     review: {
          text: "reviews",
          link: routes.socialmedialist,
          activeLink: routes.socialmedialist,
          logo: ({ color = "white" }) => {
               return <SocialNavSVG color={color} />;
          },
     },
     leadforms: {
          text: "Forms",
          link: routes.leadforms,
          activeLink: routes.leadforms,
          logo: ({ color = "white" }) => {
               return <LeadFormsNavigationSVG color={color} />;
          },
     },

     leadvideo: {
          text: "Videoes",
          link: routes.leadvideo,
          activeLink: routes.leadvideo,
          logo: ({ color = "white" }) => {
               return <VideoSVG  color={color} />;
          },
     },
     scheduler: {
          text: "scheduling",
          link: routes.scheduler,
          activeLink: routes.scheduler,
          logo: ({ color = "white" }) => {
               return <CalenderSVG color={color} />;
          },
     },

     accountSetting: {
          text: "account settings",
          link: routes.accountsetting,
          activeLink: routes.accountsetting,
          logo: ({ color = "white" }) => {
               return <RiSettings5Fill color={color} size={"24px"} />;
          },
     },

     socialMedia: {
          text: socialmedia.yelp.name,
          link: routes.socialmedialist + "/" + socialmedia.yelp.link,
          activeLink: routes.socialmedialist + "/" + socialmedia.yelp.link,
          logo: socialmedia.yelp.image,
     },
};

export const navBarLinkState = {
     default: "default",
     socialmedia: "socialmedia",
};

export const NavBarLinksList = {
     dashboard: {
          assest: NavBarLinkAssests.dashboard,
          state: navBarLinkState.default,
     },
     review: {
          assest: NavBarLinkAssests.review,
          state: navBarLinkState.default,
     },
     leadforms: {
          assest: NavBarLinkAssests.leadforms,
          state: navBarLinkState.default,
     },
     leadvideo: {
          assest: NavBarLinkAssests.leadvideo,
          state: navBarLinkState.default,
     },
     scheduler: {
          assest: NavBarLinkAssests.scheduler,
          state: navBarLinkState.default,
     },
     accountsetting: {
          assest: NavBarLinkAssests.accountSetting,
          state: navBarLinkState.default,
     },

     socialMedia: {
          assest: NavBarLinkAssests.socialMedia,
          state: navBarLinkState.socialmedia,
     },
};
