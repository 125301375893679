import React from "react";
import VideoContentComLayout from "../../components/LeadFormsComp/VideoCom/VideoContentCom/VideoContentComLayout";
import ButtonComponent from "../../basicultils/ButtonComponent";
import HeaderLeadForms from "../../components/LeadFormsComp/HeaderLeadForms";
import { useNavigate } from "react-router-dom";
import { routes } from "../../config/mainConfig";

const VideoPage = () => {
     const navigate = useNavigate();
     return (
          <div className=" w-full h-full">
               <HeaderLeadForms title="Video">
                    {/* <ButtonComponent
                         onClick={() => {
                              navigate(routes.leadvideodocs);
                         }}
                         className="  w-[132px] h-[40px] rounded-[10px] bg-mainRectangeGredient text-[16px] text-[#fff] font-[600]  "
                    >
                         Connect Video
                    </ButtonComponent> */}
                    <div className=" w-[2rem]"></div>
                    <ButtonComponent
                         onClick={() => {
                              navigate(routes.videoforms);
                         }}
                         className="  w-[132px] h-[40px] rounded-[10px] bg-mainRectangeGredient text-[16px] text-[#fff] font-[600]  "
                    >
                         Create Video
                    </ButtonComponent>
               </HeaderLeadForms>
               <VideoContentComLayout />;
          </div>
     );
};

export default VideoPage;
