import React from "react";

const DeleteContactSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="80"
               height="80"
               viewBox="0 0 80 80"
               fill="none"
          >
               <circle
                    cx="40"
                    cy="40"
                    r="38.5"
                    stroke="#EB5757"
                    stroke-width="3"
               />
               <path
                    d="M25.3571 56.2222C25.3571 58.85 27.4786 61 30.0714 61H48.9286C51.5214 61 53.6429 58.85 53.6429 56.2222V27.5556H25.3571V56.2222ZM56 20.3889H47.75L45.3929 18H33.6071L31.25 20.3889H23V25.1667H56V20.3889Z"
                    stroke="#EB5757"
                    stroke-width="2"
               />
          </svg>
     );
};

export default DeleteContactSVG;
