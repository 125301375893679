import React from "react";
import ButtonComponent from "../../../basicultils/ButtonComponent";

const DiscountBoardLayout = () => {
     return (
          <div className="w-[90vw] h-[73px] border-2 rounded-[15px] bg-discountBoardLinearGradient flex flex-row justify-center">
               <div className=" w-fit h-full flex flex-row items-center gap-[0.5rem]">
                    <h1 className=" font-bold text-[25px] font-montserrat">
                         Enjoy special discounts and Offers
                    </h1>
                    <ButtonComponent className=" w-[130px] h-[35px] rounded-[15px] bg-discountBoardLinearGradient text-[15px] font-montserrat font-bold">
                         Get Today
                    </ButtonComponent>
               </div>
          </div>
     );
};

export default DiscountBoardLayout;
