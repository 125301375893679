import { createSlice } from "@reduxjs/toolkit";
import { PostAPI } from "../../API-utils/PostAPI";
import { defaultHeader, loginUser_FullAPI } from "../../API-utils/APIConfig";
import { status } from "../../API-utils/GetAPI";
import {
     masterSliceActions,
     platforms_retention_delete_thunk,
} from "../masterSlice";
import { routes } from "../../config/mainConfig";
import { navBarSliceActions } from "../DashboardSlices/NavbarSlice";
import { SelectPlanFoundWithId } from "../../config/SelectPlanConfig";
import { invoiceObjThunk } from "./UserRegisterationSlice";

const initialState = {
     auth: {
          isAuth: false,
          email: "",
          token: "",
     },
     content: {
          email: {
               value: "",
               valid: false,
               touched: false,
               message: "email includes @",
          },
          password: {
               value: "",
               valid: false,
               touched: false,
               message: "password should have 6 characters",
          },
     },

     ui: {
          formButtonIsLoading: false,
     },
};

export const emailValid = (val) => {
     if (val.includes("@")) {
          return true;
     }
     return false;
};

export const passwordChecker = (val) => {
     if (val.length >= 6) {
          return true;
     }
     return false;
};

export const mobilenumberChecker = (val) => {
     if (val.length >= 10) {
          return true;
     }
     return false;
};

export const loginSlice = createSlice({
     name: "Login Exiting slice state management",
     initialState,

     reducers: {
          setEmailValue(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         email: {
                              ...state.content.email,
                              value: action.payload,
                              valid: true,
                         },
                    },
               };
          },

          setEmailBlur(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         email: {
                              ...state.content.email,
                              touched: true,
                         },
                    },
               };
          },

          // password

          setPasswordValue(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         password: {
                              ...state.content.password,
                              value: action.payload,
                              valid: passwordChecker(action.payload),
                         },
                    },
               };
          },

          setPasswordBlur(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         password: {
                              ...state.content.password,
                              touched: true,
                         },
                    },
               };
          },

          //set touched

          //set auth ;

          setAuth(state, action) {
               console.log(action.payload);
               return {
                    ...state,
                    auth: {
                         isAuth: true,
                         email: action.payload.email,
                         token: action.payload.token,
                    },
               };
          },

          //reset content

          resetContent(state, action) {
               return {
                    ...state,
                    content: initialState.content,
               };
          },

          // modifying form butttn is loading
          modifyFormButtonIsLoading(state, action) {
               return {
                    ...state,
                    ui: {
                         ...state.ui,
                         formButtonIsLoading: action.payload,
                    },
               };
          },
     },
});

export const loginSliceActions = loginSlice.actions;
export default loginSlice;

export const loginAPIThunk = (
     inputObj,
     navigate,
     redirect_uri = routes?.socialmedialist
) => {
     return async (dispatch) => {
          dispatch(loginSliceActions.modifyFormButtonIsLoading(true));
          console.log(inputObj);

          const obj = {
               username: inputObj.email.value.trim(),
               password: inputObj.password.value,
          };

          const api = new URL(loginUser_FullAPI);
          console.log(api);
          let response = await PostAPI(api, obj, {
               ...defaultHeader,
          });

          console.log(response);

          if (response.status === status.successful) {
               let responsedata = response.responsedata;

               dispatch(
                    updateAuthWithAuthenticationObj(
                         responsedata,
                         navigate,
                         redirect_uri
                    )
               );

               if (responsedata?.error) {
                    dispatch(
                         loginSliceActions.modifyFormButtonIsLoading(false)
                    );
                    return;
               }

               console.log(responsedata);
          } else if (response.status === status.error) {
               console.log(response);
          }

          dispatch(loginSliceActions.modifyFormButtonIsLoading(false));
     };
};

// update Auth obj and other process with a middleware

export const updateAuthWithAuthenticationObj = (
     responsedata,
     navigate,
     redirect_uri = routes?.socialmedialist
) => {
     return async (dispatch) => {
          let data = responsedata?.data;

          let sessionToken = data.session_token;
          let user = data?.member;

          if (data?.user) {
               user = data?.user;
          }

          let obj = {
               email: user?.email,
               id: user?.id,
               mobile: user?.mobile,
               type: user?.role,
               username: user?.username,
               session_token: sessionToken,
               access: user?.access,
          };

          console.log(obj);

          let invoice = data?.invoice;

          if (invoice) {
               let payment = invoice?.payment;
               let message = data?.message;
               dispatch(
                    navBarSliceActions.modifyNavPlan({
                         message: message,
                    })
               );
               dispatch(invoiceObjThunk([invoice]));
          }

          // checking whether the user has creds in local storage or not
          let localStorage = getLoginAuthLocalStorage();
          if (localStorage?.id === obj?.id) {
               // No issues
          } else {
               dispatch(platforms_retention_delete_thunk());
          }

          setLoginAuthLocalStorage(obj);
          dispatch(
               navBarSliceActions.modifyNavPlan({
                    currentPlan: SelectPlanFoundWithId(obj.type).title,
               })
          );

          dispatch(masterSliceActions.updateAuthentication(obj));
          //      dispatch(
          //           navBarSliceActions.modifyNavPlan({
          //                currentPlan: SelectPlanFoundWithId(obj.type)
          //                     .title,
          //           })
          //      );
          navigate(redirect_uri);
          // }
     };
};

export const setLoginAuthLocalStorage = (Authobj) => {
     const obj = {
          session_token: Authobj.session_token,
          email: Authobj.email,
          username: Authobj.username,
          mobile: Authobj.mobile,
          id: Authobj.id,
          type: Authobj.type,
          last_time: new Date(),
          access: Authobj?.access,
     };

     localStorage.setItem("loginAuth", JSON.stringify(obj));
};

export const getLoginAuthLocalStorage = () => {
     let loginAuth = JSON.parse(localStorage.getItem("loginAuth"));
     console.log(loginAuth);
     return loginAuth;
};

export const getLoginAuthLocalStorageThunk = (navigate = () => {}) => {
     return async (dispatch) => {
          let loginAuth = getLoginAuthLocalStorage();

          if (!loginAuth?.session_token) {
               console.log("Not Authenticated Access");
               navigate(routes.signin);
               return;
          }

          let email = loginAuth?.email;
          let id = loginAuth?.id;
          let type = loginAuth?.type;
          let username = loginAuth.username;
          let mobile = loginAuth.mobile;
          let session_token = loginAuth?.session_token;
          let last_time = new Date(loginAuth?.last_time);
          let access = loginAuth?.access;

          let current_Date = new Date();
          current_Date = new Date(current_Date).setDate(
               current_Date.getDate() - 1
          );

          console.log("lastime ", last_time, "currentDAte", current_Date);

          let dif = last_time - current_Date;
          console.log(dif);
          if (dif > 0) {
               const obj = {
                    email: email,
                    username: username,
                    mobile: mobile,
                    session_token: session_token,
                    id: id,
                    type: type,
                    access,
               };
               console.log("Login Through LOCAL STORAGE", obj);
               dispatch(masterSliceActions.updateAuthentication(obj));
               dispatch(
                    navBarSliceActions.modifyNavPlan({
                         currentPlan: SelectPlanFoundWithId(obj.type).title,
                    })
               );
          } else {
               console.log("No retention");
               navigate(routes.signin);
          }
     };
};
