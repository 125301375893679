import React from "react";

const IconContainer = ({ children, size = "  w-[25px] h-[25px]" }) => {
     return (
          <div
               className={
                    size +
                    " flex flex-col justify-center items-center  relative cursor-pointer"
               }
          >
               {children}
          </div>
     );
};

export default IconContainer;
