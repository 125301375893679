import React from "react";

// UI Layout to display the profile Image in review dashboard and other dashboards
const ProfileImageComponent = ({
     alt = "profile photo",
     image = "",
     size = "w-[46px] h-[46px] ",
}) => {
     return (
          <div
               className={
                    size +
                    "  rounded-[50%] flex flex-row justify-center items-center border-2 border-[#FFCA00] "
               }
          >
               <div className=" w-[90%] h-[90%] rounded-[50%]  overflow-hidden ">
                    <img
                         alt={alt}
                         src={image}
                         className=" w-full h-full object-cover"
                    />
               </div>
          </div>
     );
};

export default ProfileImageComponent;
