import React from "react";
import FeatureSectionLayoutType2 from "../utils/FeatureSectionComponents/FeatureSectionLayoutType2";
import eyecatcher from "../../assest/landingpageimages/fivthsectioneyecatcher.png";

const content = {
     heading: "Features-2",
     body: "Use reviews to improve your local search visibility and use proof-based marketing for customer acquisitions.",
     image: eyecatcher,
     featureList: [
          {
               heading: "Review Inbox",
               body: "Manage, monitor, and respond to reviews, for multiple locations from Google Business Profile and Facebook– all in a single inbox.",
          },
          {
               heading: "Review Requests",
               body: "Request more reviews from your happy customers and improve your ratings pro-actively.",
          },
          {
               heading: "Review Embed",
               body: "Embed reviews as a part of your proof-based marketing and influence buying decisions of every visitor on your website.",
          },
     ],
};
const FivthSection = () => {
     return <FeatureSectionLayoutType2 content={content} />;
};

export default FivthSection;
