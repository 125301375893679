import React from "react";
import { schedulerRigthColumns } from "../../../../config/SchedulerConfig";
import CheckBoxComponent from "../../../../basicultils/CheckBoxComponent";
import SchedulerListColumnCompound from "./utils/SchedulerListColumnCompound";

const SchedulerListingHeader = () => {
     return (
          <div className=" min-w-full w-fit h-[48px] flex flex-row justify-between gap-2 bg-[#666E7D] bg-opacity-10">
               <div className=" w-fit h-full flex flex-row gap-2 ps-8">
                    <SchedulerListColumnCompound width="w-[50px]">
                         <CheckBoxComponent value={false} onClick={() => {}} />
                    </SchedulerListColumnCompound>
                    <SchedulerListColumnCompound
                         width={schedulerRigthColumns[0].width}
                    >
                         {schedulerRigthColumns[0].columnHeader}
                    </SchedulerListColumnCompound>
                    <SchedulerListColumnCompound
                         width={schedulerRigthColumns[1].width}
                    >
                         {schedulerRigthColumns[1].columnHeader}
                    </SchedulerListColumnCompound>
                    <SchedulerListColumnCompound
                         width={schedulerRigthColumns[2].width}
                    >
                         {schedulerRigthColumns[2].columnHeader}
                    </SchedulerListColumnCompound>
                    <SchedulerListColumnCompound
                         width={schedulerRigthColumns[3].width}
                    >
                         {schedulerRigthColumns[3].columnHeader}
                    </SchedulerListColumnCompound>
                    <SchedulerListColumnCompound
                         width={schedulerRigthColumns[4].width}
                    >
                         {schedulerRigthColumns[4].columnHeader}
                    </SchedulerListColumnCompound>
               </div>
               <div className=" w-[100px] h-full "></div>
          </div>
     );
};

export default SchedulerListingHeader;
