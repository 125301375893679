import React, { useEffect } from "react";

// Components Layous
import SchedulerGridHeader from "./SchedulerGridHeader";
import SchedulerGridViewLayout from "./SchedulerGridViewComponents/SchedulerGridViewLayout";
import SchedulerListingOtherSocialMedia from "./SchedulerListingOtherSocialMedia";

// Custom Hook
import UseGetSchedulerList from "../../../customHooks/UseGetSchedulerList";

// Hooks
import { useSelector } from "react-redux";

const SchedulerGridLayout = () => {
     // Hook
     const [fetchSchdulerList] = UseGetSchedulerList();
     const { auth } = useSelector((state) => {
          return state.master;
     });
     const { selectedSocialMedia } = useSelector((state) => {
          return state.scheduler;
     });
     useEffect(() => {
          fetchSchdulerList();
     }, [auth, selectedSocialMedia]);

     return (
          <div className=" w-full h-full flex flex-col overflow-auto pt-[1rem] ">
               <SchedulerGridHeader />
               <SchedulerListingOtherSocialMedia />
               <div className=" flex-1 ">
                    <SchedulerGridViewLayout />
               </div>
          </div>
     );
};

export default SchedulerGridLayout;
