import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ModalComponent from "../../../basicultils/ModalComponent";
import InputComponent from "../../../basicultils/InputComponent";
import ButtonComponent from "../../../basicultils/ButtonComponent";
import { routes } from "../../../config/mainConfig";
import toast from "react-hot-toast";
import { setLinkedinLocalStorage } from "../../../Api-Calling/linkedinAPICalling";
import { masterSliceActions } from "../../../store/masterSlice";

import {
     baseURL_Scheduler,
     schedulersAPIroute,
} from "../../../API-utils/APIConfig";
import { PostAPI } from "../../../API-utils/PostAPI";
import { GetAPI } from "../../../API-utils/GetAPI";
import {
     dashbaordViewModeObj,
     navBarSliceActions,
} from "../../../store/DashboardSlices/NavbarSlice";

let initialTime = 1;
let error = {
     updateplan: "updateplan",
     alreadyAuthenticated: "alreadyAuthenticated",
     authentication: "authentication",
};
const SchedulerLinkedInAccountDetails = () => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const { auth } = useSelector((state) => {
          return state.master;
     });

     // Local States
     const [varinityName, setVarinityName] = useState("");
     const [addAccountButtonIsLoading, setAddAccountButtonIsLoading] =
          useState(false);

     // Toaster
     const updatePlanToastFunction = (message) => {
          toast.custom(
               (t) => (
                    <div
                         className={` max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                    >
                         <div className="flex-1 w-0 p-4">
                              <div className="flex items-start">
                                   <div className="ml-3 flex-1">
                                        <p className="text-sm font-medium text-gray-900">
                                             {message}
                                        </p>
                                   </div>
                              </div>
                         </div>
                         <div className="flex border-l border-gray-200">
                              <button
                                   onClick={() => {
                                        navigate(routes?.accountsetting);
                                        dispatch(
                                             navBarSliceActions.modifyDashboardViewMode(
                                                  dashbaordViewModeObj.plan
                                             )
                                        );

                                        toast.dismiss(t.id);
                                   }}
                                   className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              >
                                   Update Plan
                              </button>
                         </div>
                    </div>
               ),
               {
                    position: "top-right",
                    duration: 10000,
               }
          );
     };

     // handler
     const addAccountButtonHandler = async () => {
          if (addAccountButtonIsLoading) {
               // Button is already clicked
               return;
          }

          if (varinityName === "") {
               // If the varinity name is empty
               toast(
                    `Please Enter your Linkedin Varinity Name 
                  ( https://www.linkedin.com/in/{ varinity name }/ ) `,
                    {
                         position: "top-right",
                         duration: 8000,
                    }
               );
               return;
          }

          try {
               // Starting the spinner in button
               setAddAccountButtonIsLoading(true);

               // Register account and get the auth url
               let response = await linkedinAuthenticate(varinityName, auth);
               console.log(response);

               if (response?.error) {
                    // if any error occured
                    if (response?.error === error?.updateplan) {
                         // Putting toast message of update plan
                         updatePlanToastFunction(response?.obj?.message);
                    } else if (
                         response?.error === error?.alreadyAuthenticated
                    ) {
                         // Already Authenticated
                         toast.error(response?.obj, {
                              position: "top-right",
                              duration: 8000,
                         });
                    } else if (response?.error === error?.authentication) {
                         // Authenticated error
                         toast.error(response?.obj, {
                              duration: 5000,
                         });
                    } else {
                         throw {};
                    }
               } else {
                    // If no error occured
                    // opening url in same tab
                    window.location.href = response?.obj?.auth_url;
                    //toast(response?.obj?.auth_url);
               }
          } catch (e) {
               // something went wrong
               toast.error("Something went wrong", {
                    position: "top-right",
               });
          }
          setAddAccountButtonIsLoading(false);
     };

     // -- redirection logic
     //---------------------------------------------------
     const [searchParams] = useSearchParams();
     useEffect(() => {
          console.log(searchParams.get("code"));
          if (searchParams.get("code")) {
               dispatch(
                    masterSliceActions?.updateLinkedAuthentication({
                         isAccount: true,
                    })
               );
               setLinkedinLocalStorage({ isAccount: true });
               navigate("/dashboard/scheduler/linkedin/grid");
               if (initialTime) {
                    initialTime--;
                    toast.success("You are authenticated Successfully", 5000);
               }
          }
     }, [auth]);

     return (
          <ModalComponent>
               <div className=" w-full h-full bg-black bg-opacity-20 absolute  flex flex-row justify-center items-center ">
                    <div className=" max-w-[524px] w-[80%] h-fit min-h-[240px] bg-[#fff] flex flex-col  p-3 rounded-[8px]">
                         <div className=" w-full flex flex-row justify-center">
                              <div className="  md:w-[75%] w-[90%] mt-[1rem] flex flex-row justify-start md:text-[16px] text-[14px] text-[#577496] font-lato ps-2 pe-2">
                                   {`    Enter your Linkedin Varinity Name (
                                   https://www.linkedin.com/in/{ varinity name }/ 
                                   ) :`}
                              </div>
                         </div>
                         <div className=" w-full h-fit flex flex-row justify-center mt-[1rem]">
                              <InputComponent
                                   type="text"
                                   placeholder={"Varinity Name"}
                                   value={varinityName}
                                   onChange={(e) => {
                                        setVarinityName(e.target.value);
                                   }}
                                   className=" md:w-[75%] w-[90%] h-[45px] border-[#577496] border-2 text-[18px]  font-lato  rounded-[8px]  ps-5 "
                              />
                         </div>
                         <div className=" w-full h-fit flex flex-row justify-center gap-[1rem] mt-[1.75rem] ">
                              <ButtonComponent
                                   onClick={() => {
                                        navigate(routes.scheduler);
                                   }}
                                   className=" w-[122px] h-[40px] text-[#577496] text-[16px] font-lato border-[#577496] border-2 rounded-[6px]"
                              >
                                   Close
                              </ButtonComponent>

                              <ButtonComponent
                                   onClick={addAccountButtonHandler}
                                   isLoading={addAccountButtonIsLoading}
                                   className=" w-[180px] h-[40px]  text-[16px] text-[#fff] font-lato  border-2 rounded-[6px] bg-mainRectangeGredient"
                              >
                                   Authenticate your Account
                              </ButtonComponent>
                         </div>
                    </div>
               </div>
          </ModalComponent>
     );
};

export default SchedulerLinkedInAccountDetails;

export const linkedinAuthenticate = async (varinityName, auth) => {
     let registerResponse = await linkedinRegisterApp(varinityName, auth);

     if (registerResponse) {
          if (registerResponse?.message) {
               return {
                    error: error?.updateplan,
                    obj: registerResponse,
               };
          }

          if (!registerResponse) {
               return {
                    error: error?.alreadyAuthenticated,
                    obj: "This Account is already integrated with other ranblitz account, Try with other Linkedin Accounts",
               };
          }

          let authenticationAPIResponse = await linkedinAuthenticationAPI(auth);

          if (
               !authenticationAPIResponse &&
               !authenticationAPIResponse?.auth_url
          ) {
               return {
                    error: error?.authentication,
                    obj: "Error in Authentication process",
               };
          }

          return {
               error: false,
               obj: authenticationAPIResponse,
          };
     }

     return {
          error: error?.alreadyAuthenticated,
          obj: "This Account is already integrated with other ranblitz account, Try with other Linkedin Accounts",
     };
};

export const linkedinRegisterApp = async (inputValue, auth) => {
     let api = new URL(
          baseURL_Scheduler + schedulersAPIroute + "/linkedin/register/app"
     );
     console.log(api.href);

     let bodyObj = {
          name: inputValue,
     };

     let headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": [
               "POST",
               "GET",
               "OPTIONS",
               "DELETE",
               "PUT",
          ],
          "Access-Control-Allow-Headers": [
               "append",
               "delete",
               "entries",
               "foreach",
               "get",
               "has",
               "keys",
               "set",
               "values",
               "Authorization",
          ],

          Authorization: auth?.sessionToken,

          redirect: "follow",
     };

     const response = await PostAPI(api, bodyObj, headers);

     if (response.response.ok) {
          let responsedata = response.responsedata;

          console.log(responsedata);
          if (responsedata?.error) {
               return null;
          }

          if (response?.message) {
               return responsedata;
          }

          return responsedata;
     }

     return null;
};

export const linkedinAuthenticationAPI = async (auth) => {
     let api = new URL(
          baseURL_Scheduler + schedulersAPIroute + "/linkedin/auth/linkedin/url"
     );
     console.log(api.href);

     let headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": [
               "POST",
               "GET",
               "OPTIONS",
               "DELETE",
               "PUT",
          ],
          "Access-Control-Allow-Headers": [
               "append",
               "delete",
               "entries",
               "foreach",
               "get",
               "has",
               "keys",
               "set",
               "values",
               "Authorization",
          ],

          Authorization: auth?.sessionToken,

          redirect: "follow",
     };

     const response = await GetAPI(api, headers);

     if (response?.response?.ok) {
          let responsedata = response.responsedata;

          console.log(responsedata);
          if (responsedata?.error) {
               return null;
          }

          return responsedata;
     }

     return null;
};
