import React from "react";

const SocialNavSVG = ({ color = "white" }) => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="25"
               height="25"
               viewBox="0 0 25 25"
               fill="none"
          >
               <path
                    d="M4.93797 3.83049H3.89288C4.05008 2.74695 4.69358 2.69251 4.98972 2.66744C5.28557 2.64236 5.51203 2.41146 5.51203 2.13489V0.534693C5.51203 0.387694 5.44702 0.247112 5.33226 0.146101C5.21751 0.0450889 5.06301 -0.00726173 4.90546 0.000812782C3.08994 0.0958889 0.925781 0.949328 0.925781 4.47527V7.25804C0.925781 7.72256 1.33168 8.10046 1.83061 8.10046H4.93803C5.43697 8.10046 5.84286 7.72251 5.8428 7.25798V4.67291C5.8428 4.20838 5.43691 3.83049 4.93797 3.83049ZM4.69416 7.03093H2.07448V4.47527C2.07448 2.01495 3.24966 1.32781 4.36333 1.13387V1.69609C3.59422 1.91405 2.70903 2.55776 2.70903 4.36522C2.70903 4.66056 2.96616 4.89996 3.28338 4.89996H4.69416V7.03093Z"
                    fill={color}
               />
               <path
                    d="M10.8091 3.83049H9.76397C9.92117 2.74695 10.5647 2.69251 10.8608 2.66744C11.1567 2.64236 11.3831 2.41146 11.3831 2.13489V0.534693C11.3831 0.387694 11.3181 0.247112 11.2034 0.146101C11.0886 0.0450889 10.934 -0.00726173 10.7766 0.000812782C8.96103 0.0958889 6.79688 0.949328 6.79688 4.47527V7.25804C6.79688 7.72256 7.20277 8.10046 7.70171 8.10046H10.8091C11.3081 8.10046 11.714 7.72251 11.714 7.25793V4.67291C11.714 4.20838 11.3081 3.83049 10.8091 3.83049ZM10.5653 7.03099H7.94558V4.47527C7.94558 2.01495 9.12076 1.32781 10.2344 1.13387V1.69609C9.46531 1.91405 8.58012 2.55776 8.58012 4.36522C8.58012 4.66056 8.83726 4.89996 9.15447 4.89996H10.5653V7.03099Z"
                    fill={color}
               />
               <path
                    d="M14.4401 5.40867C14.2791 5.25882 14.0559 5.17261 13.8283 5.17261C13.6007 5.17261 13.3774 5.25882 13.2164 5.40867C13.0555 5.55853 12.9629 5.7664 12.9629 5.97829C12.9629 6.19018 13.0555 6.39805 13.2164 6.54791C13.3774 6.69776 13.6007 6.78397 13.8283 6.78397C14.0559 6.78397 14.2791 6.69776 14.4401 6.54791C14.601 6.39805 14.6936 6.19018 14.6936 5.97829C14.6936 5.7664 14.601 5.55853 14.4401 5.40867Z"
                    fill={color}
               />
               <path
                    d="M16.3216 5.17261H15.7063C15.4367 5.17261 15.2181 5.37708 15.2181 5.62932C15.2181 5.88157 15.4367 6.08604 15.7063 6.08604H16.3216C20.5684 6.08604 24.0234 9.31771 24.0234 13.2899C24.0234 16.6802 21.4527 19.6452 17.9108 20.34C17.6847 20.3844 17.5228 20.5709 17.5228 20.787V23.4407L14.5152 20.6276C14.4237 20.542 14.2995 20.4938 14.17 20.4938H8.67837C4.43159 20.4938 0.976562 17.2622 0.976562 13.2899C0.976562 12.0657 1.31069 10.8568 1.94287 9.79388C2.07402 9.57347 1.98921 9.29537 1.75356 9.17275C1.51787 9.05007 1.22056 9.1294 1.0895 9.34981C0.376709 10.5482 0 11.9107 0 13.2899C0 17.7659 3.89307 21.4073 8.67837 21.4073H13.9677L17.6658 24.8662C17.7592 24.9536 17.884 25 18.0111 25C18.074 25 18.1375 24.9886 18.1979 24.9652C18.3803 24.8946 18.4993 24.728 18.4993 24.5433V21.149C20.2661 20.7207 21.8636 19.7679 23.0309 18.4387C24.3007 16.9928 25 15.1643 25 13.2899C25 8.81404 21.1069 5.17265 16.3216 5.17261Z"
                    fill={color}
               />
               <path
                    d="M18.0642 10.0571H6.93649C6.68679 10.0571 6.48438 10.279 6.48438 10.5528C6.48438 10.8266 6.68679 11.0485 6.93649 11.0485H18.0641C18.3138 11.0485 18.5163 10.8266 18.5163 10.5528C18.5163 10.279 18.3139 10.0571 18.0642 10.0571Z"
                    fill={color}
               />
               <path
                    d="M18.0642 12.3025H6.93649C6.68679 12.3025 6.48438 12.5244 6.48438 12.7982C6.48438 13.0719 6.68679 13.2939 6.93649 13.2939H18.0641C18.3138 13.2939 18.5163 13.0719 18.5163 12.7982C18.5163 12.5244 18.3139 12.3025 18.0642 12.3025Z"
                    fill={color}
               />
               <path
                    d="M18.0642 14.5474H6.93649C6.68679 14.5474 6.48438 14.7693 6.48438 15.043C6.48438 15.3168 6.68679 15.5387 6.93649 15.5387H18.0641C18.3138 15.5387 18.5163 15.3168 18.5163 15.043C18.5163 14.7693 18.3139 14.5474 18.0642 14.5474Z"
                    fill={color}
               />
               <path
                    d="M18.0651 16.7922H12.1338C11.8841 16.7922 11.6816 17.0142 11.6816 17.2879C11.6816 17.5616 11.8841 17.7836 12.1338 17.7836H18.0651C18.3148 17.7836 18.5172 17.5616 18.5172 17.2879C18.5172 17.0142 18.3148 16.7922 18.0651 16.7922Z"
                    fill={color}
               />
               <path
                    d="M9.5883 16.7922H9.58102C9.33132 16.7922 9.12891 16.9807 9.12891 17.2132C9.12891 17.4456 9.33132 17.6341 9.58102 17.6341H9.5883C9.838 17.6341 10.0404 17.4456 10.0404 17.2132C10.0404 16.9807 9.838 16.7922 9.5883 16.7922Z"
                    fill={color}
               />
          </svg>
     );
};

export default SocialNavSVG;
