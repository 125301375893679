import React from "react";
import ButtonComponent from "../../../../basicultils/ButtonComponent";
import { BsThreeDots } from "react-icons/bs";
import CalenderLayout from "../CalenderComponents/CalenderLayout";
import { useDispatch, useSelector } from "react-redux";
import { calenderSliceActions } from "../CalenderComponents/calenderStore/calenderSlice";
import { socialmedia } from "../../../../config/socialmediaConfig";
import UseGetAccountDetails, {
     useGetAccountDetails_getDetailsFrom_modes,
} from "../../../../customHooks/UseGetAccountDetails";
import { postTwitterPostThunk } from "../../../../store/SchedulerSlices/schedulerSlide";
import { useNavigate } from "react-router-dom";
import { postLinkedinPostThunk } from "../../../../store/SchedulerSlices/LinkedinAuthenticationThunks";
import { tiktokDraftPostThunk } from "../../../../store/SchedulerSlices/TiktokAuthenticationThunks";
import { returnWithSetTime } from "../../../../config/CalenderConfig";
import { twitter_postThunk } from "../../../../Api-Calling/twitterAPICaliing";
import { linkedinPostThunk } from "../../../../Api-Calling/linkedinAPICalling";
import { post_tiktok_thunk } from "../../../../Api-Calling/tiktokAPICalling";
import toast from "react-hot-toast";

const SchedulerButton = () => {
     const [getDetailsFrom] = UseGetAccountDetails();
     const navigate = useNavigate();
     const calender = useSelector((state) => {
          return state.calender;
     });

     const { auth } = useSelector((state) => {
          return state.master;
     });
     const { addPost, ui, selectedSocialMedia } = useSelector((state) => {
          return state.scheduler;
     });
     const isDisplaying = useSelector((state) => state.calender.isDisplaying);
     const dispatch = useDispatch();

     let isLoading = ui.postNowButtonIsLoading;

     const postNowClickHandler = (schedulerTime = "") => {
          // Twitter
          if (selectedSocialMedia?.link === socialmedia?.twitter?.link) {
               let loginDetails = getDetailsFrom(
                    useGetAccountDetails_getDetailsFrom_modes.schedule
               );

               if (!loginDetails?.isAccounted) {
                    return;
               }

               const obj = {
                    oauth_token: loginDetails?.obj?.oauth_token,
                    oauth_token_secret: loginDetails?.obj?.oauth_token_secret,
                    tweet_text: addPost.description,
               };

               console.log(obj);
               // dispatch(
               //      postTwitterPostThunk(obj, auth, schedulerTime, navigate)
               // );
               dispatch(
                    twitter_postThunk(obj, schedulerTime, auth, {
                         successToaster,
                         navigate,
                         toasterErrorFunction,
                    })
               );
          } else if (
               selectedSocialMedia?.link === socialmedia?.linkedin?.link
          ) {
               // dispatch(
               //      postLinkedinPostThunk(
               //           { text_msg: addPost.description },
               //           auth,
               //           schedulerTime,
               //           navigate
               //      )
               // );
               dispatch(
                    linkedinPostThunk(addPost, auth, schedulerTime, {
                         successToaster,
                         navigate,
                         toasterErrorFunction,
                    })
               );
          } else if (selectedSocialMedia?.link === socialmedia?.tiktok?.link) {
               console.log("Tiktok");
               // dispatch(
               //      tiktokDraftPostThunk(addPost, auth, schedulerTime, navigate)

               // );
               dispatch(
                    post_tiktok_thunk(addPost, schedulerTime, auth, {
                         successToaster,
                         navigate,
                         toasterErrorFunction,
                    })
               );
          }
     };

     // Toaster

     const toasterErrorFunction = (message, time = 6000) => {
          toast.error(message, {
               duration: time,
               position: "top-right",
          });
     };

     const successToaster = (m, t = 4000) => {
          toast.success(m, { duration: t, position: "top-right" });
     };

     return (
          <>
               <div className=" w-full flex flex-row justify-end gap-4 ">
                    <ButtonComponent
                         onClick={() => {
                              postNowClickHandler(null);
                         }}
                         isLoading={isLoading}
                         className=" h-[40px] w-[135px] font-montserrat text-[18px] font-[600] text-[#fff] rounded-[10px] bg-[#BDBDBD]  "
                    >
                         Post Now
                    </ButtonComponent>
                    <ButtonComponent
                         onClick={() => {
                              let time = calender.time;
                              let returnDataObj = returnWithSetTime(
                                   calender.selectedDate,
                                   time.hours,
                                   time.minutes,
                                   time.zones
                              );
                              console.log(returnDataObj);
                              postNowClickHandler(returnDataObj);
                         }}
                         isLoading={false}
                         className=" h-[40px] w-[140px] font-montserrat text-[18px] font-[600] text-[#fff] rounded-[10px]  bg-mainRectangeGredient  flex flex-row justify-evenly items-center gap-2 "
                    >
                         Schedule
                         {/* <div className="w-[50px] h-[30px] rounded-[10px] bg-[#fff] flex flex-row justify-center items-center text-[12px] text-black ">
                              <BsThreeDots />
                         </div> */}
                    </ButtonComponent>
                    <ButtonComponent
                         onClick={() => {
                              dispatch(
                                   calenderSliceActions.toggleIsDisplaying()
                              );
                         }}
                         className="w-[50px] h-[40px] rounded-[10px] border-2 bg-[#fff] flex flex-row justify-center items-center text-[12px] text-black "
                    >
                         <BsThreeDots />
                    </ButtonComponent>
               </div>
               {isDisplaying ? (
                    <div className=" w-full flex flex-col items-end duration-200 ">
                         <CalenderLayout />
                         <div className=" w-full h-[300px]"></div>
                    </div>
               ) : (
                    <div className=" w-full h-[500px]"></div>
               )}
          </>
     );
};

export default SchedulerButton;
