import React from "react";
import SearchBar from "../../../basicultils/SearchBar";
import EmptyMentionSection from "./EmptyMentionSection";

import ListingMentionContent from "./ListingMentionContent";

import { useSelector } from "react-redux";
import UseGetAccountDetails, {
     useGetAccountDetails_getDetailsFrom_modes,
} from "../../../customHooks/UseGetAccountDetails";

const MentionLayout = () => {
     const [getDetailsFrom] = UseGetAccountDetails();
     let loginDetails = getDetailsFrom(
          useGetAccountDetails_getDetailsFrom_modes.review
     );

     const { allReviewList, selectedSocialMedia } = useSelector((state) => {
          return state.dynamicsocialmedia;
     });

     return (
          <div className="w-full h-full  border-e-2 flex flex-col ">
               <div className=" w-full h-[50px] flex flex-col justify-center items-center">
                    <SearchBar
                         searchbarcss={
                              "w-[90%] h-[33px] relative overflow-hidden   flex flex-row justify-between font-montserrat"
                         }
                         isLogoAtFront={true}
                    />
               </div>
               <div className=" flex-1  overflow-hidden ">
                    {allReviewList.length <= 0 ? (
                         <EmptyMentionSection
                              content={selectedSocialMedia}
                              loginDetails={loginDetails}
                         />
                    ) : (
                         <ListingMentionContent content={allReviewList} />
                    )}
               </div>
          </div>
     );
};

export default MentionLayout;
