import React from "react";

const CurveLineSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="100%"
               height="28"
               viewBox="0 0 403 28"
               fill="none"
          >
               <path
                    d="M3 25C60.7106 8.39828 220.905 -14.8441 400 25"
                    stroke="#EC3833"
                    stroke-width="6"
                    stroke-linecap="round"
               />
          </svg>
     );
};

export default CurveLineSVG;
