import React from "react";

//Layout and UI
import ButtonComponent from "../../../basicultils/ButtonComponent";

const EmptyMentionLayout = ({
     mainText = "",
     buttonText = "create video",
     onClick = () => {},
}) => {
     return (
          <div className=" w-full h-full flex flex-col justify-center gap-[0.5rem]">
               <div className=" flex flex-col gap-[1rem]">
                    <h1 className=" font-montserrat lg:text-[18px] text-[16px] capitalize font-[600] text-center">
                         {mainText}
                    </h1>
                    <div className=" w-full flex flex-row justify-center">
                         <ButtonComponent
                              onClick={onClick}
                              className="  w-[132px] h-[40px] rounded-[10px] bg-mainRectangeGredient text-[16px] text-[#fff] font-[600]  "
                         >
                              {buttonText}
                         </ButtonComponent>
                    </div>
               </div>
          </div>
     );
};

export default EmptyMentionLayout;
