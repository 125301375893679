// thunk
import {
     baseURL_Scheduler,
     tiktok_list,
     tiktok_post,
} from "../API-utils/APIConfig";
import { GetAPI } from "../API-utils/GetAPI";
import { PostAPI, PostAPIWithOutStringify } from "../API-utils/PostAPI";
import { masterSliceActions } from "../store/masterSlice";
import {
     schedulerSlideActions,
     updatePostListScheduler,
} from "../store/SchedulerSlices/schedulerSlide";

let defaultHeaders = {
     "Content-Type": "application/json",
     "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Methods": [
          "POST",
          "GET",
          "OPTIONS",
          "DELETE",
          "PUT",
     ],
     "Access-Control-Allow-Headers": [
          "append",
          "delete",
          "entries",
          "foreach",
          "get",
          "has",
          "keys",
          "set",
          "values",
          "Authorization",
     ],

     redirect: "follow",
};

// Local Storage
const key_tiktok = "tiktok_auth";

export const setTikTokLocalStorage = (obj = "") => {
     localStorage.setItem(key_tiktok, JSON.stringify(obj));
};

export const getTikTokLocalStorage = () => {
     // Getting local storage of tiktok
     let obj = JSON.parse(localStorage.getItem(key_tiktok));
     console.log("Tiktok : ", obj);
     return obj;
};

// Thunk to update master slice

export const updateTiktokMasterSliceAuthStatusThunk = () => {
     return async (dispatch) => {
          let localStorage = getTikTokLocalStorage();

          if (!localStorage) return;

          dispatch(masterSliceActions.updateTiktokAuthentication(localStorage));
     };
};

// Posting Tiktok video thunk

function formatDate(date) {
     //  Return value is  based GMT time format
     const year = date.getUTCFullYear();
     const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
     const day = String(date.getUTCDate()).padStart(2, "0");
     const hours = String(date.getUTCHours()).padStart(2, "0");
     const minutes = String(date.getUTCMinutes()).padStart(2, "0");

     return `${year}-${month}-${day} ${hours}:${minutes}:00`;
}

export const post_tiktok_thunk = (
     content,
     schedule_time,
     auth,
     { successToaster, navigate, toasterErrorFunction }
) => {
     return async (dispatch) => {
          if (!content?.title || !content?.file) {
               return;
          }

          dispatch(schedulerSlideActions.modifyPostNowButtonIsLoading(true));

          let url = new URL(baseURL_Scheduler + tiktok_post);

          let body = {
               title: content?.title,
               file: content?.file,
          };

          console.log(content);

          let bodyData = new FormData();
          bodyData.append("video", body?.file);
          bodyData?.append("title", body?.title);

          if (schedule_time) {
               body = {
                    ...body,
                    task_time: formatDate(new Date(schedule_time)),
               };
               bodyData.append("task_time", body?.task_time);
          }

          let headers = {
               // ...defaultHeaders,
               Authorization: auth?.sessionToken,
          };

          let response = await PostAPIWithOutStringify(url, bodyData, headers);

          if (response?.response?.ok) {
               console.log(response?.responsedata);
               dispatch(tiktok_list_fetch_thunk(auth));
               if (schedule_time) {
                    successToaster("Scheduled");
               } else {
                    successToaster("Posted");
               }
          } else {
               toasterErrorFunction("Something went wrong");
          }
          dispatch(schedulerSlideActions.modifyPostNowButtonIsLoading(false));
     };
};

// --- list tiktok thunk

const isPostedHelper = (date) => {
     const now = new Date();
     return date < now;
};

export const tiktok_list_format = (list = []) => {
     let array = [];

     for (let i = 0; i < list.length; i++) {
          let current = list[i];
          array.push({
               content: current?.title,
               taskTime: new Date(current?.task_time),
               isPosted: isPostedHelper(new Date(current?.task_time)),
          });
     }
     return array;
};

export const tiktok_list_fetch_thunk = (auth) => {
     return async (dispatch) => {
          let url = new URL(baseURL_Scheduler + tiktok_list);

          let headers = {
               ...defaultHeaders,
               Authorization: auth?.sessionToken,
          };

          let response = await GetAPI(url, headers);

          if (response?.response?.ok) {
               let responsedata = response?.responsedata;
               let user = responsedata?.user;
               let posts = user.posts;
               let formatted_list = tiktok_list_format(posts);
               console.log("Format tikok : ", formatted_list);

               dispatch(updatePostListScheduler(formatted_list));
          }
     };
};
