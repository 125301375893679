import React from "react";

import eyecatcher from "../../assest/landingpageimages/secondsectioneyecatcher.png";
import SecondSectionContentBox from "./SecondSectionContentBox";
import DelightSVG from "../../components/templates/Svg/Landingpagesvgs/DelightSVG";
import ImproveSVG from "../../components/templates/Svg/Landingpagesvgs/ImproveSVG";
import LocalSVG from "../../components/templates/Svg/Landingpagesvgs/LocalSVG";
import DriveSVG from "../../components/templates/Svg/Landingpagesvgs/DriveSVG";

const SecondSection = () => {
     return (
          <div className=" w-full h-fit md:m-0 mt-[4rem]" id={"benefits"}>
               <div className=" w-full h-fit flex flex-col gap-[1rem] md:p-0 p-6 ">
                    <h1 className=" font-poppins text-[20px] font-[600] bg-clip-text text-transparent bg-mainRectangeGredient2 md:text-center text-start">
                         BENEFITS
                    </h1>
                    <p className=" md:text-center text-start font-poppins text-[36px] leading-[38px] font-[600] text-[#1C1F25]">
                         Amplify Your Reach by Leveraging the Advantages <br />
                         of Centralized Dashboard
                    </p>
               </div>

               <div className=" w-full md:h-[80vh]  h-fit flex md:flex-row flex-col-reverse  md:gap-0 gap-[2.5rem]  mt-[2.5rem] ">
                    <div className=" md:w-[50%] w-full h-full  flex flex-row justify-center">
                         <div className=" md:w-[75%] w-[85%] h-fit flex flex-col gap-[3rem]">
                              <SecondSectionContentBox
                                   title="Delight Customers"
                                   body=" Delight your customers by responding to their reviews, mentions, and comments from a single inbox."
                              >
                                   <DelightSVG />
                              </SecondSectionContentBox>
                              <SecondSectionContentBox
                                   hover={true}
                                   title="Improve Ratings"
                                   body="Improve your ratings by collecting more reviews from happy customers."
                              >
                                   <ImproveSVG />
                              </SecondSectionContentBox>
                              <SecondSectionContentBox
                                   title="Local SEO"
                                   body="Get on the top of local search results with the help of user generated contents and higher star ratings."
                              >
                                   <LocalSVG />
                              </SecondSectionContentBox>
                              <SecondSectionContentBox
                                   title="Drive Sales"
                                   body="Acquire more customers by word of mouth, user generated content, and local search visibility."
                              >
                                   <DriveSVG />
                              </SecondSectionContentBox>
                         </div>
                    </div>

                    <div className=" md:w-[50%] w-full h-full  flex flex-row justify-center items-center ">
                         <div className=" md:w-[70%] md:h-[70%] h-[85%] w-[85%] bg-[#FFF5F5]">
                              <img
                                   src={eyecatcher}
                                   className=" w-full h-full object-contain"
                                   alt={"Benefits related"}
                              />
                         </div>
                    </div>
               </div>
          </div>
     );
};

export default SecondSection;
