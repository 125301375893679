import React from "react";

const HeaderLeadForms = ({ title = "", children = <></> }) => {
     return (
          <div className=" md:h-[66px] h-[50px] w-full p-2 border-b-2 border-[#D0DAE2] flex flex-row justify-between items-center md:ps-8 ps-4">
               <h1 className=" md:text-[20px] text-[16px] font-bold text-mentionButtoncolor">
                    {title}
               </h1>
               <div className=" flex flex-row">{children}</div>
          </div>
     );
};

export default HeaderLeadForms;
