import React from "react";

const CalenderEachRowLayout = ({ children }) => {
     return (
          <div className=" w-full h-[50px] flex flex-row justify-evenly items-center ">
               {children}
          </div>
     );
};

export default CalenderEachRowLayout;
