import React from "react";

const EditSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="13"
               height="13"
               viewBox="0 0 13 13"
               fill="none"
          >
               <g clip-path="url(#clip0_1541_2089)">
                    <path
                         d="M12.0635 1.49422L11.5045 0.93522C11.1015 0.53222 10.5685 0.324219 10.0355 0.324219C9.50246 0.324219 8.96947 0.53222 8.56647 0.93522L0.948463 8.55322C0.805463 8.69622 0.714463 8.87822 0.688463 9.07322L0.324465 12.1802C0.298465 12.4532 0.506468 12.6742 0.766468 12.6742C0.779468 12.6742 0.805463 12.6742 0.818463 12.6742L3.92547 12.3102C4.12047 12.2842 4.31546 12.1932 4.44547 12.0502L12.0635 4.43222C12.8825 3.62622 12.8825 2.31322 12.0635 1.49422ZM2.05346 8.76122L8.31946 2.49522L10.5035 4.67922L4.23746 10.9452L2.05346 8.76122ZM1.57246 9.61922L3.37946 11.4262L1.32547 11.6602L1.57246 9.61922ZM11.4005 3.78222L11.1665 4.01622L8.98246 1.83222L9.21646 1.59822C9.43747 1.37722 9.72346 1.26022 10.0225 1.26022C10.3215 1.26022 10.6205 1.37722 10.8285 1.59822L11.3875 2.15722C11.6085 2.37822 11.7255 2.66422 11.7255 2.96322C11.7385 3.27522 11.6215 3.56122 11.4005 3.78222Z"
                         fill="#8999AB"
                    />
               </g>
               <defs>
                    <clipPath id="clip0_1541_2089">
                         <rect width="13" height="13" fill="white" />
                    </clipPath>
               </defs>
          </svg>
     );
};

export default EditSVG;
