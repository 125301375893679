import React from "react";
import SearchBar from "../../../../../basicultils/SearchBar";
import ContactLeftListingPage from "./ContactLeftListingPage";

const ContactLeft = () => {
     return (
          <div className=" w-full h-full overflow-hidden max-w-[500px]  flex flex-col">
               <div className=" w-full h-[70px] flex flex-col justify-center items-center ">
                    <SearchBar
                         searchbarcss={
                              "w-[90%] h-[33px] relative overflow-hidden   flex flex-row justify-between font-montserrat"
                         }
                         isLogoAtFront={true}
                    />
               </div>
               <div className=" flex-1 overflow-hidden">
                    <ContactLeftListingPage />
               </div>
          </div>
     );
};

export default ContactLeft;
