import React from "react";
import TickAndXmark from "../../components/OtherComponents/Utils/TickAndXmark";
import {
     enterPrisePlan,
     feePlan,
     professionalPlan,
} from "../../config/SelectPlanConfig";
import ButtonComponent from "../../basicultils/ButtonComponent";

const RowElement = ({ value, text, id }) => [
     <div className=" w-full flex flex-row items-center gap-[1rem]">
          <TickAndXmark value={value} />

          <h2
               className={` font-manrope text-[16px] font-[500]   ${
                    value
                         ? ` ${id ? "text-[#fff]" : "text-[#191D23]"} `
                         : " text-[#A0ABBB]"
               }`}
          >
               {text}
          </h2>
     </div>,
];

const PlanUILandingPage = ({
     content,
     onClick = () => {},
     isSelected = false,
}) => {
     let id = content?.id === professionalPlan.id;

     const clickHandler = () => {
          onClick(content);
     };
     return (
          <div
               className={` lg:w-[27vw] md:w-[30vw] w-[90vw]  h-fit  rounded-[6px] p-[2rem] overflow-y-auto ${
                    id ? " bg-mainRectangeGredient" : " bg-[#fff]"
               } `}
          >
               <div className=" w-full h-[170px] border-b-2 flex flex-col justify-evenly pb-[1rem] ">
                    <h1
                         className={`   font-manrope font-bold  ${
                              id ? "text-[#fff]" : "text-[#333333]"
                         } text-[22px]`}
                    >
                         {content?.title}
                    </h1>
                    <div className=" flex flex-row items-center gap-[0.2rem] ">
                         <h3
                              className={`  font-manrope font-bold text-[46px]  ${
                                   id ? "text-[#fff]" : "text-[#000]"
                              } `}
                         >
                              {content?.id === feePlan.id && "₹0"}
                              {content?.id === professionalPlan.id && "₹699"}
                              {content?.id === enterPrisePlan.id && (
                                   <p className=" text-[32px]">
                                        {"Custom price"}
                                   </p>
                              )}
                         </h3>
                         {content?.id != enterPrisePlan.id && (
                              <h5
                                   className={` font-manrope  font-light text-[16px]  ${
                                        id ? "text-[#fff]" : "text-[#888888]"
                                   }`}
                              >
                                   / Month
                              </h5>
                         )}
                    </div>
                    {content?.id === feePlan.id && (
                         <ButtonComponent
                              onClick={clickHandler}
                              className=" w-full h-[44px] border-2 border-[8999AB] bg-[#F5F5F5] font-semibold font-manrope text-[#8999AB] text-[16px] "
                         >
                              {isSelected ? "Current Plan" : "Get Started"}
                         </ButtonComponent>
                    )}
                    {content?.id === professionalPlan.id && (
                         <ButtonComponent
                              onClick={clickHandler}
                              className=" w-full h-[44px] border-2 border-[8999AB] bg-[#FFF] font-semibold font-manrope  text-[16px] "
                         >
                              <p className=" bg-clip-text text-transparent bg-mainRectangeGredient">
                                   {isSelected ? "Current Plan" : "Get Started"}
                              </p>
                         </ButtonComponent>
                    )}
                    {content?.id === enterPrisePlan.id && (
                         <ButtonComponent
                              onClick={clickHandler}
                              className=" w-full h-[44px] border-2 border-[#F17A01]  font-semibold font-manrope  text-[16px] "
                         >
                              <p className=" bg-clip-text text-transparent bg-mainRectangeGredient">
                                   {isSelected ? "Current Plan" : "Get Started"}
                              </p>
                         </ButtonComponent>
                    )}
               </div>

               <div className=" w-full h-fit flex flex-col gap-[0.8rem] pt-[0.8rem] ">
                    {content?.features.map((item) => {
                         return (
                              <RowElement
                                   value={item.value}
                                   text={item.text}
                                   id={id}
                              />
                         );
                    })}
               </div>
          </div>
     );
};

export default PlanUILandingPage;
