import { createSlice } from "@reduxjs/toolkit";
import {
     CalenderMonths,
     hoursArray,
     minutesArray,
     zoneArray,
} from "../../../../../config/CalenderConfig";

const initialSettingDateArr = () => {
     let currentDate = new Date();

     let currentMonth = currentDate.getMonth();
     let currentFullYear = currentDate.getFullYear();

     let firstDayOfCurrentMonth = new Date(
          `01-${CalenderMonths[currentMonth]}-${currentFullYear}`
     );

     let firstDayOfNextMonth = new Date(
          `01-${CalenderMonths[currentMonth === 11 ? 0 : currentMonth + 1]}-${
               currentMonth === 11 ? ++currentFullYear : currentFullYear
          }`
     );

     let currentMonthLastDate = new Date(
          new Date(firstDayOfNextMonth).setDate(
               firstDayOfCurrentMonth.getDate() - 1
          )
     );

     let prevMonthLastDate = new Date(
          new Date(firstDayOfCurrentMonth).setDate(
               firstDayOfCurrentMonth.getDate() - 1
          )
     );

     console.log(
          firstDayOfCurrentMonth,
          firstDayOfNextMonth,
          currentMonthLastDate,
          prevMonthLastDate
     );

     let dateArray = [];
     let noOfDays = currentMonthLastDate.getDate();
     for (let i = 1; i <= noOfDays; i++) {
          dateArray.push({
               date: i,
               isSelected: false,
               selectedAble: true,
          });
     }

     let prevMonthLastDay = prevMonthLastDate.getDay();

     if (prevMonthLastDay === 0) {
          prevMonthLastDay = 7;
     }

     console.log(prevMonthLastDay);
     for (let i = 0; i <= prevMonthLastDay; i++) {
          dateArray.unshift({
               date: prevMonthLastDate.getDate() - i,
               isSelected: false,
               selectedAble: false,
          });
     }

     for (let i = 1; i < 45 - noOfDays; i++) {
          dateArray.push({
               date: i,
               isSelected: false,
               selectedAble: false,
          });
     }

     return dateArray;
};

// Initial Minutes, hours and Zones

const initialMinutes = () => {
     let d = new Date();
     let minutes = d.getMinutes();
     return minutes;
};

const initialHours = () => {
     let d = new Date();
     let hours = d.getHours();
     if (hours <= 12) return hours;
     return hours - 12;
};

const initialZone = () => {
     let d = new Date();
     let hours = d.getHours();
     if (hours < 12) return 0;
     return 1;
};

const initialState = {
     selectedDate: new Date(),
     dateArr: initialSettingDateArr(),

     currentSlide: new Date(),

     time: {
          minutes: minutesArray[initialMinutes()],
          minutesIndex: initialMinutes(),

          hours: hoursArray[initialHours()],
          hoursIndex: initialHours(),

          zones: zoneArray[initialZone()],
          zonesIndex: initialZone(),
     },

     isDisplaying: false,
};

const calenderSlice = createSlice({
     name: "Calender UI",
     initialState: initialState,

     reducers: {
          prevMonth(state, action) {
               let currentSlide = new Date(state.currentSlide);

               let currentMonth = currentSlide.getMonth();
               let currentFullYear = currentSlide.getFullYear();

               let firstDateOfCurrentSlide = new Date(
                    `01-${CalenderMonths[currentMonth]}-${currentFullYear}`
               );

               let prevMonthLastDate = new Date(
                    new Date(firstDateOfCurrentSlide).setDate(
                         firstDateOfCurrentSlide.getDate() - 1
                    )
               );

               let prevMonthFirstDateObj = new Date(
                    new Date(prevMonthLastDate).setDate("1")
               );

               let prevPrevMonthLastDateObj = new Date(
                    new Date(prevMonthFirstDateObj).setDate(
                         prevMonthFirstDateObj.getDate() - 1
                    )
               );

               console.log(
                    prevMonthFirstDateObj,
                    prevMonthLastDate,
                    prevPrevMonthLastDateObj
               );

               let selectedDateObj = state.selectedDate;
               let selectedDate = selectedDateObj.getDate();
               let selectedMonth = selectedDateObj.getMonth();
               let selectedFullYear = selectedDateObj.getFullYear();

               console.log(selectedDate, selectedMonth);

               let dateArray = [];

               let noOfDays = prevMonthLastDate.getDate();
               for (let i = 1; i <= noOfDays; i++) {
                    dateArray.push({
                         date: i,
                         isSelected:
                              prevMonthLastDate.getMonth() === selectedMonth &&
                              selectedFullYear ===
                                   prevMonthLastDate.getFullYear() &&
                              i === selectedDate
                                   ? true
                                   : false,
                         selectedAble: true,
                    });
               }

               let prevMonthLastDateDay = prevPrevMonthLastDateObj.getDay();

               if (prevMonthLastDateDay === 0) {
                    prevMonthLastDateDay = 7;
               }

               for (let i = 0; i <= prevMonthLastDateDay; i++) {
                    dateArray.unshift({
                         date: prevPrevMonthLastDateObj.getDate() - i,
                         isSelected: false,
                         selectedAble: false,
                    });
               }

               for (let i = 1; i < 45 - noOfDays; i++) {
                    dateArray.push({
                         date: i,
                         isSelected: false,
                         selectedAble: false,
                    });
               }

               console.log(dateArray);

               return {
                    ...state,
                    dateArr: dateArray,
                    currentSlide: prevMonthLastDate,
               };
          },

          nextMonth(state, action) {
               let currentSlide = new Date(state.currentSlide);

               let currentMonth = currentSlide.getMonth();
               let currentFullYear = currentSlide.getFullYear();

               console.log(currentFullYear, currentMonth);

               let nextMonthFirstDateObj = new Date(
                    `01-${
                         CalenderMonths[
                              currentMonth === 11 ? 0 : currentMonth + 1
                         ]
                    }-${
                         currentMonth === 11
                              ? ++currentFullYear
                              : currentFullYear
                    }`
               );

               let currentMonthLastDateObj = new Date(
                    new Date(nextMonthFirstDateObj).setDate(
                         nextMonthFirstDateObj.getDate() - 1
                    )
               );

               let nextMonthDate_month = nextMonthFirstDateObj.getMonth();
               let nextMonthDate_year = nextMonthFirstDateObj.getFullYear();

               let nextNextMonthFirstDateObj = new Date(
                    `01-${
                         CalenderMonths[
                              nextMonthDate_month === 11
                                   ? 0
                                   : nextMonthDate_month + 1
                         ]
                    }-${
                         nextMonthDate_month === 11
                              ? nextMonthDate_year + 1
                              : nextMonthDate_year
                    }`
               );

               let nextMonthLastDateObj = new Date(
                    new Date(nextNextMonthFirstDateObj).setDate(
                         nextNextMonthFirstDateObj.getDate() - 1
                    )
               );

               console.log(
                    nextMonthFirstDateObj,
                    currentMonthLastDateObj,
                    nextNextMonthFirstDateObj,
                    nextMonthLastDateObj
               );

               let selectedDateObj = state.selectedDate;
               let selectedDate = selectedDateObj.getDate();
               let selectedMonth = selectedDateObj.getMonth();
               let selectedFullYear = selectedDateObj.getFullYear();

               console.log(selectedDate, selectedMonth);

               let dateArray = [];

               let nextMonthLastDate = nextMonthLastDateObj.getDate();
               let nextMonth = nextMonthLastDateObj.getMonth();
               let nextMonthYear = nextMonthLastDateObj.getFullYear();

               for (let i = 1; i <= nextMonthLastDate; i++) {
                    dateArray.push({
                         date: i,
                         isSelected:
                              nextMonth === selectedMonth &&
                              selectedFullYear === nextMonthYear &&
                              i === selectedDate
                                   ? true
                                   : false,
                         selectedAble: true,
                    });
               }

               let currentMonthLastDay = currentMonthLastDateObj.getDay();

               if (currentMonthLastDay === 0) {
                    currentMonthLastDay = 7;
               }

               for (let i = 0; i <= currentMonthLastDay; i++) {
                    dateArray.unshift({
                         date: currentMonthLastDateObj.getDate() - i,
                         isSelected: false,
                         selectedAble: false,
                    });
               }

               for (let i = 1; i < 45 - nextMonthLastDate; i++) {
                    dateArray.push({
                         date: i,
                         isSelected: false,
                         selectedAble: false,
                    });
               }
               console.log(dateArray, currentMonthLastDay);

               return {
                    ...state,
                    dateArr: dateArray,
                    currentSlide: nextMonthFirstDateObj,
               };
          },

          setSelectedDate(state, action) {
               const pickedDate = action.payload;

               let currentSlide = new Date(state.currentSlide);

               let currentMonth = currentSlide.getMonth();
               let currentFullYear = currentSlide.getFullYear();

               let pickDateObj = new Date(
                    `${pickedDate < 10 ? "0" + pickedDate : pickedDate}-${
                         CalenderMonths[currentMonth]
                    }-${currentFullYear}`
               );

               return {
                    ...state,
                    selectedDate: pickDateObj,
               };
          },

          alterMinutes(state, action) {
               const change = action.payload;

               let minutesIndex = state.time.minutesIndex + change;

               if (minutesIndex < 0) {
                    minutesIndex = minutesArray.length - 1;
               } else if (minutesIndex >= minutesArray.length) {
                    minutesIndex = 0;
               }

               let minutes = minutesArray[minutesIndex];

               console.log(minutes, minutesIndex, change);

               return {
                    ...state,
                    time: {
                         ...state.time,
                         minutesIndex,
                         minutes,
                    },
               };
          },

          alterHours(state, action) {
               const changes = action.payload;

               let hoursIndex = state.time.hoursIndex + changes;

               if (hoursIndex < 0) {
                    hoursIndex = hoursArray.length - 1;
               } else if (hoursIndex >= hoursArray.length) {
                    hoursIndex = 0;
               }

               let hours = hoursArray[hoursIndex];

               return {
                    ...state,
                    time: {
                         ...state.time,
                         hours,
                         hoursIndex,
                    },
               };
          },

          alterZone(state, action) {
               const change = action.payload;

               let zonesIndex = state.time.zonesIndex + change;

               if (zonesIndex < 0) {
                    zonesIndex = zoneArray.length - 1;
               } else if (zonesIndex >= zoneArray.length) {
                    zonesIndex = 0;
               }

               let zones = zoneArray[zonesIndex];

               return {
                    ...state,
                    time: {
                         ...state.time,
                         zones,
                         zonesIndex,
                    },
               };
          },

          toggleIsDisplaying(state, action) {
               return {
                    ...state,
                    isDisplaying: !state.isDisplaying,
               };
          },
     },
});

export const calenderSliceActions = calenderSlice.actions;
export default calenderSlice;
