// export const baseURL = "http://13.51.197.126:8000/api/v1";
// export const baseURL_WITH_PORT_8080 = "http://13.51.197.126:8081/api/v1";
// export const baseURl_User_Module = "http://13.51.197.126:8080/api/v1";
// export const baseURL_Scheduler = "http://13.51.197.126:8082/api/v1";
// export const baseURL_Profolio = "http://13.51.197.126:8083/api/v1";

export const baseURL = "https://apps.ranblitz.com:9000/api/v1";
export const baseURL_WITH_PORT_8080 = "https://apps.ranblitz.com:9091/api/v1";
export const baseURl_User_Module = "https://apps.ranblitz.com:9090/api/v1";
export const baseURL_Scheduler = "https://apps.ranblitz.com:9092/api/v1";
export const baseURL_Profolio = "https://apps.ranblitz.com:9093/api/v1";

export const defaultHeader = {
     "Content-type": "application/json",
     "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
     redirect: "follow",
};

//-- API Routes

export const searchBussinessRoute = "/business/search";
export const reviewRoute = "/reviews/fetch";
export const createUser = "/users/create";
export const loginUserAPI = "/users/login";
export const schedulersAPIroute = "/schedulers";
export const tiktokCallBack = "/schedulers/tiktok/auth/callback";
export const tiktokDraftPost = "/schedulers/tiktok/post/video";
export const tiktokPublishPostAPIENDPOINT = "/schedulers/tiktok/post/publish";
export const formsFetch = "/forms/fetch";
export const videoFetch = "/videos/fetch";
export const videoupload = "/videos/upload";
export const videodelete = "/videos/delete";

export const loginUser_FullAPI = baseURl_User_Module + loginUserAPI;

export const userMembershipChange = "/users/membership";

export const getbills = "/bills/";
export const postbills = "/bills/create";

// YELP
export const yelp_reviewFetch = "/yelp/reviews/fetch";

// Twitter
export const twitter_posting_apiendpoints = "/schedulers/twitter/post";
export const twitter_scheduler_list = "/schedulers/twitter/post";

// Linkedin
export const linkedin_posting_apiendpoints =
     "/schedulers/linkedin/post/linkedin";

export const linkedin_getMyAccount_apiendpoints =
     "/schedulers/linkedin/my-account";

// Tiktok
export const tiktok_post = "/schedulers/tiktok/post/video";
export const tiktok_list = "/schedulers/tiktok/user/info";
