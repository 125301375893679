import React from "react";
import ButtonComponent from "../../../basicultils/ButtonComponent";
import { routes } from "../../../config/mainConfig";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { useSelector } from "react-redux";

const SchedulerNewPostButton = () => {
     const selectedSocialMedia = useSelector((state) => {
          return state.scheduler.selectedSocialMedia;
     });
     const navigate = useNavigate();
     return (
          <ButtonComponent
               onClick={() => {
                    navigate(
                         routes.scheduler +
                              "/" +
                              selectedSocialMedia?.link +
                              "/" +
                              routes.schedulersocialmediaaddpost
                    );
               }}
               isLoading={false}
               className=" p-[12px] font-montserrat text-[14px] font-[600] text-[#fff] rounded-[4px]  bg-mainRectangeGredient flex items-center gap-2  "
          >
               <FaPlus /> NewPost
          </ButtonComponent>
     );
};

export default SchedulerNewPostButton;
