import { createSlice } from "@reduxjs/toolkit";
import { GetAPI } from "../../API-utils/GetAPI";

export const formsState = {
     left: "Left",
     right: "Right",
};

const initialState = {
     smallScreenMode: formsState.right,
};

const LeadformsSlice = createSlice({
     name: "Leadform-state-management",
     initialState,
     reducers: {
          modifySmallScreenMode(state, action) {
               return {
                    ...state,
                    smallScreenMode: action.payload,
               };
          },
          
     },
});

export const LeadformsSliceActions = LeadformsSlice.actions;
export default LeadformsSlice;

