import React, { useState } from "react";
import ButtonComponent from "../../../../../basicultils/ButtonComponent";
import ContactDeleteSVG from "../../../../templates/Svg/ContactPage/ContactDeleteSVG";
import EyeContactSVG from "../../../../templates/Svg/EyeContactSVG";
import ContactTimeLogSVG from "../../../../templates/Svg/ContactTimeLogSVG";
import {
     baseURL_Profolio,
     videodelete,
} from "../../../../../API-utils/APIConfig";
import { useDispatch, useSelector } from "react-redux";
import { GetAPI } from "../../../../../API-utils/GetAPI";
import { videoFetchListingThunk } from "../../../../../store/contactFormSlice";

const VideoContentLeftIndivRow = ({ content = {}, index = "" }) => {
     const { auth } = useSelector((state) => {
          return state.master;
     });
     const dispatch = useDispatch();
     // Values required in this component
     let status = "Live";

     // Button Detail and view and edit Button css
     const buttonCss =
          "w-[30%]  lg:max-w-[55px] h-[16px] border-[1px] border-[#B8CADE] font-montserrat text-[10px] text-contactLeftButtonTextColor";

     let [isLoading, setIsLoading] = useState(false);
     let deleteButton = async () => {
          setIsLoading(true);
          let response = await deleteVideoFunction(content?.s3key, auth);

          dispatch(videoFetchListingThunk(auth));

          setIsLoading(false);
     };

     return (
          <div
               key={content?.id}
               className=" w-[90%] lg:h-[71px] h-fit flex flex-col justify-evenly border-2 border-borderContactListing rounded-[4px] p-2 lg:gap-0 gap-[0.5rem] duration-100"
          >
               <div className=" w-full h-fit flex lg:flex-row flex-col justify-between lg:gap-[0] gap-[0.35rem]  ">
                    <div className="lg:w-fit w-full flex flex-row lg:justify-start justify-between lg:gap-[0.5rem]  ">
                         <h1 className=" lg:text-[12px] text-[10px] text-[#000] font-montserrat font-[600]">
                              {content?.videoDetails}
                         </h1>
                         <div className=" w-fit h-fit">
                              <p className=" bg-contactLeftStatusBg lg:text-[9px] text-[7px] font-montserrat text-[#333353]   p-1 rounded-[30%] font-[500]">
                                   {status}
                              </p>
                         </div>
                    </div>
                    <div className=" lg:w-fit w-full lg:p-0 pe-2">
                         <ButtonComponent
                              onClick={() => {
                                   navigator.clipboard.writeText(
                                        content?.videoUrl
                                   );
                              }}
                              className=" w-fit h-fit flex flex-row gap-[0.25rem] items-center text-[14px] font-[500] text-[#E91050]"
                         >
                              <svg
                                   width="15"
                                   height="16"
                                   viewBox="0 0 15 16"
                                   fill="none"
                                   xmlns="http://www.w3.org/2000/svg"
                              >
                                   <path
                                        d="M13.125 12.125H7.5C6.44531 12.125 5.625 11.3047 5.625 10.25V2.75C5.625 1.72461 6.44531 0.875 7.5 0.875H11.6016C11.9531 0.875 12.334 1.05078 12.5977 1.31445L14.5605 3.27734C14.8242 3.54102 15 3.92188 15 4.27344V10.25C15 11.3047 14.1504 12.125 13.125 12.125ZM1.875 4.625H4.6875V6.03125H1.875C1.61133 6.03125 1.40625 6.26562 1.40625 6.5V14C1.40625 14.2637 1.61133 14.4688 1.875 14.4688H7.5C7.73438 14.4688 7.96875 14.2637 7.96875 14V13.0625H9.375V14C9.375 15.0547 8.52539 15.875 7.5 15.875H1.875C0.820312 15.875 0 15.0547 0 14V6.5C0 5.47461 0.820312 4.625 1.875 4.625Z"
                                        fill="#E91050"
                                   />
                              </svg>
                              <p>copy</p>
                         </ButtonComponent>
                    </div>
               </div>

               <div className=" w-full  lg:h-[50px] h-fit flex lg:flex-row  flex-col items-center justify-between lg:gap-0 gap-[0.5rem]  ">
                    <div className=" lg:w-fit w-full h-full flex flex-row lg:justify-start justify-between  items-center xl:gap-[1.5rem] gap-[0.75rem]">
                         <div className=" w-fit h-fit flex flex-row items-center xl:gap-[0.5rem] gap-[0.25rem]">
                              {/* <ContactTimeLogSVG />
                              <p className=" w-fit text-[10px]  text-[#000] font-montserrat font-[400]">
                                   12 Jun 2021
                              </p>
                              <p className=" w-fit text-[10px]  text-[#000] font-montserrat font-[400]">
                                   |
                              </p>
                              <p className=" w-fit text-[10px]  text-[#000] font-montserrat font-[400]">
                                   12:30PM
                              </p> */}
                         </div>
                         <div className=" flex flex-row  gap-[3px] items-center text-[10px]  font-montserrat">
                              {/* <EyeContactSVG /> 2567 */}
                         </div>
                    </div>

                    <div className=" flex-1 h-full lg:w-fit  w-full   flex flex-row lg:justify-end  justify-center  gap-[0.25rem] items-center  ">
                         <ButtonComponent
                              className={buttonCss}
                              onClick={() => {
                                   deleteButton();
                              }}
                              isLoading={isLoading}
                         >
                              <div className=" flex flex-row justify-evenly items-center font-montserrat">
                                   <ContactDeleteSVG /> Delete
                              </div>
                         </ButtonComponent>
                         <ButtonComponent
                              className={buttonCss}
                              onClick={() => {}}
                              isLoading={false}
                         >
                              <div className=" flex flex-row justify-evenly items-center font-montserrat">
                                   {" "}
                                   <ContactDeleteSVG /> Edit
                              </div>
                         </ButtonComponent>
                         <ButtonComponent
                              className={buttonCss}
                              onClick={() => {}}
                              isLoading={false}
                         >
                              <div className=" flex flex-row justify-evenly items-center font-montserrat">
                                   {" "}
                                   <ContactDeleteSVG /> View
                              </div>
                         </ButtonComponent>
                    </div>
               </div>
          </div>
     );
};

export default VideoContentLeftIndivRow;

const deleteVideoFunction = async (id, auth) => {
     let url = new URL(baseURL_Profolio + videodelete + "/" + id);

     let headers = {
          Authorization: auth.sessionToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": [
               "POST",
               "GET",
               "OPTIONS",
               "DELETE",
               "PUT",
          ],
          "Access-Control-Allow-Headers": [
               "append",
               "delete",
               "entries",
               "foreach",
               "get",
               "has",
               "keys",
               "set",
               "values",
               "Authorization",
          ],
     };

     let response = await GetAPI(url, headers, "DELETE");

     if (response?.ok) {
          return response;
     } else {
          return null;
     }
};
