import React from "react";

// Custom Hooks
import UseGetAccountDetails, {
     useGetAccountDetails_getDetailsFrom_modes,
} from "./UseGetAccountDetails";

// Redux Hooks
import { useDispatch, useSelector } from "react-redux";
import { socialmedia } from "../config/socialmediaConfig";

// Thunk
import { updateYelpReviewListThunk } from "../Api-Calling/yelpAPICalling";

// This Hook is to call the review thunk in a single place

// Return array
// Array index 0 : function fetchReview is to fetch the reviews

const useCallingReview = () => {
     const dispatch = useDispatch();

     const [getDetailsFrom] = UseGetAccountDetails();
     const { selectedSocialMedia } = useSelector((state) => {
          return state.dynamicsocialmedia;
     });

     const fetchReview = () => {
          // Getting auth status and auth obj of currently selected
          let socialMediaAuthObj = getDetailsFrom(
               useGetAccountDetails_getDetailsFrom_modes.review
          );

          if (!socialMediaAuthObj?.isAccounted) {
               console.log(selectedSocialMedia.name + " is not accounted yet");
               return;
          }

          if (selectedSocialMedia?.link === socialmedia.yelp?.link) {
               dispatch(updateYelpReviewListThunk(socialMediaAuthObj));
          }
     };

     return [fetchReview];
};

export default useCallingReview;
