import React from "react";
import IndivWallContent from "./IndivWallContent";
import IndivWallContentCard from "./Utils/IndivWallContentCard";

const ListWallContent = ({ content = [] }) => {
     return (
          <div className=" w-full h-full overflow-y-scroll flex flex-row justify-evenly gap-y-4 flex-wrap mt-2 pb-[30px]">
               {content.map((cont) => {
                    return <IndivWallContent content={cont} />;
               })}

               <IndivWallContentCard isDummy={true}></IndivWallContentCard>
               <IndivWallContentCard isDummy={true}></IndivWallContentCard>
          </div>
     );
};

export default ListWallContent;
