import React from "react";
import PlanUI from "../../components/OtherComponents/Utils/PlanUI";
import {
     enterPrisePlan,
     feePlan,
     professionalPlan,
} from "../../config/SelectPlanConfig";
import PlanUILandingPage from "./PlanUILandingPage";
import { routes } from "../../config/mainConfig";

const SelectPlanSectionLandingPage = () => {
     return (
          <div
               className=" w-full h-fit pt-[5rem] flex flex-col gap-[3rem] "
               id={"pricing"}
          >
               <div className=" w-full h-fit flex flex-col items-center gap-[1.5rem] p-6 rounded-[8px]">
                    <p className=" lg:w-[50%] md:w-[65%] w-[100%]  md:text-center text-start font-poppins text-[48px] leading-[38px] font-[600] text-[#111013]">
                         Our Pricing Plan
                    </p>
               </div>
               <div className=" w-full h-fit flex flex-row overflow-y-auto md:overflow-hidden relative justify-center md:items-center items-start">
                    <div className="w-full md:h-full h-fit flex md:flex-row flex-col gap-[1rem] md:justify-center justify-start items-center  md:mt-[2rem] mt-[3rem] mb-[2rem]">
                         {/* ---------------------------- */}
                         <PlanUILandingPage content={feePlan} />
                         <PlanUILandingPage content={professionalPlan} />
                         <PlanUILandingPage content={enterPrisePlan} />
                         {/* ---------------------------- */}
                    </div>
               </div>
          </div>
     );
};

export default SelectPlanSectionLandingPage;
