import React from "react";
import logoimage from "../../../assest/image/logoimage.png";
import background from "../../../assest/image/MaskGroup.png";

//Side bar for signin and sign up
const SideBar = ({ className }) => {
     return (
          <div className={className + " flex flex-col justify-center"}>
               <div className=" w-full h-fit flex flex-col gap-[2rem] ps-12 pe-4 z-[1000]">
                    <h1 className=" w-[150px] text-headerAndButtonColor font-Lato font-semibold text-[22px]">
                         <img
                              src={logoimage}
                              alt={"company logo"}
                              className=" w-full h-full"
                         />
                    </h1>

                    <div className=" font-poppins text-[36px] font-bold text-[#fff] ">
                         Your Social Voice <br></br> Amplified: Uniting{" "}
                         <br></br> Reviews, One <br></br>Platform at a Time!
                    </div>

                    <h2 className=" text-[24px]  font-poppins font-bold text-[#fff]">
                         Capture. Curate. Connect.
                    </h2>
               </div>

               <div className=" w-full h-[50%] absolute bottom-0 z-[100]">
                    <img src={background} className=" w-full h-full " />
               </div>
          </div>
     );
};

export default SideBar;
