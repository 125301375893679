import React from "react";

const FollowSvg = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="26"
               height="26"
               viewBox="0 0 26 26"
               fill="none"
          >
               <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.625 13C1.625 16.0168 2.82343 18.9101 4.95666 21.0433C7.08989 23.1766 9.98316 24.375 13 24.375C16.0168 24.375 18.9101 23.1766 21.0433 21.0433C23.1766 18.9101 24.375 16.0168 24.375 13C24.375 9.98316 23.1766 7.08989 21.0433 4.95666C18.9101 2.82343 16.0168 1.625 13 1.625C9.98316 1.625 7.08989 2.82343 4.95666 4.95666C2.82343 7.08989 1.625 9.98316 1.625 13ZM26 13C26 16.4478 24.6304 19.7544 22.1924 22.1924C19.7544 24.6304 16.4478 26 13 26C9.55219 26 6.24558 24.6304 3.80761 22.1924C1.36964 19.7544 0 16.4478 0 13C0 9.55219 1.36964 6.24558 3.80761 3.80761C6.24558 1.36964 9.55219 0 13 0C16.4478 0 19.7544 1.36964 22.1924 3.80761C24.6304 6.24558 26 9.55219 26 13ZM7.3125 12.1875C7.09701 12.1875 6.89035 12.2731 6.73798 12.4255C6.5856 12.5778 6.5 12.7845 6.5 13C6.5 13.2155 6.5856 13.4222 6.73798 13.5745C6.89035 13.7269 7.09701 13.8125 7.3125 13.8125H16.7261L13.2372 17.2997C13.1617 17.3753 13.1018 17.465 13.0609 17.5637C13.02 17.6624 12.999 17.7682 12.999 17.875C12.999 17.9818 13.02 18.0876 13.0609 18.1863C13.1018 18.285 13.1617 18.3747 13.2372 18.4503C13.3128 18.5258 13.4025 18.5857 13.5012 18.6266C13.5999 18.6675 13.7057 18.6885 13.8125 18.6885C13.9193 18.6885 14.0251 18.6675 14.1238 18.6266C14.2225 18.5857 14.3122 18.5258 14.3878 18.4503L19.2628 13.5753C19.3384 13.4998 19.3984 13.4101 19.4394 13.3114C19.4804 13.2127 19.5015 13.1069 19.5015 13C19.5015 12.8931 19.4804 12.7873 19.4394 12.6886C19.3984 12.5899 19.3384 12.5002 19.2628 12.4247L14.3878 7.54975C14.3122 7.47421 14.2225 7.41428 14.1238 7.3734C14.0251 7.33252 13.9193 7.31147 13.8125 7.31147C13.7057 7.31147 13.5999 7.33252 13.5012 7.3734C13.4025 7.41428 13.3128 7.47421 13.2372 7.54975C13.1617 7.62529 13.1018 7.71497 13.0609 7.81368C13.02 7.91238 12.999 8.01817 12.999 8.125C12.999 8.23183 13.02 8.33762 13.0609 8.43632C13.1018 8.53502 13.1617 8.62471 13.2372 8.70025L16.7261 12.1875H7.3125Z"
                    fill="white"
               />
          </svg>
     );
};

export default FollowSvg;
