import React from "react";
import ProfileImageComponent from "../../../basicultils/ProfileImageComponent";
import SocialMediaLogo from "../../../basicultils/SocialMediaLogo";
import LikeAndDateCom from "../utils/LikeAndDateCom";
import DeleteSVG from "../../templates/Svg/DeleteSVG";
import ButtonComponent from "../../../basicultils/ButtonComponent";
import IndivWallContentCard from "./Utils/IndivWallContentCard";
import { useSelector } from "react-redux";
import { getDateText } from "../../../config/CalenderConfig";
// const src1 =
//      "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg";

const IndivWallContent = ({ content }) => {
     const selectedSocialMedia = useSelector((state) => {
          return state.dynamicsocialmedia.selectedSocialMedia;
     });

     let user = content?.user;

     const dateText = getDateText(content.time_created);

     return (
          <IndivWallContentCard>
               <div
                    className=" w-full h-[60px] flex flex-row justify-between  lg:p-2 p-1"
                    key={content?.id}
               >
                    {/* Profile Photo section */}
                    <div className=" lg:w-[44px] w-[35px] h-full flex flex-col justify-center items-center ">
                         <ProfileImageComponent
                              image={user?.image_url}
                              size={
                                   " lg:w-[36px] lg:h-[36px] w-[28px] h-[28px]"
                              }
                         />
                    </div>
                    {/* profile username section */}
                    <div className=" flex-1 flex flex-col justify-center lg:ps-2 ps-1 ">
                         <div className=" w-fit h-min  font-montserrat  lg:text-[12px] text-[10px]  ">
                              <h1 className=" text-mentioncolor font-bold truncate">
                                   {user.name}
                              </h1>
                              <p className="  font-[500]  text-profileGreyTextColor">
                                   {/* {email} */}
                              </p>
                         </div>
                    </div>
                    {/* social media section  */}
                    <div className=" lg:w-[60px] w-[40px] h-full  flex flex-row justify-center items-center">
                         <SocialMediaLogo
                              content={selectedSocialMedia}
                              maincss="lg:w-[25px] lg:h-[25px] w-[20px] h-[20px]"
                         />
                    </div>
               </div>

               {/* Content Page  */}
               <div className=" flex-1 overflow-hidden ">
                    <div
                         className=" w-full h-full ps-3 font-montserrat text-[10px]   overflow-y-auto text-mentionparaTextColor   "
                         //  style={{
                         //       textOverflow: "ellipsis",
                         //  }}
                    >
                         {content.text}
                    </div>
               </div>

               <div className=" w-full h-[30px] flex flex-row   justify-between items-center  lg:p-3 p-2">
                    <LikeAndDateCom likeno={content.rating} date={dateText} />
                    <ButtonComponent
                         isLoading={false}
                         onClick={() => {}}
                         className=" w-min h-min"
                    >
                         <DeleteSVG />
                    </ButtonComponent>
               </div>
          </IndivWallContentCard>
     );
};

export default IndivWallContent;
