import React from "react";

const InputComponent = ({
     type = "text",
     placeholder = "Enter Something",
     value = "",
     className = " ",
     onChange = () => {},
}) => {
     // const [text, setText] = useState("");
     // const changeHandler = (e) => {
     //      onChange(e.target.value);
     // };
     return (
          <input
               type={type}
               placeholder={placeholder}
               value={value}
               onChange={onChange}
               className={className}
          />
     );
};

export default InputComponent;
