import React, { useEffect } from "react";
import HeaderSection from "./HeaderSection";
import SideBarSection from "./SideBarSection";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { navBarSliceActions } from "../../../store/DashboardSlices/NavbarSlice";

import UseAuthenticationOperations from "../../../customHooks/UseAuthenticationOperations";

const DashboardTemplate = () => {
     const isOpenSideNav = useSelector((state) => {
          return state.navbar.isOpenSideNav;
     });

     const dispatch = useDispatch();

     const [isAuthenticated] = UseAuthenticationOperations();
     useEffect(() => {
          isAuthenticated();
     }, []);

     return (
          <>
               <div className="  w-full h-screen  flex-col md:flex hidden  overflow-hidden">
                    <HeaderSection />
                    <div className=" flex-1  flex flex-row overflow-auto  ">
                         <SideBarSection />
                         <div className=" flex-1 overflow-hidden   ">
                              <Outlet />
                         </div>
                    </div>
               </div>

               <div className=" w-full h-screen flex flex-col md:hidden  overflow-hidden">
                    <div className=" w-full h-fit border-b-2">
                         <HeaderSection
                              isBigscreen={false}
                              onClickHamHandler={() => {
                                   dispatch(
                                        navBarSliceActions.modifyIsOpenNavBar(
                                             !isOpenSideNav
                                        )
                                   );
                              }}
                         />
                    </div>

                    <div className=" flex-1 overflow-hidden  ">
                         <div className=" w-full h-full relative ">
                              <Outlet />
                              {isOpenSideNav ? (
                                   <div className=" w-full h-full  bg-black bg-opacity-50  absolute top-0 flex flex-row">
                                        <SideBarSection isBigscreen={false} />
                                   </div>
                              ) : (
                                   <div></div>
                              )}
                         </div>
                    </div>
               </div>
          </>
     );
};

export default DashboardTemplate;
