import React from "react";
import { contactRigthColumns } from "../../../../../config/ContactConfig";
import ButtonComponent from "../../../../../basicultils/ButtonComponent";
import RightArrow from "../../../../templates/Svg/ContactPage/RightArrow";
import ContactRigthListingEachColumnSection from "./Utils/ContactRigthListingEachColumnSection";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../../config/mainConfig";
import { useDispatch } from "react-redux";
import { contactFormSliceActions } from "../../../../../store/contactFormSlice";

// Component to display the contact right form item

const Ptag = ({ children }) => {
     return (
          <p className=" text-[12px] truncate  font-montserrat font-[400]  text-contactRightListitemsTextColor">
               {children}
          </p>
     );
};

function formatDate(dateObj) {
     const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
     ];

     dateObj = new Date(dateObj);
     const day = dateObj.getDate();
     const month = months[dateObj.getMonth()];
     const year = dateObj.getFullYear();

     let hours = dateObj.getHours();
     const minutes = dateObj.getMinutes();
     const ampm = hours >= 12 ? "PM" : "AM";
     hours = hours % 12;
     hours = hours ? hours : 12; // the hour '0' should be '12'

     const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

     return `${day} ${month} ${year} ${hours}:${formattedMinutes}${ampm}`;
}

const IndivContactRigthItem = ({ index = "0", content = {} }) => {
     const emailId = content?.email;
     const formName = content?.formName;
     const dateTime = formatDate(content?.createdDate);
     const actionIp = "";

     //-----------------------------------------------

     const navigate = useNavigate();
     const dispatch = useDispatch();

     const viewClickHandler = () => {
          dispatch(contactFormSliceActions.setContactForm());
          navigate(routes.contactforms);
     };

     return (
          <div
               className=" min-w-full w-fit h-[50px] flex flex-row border-b-2 ps-4 justify-between "
               key={index}
          >
               <ContactRigthListingEachColumnSection
                    width={contactRigthColumns[0].width}
               >
                    <Ptag>{index}</Ptag>
               </ContactRigthListingEachColumnSection>
               <ContactRigthListingEachColumnSection
                    width={contactRigthColumns[1].width}
               >
                    <Ptag>{emailId}</Ptag>
               </ContactRigthListingEachColumnSection>

               <ContactRigthListingEachColumnSection
                    width={contactRigthColumns[2].width}
               >
                    <Ptag>{formName}</Ptag>
               </ContactRigthListingEachColumnSection>
               <ContactRigthListingEachColumnSection
                    width={contactRigthColumns[3].width}
               >
                    <Ptag>{dateTime}</Ptag>
               </ContactRigthListingEachColumnSection>
               <ContactRigthListingEachColumnSection
                    width={contactRigthColumns[4].width}
               >
                    <Ptag>{actionIp}</Ptag>
               </ContactRigthListingEachColumnSection>
               <ContactRigthListingEachColumnSection
                    width={contactRigthColumns[5].width}
               >
                    <ButtonComponent
                         className={
                              "w-[80px] h-[25px] border-[2px] border-[#FFE6E6] rounded-[3px] font-montserrat text-[12px] text-contactLeftButtonTextColor"
                         }
                         onClick={viewClickHandler}
                         isLoading={false}
                    >
                         <div className=" flex flex-row justify-evenly items-center font-montserrat  text-[#E91050]">
                              View <RightArrow />
                         </div>
                    </ButtonComponent>
               </ContactRigthListingEachColumnSection>
          </div>
     );
};

export default IndivContactRigthItem;
