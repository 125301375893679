import { status } from "./GetAPI";

export const PostAPI = async (api, body, headers) => {
     let response = null;
     let responsedata = null;
     // console.log(api, body, headers);
     try {
          response = await fetch(api, {
               method: "POST",
               body: JSON.stringify(body),
               headers: headers,
          });
          responsedata = await response.json();
          return {
               status: status.successful,
               response,
               responsedata,
          };
     } catch (e) {
          //   responsedate = response.data();
          // console.log(e.message);
          // console.log(e.name);
          // console.log(e.status);

          return {
               status: status.error,
               e,
          };
          // console.log(response, responsedate);
     }
};

export const PostAPIWithOutStringify = async (api, body, headers = {}) => {
     let response = null;
     let responsedata = null;
     console.log(api, body, headers);
     try {
          response = await fetch(api, {
               method: "POST",
               body: body,
               headers: headers,
          });
          responsedata = await response.json();
          return {
               status: status.successful,
               response,
               responsedata,
          };
     } catch (e) {
          //   responsedate = response.data();
          // console.log(e.message);
          // console.log(e.name);
          // console.log(e.status);

          return {
               status: status.error,
               e,
          };
          // console.log(response, responsedate);
     }
};
