import React from "react";
import SchedulerWeekGridViewHeader from "./SchedulerWeekGridViewHeader";
import SchedulerWeekGridEachRow from "./SchedulerWeekGridEachRow";

const SchedulerWeekLayout = () => {
     return (
          <div className=" w-fit h-full  p-4 bg-red-50 ">
               <SchedulerWeekGridViewHeader />

               <SchedulerWeekGridEachRow />
               <SchedulerWeekGridEachRow />
               <SchedulerWeekGridEachRow />

               <SchedulerWeekGridEachRow />
               <SchedulerWeekGridEachRow />
               <SchedulerWeekGridEachRow />

               <SchedulerWeekGridEachRow />
               <SchedulerWeekGridEachRow />
               <SchedulerWeekGridEachRow />

               <SchedulerWeekGridEachRow />
               <SchedulerWeekGridEachRow />
               <SchedulerWeekGridEachRow />

               <SchedulerWeekGridEachRow />
               <SchedulerWeekGridEachRow />
               <SchedulerWeekGridEachRow />
          </div>
     );
};

export default SchedulerWeekLayout;
