import React, { useEffect, useState } from "react";

import IndivSideNavBar from "./Utils/IndivSideNavBar.js";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import LeftArrowLandingPagesvg from "../Svg/Landingpagesvgs/LeftArrowLandingPagesvg.js";
import ButtonComponent from "../../../basicultils/ButtonComponent.js";
import { useDispatch, useSelector } from "react-redux";
import { navBarSliceActions } from "../../../store/DashboardSlices/NavbarSlice.js";
import UseRoleAuthenticationOperation from "../../../customHooks/UseRoleAuthenticationOperation.js";

const SideBarSection = ({ isBigscreen = true }) => {
     const dispatch = useDispatch();
     const [isExtended, setIsExtended] = useState(false);

     // Custom Hooks to find that access is allowed or not
     const checkAccessAllowed = UseRoleAuthenticationOperation()[0];
     let isAllowed = checkAccessAllowed();

     useEffect(() => {
          setIsExtended(isBigscreen ? isExtended : true);
     }, [isBigscreen, isExtended]);

     // const scheduler = ()
     const navBar = useSelector((state) => {
          return state?.navbar;
     });
     // console.log(navBar);
     let links = navBar?.navbarLink;

     return (
          <div
               className={` ${
                    isExtended ? "w-[230px]" : "w-[73px] "
               }  h-full bg-mainRectangeGredient flex flex-col items-start gap-[1vh] relative duration-200  `}
          >
               {isBigscreen && (
                    <ButtonComponent
                         onClick={() => {
                              setIsExtended((state) => {
                                   return isBigscreen ? !state : true;
                              });
                         }}
                         className={` ${
                              isExtended ? "" : " rotate-180"
                         } absolute w-[30px] h-[30px] rounded-[50%] bg-[#F17A01] bg-opacity-80 flex flex-row justify-center items-center right-[-15px] z-[1500] top-[2rem]`}
                    >
                         <MdOutlineKeyboardArrowLeft size={30} color="grey" />
                    </ButtonComponent>
               )}

               <div className=" w-full h-[5rem]"></div>

               {isAllowed && (
                    <>
                         <IndivSideNavBar
                              resetFunction={() => {
                                   dispatch(
                                        navBarSliceActions.resetReviewNavLink()
                                   );
                              }}
                              content={links.review}
                              isExtended={isExtended}
                         />
                         <IndivSideNavBar
                              content={links.leadforms}
                              isExtended={isExtended}
                         />
                         <IndivSideNavBar
                              content={links.leadvideo}
                              isExtended={isExtended}
                         />
                         <IndivSideNavBar
                              content={links.scheduler}
                              isExtended={isExtended}
                              resetFunction={() => {
                                   dispatch(
                                        navBarSliceActions.resetSchedulerNavLink()
                                   );
                              }}
                         />
                         <IndivSideNavBar
                              content={links.accountsetting}
                              isExtended={isExtended}
                         />
                    </>
               )}
               {/* <IndivSideNavBar
                    content={links.dashboard}
                    isExtended={isExtended}
               /> */}
               {/* <IndivSideNavBar
                    content={links.socialMedia}
                    isExtended={isExtended}
               /> */}
          </div>
     );
};

export default SideBarSection;
