import React from "react";
import { socialmedia } from "../config/socialmediaConfig";

const SocialMediaLogo = ({ maincss, content = socialmedia.yelp }) => {
     return (
          <div className={"" + maincss}>
               <img
                    src={content.image}
                    alt={content.name}
                    className="object-cover"
               />
          </div>
     );
};

export default SocialMediaLogo;
