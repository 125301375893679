import React from "react";
import ListingDashboardItems from "../components/DashboardPage/ListingDashboardItems";

const DashboardPage = () => {
     return (
          <div className=" w-full h-full flex flex-col">
               <div className=" w-full h-[75px] flex flex-col justify-center font-montserrat font-bold text-[18px] text-dashboardheader  ps-[2%]">
                    Dashboard
               </div>
               <div className=" flex-1 overflow-hidden">
                    <ListingDashboardItems content={[]} />
               </div>
          </div>
     );
};

export default DashboardPage;
