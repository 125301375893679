import React from "react";

const DoneSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="21"
               height="20"
               viewBox="0 0 21 20"
               fill="none"
          >
               <path
                    d="M10.1055 17.5C14.2476 17.5 17.6055 14.1421 17.6055 10C17.6055 5.85786 14.2476 2.5 10.1055 2.5C5.96333 2.5 2.60547 5.85786 2.60547 10C2.60547 14.1421 5.96333 17.5 10.1055 17.5Z"
                    stroke="#02A443"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
               />
               <path
                    d="M7.60547 9.99998L9.27214 11.6666L12.6055 8.33331"
                    stroke="#02A443"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
               />
          </svg>
     );
};

export default DoneSVG;
