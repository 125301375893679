import React from "react";

const LeftArrowSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="10"
               height="16"
               viewBox="0 0 10 16"
               fill="none"
          >
               <path d="M9 1L2 8L9 15" stroke="#D6D6D6" stroke-width="2" />
          </svg>
     );
};

export default LeftArrowSVG;
