import React from "react";
import ButtonComponent from "../../../../../basicultils/ButtonComponent";
import { useDispatch } from "react-redux";
import {
     LeadformsSliceActions,
     formsState,
} from "../../../../../store/LeadformsSlices/LeadformSlice";

const ContactRigthHeader = () => {
     const dispatch = useDispatch();
     return (
          <div className=" w-full md:h-[100px] h-[40px] flex flex-row items-end justify-between md:ps-8 md:pe-8 ps-4 pe-4 ">
               <h1 className=" text-mentionButtoncolor font-montserrat font-[700] md:text-[24px] text-[18px]">
                    Contact Form
               </h1>
               <ButtonComponent
                    onClick={() => {
                         dispatch(
                              LeadformsSliceActions.modifySmallScreenMode(
                                   formsState.left
                              )
                         );
                    }}
                    className=" md:w-[120px] md:h-[35px]  w-[75px] h-[30px] border-2 rounded-[6px] bg-mainRectangeGredient font-[600] font-montserrat  md:text-[18px] text-[14px] text-[#fff]"
               >
                    Export
               </ButtonComponent>
          </div>
     );
};

export default ContactRigthHeader;
