import React from "react";

import eyecatcher from "../../assest/landingpageimages/eighthsectioneyecatcher.png";
import EightSectionContentBox from "./EightSectionContentBox";
import UntiedSVG from "../../components/templates/Svg/Landingpagesvgs/UntiedSVG";
import TeamCollboration from "../../components/templates/Svg/Landingpagesvgs/TeamCollboration";
import WorkSpace from "../../components/templates/Svg/Landingpagesvgs/WorkSpace";
import WorkFlowSVG from "../../components/templates/Svg/Landingpagesvgs/WorkFlowSVG";

const content = [
     {
          title: "Unified Inbox",
          body: "Manage your reviews, mentions, comments, feedback, and testimonials from a unified inbox.",
     },
     {
          title: "Team Collaboration",
          body: "Invite your team members to collaborate on managing reviews, comments, and mentions.",
     },
     {
          title: "Workspaces",
          body: "Create workspaces for each brands and manage users and permissions for each workspaces.",
     },
     {
          title: "Workflow",
          body: "Automate your feedback, testimonial, and review collection with our email & sms workflow.",
     },
];

const EightSection = () => {
     return (
          <div className=" w-full pt-[4rem] pb-[4rem] bg-[#111013] relative overflow-hidden">
               <div className=" w-full h-fit flex flex-col items-center gap-[1.5rem] p-6">
                    <h1 className=" w-[100%] flex flex-row md:justify-center justify-start font-poppins text-[20px] font-[600] bg-clip-text text-transparent bg-mainRectangeGredient2 ">
                         FEATURES
                    </h1>
                    <p className=" lg:w-[50%] md:w-[65%] w-[100%]  md:text-center text-start font-poppins text-[36px] leading-[38px] font-[600] text-[#fff]">
                         Enhance Team Collaboration and Workflow Optimization
                    </p>
                    <p className=" md:w-[75%] w-[100%] md:text-center text-start font-poppins text-[20px] leading-[24px] font-[500] text-[#9497A1] ">
                         Empower your team with streamlined communication tools
                         and collaborative workspaces, ensuring seamless
                         workflow optimization and productivity enhancement.
                    </p>
               </div>

               <div className=" w-full md:h-[80vh] h-fit flex md:flex-row flex-col-reverse  md:gap-0 gap-[1rem] md:mt-0 mt-[1rem]">
                    <div className="md:w-[50%] w-full h-full flex flex-row justify-center">
                         <div className=" xl:w-[80%] md:w-[90%] w-full h-full border-[#fff] flex flex-col md:gap-0 gap-[1.2rem] md:justify-evenly">
                              <EightSectionContentBox
                                   title={content[0].title}
                                   body={content[0].body}
                              >
                                   <UntiedSVG />
                              </EightSectionContentBox>
                              <EightSectionContentBox
                                   title={content[1].title}
                                   body={content[1].body}
                              >
                                   <TeamCollboration />
                              </EightSectionContentBox>
                              <EightSectionContentBox
                                   title={content[2].title}
                                   body={content[2].body}
                              >
                                   <WorkSpace />
                              </EightSectionContentBox>
                              <EightSectionContentBox
                                   title={content[3].title}
                                   body={content[3].body}
                              >
                                   <WorkFlowSVG />
                              </EightSectionContentBox>
                         </div>
                    </div>
                    <div className=" md:w-[50%] w-full md:h-full h-fit flex flex-row justify-center items-center">
                         <div className=" md:w-[80%] md:h-[80%] w-[90%] h-[90%]">
                              <img
                                   src={eyecatcher}
                                   className=" w-full h-full object-contain"
                                   alt=""
                              />
                         </div>
                    </div>
               </div>

               <div className=" hidden md:block w-[7.5vw] h-[7.5vw] bg-[#000] rounded-[50%] absolute left-[10vw] top-[10vh]"></div>
               <div className="hidden md:block w-[12.5vw] h-[12.5vw] bg-[#000] rounded-[50%] absolute right-[-2vw] top-[-6vh]"></div>
               <div className="hidden md:block w-[10vw] h-[10vw] bg-[#000] rounded-[50%] absolute right-[2vw] bottom-[1vw]"></div>
          </div>
     );
};

export default EightSection;
