import React from "react";
import IndivMentionContent from "./IndivMentionContent";

const ListingMentionContent = ({ content = [] }) => {
    // console.log(content);
     return (
          <div
               className=" w-full h-full  flex flex-col lg:gap-6 gap-3 pb-8 items-center overflow-y-scroll 
           "
          >
               {content.map((item) => {
                    return <IndivMentionContent content={item} />;
               })}
          </div>
     );
};

export default ListingMentionContent;
