import React from "react";
import CheckBoxComponent from "../../../../basicultils/CheckBoxComponent";

const SchedulerFullSelect = () => {
     return (
          <div className=" w-full h-[70px] flex flex-row justify-between ps-4 pe-4 border-2">
               <div className=" w-fit h-full  flex flex-row gap-8">
                    <div className=" w-fit h-full flex flex-col justify-center ">
                         <div className=" w-fit h-fit flex flex-row gap-2">
                              <CheckBoxComponent
                                   value={false}
                                   onClick={() => {}}
                              />
                              <h1 className="  text-[#585E6A] text-[14px] font-poppins">
                                   Hide Posted
                              </h1>
                         </div>
                    </div>
                    <div className=" w-fit h-full flex flex-col justify-center ">
                         <div className=" w-fit h-fit flex flex-row gap-2">
                              <CheckBoxComponent
                                   value={false}
                                   onClick={() => {}}
                              />
                              <h1 className="  text-[#585E6A] text-[14px] font-poppins">
                                   Show Scheduled
                              </h1>
                         </div>
                    </div>
               </div>
               <div></div>
          </div>
     );
};

export default SchedulerFullSelect;
