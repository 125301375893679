import FormsContent from "../../components/LeadFormsComp/FormsCom/FormsContact/FormsContent";

const Forms = () => {
     return (
          <div className=" w-full h-full">
               <FormsContent />
          </div>
     );
};

export default Forms;
