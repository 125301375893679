import React from "react";

const ContactBodyDeleteCard = ({ children }) => {
     return (
          <div className=" w-full h-full flex flex-col justify-center items-center">
               <div className=" md:w-[524px] w-[65%] h-[250px] bg-[#fff] p-3 ">
                    {children}
               </div>
          </div>
     );
};

export default ContactBodyDeleteCard;
