import React from "react";
import ContactBodyHeaderText from "./ContactBodyHeaderText";
import EditAndSaveButton from "./EditAndSaveButton";

import ContactBodyEditComponent from "./Edit/ContactBodyEditComponent";
import ContactBodyDeleteComponet from "./Delete/ContactBodyDeleteComponet";
import ContactBodyViewComponent from "./ContactBodyViewComponent";
import ContactBodySuccessfullDelete from "./Delete/ContactBodySuccessfullDelete";
import { useSelector } from "react-redux";
import { contactFormModes } from "../../../../config/ContactFormConfig";

const ContactBody = () => {
     const mode = useSelector((state) => state.contactform.mode);

     // console.log(mode);

     return (
          <>
               <EditAndSaveButton />
               <div className=" w-full h-full overflow-auto  flex flex-col items-center">
                    <div className=" md:w-[500px] w-[90%]  h-fit flex flex-col gap-12">
                         <ContactBodyHeaderText />

                         {contactFormModes.form.view === mode.form && (
                              <ContactBodyViewComponent />
                         )}
                         {contactFormModes.form.edit === mode.form && (
                              <ContactBodyEditComponent />
                         )}
                         {contactFormModes.delete.deleting === mode.delete && (
                              <ContactBodyDeleteComponet />
                         )}
                         {contactFormModes.delete.successfullyDeleted ===
                              mode.delete && <ContactBodySuccessfullDelete />}
                    </div>
               </div>
          </>
     );
};

export default ContactBody;
