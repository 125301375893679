import React from "react";
import IndivReviewSchedulerSocialMediaCard from "../Utils/SocialMediaListingTemplates/IndivReviewSchedulerSocialMediaCard";
import IndivSchedulerSocialMediaCom from "../Utils/SocialMediaListingTemplates/IndivSchedulerSocialMediaCom";
import { schedulerPageSocialMediaListing } from "../../config/socialmediaConfig";

const SchedulerSocialMediaListingCom = () => {
     return (
          <div className=" w-full h-full flex flex-row  justify-evenly overflow-auto  flex-wrap gap-[20px] lg:p-[20px] p-[10px] pt-[80px] ">
               {schedulerPageSocialMediaListing.map((media) => {
                    return <IndivSchedulerSocialMediaCom media={media} />;
               })}

               {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => {
                    return (
                         <IndivReviewSchedulerSocialMediaCard
                              isDummy={true}
                         ></IndivReviewSchedulerSocialMediaCard>
                    );
               })}
          </div>
     );
};

export default SchedulerSocialMediaListingCom;
