import React, { useEffect } from "react";
import SearchBar from "../../../../../basicultils/SearchBar";
import VideoContentListing from "./VideoContentListing";

import { useSelector, useDispatch } from "react-redux";
import { videoFetchListingThunk } from "../../../../../store/contactFormSlice";
const VideoContentLeftComLayout = () => {
     const { videoListingArray } = useSelector((state) => {
          return state.contactform.video;
     });

     console.log(videoListingArray);

     return (
          <div className=" w-full h-full max-w-[500px]  flex flex-col">
               <div className=" w-full h-[70px] flex flex-col justify-center items-center ">
                    <SearchBar
                         searchbarcss={
                              "w-[90%] h-[33px] relative overflow-hidden   flex flex-row justify-between font-montserrat"
                         }
                         isLogoAtFront={true}
                    />
               </div>
               <div className="  flex-1 overflow-hidden ">
                    <div className=" w-full h-[90%] overflow-auto ">
                         <VideoContentListing content={videoListingArray} />
                    </div>
               </div>
          </div>
     );
};

export default VideoContentLeftComLayout;
