import React, { useRef, useState } from "react";
import VideoSVG from "../../../templates/Svg/VideoSVG";
import ButtonComponent from "../../../../basicultils/ButtonComponent";
import { FaXmark } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { videoFileUploadThunk } from "../../../../store/contactFormSlice";

const UploadComponents = () => {
     return (
          <ButtonComponent className="w-[350px] h-[350px] flex flex-row justify-center items-center border-2 border-dashed rounded-[8px] border-[#B5B5B5]  shadow-lg ">
               <div className=" w-fit h-fit flex flex-col items-center gap-[1rem]">
                    <VideoSVG width="72" height="48" />
                    <h1 className=" text-[14px] font-montserrat ">
                         Drop your Video here or{" "}
                         <span className=" text-[#1B92FF]">browse</span>{" "}
                         <br></br>
                         <p className=" text-[10px]">
                              {`Note : Videos less than 4 MB with .mp3 format only accepted`}
                         </p>
                    </h1>
               </div>
          </ButtonComponent>
     );
};

const DisplayVideoFile = ({
     uploadState = null,
     clearUploadState = () => {},
}) => {
     console.log(URL.createObjectURL(uploadState));
     return (
          <div className=" relative  w-full h-full border-2 border-dashed rounded-[8px] border-[#B5B5B5]  shadow-lg flex flex-col gap-[2rem] font-bold justify-center items-center ">
               <ButtonComponent
                    onClick={clearUploadState}
                    className=" w-fit h-fit absolute right-[20px] top-[20px]"
               >
                    <FaXmark size={20} />
               </ButtonComponent>
               <div className=" w-[90%] h-[150px] rounded-[8px] bg-red-50 flex flex-row justify-center items-center">
                    The Video is selected
               </div>

               <h1 className=" text-[14px] font-montserrat ">
                    {uploadState?.name}
               </h1>
          </div>
     );
};
const VideoUploadLayout = () => {
     const inputRef = useRef();
     const [uploadState, setUploadState] = useState(null);
     const [title, setTitle] = useState("");
     const auth = useSelector((state) => {
          return state.master.auth;
     });
     const ui = useSelector((state) => {
          return state.contactform.ui;
     });

     const dispatch = useDispatch();

     // console.log(uploadState);

     const createVideoClickHandler = () => {
          if (!uploadState) return;
          dispatch(videoFileUploadThunk(uploadState, title, auth));
     };

     return (
          <div className=" w-full h-full flex flex-col  ">
               <div className=" w-full h-full flex flex-col gap-[2.5rem] justify-center items-center">
                    <div className=" w-fit h-fit flex flex-col gap-[1rem]">
                         <input
                              value={title}
                              onChange={(e) => {
                                   setTitle(e.target.value);
                              }}
                              placeholder="Enter a title for the video below "
                              className=" w-[350px]  h-[42px] border-2 border-[#B8CADE] rounded-[4px] shadow-sm text-[12px] font-montserrat text-[#8999AB] font-[400] ps-3"
                         />
                         <ButtonComponent
                              onClick={() => {
                                   inputRef.current.click();
                              }}
                              className=" w-[350px] h-[350px]  "
                         >
                              {uploadState ? (
                                   <DisplayVideoFile
                                        uploadState={uploadState}
                                        clearUploadState={() => {
                                             setUploadState(null);
                                        }}
                                   />
                              ) : (
                                   <UploadComponents />
                              )}

                              {/* input tag  */}
                              <input
                                   type="file"
                                   ref={inputRef}
                                   onChange={(e) => {
                                        console.log(e.target.files);
                                        setUploadState(e.target.files[0]);
                                   }}
                                   className=" hidden"
                              />
                              {/* input tag  */}
                         </ButtonComponent>
                    </div>

                    <ButtonComponent
                         className={`  w-[132px] h-[40px] rounded-[10px] bg-mainRectangeGredient text-[16px] text-[#fff] font-[600]  ${
                              uploadState ? "block" : "hidden"
                         } `}
                         onClick={createVideoClickHandler}
                         isLoading={ui?.createVideoButtonIsLoading}
                    >
                         Create Video
                    </ButtonComponent>
               </div>
          </div>
     );
};

export default VideoUploadLayout;
