import React from "react";
import ButtonComponent from "../../../../basicultils/ButtonComponent";

const UnFillButtonMention = ({
     onClick = () => {},
     isLoading = () => {},
     children,
}) => {
     return (
          <ButtonComponent
               className="w-[30%] h-[30px] rounded-[5px] text-ignoreButtonTextColor capitalize text-[11px] font-[500] font-montserrat border-2 border-ignoreButtonTextColor "
               onClick={onClick}
               isLoading={false}
          >
               {children}
          </ButtonComponent>
     );
};

export default UnFillButtonMention;
