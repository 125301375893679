import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { navBarSliceActions } from "../../store/DashboardSlices/NavbarSlice";
import {
     findSocialMediaByLinks,
     socialmedia,
} from "../../config/socialmediaConfig";
import {
     schedulerSlideActions,
     updateSelectedSocialMediaMiddleware,
} from "../../store/SchedulerSlices/schedulerSlide";
import UseGetAccountDetails, {
     useGetAccountDetails_getDetailsFrom_modes,
} from "../../customHooks/UseGetAccountDetails";
import { routes } from "../../config/mainConfig";

const SchedulerDynamicSocialMediaPage = () => {
     const parmas = useParams();
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const [getDetailsFrom] = UseGetAccountDetails();
     const selectedSocialMedia = useSelector((state) => {
          return state.scheduler.selectedSocialMedia;
     });
     const socialmedianame = parmas.socialmedianame;

     useEffect(() => {
          if (socialmedianame !== selectedSocialMedia?.link) {
               let media = findSocialMediaByLinks(socialmedianame);

               if (media) {
                    let socialObj = socialmedia[media];
                    // Updating Selected Media in scheduler dashboard
                    dispatch(updateSelectedSocialMediaMiddleware(socialObj));

                    let loginDetails = getDetailsFrom(
                         useGetAccountDetails_getDetailsFrom_modes.custom,
                         socialmedianame
                    );
                    console.log(loginDetails);

                    if (!loginDetails?.isAccounted) {
                         dispatch(schedulerSlideActions.resetContent());
                         navigate(
                              routes.scheduler +
                                   "/" +
                                   socialmedianame +
                                   "/" +
                                   routes.schedulersocialmediaaddaccount
                         );
                    }
               }
          }
     }, [dispatch, socialmedianame, selectedSocialMedia]);

     return (
          <div className=" w-full h-full ">
               <Outlet />
          </div>
     );
};

export default SchedulerDynamicSocialMediaPage;
