import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonComponent from "../../../basicultils/ButtonComponent";
import google from "../../../assest/image/google.png";
import facebook from "../../../assest/socialmediaassest/facebook.png";
import apple from "../../../assest/image/apple.png";
import { useGoogleLogin } from "@react-oauth/google";
import {
     baseURl_User_Module,
     loginUserAPI,
} from "../../../API-utils/APIConfig";
import { PostAPI } from "../../../API-utils/PostAPI";
import { useDispatch } from "react-redux";
import { updateAuthWithAuthenticationObj } from "../../../store/AuthenticationSlices/loginSlice";

const LoginFooter = ({ isUser }) => {
     // isUser is props that has boolean value
     // true - signin
     //false - sign up
     const dispatch = useDispatch();
     const navigate = useNavigate();

     const message = {
          message: isUser
               ? "Haven’t yet an account?"
               : "Already have an account?",
          linkMessage: isUser ? "Sign Up" : "Log in",
          route: isUser ? "/signup" : "/signin",
     };

     //--- google login

     const [user, setUser] = useState({});
     const login = useGoogleLogin({
          onSuccess: (codeResponse) => {
               setUser(codeResponse);
               console.log(codeResponse);
               dispatch(googleAuth_thunk(codeResponse, navigate));
          },
          onError: (error) => console.log("Login Failed:", error),
     });

     return (
          <div className=" w-fit flex flex-col">
               <div className=" flex flex-row items-center gap-2 mt-4">
                    <svg
                         xmlns="http://www.w3.org/2000/svg"
                         width="110"
                         height="2"
                         viewBox="0 0 101 2"
                         fill="none"
                    >
                         <path
                              d="M0 1H151"
                              stroke="#97B7D6"
                              stroke-dasharray="2 2"
                         />
                    </svg>{" "}
                    <p className=" text-center  text-[#444B59] font-poppins">
                         or continue with
                    </p>
                    <svg
                         xmlns="http://www.w3.org/2000/svg"
                         width="110"
                         height="2"
                         viewBox="0 0 151 2"
                         fill="none"
                    >
                         <path
                              d="M0 1H151"
                              stroke="#97B7D6"
                              stroke-dasharray="2 2"
                         />
                    </svg>
               </div>

               <div className="w-full flex flex-row justify-center gap-2 mt-[1rem] mb-[1rem]">
                    <ButtonComponent
                         onClick={login}
                         className=" w-[80px] h-[60px] rounded-[8px] border-[DADADA] border-2 flex flex-col justify-center items-center"
                    >
                         <div className=" w-[40px] h-[40px] ">
                              <img
                                   src={google}
                                   alt="Google logo"
                                   className=" w-full h-full object-contain"
                              />
                         </div>
                    </ButtonComponent>
                    <ButtonComponent className=" w-[80px] h-[60px] rounded-[8px] border-[DADADA] border-2 flex flex-col justify-center items-center">
                         <div className=" w-[40px] h-[40px] ">
                              <img
                                   src={facebook}
                                   alt="Facebook logo"
                                   className=" w-full h-full object-contain"
                              />
                         </div>
                    </ButtonComponent>
                    <ButtonComponent className=" w-[80px] h-[60px] rounded-[8px] border-[DADADA] border-2 flex flex-col justify-center items-center">
                         <div className=" w-[40px] h-[40px] ">
                              <img
                                   src={apple}
                                   alt="Apple logo"
                                   className=" w-full h-full object-contain"
                              />
                         </div>
                    </ButtonComponent>
               </div>
               <div className=" flex flex-row items-center gap-4 w-fit mt-4">
                    <svg
                         xmlns="http://www.w3.org/2000/svg"
                         width="158"
                         height="2"
                         viewBox="0 0 171 2"
                         fill="none"
                    >
                         <path
                              d="M0 1H151"
                              stroke="#97B7D6"
                              stroke-dasharray="2 2"
                         />
                    </svg>{" "}
                    <p className=" pb-1  bg-inputBorderColor w-10 text-center rounded-lg">
                         or
                    </p>
                    <svg
                         xmlns="http://www.w3.org/2000/svg"
                         width="158"
                         height="2"
                         viewBox="0 0 170 2"
                         fill="none"
                    >
                         <path
                              d="M0 1H151"
                              stroke="#97B7D6"
                              stroke-dasharray="2 2"
                         />
                    </svg>
               </div>
               <div className="text-center mt-3 font-base">
                    <p className=" font-Roboto ">
                         {message.message}
                         <Link
                              to={message.route}
                              className=" text-linkColor font-bold"
                         >
                              {" "}
                              {message.linkMessage}
                         </Link>
                    </p>
               </div>
          </div>
     );
};

export default LoginFooter;

const googleAuth_thunk = (obj, navigate) => {
     return async (dispatch) => {
          let url = new URL(baseURl_User_Module + loginUserAPI);

          let body = {
               token: obj?.access_token,
               login_type: "google",
          };

          let headers = {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
               "Access-Control-Allow-Methods": [
                    "POST",
                    "GET",
                    "OPTIONS",
                    "DELETE",
                    "PUT",
               ],
               "Access-Control-Allow-Headers": [
                    "append",
                    "delete",
                    "entries",
                    "foreach",
                    "get",
                    "has",
                    "keys",
                    "set",
                    "values",
                    "Authorization",
               ],

               redirect: "follow",
          };

          let response = await PostAPI(url, body, headers);

          if (response?.response?.ok) {
               console.log(response);

               dispatch(
                    updateAuthWithAuthenticationObj(
                         response?.responsedata,
                         navigate
                    )
               );
          }
     };
};
