import React from "react";

const ContactBodyHeaderText = () => {
     return (
          <div className=" w-fit hit">
               <h1 className=" font-montserrat text-[18px] font-bold">
                    Contact Form
               </h1>
          </div>
     );
};

export default ContactBodyHeaderText;
