import React from "react";

const ContactBodyViewContent = ({ children }) => {
     return (
          <p className=" text-contactBodyContentTextColor text-[14px]  font-montserrat ">
               {children}
          </p>
     );
};

export default ContactBodyViewContent;
