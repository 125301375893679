import React from "react";
import { routes } from "../../../config/mainConfig";
import { NavLink } from "react-router-dom";
import FourCircleWhiteSVG from "../../templates/Svg/FourCircleWhiteSVG";
import ListSVG from "../../templates/Svg/ListSVG";
import { useSelector } from "react-redux";

const SchedulerNavLink = () => {
     const selectedSocialMedia = useSelector((state) => {
          return state.scheduler.selectedSocialMedia;
     });
     return (
          <div className=" w-fit h-full flex flex-row items-center gap-2">
               <NavLink
                    to={
                         routes.scheduler +
                         "/" +
                         selectedSocialMedia?.link +
                         "/" +
                         routes.schedulersocialmediagrid
                    }
                    className={({ isActive }) =>
                         ` w-[45px] h-[45px]  flex-row justify-center font-montserrat text-[14px] font-[600] text-[#fff] rounded-[4px] ${
                              isActive
                                   ? "bg-mainRectangeGredient"
                                   : " bg-schedulergrey1"
                         } flex items-center gap-2  `
                    }
               >
                    <FourCircleWhiteSVG />
               </NavLink>
               <NavLink
                    to={
                         routes.scheduler +
                         "/" +
                         selectedSocialMedia?.link +
                         "/" +
                         routes.schedulersocialmedialist
                    }
                    className={({ isActive }) =>
                         ` w-[45px] h-[45px]  flex-row justify-center font-montserrat text-[14px] font-[600] text-[#fff] rounded-[4px] ${
                              isActive
                                   ? "bg-mainRectangeGredient"
                                   : " bg-schedulergrey1"
                         } flex items-center gap-2  `
                    }
               >
                    <ListSVG />
               </NavLink>
               {/* <ButtonComponent
         onClick={() => {}}
         isLoading={false}
         className=" w-[45px] h-[45px]  flex-row justify-center font-montserrat text-[14px] font-[600] text-[#fff] rounded-[4px] bg-schedulergrey1 flex items-center gap-2  "
    >
         <FourCircleWhiteSVG />
    </ButtonComponent>
    <ButtonComponent
         onClick={() => {}}
         isLoading={false}
         className=" w-[45px] h-[45px]  flex-row justify-center font-montserrat text-[14px] font-[600] text-[#fff] rounded-[4px] bg-mainRectangeGredient flex items-center gap-2  "
    >
         <ListSVG />
    </ButtonComponent> */}
          </div>
     );
};

export default SchedulerNavLink;
