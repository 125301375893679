import React from "react";
import SideBar from "./SideBar";

const AuthenticationTemplate = ({ children }) => {
     return (
          <div className="w-full flex flex-row">
               <SideBar
                    className={
                         " w-1/2 h-screen bg-mainRectangeGredient flex-none relative"
                    }
               />
               <div className="  flex-1 flex flex-col justify-center items-center  ">
                    {children}
               </div>
          </div>
     );
};

export default AuthenticationTemplate;
