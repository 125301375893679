import React from "react";
import SchedulerListingHeader from "./SchedulerListingHeader";
import SchedulerListInDivRow from "./SchedulerListInDivRow";
import { useSelector } from "react-redux";

const SchedulerListingLayout = () => {
     const { scheduler } = useSelector((state) => {
          return state.scheduler;
     });

     // console.log("Schduler List : ", scheduler);

     return (
          <div className=" w-full h-full flex flex-col   ">
               <SchedulerListingHeader />
               <div className=" w-full h-fit flex flex-col overflow-scroll">
                    {scheduler.list.map((item) => {
                         return <SchedulerListInDivRow content={item} />;
                    })}
               </div>
          </div>
     );
};

export default SchedulerListingLayout;
