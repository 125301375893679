import React from "react";
import SchedulerGridSetting from "./SchedulerGridSetting";
import SchedulerWeekLayout from "./SchedulerGridListComponents/SchedulerWeekGridComponets/SchedulerWeekLayout";
import SchedulerMonthLayout from "./SchedulerGridListComponents/SchedulerMonthGridComponents/SchedulerMonthLayout";
import { useSelector } from "react-redux";
import { schedulerModes } from "../../../../config/SchedulerConfig";

const SchedulerGridViewLayout = () => {
     const mode = useSelector((state) => state.scheduler.mode);
     return (
          <div className=" w-full h-full flex flex-col overflow-hidden">
               <div className=" w-full h-fit">
                    <SchedulerGridSetting />
               </div>
               <div className=" flex-1 w-full h-full  overflow-auto  ">
                    {mode === schedulerModes.week && <SchedulerWeekLayout />}
                    {mode === schedulerModes.month && <SchedulerMonthLayout />}
               </div>
          </div>
     );
};

export default SchedulerGridViewLayout;
