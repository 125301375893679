import React from "react";
import DeleteContactSVG from "../../../../../templates/Svg/ContactForm/DeleteContactSVG";

const DeleteLogoComponent = () => {
     return (
          <div className=" w-full h-fit flex flex-row justify-center">
               <DeleteContactSVG />
          </div>
     );
};

export default DeleteLogoComponent;
