// API config
import {
     baseURL_Scheduler,
     twitter_posting_apiendpoints,
     twitter_scheduler_list,
} from "../API-utils/APIConfig";
import { GetAPI, status } from "../API-utils/GetAPI";
import { PostAPI } from "../API-utils/PostAPI";

// Slice Actions
import {
     schedulerSlideActions,
     updatePostListScheduler,
} from "../store/SchedulerSlices/schedulerSlide";
import { masterSliceActions } from "../store/masterSlice";
// --------------

let Defaultheaders = {
     "Content-Type": "application/json",
     "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Methods": [
          "POST",
          "GET",
          "OPTIONS",
          "DELETE",
          "PUT",
     ],
     "Access-Control-Allow-Headers": [
          "append",
          "delete",
          "entries",
          "foreach",
          "get",
          "has",
          "keys",
          "set",
          "values",
          "Authorization",
     ],

     redirect: "follow",
};

// --------------

const key_twitter = "twitter_auth";

export const setLocalStorage_twitter = (obj = "") => {
     localStorage.setItem(key_twitter, JSON.stringify(obj));
};

export const getLocalStorage_twitter = () => {
     let obj = JSON.parse(localStorage.getItem(key_twitter));
     console.log("Twiiter Obj : ", obj);
     return obj;
};

export const updateTwitterMasterSliceAuthStatus = () => {
     return async (dispatch) => {
          let response = getLocalStorage_twitter();
          if (!response) return;
          dispatch(masterSliceActions.updateTwiierAccountObj(response));
     };
};

// -------------------------
// Post Twitter

export const postTwitterAPI = async (bodyObj, schedule = null, auth) => {
     // URL
     let api = new URL(baseURL_Scheduler + twitter_posting_apiendpoints);

     // BODY
     let body = bodyObj;

     console.log(schedule);

     if (schedule) {
          body = { ...bodyObj, schedule_time: new Date(schedule) };
     }

     // Headers
     let header = {
          ...Defaultheaders,
          Authorization: auth?.sessionToken,
     };

     let response = await PostAPI(api, body, header);

     console.log(response);

     if (response.status === status.successful) {
          console.log("Response Data : ", response.responsedata);
          return response.responsedata;
     }

     return null;
};

// Thunk
export const twitter_postThunk = (
     obj,
     schedule,
     auth,
     { successToaster, toasterErrorFunction }
) => {
     return async (dispatch) => {
          dispatch(schedulerSlideActions.modifyPostNowButtonIsLoading(true));

          let response = await postTwitterAPI(obj, schedule, auth);

          if (response) {
               dispatch(schedulerSlideActions.resetAddPost());
               dispatch(twitter_scheduler_list_fetch_thunk(auth));

               if (schedule) {
                    successToaster("Scheduled");
               } else {
                    successToaster("Posted");
               }
               console.log("Posted");
          } else {
               toasterErrorFunction("Something went wrong");
          }

          dispatch(schedulerSlideActions.modifyPostNowButtonIsLoading(false));
     };
};

// --------------

export const twitter_post_list_format = (list = []) => {
     let array = [];

     for (let i = 0; i < list.length; i++) {
          let current = list[i];
          array.push({
               content: current?.content,
               taskTime: new Date(current?.task_time),
               isPosted: true,
          });
     }
     return array;
};

export const twitter_scheduler_list_fetch_thunk = (auth) => {
     return async (dispatch) => {
          let url = new URL(baseURL_Scheduler + twitter_scheduler_list);

          let headers = {
               ...Defaultheaders,
               Authorization: auth?.sessionToken,
          };

          let response = await GetAPI(url, headers);

          if (response?.response?.ok) {
               let posts = response?.responsedata?.posts;
               // console.log("Posts :  ", posts);
               let posts_format = twitter_post_list_format(posts);
               // console.log("Posts format : ", posts_format);
               dispatch(updatePostListScheduler(posts_format));
          }

          console.log("Twitter Scheduler List : ", response);
     };
};
