import React from "react";

import logo from "../../../../assest/image/logoimage.png";
import logowhite from "../../../../assest/image/logoimagewhite.png";

const LogoSection = ({ isBigscreen = true }) => {
     return (
          <div className={` ${ isBigscreen ? "h-full " : " h-[80%] w-[100px]"} flex flex-col justify-center `}>
               {isBigscreen ? (
                    <img
                         src={logo}
                         className="  object-contain  cursor-pointer"
                         alt="logo img"
                    />
               ) : (
                    <img
                         src={logowhite}
                         className="  object-contain  cursor-pointer"
                         alt="logo img"
                    />
               )}
          </div>
     );
};

export default LogoSection;
