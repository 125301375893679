import React, { useState } from "react";
import LeftArrowLandingPagesvg from "../../components/templates/Svg/Landingpagesvgs/LeftArrowLandingPagesvg";
import ButtonComponent from "../../basicultils/ButtonComponent";

const content = [
     {
          question: "How many 3rd party review sites you support?",
          answer: "Pricing is the process whereby a business sets the price at which it will sell its products and services, and may be part of the business's marketing plan",
     },
     {
          question: "Can I add my team members to collaborate?",
          answer: "Pricing is the process whereby a business sets the price at which it will sell its products and services, and may be part of the business's marketing plan",
     },
     {
          question: "Can I add multiple Facebook pages?",
          answer: "Pricing is the process whereby a business sets the price at which it will sell its products and services, and may be part of the business's marketing plan",
     },
     {
          question: "Do you support HD videos?",
          answer: "Pricing is the process whereby a business sets the price at which it will sell its products and services, and may be part of the business's marketing plan",
     },
     {
          question: "Can I add multiple Google Locations?",
          answer: "Pricing is the process whereby a business sets the price at which it will sell its products and services, and may be part of the business's marketing plan",
     },
     {
          question: "Do you offer a free plan?",
          answer: "Pricing is the process whereby a business sets the price at which it will sell its products and services, and may be part of the business's marketing plan",
     },
];

const QuestionContainer = ({ children }) => {
     return (
          <div className=" w-full lg:w-[48%] h-fit flex flex-col justify-center">
               {children}
          </div>
     );
};

const EachQuestion = ({ name }) => {
     return (
          <div className=" max-w-[588px] lg:w-[85%] md:w-[80%] w-[90%] xl:h-[66px] h-[60px] flex flex-row justify-between ps-8 pe-8 bg-[#FFF5F5]">
               <div className=" w-fit h-full flex flex-row items-center font-poppins xl:text-[18px] text-[14px] text-[#111013] font-[500]">
                    {name}
               </div>
               <div className=" w-fit h-full flex flex-col justify-center">
                    <LeftArrowLandingPagesvg />
               </div>
          </div>
     );
};

const Accordin = ({
     content = {},
     isOpen = 0,
     onClick = () => {},
     open = "001",
}) => {
     // const [isOpen, setIsOpen] = useState(isOpenf);

     return (
          <div className=" w-full  h-fit flex flex-col items-center justify-center gap-[0.001rem]">
               <ButtonComponent
                    onClick={() => {
                         // setIsOpen((state) => {
                         //      return !state;
                         // });
                         onClick(open);
                    }}
                    className=" max-w-[588px] lg:w-[85%] md:w-[80%] w-[90%] xl:h-[66px] h-[60px] flex flex-row justify-between ps-8 pe-8 bg-[#FFF5F5]"
               >
                    <div className=" w-fit h-full flex flex-row items-center font-poppins xl:text-[18px] text-[14px] text-[#111013] font-[500]">
                         {content?.question}
                    </div>
                    <div
                         className={` w-fit h-full flex flex-col justify-center ${
                              isOpen === "1" ? "rotate-90" : ""
                         }`}
                    >
                         <LeftArrowLandingPagesvg />
                    </div>
               </ButtonComponent>
               {isOpen == "1" && (
                    <div className="max-w-[588px] lg:w-[85%] md:w-[80%] w-[90%] xl:h-[100px] h-[100px] flex flex-row justify-between ps-8 pe-8 pt-2 bg-[#FFF5F5] rounded-[8px]">
                         <div className=" w-full h-full  text-[grey]  font-poppins xl:text-[14px] text-[11px]  font-[500]">
                              {content?.answer}
                         </div>
                    </div>
               )}
          </div>
     );
};

const NinethSectionFAQ = () => {
     const [firstSectionIsOpen, setFirstSectionIsOpen] = useState("100");
     const [secondSectionIsOpen, setSecondSectionIsOpen] = useState("100");

     const clickFirstSection = (string) => {
          setFirstSectionIsOpen(string);
     };

     const clickSecondSection = (string) => {
          setSecondSectionIsOpen(string);
     };

     return (
          <div className=" w-full h-fit  flex md:flex-row flex-col md:items-start items-center justify-between gap-y-[2rem]">
               {/* {content.map((item) => {
                    return (
                         <QuestionContainer>
                              <EachQuestion name={item} />
                         </QuestionContainer>
                    );
               })} */}

               <div className=" md:w-1/2 w-full h-fit flex flex-col justify-center gap-[1rem]">
                    <Accordin
                         content={content[0]}
                         isOpen={firstSectionIsOpen[0]}
                         onClick={clickFirstSection}
                         open="100"
                    />
                    <Accordin
                         content={content[1]}
                         isOpen={firstSectionIsOpen[1]}
                         onClick={clickFirstSection}
                         open="010"
                    />
                    <Accordin
                         content={content[2]}
                         isOpen={firstSectionIsOpen[2]}
                         onClick={clickFirstSection}
                         open="001"
                    />
               </div>

               <div className=" md:w-1/2 w-full h-fit flex flex-col justify-center gap-[1rem]">
                    <Accordin
                         content={content[3]}
                         isOpen={secondSectionIsOpen[0]}
                         onClick={clickSecondSection}
                         open="100"
                    />
                    <Accordin
                         content={content[4]}
                         isOpen={secondSectionIsOpen[1]}
                         onClick={clickSecondSection}
                         open="010"
                    />
                    <Accordin
                         content={content[5]}
                         isOpen={secondSectionIsOpen[2]}
                         onClick={clickSecondSection}
                         open="001"
                    />
               </div>
          </div>
     );
};

export default NinethSectionFAQ;
