import React from "react";
import SchedulerMonthGridContentBox from "./utils/SchedulerMonthGridContentBox";
import { useSelector } from "react-redux";

const SchedulerMonthBodyDateRowComponet = ({ content }) => {
     let date = new Date(content.date);
     return (
          <div className=" min-w-[205px] h-[200px] border-2 flex flex-col justify-center gap-2 p-2 overflow-auto">
               <div className=" w-full text-center h-fit font-poppins text-[28px] ">
                    {date.getDate()}
               </div>
               <div className=" w-full h-fit overflow-auto flex flex-col gap-2">
                    {content.postArray.map((item) => {
                         return (
                              <SchedulerMonthGridContentBox
                                   postContent={item}
                              />
                         );
                    })}
               </div>
          </div>
     );
};

const SchedulerMonthBodyDate = () => {
     const { month } = useSelector((state) => {
          return state.schedulerMonthWeek;
     });

     // monthly
     return (
          <div className=" w-fit h-fit  grid grid-cols-7  ">
               {month.dateList.map((item) => {
                    return <SchedulerMonthBodyDateRowComponet content={item} />;
               })}
          </div>
     );
};

export default SchedulerMonthBodyDate;
