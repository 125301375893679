import React, { useEffect } from "react";
import SchedulerSocialMediaListingCom from "../../components/Scheduler/SchedulerSocialMediaListingCom";
// import UseAuthenticationOperations from "../../customHooks/UseAuthenticationOperations";

const Scheduler = () => {
     // const [isAuthenticated] = UseAuthenticationOperations();
     // useEffect(() => {
     //      isAuthenticated();
     // }, []);
     return (
          <>
               <SchedulerSocialMediaListingCom />;
          </>
     );
};

export default Scheduler;
