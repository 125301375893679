import React from "react";

const SecondSectionContentBox = ({ children, title, body, hover = false }) => {
     return (
          <div
               className={` w-full h-fit p-4 rounded-[12px] flex flex-col gap-3  ${
                    hover ? "border-[#F17A01]  border-2 " : ""
               } cursor-pointer`}
          >
               <h1 className=" font-poppins font-[600] lg:text-[16px] text-[14px] flex flex-row gap-3">
                    {children} {title}
               </h1>
               <p className=" text-ellipsis text-[#4B5162] font-poppins lg:text-[16px] text-[14px]">
                    {body}
               </p>
          </div>
     );
};

export default SecondSectionContentBox;
