import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { navBarLinkState } from "../../../../config/navConfig";
import { navBarSliceActions } from "../../../../store/DashboardSlices/NavbarSlice";
import { useDispatch } from "react-redux";

const findActive = (current = "", navlink = "") => {
     let string = "";
     for (let i = 0; i < current.length; i++) {
          string = string + current[i];
          if (string === navlink) {
               return true;
          }
     }
     return false;
};

const colorcode = {
     notactive: "white",
     active: "#FFCA00",
};

const IndivSideNavBar = ({
     content,
     isExtended = false,
     resetFunction = () => {},
}) => {
     const dispatch = useDispatch();
     const location = useLocation();
     //  console.log(location.pathname);

     //-------------------------------------------------------------------------------------
     let assest = content?.assest;
     let state = content?.state;
     let Logo;

     if (state === navBarLinkState.default) {
          Logo = (props) => {
               return <assest.logo {...props} />;
          };
     }

     if (state === navBarLinkState.socialmedia) {
          Logo = assest.logo;
     }

     let isActive = findActive(location?.pathname, assest?.activeLink);

     return (
          <NavLink
               to={assest?.link}
               onClick={() => {
                    resetFunction();
                    dispatch(navBarSliceActions.modifyIsOpenNavBar(false));
               }}
               className={` w-full h-fit flex flex-row  justify-center items-center gap-[4] border-s-[4px] border-[#FFCA00] pt-4 pb-4 ${
                    isActive ? " border-opacity-100 " : " border-opacity-0"
               } `}
          >
               <div
                    className={` w-full flex flex-row   gap-4 ${
                         isExtended
                              ? "ps-[30px] justify-start"
                              : "justify-center"
                    } `}
               >
                    {state === navBarLinkState.default && (
                         <Logo
                              color={
                                   isActive
                                        ? colorcode.active
                                        : colorcode.notactive
                              }
                         />
                    )}
                    {state === navBarLinkState.socialmedia && (
                         <div className=" w-[30px] h-[30px] relative">
                              <img
                                   src={Logo}
                                   className=" w-full h-full"
                                   alt={assest.text}
                              />
                         </div>
                    )}
                    {isExtended && (
                         <h2
                              className={` text-[16px] capitalize font-[600] ${
                                   isActive ? " text-[#FFCA00]" : " text-[#fff]"
                              } `}
                         >
                              {assest.text}
                         </h2>
                    )}
               </div>
          </NavLink>
     );
};

export default IndivSideNavBar;
