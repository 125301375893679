import { createSlice } from "@reduxjs/toolkit";
import {
     baseURL,
     baseURL_WITH_PORT_8080,
     searchBussinessRoute,
} from "../API-utils/APIConfig";
import { GetAPI, status } from "../API-utils/GetAPI";
import {
     dynamicSocialMediaSliceActions,
     fetchGetReviewAPIThunk,
} from "./DynamicSocialMediaSlices/DynamicSocialMediaSlices";
import { socialmedia } from "../config/socialmediaConfig";
import { navBarSliceActions } from "./DashboardSlices/NavbarSlice";

// Plat forms local config functions
import { setYelpLocalStorage } from "../Api-Calling/yelpAPICalling";
import { setTripAdvisorLocalStorage } from "../Api-Calling/tripAdvisorAPICalling";
import { setLinkedinLocalStorage } from "../Api-Calling/linkedinAPICalling";
import { setTikTokLocalStorage } from "../Api-Calling/tiktokAPICalling";
import { setLocalStorage_twitter } from "../Api-Calling/twitterAPICaliing";

const initialState = {
     auth: {
          isAuth: false,
          email: "",
          username: "",
          mobile: "",
          id: "",
          sessionToken: "",
          type: "",
          access: "",
     },

     account: {
          yelp: {
               isAccounted: false,
               alias: "",
               obj: {},
          },

          tripvisor: {
               isAccounted: false,
               alias: "",
               obj: {},
          },
          instagram: {
               isAccounted: false,
               alias: "",
               obj: {},
          },
          tiktok: {
               isAccounted: false,
               alias: "",
               obj: {},
          },
          facebook: {
               isAccounted: false,
               alias: "",
               obj: {},
          },
          bingplaces: {
               isAccounted: false,
               alias: "",
               obj: {},
          },
          trustpliot: {
               isAccounted: false,
               alias: "",
               obj: {},
          },
          googlereviewmybusiness: {
               isAccounted: false,
               alias: "",
               obj: {},
          },
          twitter: {
               isAccounted: false,
               alias: "",
               obj: {},

               authData: {
                    authorization_url: "",
                    resource_owner_key: "",
                    resource_owner_secret: "",
               },
          },
          linkedin: {
               isAccounted: false,
               alias: "",
               obj: {},
          },
     },
};

const masterSlice = createSlice({
     name: "Master-state-management",
     initialState,
     reducers: {
          updateYelp(state, action) {
               console.log(action.payload, action.payload.alias);
               return {
                    ...state,
                    account: {
                         ...state.account,
                         yelp: {
                              isAccounted: true,
                              alias: action.payload.alias,
                              obj: action.payload,
                         },
                    },
               };
          },

          updateTripadvisor(state, action) {
               console.log(action.payload, action.payload._id);
               return {
                    ...state,
                    account: {
                         ...state.account,

                         tripvisor: {
                              ...state.tripvisor,
                              name: "asdfasdf sa",
                              isAccounted: true,
                              alias: action.payload?.id,
                              obj: action.payload,
                         },
                    },
               };
          },

          updateAuthentication(state, action) {
               console.log(action.payload);
               return {
                    ...state,
                    auth: {
                         ...state.auth,
                         username: action.payload.username,
                         email: action.payload.email,
                         mobile: action.payload.mobile,
                         sessionToken: action.payload.session_token,
                         id: action.payload.id,
                         type: action.payload.type,
                         isAuth: true,
                         access: action.payload?.access,
                    },
               };
          },

          updateTwiierAccountObj(state, action) {
               return {
                    ...state,
                    account: {
                         ...state.account,
                         twitter: {
                              ...state.account.twitter,

                              isAccounted: true,
                              obj: action.payload,
                         },
                    },
               };
          },

          updateTwitterAuthObj(state, action) {
               return {
                    ...state,
                    account: {
                         ...state.account,

                         twitter: {
                              ...state.account.twitter,
                              authData: {
                                   ...state.account.twitter.authData,
                                   authorization_url:
                                        action.payload.authorization_url,
                                   resource_owner_key:
                                        action.payload.resource_owner_key,
                                   resource_owner_secret:
                                        action.payload.resource_owner_secret,
                              },
                         },
                    },
               };
          },

          updateLinkedAuthentication(state, action) {
               return {
                    ...state,
                    account: {
                         ...state.account,

                         linkedin: {
                              ...state.account.linkedin,
                              isAccounted: true,
                              obj: {
                                   ...action.payload,
                              },
                         },
                    },
               };
          },

          updateTiktokAuthentication(state, action) {
               return {
                    ...state,
                    account: {
                         ...state.account,
                         tiktok: {
                              ...state.account.tiktok,
                              isAccounted: true,
                              alias: action.payload,
                         },
                    },
               };
          },

          updateFacebookAuthentication(state, action) {
               return {
                    ...state,
                    account: {
                         ...state.account,
                         facebook: {
                              ...state.account.facebook,
                              isAccounted: true,
                              obj: action.payload,
                         },
                    },
               };
          },
     },
});

export const masterSliceActions = masterSlice.actions;
export default masterSlice;

export const yelpIntegrationBussiness = async (url, searchInput) => {
     let api = new URL(url);
     // api.searchParams.set("searchQuery", searchInput);
     api = new URL(api.href + "/" + searchInput);
     console.log(api.href);
     const responseObj = await GetAPI(api.href);

     console.log(responseObj);

     //-----------------------------------------------------
     // logic
     let returnObj = {
          array: [],
          ...responseObj,
     };
     if (responseObj.status === status.successful) {
          let data = responseObj.responsedata.data;
          console.log(data);

          if (data?.businesses.length <= 0) {
               // Businness not found
               return returnObj;
          }

          // taking first business
          let businesses = data?.businesses;
          let firstBusiness = businesses[0];
          returnObj.array = data?.businesses;

          console.log(firstBusiness);
     } else if (responseObj.status === status.error) {
     }

     return returnObj;
};

export const tripadvisorIntegrationBussiness = async (url, searchInput) => {
     let api = new URL(
          baseURL_WITH_PORT_8080 +
               "/" +
               socialmedia.tripadvisor.api +
               searchBussinessRoute
     );
     api.searchParams.set("searchQuery", searchInput);
     console.log(api.href);

     const responseObj = await GetAPI(api.href, {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
               "Origin, X-Requested-With, Content-Type, Accept",
          redirect: "follow",
          accept: "application/json",
          Authorization: "5129ff3bc3534a669d13bfb833367adb",
     });
     console.log(responseObj);

     //-----------------------------------------------------
     // logic
     let returnObj = {
          array: [],
          ...responseObj,
     };
     if (responseObj.status === "good") {
          let data = responseObj?.responsedata?.data;
          console.log(data);

          if (data?.length <= 0) {
               return returnObj;
          }

          let array = data;

          returnObj.array = array;

          //console.log(returnObj.array[0]);
     } else if (responseObj.status === status.error) {
     }

     return returnObj;
};

export const searchBussinessThunk = (
     api,
     additionalRoute,
     inputType,
     naviagte
) => {
     return async (dispatch) => {
          console.log("search Bussiness thunk called ");
          if (additionalRoute === "" || !api) {
               // naviagte("..");
               return;
          }

          dispatch(
               dynamicSocialMediaSliceActions.modifyAddAccountButtonIsLoading(
                    true
               )
          );

          let url = new URL(baseURL + "/" + api + searchBussinessRoute);

          let responseObj = null;

          if (api === socialmedia.yelp.api) {
               responseObj = await yelpIntegrationBussiness(
                    url,
                    additionalRoute
               );
          } else if (api === socialmedia.tripadvisor.api) {
               responseObj = await tripadvisorIntegrationBussiness(
                    url,
                    additionalRoute
               );
          }

          console.log(responseObj);

          if (responseObj?.status === status.successful) {
               if (responseObj?.array?.length <= 0) {
                    console.log(responseObj);
                    naviagte("..");
                    dispatch(
                         dynamicSocialMediaSliceActions.modifyAddAccountButtonIsLoading(
                              false
                         )
                    );
                    return null;
               }

               let array = responseObj?.array;
               let firstBusiness = array[0];
               console.log(firstBusiness, array);

               if (api === socialmedia.yelp.api) {
                    console.log("Yelp Integration : ", firstBusiness);
                    dispatch(masterSliceActions.updateYelp(firstBusiness));
                    setYelpLocalStorage(firstBusiness);
                    dispatch(
                         fetchGetReviewAPIThunk(
                              baseURL,
                              {
                                   "Content-Type": "application/json",
                              },
                              api,
                              firstBusiness.alias,
                              10,
                              naviagte
                         )
                    );
               } else if (api === socialmedia.tripadvisor.api) {
                    dispatch(
                         masterSliceActions.updateTripadvisor(firstBusiness)
                    );
                    setTripAdvisorLocalStorage(firstBusiness);
                    dispatch(
                         fetchGetReviewAPIThunk(
                              baseURL_WITH_PORT_8080,
                              {
                                   "Content-Type": "application/json",
                                   "Access-Control-Allow-Origin": "*",
                                   "Access-Control-Allow-Methods": [
                                        "POST",
                                        "GET",
                                        "OPTIONS",
                                        "DELETE",
                                        "PUT",
                                   ],
                                   "Access-Control-Allow-Headers": [
                                        "append",
                                        "delete",
                                        "entries",
                                        "foreach",
                                        "get",
                                        "has",
                                        "keys",
                                        "set",
                                        "values",
                                        "Authorization",
                                   ],
                                   //  "Access-Control-Allow-Headers":
                                   //       "Origin, X-Requested-With, Content-Type, Accept",
                                   redirect: "follow",
                                   Authorization:
                                        "5129ff3bc3534a669d13bfb833367adb",
                                   accept: "application/json",
                              },
                              api,
                              firstBusiness._id,
                              10,
                              naviagte
                         )
                    );
               }
          } else if (responseObj?.status === status.error) {
               console.log(responseObj);
               dispatch(navBarSliceActions.resetReviewNavLink());
          }

          naviagte("..");
          dispatch(
               dynamicSocialMediaSliceActions.modifyAddAccountButtonIsLoading(
                    false
               )
          );
     };
};

// ---- Middle ware for social media platform's retention

export const platforms_retention_delete_thunk = () => {
     return async (dispatch) => {
          console.log("DELETIING THE LOCAL STORAGE");
          setLinkedinLocalStorage(null);
          setTikTokLocalStorage(null);
          setTripAdvisorLocalStorage(null);
          setLocalStorage_twitter(null);
          setYelpLocalStorage(null);
     };
};
