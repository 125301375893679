import React from "react";

const VideoSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="30"
               height="30"
               viewBox="0 0 30 30"
               fill="none"
          >
               <path
                    d="M19.1402 12.5391L13.5152 8.78906C13.4093 8.71837 13.2861 8.67779 13.1589 8.67165C13.0317 8.66551 12.9051 8.69405 12.7929 8.75422C12.6806 8.81438 12.5868 8.90392 12.5214 9.01325C12.4561 9.12259 12.4217 9.24762 12.4219 9.375V16.875C12.4217 17.0024 12.4561 17.1274 12.5214 17.2367C12.5868 17.3461 12.6806 17.4356 12.7929 17.4958C12.9051 17.556 13.0317 17.5845 13.1589 17.5784C13.2861 17.5722 13.4093 17.5316 13.5152 17.4609L19.1402 13.7109C19.2369 13.6468 19.3162 13.5597 19.3711 13.4575C19.426 13.3553 19.4547 13.241 19.4547 13.125C19.4547 13.009 19.426 12.8947 19.3711 12.7925C19.3162 12.6903 19.2369 12.6032 19.1402 12.5391ZM13.8281 15.5613V10.6887L17.482 13.125L13.8281 15.5613ZM25.3125 4.92188H4.6875C4.25238 4.92188 3.83508 5.09473 3.5274 5.4024C3.21973 5.71008 3.04688 6.12738 3.04688 6.5625V19.6875C3.04688 20.1226 3.21973 20.5399 3.5274 20.8476C3.83508 21.1553 4.25238 21.3281 4.6875 21.3281H25.3125C25.7476 21.3281 26.1649 21.1553 26.4726 20.8476C26.7803 20.5399 26.9531 20.1226 26.9531 19.6875V6.5625C26.9531 6.12738 26.7803 5.71008 26.4726 5.4024C26.1649 5.09473 25.7476 4.92188 25.3125 4.92188ZM25.5469 19.6875C25.5469 19.7497 25.5222 19.8093 25.4782 19.8532C25.4343 19.8972 25.3747 19.9219 25.3125 19.9219H4.6875C4.62534 19.9219 4.56573 19.8972 4.52177 19.8532C4.47782 19.8093 4.45312 19.7497 4.45312 19.6875V6.5625C4.45312 6.50034 4.47782 6.44073 4.52177 6.39677C4.56573 6.35282 4.62534 6.32812 4.6875 6.32812H25.3125C25.3747 6.32812 25.4343 6.35282 25.4782 6.39677C25.5222 6.44073 25.5469 6.50034 25.5469 6.5625V19.6875ZM26.9531 24.375C26.9531 24.5615 26.879 24.7403 26.7472 24.8722C26.6153 25.004 26.4365 25.0781 26.25 25.0781H3.75C3.56352 25.0781 3.38468 25.004 3.25282 24.8722C3.12095 24.7403 3.04688 24.5615 3.04688 24.375C3.04688 24.1885 3.12095 24.0097 3.25282 23.8778C3.38468 23.746 3.56352 23.6719 3.75 23.6719H26.25C26.4365 23.6719 26.6153 23.746 26.7472 23.8778C26.879 24.0097 26.9531 24.1885 26.9531 24.375Z"
                    fill="white"
               />
          </svg>
     );
};

export default VideoSVG;
