import React from "react";
import CheckOff from "../components/templates/Svg/Scheduler/CheckOff";
import CheckOn from "../components/templates/Svg/Scheduler/CheckOn";
import ButtonComponent from "./ButtonComponent";

const CheckBoxComponent = ({ value = false, onClick = () => {} }) => {
     return (
          <ButtonComponent
               className=" w-fit h-fit cursor-pointer "
               onClick={() => {}}
               isLoading={false}
          >
               {value ? <CheckOn /> : <CheckOff />}
          </ButtonComponent>
     );
};

export default CheckBoxComponent;
