import React from "react";

const BoxWallSectionSVG = () => {
     return (
          <div>
               <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="101"
                    height="75"
                    viewBox="0 0 101 75"
                    fill="none"
               >
                    <path
                         d="M51.0319 11.1755V53.333L19.7598 63.1606V21.2265L51.0319 11.1755Z"
                         fill="url(#paint0_linear_232_26)"
                         stroke="#1C75BC"
                         stroke-miterlimit="10"
                    />
                    <path
                         d="M19.0766 22.4395L18.0059 63.4602L50.2421 73.5052L50.7263 32.0568L19.0766 22.4395Z"
                         fill="white"
                         stroke="#59AED3"
                         stroke-miterlimit="10"
                    />
                    <path
                         d="M51.0316 11.1755L50.9746 53.6639L82.2472 63.5723V21.2915L51.0316 11.1755Z"
                         fill="url(#paint1_linear_232_26)"
                         stroke="#1C75BC"
                         stroke-miterlimit="10"
                    />
                    <path
                         d="M83.1625 22.4395V63.3114L50.2441 73.5052L50.7307 31.8844L83.1625 22.4395Z"
                         fill="white"
                         stroke="#59AED3"
                         stroke-miterlimit="10"
                    />
                    <path
                         d="M18.0071 21.5806L2.01758 11.1574L34.3703 0.77124L51.0321 11.1574L18.0071 21.5806Z"
                         fill="white"
                         stroke="#1C75BC"
                         stroke-miterlimit="10"
                    />
                    <path
                         d="M51.0312 11.1574L66.9464 0.77124L99.0004 11.1574L83.1609 21.5429L51.0312 11.1574Z"
                         fill="white"
                         stroke="#1C75BC"
                         stroke-miterlimit="10"
                    />
                    <path
                         d="M2.0918 31.9663L18.007 21.5806L50.3972 31.9663L34.2213 42.352L2.0918 31.9663Z"
                         fill="white"
                         stroke="#59AED3"
                         stroke-miterlimit="10"
                    />
                    <path
                         d="M50.584 31.9662L83.161 21.5427L99.0005 32.3027L66.9471 42.3895L50.584 31.9662Z"
                         fill="white"
                         stroke="#59AED3"
                         stroke-miterlimit="10"
                    />
                    <defs>
                         <linearGradient
                              id="paint0_linear_232_26"
                              x1="19.7597"
                              y1="37.1681"
                              x2="51.032"
                              y2="37.1681"
                              gradientUnits="userSpaceOnUse"
                         >
                              <stop stop-color="#1C75BC" stop-opacity="0.2" />
                              <stop
                                   offset="1"
                                   stop-color="#1C75BC"
                                   stop-opacity="0.2"
                              />
                         </linearGradient>
                         <linearGradient
                              id="paint1_linear_232_26"
                              x1="50.9746"
                              y1="37.3739"
                              x2="82.2471"
                              y2="37.3739"
                              gradientUnits="userSpaceOnUse"
                         >
                              <stop stop-color="#1C75BC" stop-opacity="0.2" />
                              <stop
                                   offset="1"
                                   stop-color="#1C75BC"
                                   stop-opacity="0.2"
                              />
                         </linearGradient>
                    </defs>
               </svg>
          </div>
     );
};

export default BoxWallSectionSVG;
