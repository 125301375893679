import { createSlice, current } from "@reduxjs/toolkit";
import { NavBarLinksList, navBarLinkState } from "../../config/navConfig";
import { routes } from "../../config/mainConfig";

export const dashbaordViewModeObj = {
     view: "view",
     plan: "plan",
};

const initialState = {
     navbarLink: NavBarLinksList,
     isOpenSideNav: false,

     plan: {
          currentPlan: "",
          message: "Current Plan",
          paymentMessage: "",
          cost: "",
          isPaid: false,
          paymentLink: "",
          isUpdateAvailable: true,
     },

     ui: {
          dashboardViewMode: dashbaordViewModeObj.view,
     },
};

const navBarSlice = createSlice({
     name: "nav-bar state management",
     initialState,

     reducers: {
          ///---------------------------------------------------------------

          modifyNavPlan(state, action) {
               return {
                    ...state,
                    plan: {
                         ...state.plan,
                         ...action.payload,
                    },
               };
          },

          ///---------------------------------------------------------------
          modifyReviewNavLink(state, action) {
               console.log(action.payload);
               let media = action.payload;
               return {
                    ...state,
                    navbarLink: {
                         ...state.navbarLink,
                         review: {
                              assest: {
                                   text: media.name,
                                   link: routes.socialmedialist,
                                   //+ "/" +
                                   // media.link,
                                   activeLink:
                                        routes.socialmedialist +
                                        "/" +
                                        media.link,
                                   logo: media.image,
                              },
                              state: navBarLinkState.socialmedia,
                         },
                    },
               };
          },

          modifySchedulerNavLink(state, action) {
               console.log(action.payload);
               let media = action.payload;
               return {
                    ...state,
                    navbarLink: {
                         ...state.navbarLink,
                         scheduler: {
                              assest: {
                                   text: media.name,
                                   link: routes.scheduler,
                                   activeLink: routes.scheduler,
                                   logo: media.image,
                              },
                              state: navBarLinkState.socialmedia,
                         },
                    },
               };
          },

          modifyIsOpenNavBar(state, action) {
               return {
                    ...state,
                    isOpenSideNav: action.payload,
               };
          },

          resetReviewNavLink(state, action) {
               return {
                    ...state,
                    navbarLink: {
                         ...state.navbarLink,
                         review: NavBarLinksList.review,
                    },
               };
          },

          resetSchedulerNavLink(state, action) {
               return {
                    ...state,
                    navbarLink: {
                         ...state.navbarLink,
                         scheduler: NavBarLinksList.scheduler,
                    },
               };
          },

          // modifing the dashboard view mode
          modifyDashboardViewMode(state, action) {
               return {
                    ...state,
                    ui: {
                         ...state.ui,
                         dashboardViewMode: action.payload,
                    },
               };
          },
     },
});

export const navBarSliceActions = navBarSlice.actions;
export default navBarSlice;
