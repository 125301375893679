import React from "react";
import LeftArrowSVG from "../../../templates/Svg/ContactForm/LeftArrowSVG";
import ButtonComponent from "../../../../basicultils/ButtonComponent";
import { useNavigate } from "react-router-dom";

const ContactFormBackButton = () => {
     const navigate = useNavigate();

     return (
          <div className=" w-fit h-fit  text-contactFormBackTextColor font-montserrat text-[12px]  flex flex-col pt-4">
               <ButtonComponent
                    className="w-fit h-fit "
                    onClick={() => {
                         navigate(-1);
                    }}
                    isLoading={false}
               >
                    <div className="  flex flex-row  gap-1">
                         <LeftArrowSVG /> Back
                    </div>
               </ButtonComponent>
          </div>
     );
};

export default ContactFormBackButton;
