import React from "react";

import SignupLayout from "../../components/AuthenticationCom/SignupLayout";
import SelectPlanUIComponent from "../../components/OtherComponents/SelectPlanUIComponent";
import { useDispatch, useSelector } from "react-redux";
import { userRegisterSliceActions } from "../../store/AuthenticationSlices/UserRegisterationSlice";

const SignUpPage = () => {
     const dispatch = useDispatch();
     const userregister = useSelector((state) => {
          return state.userregister.ui;
     });

     const closeSelectPlan = () => {
          dispatch(userRegisterSliceActions.modifyIsSelectPlanOn(false));
     };

     const selectPlanComponentFunction = (e) => {
          console.log(e);
          dispatch(
               userRegisterSliceActions.setContentPlan({
                    id: e.id,
                    name: e.title,
               })
          );
     };

     return (
          <>
               <SignupLayout />
               {/* {userregister.isSelectPlanOn && (
                    <SelectPlanUIComponent
                         onClick={selectPlanComponentFunction}
                         closeOnClick={closeSelectPlan}
                    />
               )} */}
          </>
     );
};

export default SignUpPage;
