// Local Storage

import { masterSliceActions } from "../store/masterSlice";

const key_tripAdivsor = "trip_auth";

export const setTripAdvisorLocalStorage = (obj = "") => {
     localStorage.setItem(key_tripAdivsor, JSON.stringify(obj));
};

export const getTripAdvisorLocalStorage = () => {
     // Getting local storage of trip
     let obj = JSON.parse(localStorage.getItem(key_tripAdivsor));
     console.log("Trip : ", obj);
     return obj;
};

// Thunk to check and update master slice
export const updateTripAdvisorMasterSliceAuthSlice = () => {
     return async (dispatch) => {
          // Get the local Storage
          let localStorage = getTripAdvisorLocalStorage();

          if (!localStorage) return;

          dispatch(masterSliceActions.updateTripadvisor(localStorage));
     };
};
