import React from "react";
import { Outlet } from "react-router-dom";

const OutletComPage = () => {
     return (
          <div className=" w-full h-full ">
               <Outlet />
          </div>
     );
};

export default OutletComPage;
