import React from "react";
import CalenderEachLetterLayout from "./utils/CalenderEachLetterLayout";
import CalenderEachRowLayout from "./utils/CalenderEachRowLayout";
import ButtonComponent from "../../../../basicultils/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { sevenPairDates } from "../../../../config/CalenderConfig";
import { calenderSliceActions } from "./calenderStore/calenderSlice";

const CalenderBodySection = () => {
     const datesArr2 = useSelector((state) => state.calender.dateArr);

     //  {
     // date : 0,
     // isSelected : true or false,
     // selectedAble : true or false,
     //  }

     const dispatch = useDispatch();
     //let date = new Date(useSelector((state) => state.calender.selectedDate));

     //  date = new Date(findLastDayOfMonth(date));

     // console.log(date);

     const changeDateHandler = (date) => {
          console.log(date);
          dispatch(calenderSliceActions.setSelectedDate(date));
          dispatch(calenderSliceActions.prevMonth());
          dispatch(calenderSliceActions.nextMonth());
     };

     return (
          <div className=" w-full flex flex-col justify-between">
               <CalenderEachRowLayout>
                    <CalenderEachLetterLayout>S</CalenderEachLetterLayout>
                    <CalenderEachLetterLayout>M</CalenderEachLetterLayout>
                    <CalenderEachLetterLayout>T</CalenderEachLetterLayout>
                    <CalenderEachLetterLayout>W</CalenderEachLetterLayout>
                    <CalenderEachLetterLayout>Th</CalenderEachLetterLayout>
                    <CalenderEachLetterLayout>F</CalenderEachLetterLayout>
                    <CalenderEachLetterLayout>Sa</CalenderEachLetterLayout>
               </CalenderEachRowLayout>

               {/* <CalenderEachRowLayout>
                    {datesArr.splice(0, 7).map((date) => {
                         return (
                              <CalenderEachLetterLayout>
                                   {date}
                              </CalenderEachLetterLayout>
                         );
                    })}
               </CalenderEachRowLayout> */}

               <CalenderEachRowLayout>
                    {sevenPairDates(datesArr2, 0).map((date) => {
                         return (
                              <CalenderEachLetterLayout>
                                   {date.selectedAble ? (
                                        <ButtonComponent
                                             className={` ${
                                                  date.isSelected
                                                       ? "w-[36px] h-[36px] rounded-[50%]  text-[#fff] bg-[#FF6161] "
                                                       : "w-full h-full "
                                             }`}
                                             onClick={() => {
                                                  changeDateHandler(date.date);
                                             }}
                                        >
                                             {date.date}
                                        </ButtonComponent>
                                   ) : (
                                        <div className=" w-full h-full text-[#C5C5C5] flex flex-row justify-center items-center ">
                                             {date.date}
                                        </div>
                                   )}
                              </CalenderEachLetterLayout>
                         );
                    })}
               </CalenderEachRowLayout>
               {/* --------------------------------------------------------------------------------------------------------- */}
               <CalenderEachRowLayout>
                    {sevenPairDates(datesArr2, 1).map((date) => {
                         return (
                              <CalenderEachLetterLayout>
                                   {date.selectedAble ? (
                                        <ButtonComponent
                                             className={` ${
                                                  date.isSelected
                                                       ? "w-[36px] h-[36px] rounded-[50%]  text-[#fff] bg-[#FF6161]"
                                                       : "w-full h-full "
                                             }`}
                                             onClick={() => {
                                                  changeDateHandler(date.date);
                                             }}
                                        >
                                             {date.date}
                                        </ButtonComponent>
                                   ) : (
                                        <div className=" w-full h-full text-[#C5C5C5] flex flex-row justify-center items-center ">
                                             {date.date}
                                        </div>
                                   )}
                              </CalenderEachLetterLayout>
                         );
                    })}
               </CalenderEachRowLayout>

               <CalenderEachRowLayout>
                    {sevenPairDates(datesArr2, 2).map((date) => {
                         return (
                              <CalenderEachLetterLayout>
                                   {date.selectedAble ? (
                                        <ButtonComponent
                                             className={` ${
                                                  date.isSelected
                                                       ? "w-[36px] h-[36px] rounded-[50%]  text-[#fff] bg-[#FF6161] "
                                                       : "w-full h-full "
                                             }`}
                                             onClick={() => {
                                                  changeDateHandler(date.date);
                                             }}
                                        >
                                             {date.date}
                                        </ButtonComponent>
                                   ) : (
                                        <div className=" w-full h-full text-[#C5C5C5] flex flex-row justify-center items-center ">
                                             {date.date}
                                        </div>
                                   )}
                              </CalenderEachLetterLayout>
                         );
                    })}
               </CalenderEachRowLayout>

               <CalenderEachRowLayout>
                    {sevenPairDates(datesArr2, 3).map((date) => {
                         return (
                              <CalenderEachLetterLayout>
                                   {date.selectedAble ? (
                                        <ButtonComponent
                                             className={` ${
                                                  date.isSelected
                                                       ? "w-[36px] h-[36px] rounded-[50%]  text-[#fff] bg-[#FF6161] "
                                                       : "w-full h-full "
                                             }`}
                                             onClick={() => {
                                                  changeDateHandler(date.date);
                                             }}
                                        >
                                             {date.date}
                                        </ButtonComponent>
                                   ) : (
                                        <div className=" w-full h-full text-[#C5C5C5] flex flex-row justify-center items-center ">
                                             {date.date}
                                        </div>
                                   )}
                              </CalenderEachLetterLayout>
                         );
                    })}
               </CalenderEachRowLayout>

               <CalenderEachRowLayout>
                    {sevenPairDates(datesArr2, 4).map((date) => {
                         return (
                              <CalenderEachLetterLayout>
                                   {date.selectedAble ? (
                                        <ButtonComponent
                                             className={` ${
                                                  date.isSelected
                                                       ? "w-[36px] h-[36px] rounded-[50%]  text-[#fff] bg-[#FF6161] "
                                                       : "w-full h-full "
                                             }`}
                                             onClick={() => {
                                                  changeDateHandler(date.date);
                                             }}
                                        >
                                             {date.date}
                                        </ButtonComponent>
                                   ) : (
                                        <div className=" w-full h-full text-[#C5C5C5] flex flex-row justify-center items-center ">
                                             {date.date}
                                        </div>
                                   )}
                              </CalenderEachLetterLayout>
                         );
                    })}
               </CalenderEachRowLayout>

               <CalenderEachRowLayout>
                    {sevenPairDates(datesArr2, 5).map((date) => {
                         return (
                              <CalenderEachLetterLayout>
                                   {date.selectedAble ? (
                                        <ButtonComponent
                                             className={` ${
                                                  date.isSelected
                                                       ? "w-[36px] h-[36px] rounded-[50%]  text-[#fff] bg-[#FF6161] "
                                                       : "w-full h-full "
                                             }`}
                                             onClick={() => {
                                                  changeDateHandler(date.date);
                                             }}
                                        >
                                             {date.date}
                                        </ButtonComponent>
                                   ) : (
                                        <div className=" w-full h-full text-[#C5C5C5] flex flex-row justify-center items-center ">
                                             {date.date}
                                        </div>
                                   )}
                              </CalenderEachLetterLayout>
                         );
                    })}
               </CalenderEachRowLayout>
               {/* --------------------------------------------------------------------------------------------------------- */}
          </div>
     );
};

export default CalenderBodySection;
