import React from "react";

// Assest Components
import ScheduledSVG from "../../../templates/Svg/Scheduler/ScheduledSVG";
import DoneSVG from "../../../templates/Svg/Scheduler/DoneSVG";
import MoreSVG from "../../../templates/Svg/Scheduler/MoreSVG";

// UI components
import CheckBoxComponent from "../../../../basicultils/CheckBoxComponent";
import ButtonComponent from "../../../../basicultils/ButtonComponent";
import SchedulerListColumnCompound from "./utils/SchedulerListColumnCompound";

// Config
import { schedulerRigthColumns } from "../../../../config/SchedulerConfig";
// Hooks
import { useSelector } from "react-redux";

// Helper Functions
function formatDate(date) {
     const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
     ];

     const day = date.getDate();
     const month = months[date.getMonth()];
     const year = date.getFullYear();

     return `${day} ${month} ${year}`;
}
function formatTime(date) {
     let hours = date.getHours();
     const minutes = date.getMinutes();
     const ampm = hours >= 12 ? "PM" : "AM";
     hours = hours % 12;
     hours = hours ? hours : 12; // the hour '0' should be '12'
     const minutesStr = minutes < 10 ? "0" + minutes : minutes;

     return `${hours}:${minutesStr} ${ampm}`;
}

const SchedulerListInDivRow = ({ content }) => {
     let date = formatDate(new Date(content?.taskTime));
     let time = formatTime(new Date(content?.taskTime));
     let post = content?.content;

     let isPosted = content?.isPosted;

     const scheduler = useSelector((state) => {
          return state.scheduler;
     });
     let selectedSchedulerPlatform = scheduler.selectedSocialMedia;

     return (
          <div className="w-fit h-[48px] flex flex-row flex-shrink-0 justify-between gap-2 border-b-2">
               <div className=" w-fit h-full flex flex-row gap-2 ps-8">
                    <SchedulerListColumnCompound width={"w-[50px]"}>
                         <CheckBoxComponent onClick={() => {}} value={false} />
                    </SchedulerListColumnCompound>
                    <SchedulerListColumnCompound
                         width={schedulerRigthColumns[0].width}
                    >
                         <p className=" text-black">{date}</p>
                    </SchedulerListColumnCompound>
                    <SchedulerListColumnCompound
                         width={schedulerRigthColumns[1].width}
                    >
                         <p className=" text-black">{time}</p>
                    </SchedulerListColumnCompound>
                    <SchedulerListColumnCompound
                         width={schedulerRigthColumns[2].width}
                    >
                         <p className=" text-black">
                              {selectedSchedulerPlatform.name}
                         </p>
                    </SchedulerListColumnCompound>
                    <SchedulerListColumnCompound
                         width={schedulerRigthColumns[3].width}
                    >
                         <p className=" text-black w-full h-full overflow-hidden flex flex-row items-center ">
                              {post}
                         </p>
                    </SchedulerListColumnCompound>
                    <SchedulerListColumnCompound
                         width={schedulerRigthColumns[4].width}
                    >
                         {!isPosted && (
                              <div className=" w-fit h-fit flex flex-row gap-1 text-[#F2994A]">
                                   <ScheduledSVG /> <p>Scheduled</p>
                              </div>
                         )}
                         {isPosted && (
                              <div className=" w-fit h-fit flex flex-row gap-1 text-[02A443]">
                                   <DoneSVG /> <p>Posted</p>
                              </div>
                         )}
                    </SchedulerListColumnCompound>
               </div>
               <div className=" w-fit h-full">
                    <SchedulerListColumnCompound>
                         <ButtonComponent className="w-fit h-fit">
                              <MoreSVG />
                         </ButtonComponent>
                    </SchedulerListColumnCompound>
               </div>
          </div>
     );
};

export default SchedulerListInDivRow;
