import React from "react";
import ButtonComponent from "../../basicultils/ButtonComponent";
import NinethSectionFAQ from "./NinethSectionFAQ";

const NinethSection = () => {
     return (
          <div
               className=" w-full h-fit pt-[5rem] pb-[5rem] flex flex-col gap-[3rem] "
               id={"faq"}
          >
               <div className=" w-full h-fit flex flex-col items-center gap-[1.5rem] p-6 rounded-[8px]">
                    <h1 className=" w-[100%] flex flex-row md:justify-center justify-start font-poppins text-[20px] font-[600] bg-clip-text text-transparent bg-mainRectangeGredient2 ">
                         FAQ
                    </h1>
                    <p className=" lg:w-[50%] md:w-[65%] w-[100%]  md:text-center text-start font-poppins text-[36px] leading-[38px] font-[600] text-[#111013]">
                         Frequently asked question
                    </p>
                    <p className=" md:w-[75%] w-[100%] md:text-center text-start font-poppins text-[20px] leading-[24px] font-[500] text-[#9497A1] ">
                         Exploring Solutions: Your Go-To Resource to Common
                         Queries
                    </p>
               </div>

               <NinethSectionFAQ />

               <div className=" w-full h-fit flex flex-row justify-center ">
                    <ButtonComponent className=" max-w-[606px] w-[90%] md:w-[85%] md:h-[66px] h-[50px] bg-[#FFEAEA]">
                         <p className="  bg-clip-text text-transparent bg-redLinearGradient font-poppins md:text-[18px] text-[14px] font-[500]">
                              Still have unanswered questions? Get in touch
                         </p>
                    </ButtonComponent>
               </div>
          </div>
     );
};

export default NinethSection;
