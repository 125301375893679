import React from "react";

const ImproveSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
          >
               <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4 1.25C3.0335 1.25 2.25 2.0335 2.25 3V6C2.25 6.9665 3.0335 7.75 4 7.75H4.75V13.25H4C3.0335 13.25 2.25 14.0335 2.25 15V18C2.25 18.9665 3.0335 19.75 4 19.75H7C7.9665 19.75 8.75 18.9665 8.75 18V17.25H10C10.4142 17.25 10.75 16.9142 10.75 16.5C10.75 16.0858 10.4142 15.75 10 15.75H8.75V15C8.75 14.0335 7.9665 13.25 7 13.25H6.25V7.75H7C7.9665 7.75 8.75 6.9665 8.75 6V5.25H14.25V6C14.25 6.9665 15.0335 7.75 16 7.75H16.75V9C16.75 9.41421 17.0858 9.75 17.5 9.75C17.9142 9.75 18.25 9.41421 18.25 9V7.75H19C19.9665 7.75 20.75 6.9665 20.75 6V3C20.75 2.0335 19.9665 1.25 19 1.25H16C15.0335 1.25 14.25 2.0335 14.25 3V3.75H8.75V3C8.75 2.0335 7.9665 1.25 7 1.25H4ZM17.5 6.25H19C19.1381 6.25 19.25 6.13807 19.25 6V3C19.25 2.86193 19.1381 2.75 19 2.75H16C15.8619 2.75 15.75 2.86193 15.75 3V4.5V6C15.75 6.13807 15.8619 6.25 16 6.25H17.5ZM7.25 15V16.5V18C7.25 18.1381 7.13807 18.25 7 18.25H4C3.86193 18.25 3.75 18.1381 3.75 18V15C3.75 14.8619 3.86193 14.75 4 14.75H5.5H7C7.13807 14.75 7.25 14.8619 7.25 15ZM7.25 3V4.5V6C7.25 6.13807 7.13807 6.25 7 6.25H5.5H4C3.86193 6.25 3.75 6.13807 3.75 6V3C3.75 2.86193 3.86193 2.75 4 2.75H7C7.13807 2.75 7.25 2.86193 7.25 3ZM16 17C15.5858 17 15.25 17.3358 15.25 17.75C15.25 18.1642 15.5858 18.5 16 18.5H18C18.4142 18.5 18.75 18.1642 18.75 17.75C18.75 17.3358 18.4142 17 18 17H16ZM15.25 15.25C15.25 14.8358 15.5858 14.5 16 14.5H18C18.4142 14.5 18.75 14.8358 18.75 15.25C18.75 15.6642 18.4142 16 18 16H16C15.5858 16 15.25 15.6642 15.25 15.25ZM15 11.25C13.4812 11.25 12.25 12.4812 12.25 14V19C12.25 20.5188 13.4812 21.75 15 21.75H19C20.5188 21.75 21.75 20.5188 21.75 19V14C21.75 12.4812 20.5188 11.25 19 11.25H15ZM13.75 14C13.75 13.3096 14.3096 12.75 15 12.75H19C19.6904 12.75 20.25 13.3096 20.25 14V19C20.25 19.6904 19.6904 20.25 19 20.25H15C14.3096 20.25 13.75 19.6904 13.75 19V14Z"
                    fill="url(#paint0_linear_88_519)"
               />
               <defs>
                    <linearGradient
                         id="paint0_linear_88_519"
                         x1="12"
                         y1="1.25"
                         x2="12"
                         y2="21.75"
                         gradientUnits="userSpaceOnUse"
                    >
                         <stop stop-color="#F17A01" />
                         <stop offset="1" stop-color="#E80757" />
                    </linearGradient>
               </defs>
          </svg>
     );
};

export default ImproveSVG;
