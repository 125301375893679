import React, { useRef } from "react";
import DragAndBrowseSVG from "../../../templates/Svg/ContactForm/DragAndBrowseSVG";

import ButtonComponent from "../../../../basicultils/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { schedulerSlideActions } from "../../../../store/SchedulerSlices/schedulerSlide";
import { FaXmark } from "react-icons/fa6";
import VideoSVG from "../../../templates/Svg/VideoSVG";

const UploadComponents = ({ onClick = () => {} }) => {
     return (
          <ButtonComponent
               onClick={onClick}
               className=" w-full h-full flex flex-row justify-center items-center border-2 border-dashed rounded-[8px] border-[#B5B5B5]  shadow-lg "
          >
               <div className=" w-fit h-fit flex flex-col items-center gap-[1rem]">
                    <VideoSVG width="72" height="48" />
                    <h1 className=" text-[14px] font-montserrat ">
                         Drop your Video here or{" "}
                         <span className=" text-[#1B92FF]">browse</span>
                         <br></br>
                         <p className=" text-[10px]">
                              {`Videos less than 4 MB works efficiently. Pls upload within the maximum limit.`}
                         </p>
                    </h1>
               </div>
          </ButtonComponent>
     );
};

const DisplayVideoFile = ({
     uploadState = null,
     clearUploadState = () => {},
}) => {
     console.log(URL.createObjectURL(uploadState));
     return (
          <div className=" relative  w-full h-full border-2 border-dashed rounded-[8px] border-[#B5B5B5]  shadow-lg flex flex-col gap-[2rem] font-bold justify-center items-center ">
               <ButtonComponent
                    onClick={clearUploadState}
                    className=" w-fit h-fit absolute right-[20px] top-[20px]"
               >
                    <FaXmark size={20} />
               </ButtonComponent>
               <div className=" w-[90%] h-[150px] rounded-[8px] bg-red-50 flex flex-row justify-center items-center">
                    The Video is selected
               </div>

               <h1 className=" text-[14px] font-montserrat ">
                    {uploadState?.name}
               </h1>
          </div>
     );
};

const SchedulerDragAndBrowse = () => {
     const dispatch = useDispatch();
     const addPost = useSelector((state) => {
          return state.scheduler.addPost;
     });

     const inputRef = useRef();
     return (
          <ButtonComponent
               // onClick={() => {
               //      inputRef.current.click();
               // }}
               className=" w-[100%] max-w-[588px] lg:h-[400px] h-[250px] border-2  rounded-[20px] border-dashed border-[#B5B5B5] flex flex-col justify-center items-center gap-8"
          >
               {addPost?.file ? (
                    <DisplayVideoFile
                         uploadState={addPost?.file}
                         clearUploadState={() => {
                              dispatch(
                                   schedulerSlideActions.updateAddPostFile(
                                        false
                                   )
                              );
                         }}
                    />
               ) : (
                    <UploadComponents
                         onClick={() => {
                              inputRef.current.click();
                         }}
                    />
               )}

               <input
                    type="file"
                    onChange={(e) => {
                         dispatch(
                              schedulerSlideActions.updateAddPostFile(
                                   e.target.files[0]
                              )
                         );
                    }}
                    className=" hidden"
                    ref={inputRef}
               />
          </ButtonComponent>
     );
};

export default SchedulerDragAndBrowse;
