import React from "react";

const FeatureSectionHeader = ({ heading = "Feature-1", body = "" }) => {
     return (
          <div className=" w-full h-fit md:p-12 p-6 flex flex-col gap-[1rem]">
               <h1 className=" font-poppins text-[17px] font-[500] capitalize bg-clip-text text-transparent bg-mainRectangeGredient3">
                    {heading}
               </h1>
               <p className="  font-poppins md:text-[30px] text-[24px] leading-[116%] font-[600] text-[#1C1F25] ">
                    {body}
               </p>
          </div>
     );
};

export default FeatureSectionHeader;
