import React from "react";
import { socialmedia } from "../../config/socialmediaConfig";

import { useSelector } from "react-redux";
import SchedulerTiktokAddAccountDetails from "../../components/Scheduler/SchedulerIntegratingSocialMedia/SchedulerTiktokAddAccountDetails";
import SchedulerFaceBookAddAccountDetails from "../../components/Scheduler/SchedulerIntegratingSocialMedia/SchedulerFaceBookAddAccountDetails";
import SchedulerInstagramAddAccountDetails from "../../components/Scheduler/SchedulerIntegratingSocialMedia/SchedulerInstagramAddAccountDetails";
import SchedulerTwitterAddAccountDetails from "../../components/Scheduler/SchedulerIntegratingSocialMedia/SchedulerTwitterAddAccountDetails";
import SchedulerLinkedInAccountDetails from "../../components/Scheduler/SchedulerIntegratingSocialMedia/SchedulerLinkedInAccountDetails";

const SchedulerAddAccountPage = () => {
     const { selectedSocialMedia } = useSelector((state) => {
          return state.scheduler;
     });

     return (
          <>
               {selectedSocialMedia.link === socialmedia.linkedin.link && (
                    <SchedulerLinkedInAccountDetails />
               )}
               {selectedSocialMedia.link === socialmedia.twitter.link && (
                    <SchedulerTwitterAddAccountDetails />
               )}
               {selectedSocialMedia.link === socialmedia.tiktok.link && (
                    <SchedulerTiktokAddAccountDetails />
               )}
               {selectedSocialMedia.link === socialmedia.facebook.link && (
                    <SchedulerFaceBookAddAccountDetails />
               )}
               {selectedSocialMedia.link === socialmedia.instagram.link && (
                    <SchedulerInstagramAddAccountDetails />
               )}
          </>
     );
};

export default SchedulerAddAccountPage;
