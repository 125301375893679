import React from "react";
import ContactLeft from "./ContactLeft/ContactLeft";
import ContactRigth from "./ContactRigth/ContactRigth";
import { formsState } from "../../../../store/LeadformsSlices/LeadformSlice";

import HeaderLeadForms from "../../HeaderLeadForms";
import ButtonComponent from "../../../../basicultils/ButtonComponent";

// Hooks
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { routes } from "../../../../config/mainConfig";

const FormsContent = () => {
     const navigate = useNavigate();

     const smallScreenMode = useSelector((state) => {
          return state.leadforms.smallScreenMode;
     });
     console.log(smallScreenMode);
     return (
          <>
               <div className=" w-full h-full flex flex-col">
                    <HeaderLeadForms title="Forms">
                         <ButtonComponent
                              onClick={() => {
                                   navigate(routes?.leadformsdocs);
                              }}
                              className=" me-[1rem]  w-[132px] h-[40px] rounded-[10px] bg-mainRectangeGredient text-[16px] text-[#fff] font-[600]  "
                         >
                              Connect Forms
                         </ButtonComponent>
                    </HeaderLeadForms>
                    <div className=" flex-1  ">
                         <div className=" w-full h-full  overflow-hidden flex-row md:flex hidden   ">
                              <div className=" xl:w-[35%] lg:w-[40%] w-[40%] min-w-[300px] h-full border-e-2  flex flex-col">
                                   <ContactLeft />
                              </div>
                              <div className=" flex-1   ">
                                   {/* <div className=" w-full h-full overflow-auto">
                                        <ContactRigth />
                                   </div> */}
                                   <ContactRigth />
                              </div>
                         </div>
                         <div className=" w-full h-full flex md:hidden flex-col items-center">
                              {smallScreenMode === formsState.left && (
                                   <ContactLeft />
                              )}
                              {smallScreenMode === formsState.right && (
                                   <ContactRigth />
                              )}
                         </div>
                    </div>
               </div>
          </>
     );
};

export default FormsContent;
