import React, { useEffect } from "react";
import ModalComponent from "../../../basicultils/ModalComponent";
import ButtonComponent from "../../../basicultils/ButtonComponent";

import { useDispatch, useSelector } from "react-redux";
import {
     tiktokCallBackAuthentication,
     tiktokOpenAuthentiationTab,
} from "../../../store/SchedulerSlices/TiktokAuthenticationThunks";
import { useSearchParams, useNavigate } from "react-router-dom";
import { routes } from "../../../config/mainConfig";

const SchedulerTiktokAddAccountDetails = () => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const [searchParams] = useSearchParams();
     const { auth } = useSelector((state) => {
          return state.master;
     });

     const clickAuthenticateButton = () => {
          tiktokOpenAuthentiationTab(auth);
     };

     useEffect(() => {
          console.log(searchParams.get("code"));

          if (searchParams.get("code")) {
               dispatch(
                    tiktokCallBackAuthentication(
                         searchParams.get("code"),
                         auth,
                         navigate
                    )
               );
          }
     }, [auth]);

     return (
          <ModalComponent>
               <div className=" w-full h-full bg-black bg-opacity-20 absolute  flex flex-row justify-center items-center ">
                    <div className=" max-w-[524px] w-[80%] h-fit min-h-[210px] bg-[#fff] flex flex-col p-3 md:pt-[2rem] pt-[1rem] rounded-[8px]">
                         <div className=" w-full pt-[1rem] h-full flex flex-col justify-center items-center md:text-[16px] text-[14px] gap-[1rem] text-[#577496] font-lato ps-2 pe-2 ">
                              <div className=" w-fit ">
                                   Click Authenticate Tiktok button to login
                                   your Tiktok account
                              </div>

                              <div className=" w-full h-fit flex flex-row justify-center gap-[1rem] mt-[1.75rem] ">
                                   <ButtonComponent
                                        onClick={() => {
                                             navigate(routes.scheduler);
                                        }}
                                        className="  w-[200px] h-[50px] text-[#577496] text-[16px] font-lato border-[#577496] border-2 rounded-[6px]"
                                   >
                                        Cancel
                                   </ButtonComponent>

                                   <ButtonComponent
                                        onClick={clickAuthenticateButton}
                                        className=" w-[200px] h-[50px] text-[16px] text-[#fff] font-lato  border-2 rounded-[6px] bg-mainRectangeGredient"
                                   >
                                        Authenticate With Tik tok
                                   </ButtonComponent>
                              </div>
                         </div>
                    </div>
               </div>
          </ModalComponent>
     );
};

export default SchedulerTiktokAddAccountDetails;
