import React, { useState } from "react";
import ButtonComponent from "../../../../basicultils/ButtonComponent";
import SelectComponent from "../../../../basicultils/SelectComponent";
import { useDispatch, useSelector } from "react-redux";
import { schedulerModes } from "../../../../config/SchedulerConfig";
import { schedulerSlideActions } from "../../../../store/SchedulerSlices/schedulerSlide";
import { schedulerMonthWeekSliceActions } from "../../../../store/SchedulerSlices/SchedulerMonthWeekSlice";

const optionarr = [
     {
          id: "1",
          content: "10 Dec 2023 - 31 Dec 2023",
     },
     {
          id: "2",
          content: "12 Dec 2023 - 31 Dec 2023",
     },
     {
          id: "3",
          content: "Instagram",
     },

     {
          id: "4",
          content: "15 Dec 2023 - 31 Dec 2023",
     },
];

function formatDate(date) {
     const options = { month: "short", year: "numeric" };
     return date.toLocaleDateString("en-US", options).replace(",", "");
}

function getNextMonthDate(date) {
     // Create a new Date object to avoid modifying the original date
     const nextMonthDate = new Date(date);

     // Set the month to the next month
     nextMonthDate.setMonth(date.getMonth() + 1);

     // Return the new Date object
     return nextMonthDate;
}

function getLastMonthDate(date) {
     const lastMonthDate = new Date(date);
     lastMonthDate.setMonth(date.getMonth() - 1);
     return lastMonthDate;
}

const SchedulerGridSetting = () => {
     const mode = useSelector((state) => state.scheduler.mode);

     const dispatch = useDispatch();

     const selectedButtonClassnames = "bg-mainRectangeGredient text-[#fff]";
     const unSelectedButtonClassnames = "text-black bg-[#fff] ";

     const [selected, setSelected] = useState(
          optionarr[0]
               ? optionarr[0]
               : { id: "none", content: "10 Dec 2023 - 31 Dec 2023" }
     );

     const updateSelected = (option) => {
          setSelected(option);
     };

     //---------------------------
     const { month } = useSelector((state) => {
          return state.schedulerMonthWeek;
     });

     const updateReducers = (method) => {
          if (mode === schedulerModes.month) {
               switch (method) {
                    case "today": {
                         updateMonth(new Date());
                         break;
                    }
                    case "back": {
                         let lastMonth = getLastMonthDate(
                              new Date(month.currentMonthDateObj)
                         );
                         updateMonth(lastMonth);
                         break;
                    }
                    case "next": {
                         let nextMonth = getNextMonthDate(
                              new Date(month.currentMonthDateObj)
                         );
                         updateMonth(nextMonth);
                         break;
                    }
               }
          }

          if (mode === schedulerModes.week) {
               switch (method) {
                    case "today": {
                         break;
                    }
                    case "back": {
                         break;
                    }
                    case "next": {
                         break;
                    }
               }
          }
     };

     const updateMonth = (month) => {
          dispatch(schedulerMonthWeekSliceActions.updateMonth(new Date(month)));
     };

     let month_string = formatDate(new Date(month.currentMonthDateObj));

     return (
          <div className=" w-full min-h-[65px] flex flex-row justify-between items-end flex-wrap ps-6 pe-6 mt-[1rem] mb-[1rem] gap-y-6">
               {/* ------------------------------------------------------------------------------------------------------ */}
               <div className=" w-fit h-full flex flex-col justify-center ">
                    <div className=" w-fit h-fit flex flex-row border-2 rounded-[10px] overflow-hidden">
                         <ButtonComponent
                              isLoading={false}
                              onClick={() => {
                                   updateReducers("today");
                              }}
                              className={`
                              w-[150px] h-[50px] font-poppins text-[16px] border-e-2 hover:bg-mainRectangeGredient hover:text-[#fff] duration-100 ${unSelectedButtonClassnames}
                              `}
                         >
                              Today
                         </ButtonComponent>
                         <ButtonComponent
                              isLoading={false}
                              onClick={() => {
                                   updateReducers("back");
                              }}
                              className={`
                              w-[150px] h-[50px] font-poppins text-[16px] border-e-2 hover:bg-mainRectangeGredient hover:text-[#fff] duration-100 ${unSelectedButtonClassnames}
                              `}
                         >
                              Back
                         </ButtonComponent>
                         <ButtonComponent
                              isLoading={false}
                              onClick={() => {
                                   updateReducers("next");
                              }}
                              className={`
                              w-[150px] h-[50px] font-poppins text-[16px] hover:bg-mainRectangeGredient hover:text-[#fff] duration-100 ${unSelectedButtonClassnames}
                              `}
                         >
                              Next
                         </ButtonComponent>
                    </div>
               </div>
               {/* --------------------------------------------------------------------------------------------------------- */}
               <div className=" w-fit h-full flex flex-col justify-center">
                    {/* <SelectComponent
                         className=" w-[270px] h-[36px] "
                         textClassName="text-[16px] font-poppins text-[#555] font-[500]"
                         optionarr={optionarr}
                         updateFunction={updateSelected}
                         value={selected}
                    /> */}
               </div>
               {/* --------------------------------------------------------------------------------------------------------- */}
               <div className=" w-fit h-full  flex flex-col justify-center flex-wrap">
                    <div className=" w-fit h-fit flex flex-row   border-2 rounded-[10px] overflow-hidden">
                         <ButtonComponent
                              isLoading={false}
                              onClick={() => {
                                   dispatch(
                                        schedulerSlideActions.updateMode(
                                             schedulerModes.month
                                        )
                                   );
                              }}
                              className={`
                              w-[150px] h-[50px] font-poppins text-[16px] border-2-b ${
                                   mode === schedulerModes.month
                                        ? selectedButtonClassnames
                                        : unSelectedButtonClassnames
                              }
                              `}
                         >
                              {month_string}
                         </ButtonComponent>
                         {/* <ButtonComponent
                              isLoading={false}
                              onClick={() => {
                                   dispatch(
                                        schedulerSlideActions.updateMode(
                                             schedulerModes.week
                                        )
                                   );
                              }}
                              className={`
                              w-[150px] h-[50px] font-poppins text-[16px]   border-e-2 ${
                                   mode === schedulerModes.week
                                        ? selectedButtonClassnames
                                        : unSelectedButtonClassnames
                              }
                              `}
                         >
                              Week
                         </ButtonComponent> */}
                         {/* <ButtonComponent
                              isLoading={false}
                              onClick={() => {}}
                              className={`
                               w-[150px] h-[50px] font-poppins text-[16px] ${unSelectedButtonClassnames}
                              `}
                         >
                              Day
                         </ButtonComponent> */}
                    </div>
               </div>
          </div>
     );
};

export default SchedulerGridSetting;
