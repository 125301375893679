import React from "react";

const HamburgerLandingpage = ({ size }) => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width={size}
               height={size}
               viewBox="0 0 24 24"
               fill="none"
          >
               <path
                    d="M15.5 17.75V16.25L3 16.5V18L15.5 17.75ZM21 12.75V11.25H3V12.75H21ZM21 7.5V6H3V7.5H21Z"
                    fill="black"
               />
          </svg>
     );
};

export default HamburgerLandingpage;
