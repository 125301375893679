import React from "react";

const EightSectionContentBox = ({ title, body, children }) => {
     return (
          <div className=" w-full h-fit flex md:flex-row flex-col  pt-2 md:gap-[0.5rem] gap-[0.2rem] ">
               <div className=" w-fit h-fit ps-2 pe-2 ">
                    <div className=" w-[60px] h-[60px] rounded-[50%] bg-[#000] flex flex-row justify-center items-center">
                         {children}
                    </div>
               </div>

               <div className=" w-fit h-fit pt-2 md:ps-0 ps-4 ">
                    <h1 className=" text-[#fff] font-poppins font-bold md:text-[20px] text-[18px] ">
                         {title}
                    </h1>

                    <p className=" text-[#A6A6A6] font-poppins md:text-[17px] text-[14px] md:leading-[25px] leading-[22px]">
                         {body}
                    </p>
               </div>
          </div>
     );
};

export default EightSectionContentBox;
