import React, { useEffect } from "react";
import MentionLayout from "./Mention/MentionLayout";
import WallLayout from "./wall/WallLayout";
import DynamicSocialMediaSmallScreenHeader from "./wall/DynamicSocialMediaSmallScreenHeader";
import { useSelector } from "react-redux";
import { dynamicState } from "../../store/DynamicSocialMediaSlices/DynamicSocialMediaSlices";

import useCallingReview from "../../customHooks/useCallingReview";

const DynamicSocialMediaLayout = () => {
     const smallScreenMode = useSelector((state) => {
          return state.dynamicsocialmedia.smallScreenMode;
     });

     const { auth } = useSelector((state) => {
          return state.master;
     });
     const { selectedSocialMedia } = useSelector((state) => {
          return state.dynamicsocialmedia;
     });

     const [fetchReview] = useCallingReview();
     useEffect(() => {
          fetchReview();
     }, [auth, selectedSocialMedia]);

     return (
          <>
               <div className=" w-full h-full hidden md:flex flex-row ">
                    <div className=" lg:w-[35%] w-[45%] h-full">
                         <MentionLayout />
                    </div>
                    <div className=" lg:w-[65%] w-[65%] h-full flex-1">
                         <WallLayout isBigScreen={true} />
                    </div>
               </div>

               <div className=" w-full h-full md:hidden flex flex-col">
                    <DynamicSocialMediaSmallScreenHeader />
                    {smallScreenMode === dynamicState.wall && (
                         <WallLayout isBigScreen={false} />
                    )}
                    {smallScreenMode === dynamicState.mention && (
                         <MentionLayout />
                    )}
               </div>
          </>
     );
};

export default DynamicSocialMediaLayout;
