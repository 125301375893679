import React from "react";

const IndivReviewSchedulerSocialMediaCard = ({ children, isDummy = false }) => {
     return (
          <div
               className={`w-[140px] h-[145px]  ${
                    isDummy
                         ? ""
                         : "rounded-[12px] shadow-socailmediaboxshadow cursor-pointer "
               } `}
          >
               {children}
          </div>
     );
};

export default IndivReviewSchedulerSocialMediaCard;
