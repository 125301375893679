import React from "react";

import VideoFormSuccessfullUploadLayout from "./VideoFormSuccessfullUploadLayout";
import VideoUploadLayout from "./VideoUploadLayout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
     contactFormSliceActions,
     uploadVideoPageMode,
} from "../../../../store/contactFormSlice";
import ButtonComponent from "../../../../basicultils/ButtonComponent";
import LeftArrowSVG from "../../../templates/Svg/ContactForm/LeftArrowSVG";
import HeaderLeadForms from "../../HeaderLeadForms";
const VideoFormAddLayout = () => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const mode = useSelector((state) => {
          return state.contactform.mode;
     });

     let uploadVideo = mode.uploadVideo;

     return (
          <div className=" w-full h-full relative  flex flex-col">
               <div className=" w-fit h-fit absolute top-[50px]  text-contactFormBackTextColor font-montserrat text-[12px]  flex flex-col pt-4">
                    <ButtonComponent
                         className="w-fit h-fit "
                         onClick={() => {
                              if (
                                   uploadVideo ===
                                   uploadVideoPageMode.successfullUpload
                              ) {
                                   dispatch(
                                        contactFormSliceActions.uploadVideoPageMode(
                                             uploadVideoPageMode.uploading
                                        )
                                   );
                              } else {
                                   navigate(-1);
                              }
                         }}
                         isLoading={false}
                    >
                         <div className="  flex flex-row  gap-1">
                              <LeftArrowSVG /> Back
                         </div>
                    </ButtonComponent>
               </div>

               <HeaderLeadForms title="Video Upload"></HeaderLeadForms>
               <div className=" flex-1 ">
                    {uploadVideo === uploadVideoPageMode.uploading && (
                         <VideoUploadLayout />
                    )}
                    {uploadVideo === uploadVideoPageMode.successfullUpload && (
                         <VideoFormSuccessfullUploadLayout />
                    )}
               </div>
          </div>
     );
};

export default VideoFormAddLayout;
