import React, { useState } from "react";
import SearchBar from "../../../basicultils/SearchBar";
import SelectComponent from "../../../basicultils/SelectComponent";
const optionarr = [
     {
          id: "1",
          content: "Balajitheblankde@gmail.com",
     },
     {
          id: "2",
          content: "mithun@gmail.com",
     },
     {
          id: "3",
          content: "blajitheblandke@gmail.cok",
     },

     {
          id: "4",
          content: "mithun@gmail.com",
     },
     {
          id: "5",
          content: "dfasfasf",
     },
     {
          id: "6",
          content: "sadfas",
     },
     {
          id: "7",
          content: "sadfas",
     },
     {
          id: "8",
          content: "sadfas",
     },
];
const WallHeaderSection = ({ isBigScreen = false }) => {
     const [selected, setSelected] = useState(
          optionarr[0] ? optionarr[0] : { id: "none", content: "jar" }
     );

     const updateSelected = (option) => {
          setSelected(option);
     };
     return (
          <div className=" w-full min-h-[50px]  flex lg:flex-row flex-col lg:gap-0 gap-[1rem] lg:m-0 mt-[0.65rem]">
               <div className=" lg:w-[60%] w-[100%] flex flex-col justify-center items-center ">
                    <SearchBar
                         searchbarcss={
                              "w-[90%] h-[33px] relative overflow-hidden   flex flex-row justify-between font-montserrat"
                         }
                         isLogoAtFront={true}
                    />
               </div>
               {/* <div className=" lg:w-[40%] w-[100%] flex flex-row flex-wrap justify-evenly items-center  ">
                    <ButtonComponent
                         onClick={() => {}}
                         isLoading={false}
                         className=" w-[40%] h-[32px] text-center text-mentionButtoncolor text-[11px] font-montserrat font-[600] bg-[#E1E7ED] border-2 rounded-[2px]"
                    >
                         Embed Code
                    </ButtonComponent>
                    <ButtonComponent
                         onClick={() => {}}
                         isLoading={false}
                         className=" w-[40%] h-[32px] text-center text-[#fff] text-[11px] font-montserrat font-[600] bg-mainRectangeGredient border-2 rounded-[2px]"
                    >
                         Publish Changes
                    </ButtonComponent>
               </div> */}
               {/* not a correct size */}
               {isBigScreen && (
                    <div className="  lg:w-[40%] w-[100%] flex flex-row flex-wrap justify-center items-center ">
                         <SelectComponent
                              textClassName=" text-[12px] font-montserrat"
                              className=" w-[90%] h-[33px] rounded-[12px] "
                              optionarr={optionarr}
                              updateFunction={updateSelected}
                              value={selected}
                         />
                    </div>
               )}
          </div>
     );
};

export default WallHeaderSection;
