import React from "react";

const ContactRigthListingEachColumnSection = ({ width, children }) => {
     return (
          <div
               className={`
 ${width}  h-full flex flex-col justify-center
 `}
          >
               {children}
          </div>
     );
};

export default ContactRigthListingEachColumnSection;
