import React from "react";
import SchedulerListViewLayout from "../../components/Scheduler/SchedulerListView.js/SchedulerListViewLayout";

const SchedulerListView = () => {
     return (
          <div className=" w-full h-full ">
               <SchedulerListViewLayout />
          </div>
     );
};

export default SchedulerListView;
