import React from "react";

const WorkFlowSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="25"
               height="25"
               viewBox="0 0 25 25"
               fill="none"
          >
               <path
                    d="M21.8753 16.6666V13.5416C21.8753 12.3853 20.9482 11.4583 19.792 11.4583H13.542V8.33325H15.6253V2.08325H9.37533V8.33325H11.4587V11.4583H5.20866C4.05241 11.4583 3.12533 12.3853 3.12533 13.5416V16.6666H1.04199V22.9166H7.29199V16.6666H5.20866V13.5416H11.4587V16.6666H9.37533V22.9166H15.6253V16.6666H13.542V13.5416H19.792V16.6666H17.7087V22.9166H23.9587V16.6666H21.8753ZM11.4587 4.16659H13.542V6.24992H11.4587V4.16659ZM5.20866 20.8333H3.12533V18.7499H5.20866V20.8333ZM13.542 20.8333H11.4587V18.7499H13.542V20.8333ZM21.8753 20.8333H19.792V18.7499H21.8753V20.8333Z"
                    fill="white"
               />
          </svg>
     );
};

export default WorkFlowSVG;
