import { createSlice } from "@reduxjs/toolkit";

const initialState = {
     content: {
          code: {
               value: "",
          },
     },
};

export const SchedulerAuthenticationSocialMediaSlice = createSlice({
     name: "SCHEDULER_AUTHENTICATION_SOCIALMEDIA_SLICE",
     initialState,
     reducers: {
          updateContentCode(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         code: {
                              ...state.content.code,
                              value: action.payload,
                         },
                    },
               };
          },
     },
});

export const SchedulerAuthenticationSocialMediaSliceActions =
     SchedulerAuthenticationSocialMediaSlice.actions;
export default SchedulerAuthenticationSocialMediaSlice;
