import React from "react";

const UntiedSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="25"
               height="25"
               viewBox="0 0 25 25"
               fill="none"
          >
               <path
                    d="M3.125 5.46875V17.9688C3.125 18.3832 3.28962 18.7806 3.58265 19.0736C3.87567 19.3666 4.2731 19.5312 4.6875 19.5312H20.3125C20.7269 19.5312 21.1243 19.3666 21.4174 19.0736C21.7104 18.7806 21.875 18.3832 21.875 17.9688V5.46875H3.125ZM3.125 3.90625H21.875C22.2894 3.90625 22.6868 4.07087 22.9799 4.3639C23.2729 4.65692 23.4375 5.05435 23.4375 5.46875V17.9688C23.4375 18.7976 23.1083 19.5924 22.5222 20.1785C21.9362 20.7645 21.1413 21.0938 20.3125 21.0938H4.6875C3.8587 21.0938 3.06384 20.7645 2.47779 20.1785C1.89174 19.5924 1.5625 18.7976 1.5625 17.9688V5.46875C1.5625 5.05435 1.72712 4.65692 2.02015 4.3639C2.31317 4.07087 2.7106 3.90625 3.125 3.90625Z"
                    fill="white"
               />
               <path
                    d="M22.0703 5.46875L16.0281 12.375C15.5882 12.878 15.0457 13.281 14.4372 13.5572C13.8287 13.8334 13.1682 13.9762 12.5 13.9762C11.8318 13.9762 11.1713 13.8334 10.5628 13.5572C9.95426 13.281 9.41184 12.878 8.97188 12.375L2.92969 5.46875H22.0703ZM5.00625 5.46875L10.1469 11.3453C10.4402 11.6807 10.8018 11.9495 11.2075 12.1337C11.6133 12.3178 12.0537 12.4131 12.4992 12.4131C12.9448 12.4131 13.3852 12.3178 13.7909 12.1337C14.1966 11.9495 14.5583 11.6807 14.8516 11.3453L19.9937 5.46875H5.00625Z"
                    fill="white"
               />
          </svg>
     );
};

export default UntiedSVG;
