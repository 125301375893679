
import React from "react";
import SchedulerAddPostCom from "../../components/Scheduler/SchedulerAddPost/SchedulerAddPostCom";

const SchedulerAddPost = () => {
     return (
          <div className=" w-full h-full">
               <SchedulerAddPostCom />
          </div>
     );
};

export default SchedulerAddPost;
