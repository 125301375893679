import React from "react";

const MoreSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="20"
               height="20"
               viewBox="0 0 20 20"
               fill="none"
          >
               <path
                    d="M9.99247 12.1951C11.1829 12.1951 12.1353 11.2195 12.1353 10C12.1353 8.78049 11.1829 7.80488 9.99247 7.80488C8.80199 7.80488 7.84961 8.78049 7.84961 10C7.84961 11.2195 8.80199 12.1951 9.99247 12.1951ZM7.84961 17.8049C7.84961 16.5854 8.80199 15.6098 9.99247 15.6098C11.1829 15.6098 12.1353 16.5854 12.1353 17.8049C12.1353 19.0244 11.1829 20 9.99247 20C8.80199 20 7.84961 19.0244 7.84961 17.8049ZM7.84961 2.19512C7.84961 0.975609 8.80199 0 9.99247 0C11.1829 0 12.1353 0.975609 12.1353 2.19512C12.1353 3.41463 11.1829 4.39024 9.99247 4.39024C8.80199 4.39024 7.84961 3.41463 7.84961 2.19512Z"
                    fill="#F2994A"
               />
          </svg>
     );
};

export default MoreSVG;
