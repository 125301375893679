import React from "react";

const SchedulerListColumnCompound = ({ children, width = "w-[116px]" }) => {
     return (
          <div
               className={` ${width} h-full font-inter font-[600] text-[12px] text-[#666E7D] flex flex-row justify-start items-center`}
          >
               {children}
          </div>
     );
};

export default SchedulerListColumnCompound;
