import React, { useEffect } from "react";
import ProfileInformationLayout from "./utils/ProfileInformationLayout";
import GoogleSVG from "../templates/Svg/profile/GoogleSVG";
import ButtonComponent from "../../basicultils/ButtonComponent";
import SelectPlanUIComponent from "../OtherComponents/SelectPlanUIComponent";
import { useDispatch, useSelector } from "react-redux";
import {
     dashbaordViewModeObj,
     navBarSliceActions,
} from "../../store/DashboardSlices/NavbarSlice";
import { SelectPlanFoundWithId } from "../../config/SelectPlanConfig";
import {
     billsFetchingThunk,
     changeMembershipThunk,
} from "../../store/AuthenticationSlices/UserRegisterationSlice";

const ProfileInformation = () => {
     const dispatch = useDispatch();
     const { ui } = useSelector((state) => {
          return state.navbar;
     });
     const { auth } = useSelector((state) => {
          return state.master;
     });

     const { plan } = useSelector((state) => {
          return state.navbar;
     });
     console.log(plan);

     let dashboardViewMode = ui.dashboardViewMode;

     useEffect(() => {
          dispatch(billsFetchingThunk(auth.sessionToken));
     }, [auth]);

     const updatePlanButtonFunction = () => {
          dispatch(
               navBarSliceActions.modifyDashboardViewMode(
                    dashbaordViewModeObj.plan
               )
          );
     };
     const payButtonFunction = () => {
          window.open(plan.paymentLink, "_blank");
     };

     const closeSelectPlan = () => {
          dispatch(
               navBarSliceActions.modifyDashboardViewMode(
                    dashbaordViewModeObj.view
               )
          );
     };

     const selectPlanComponentFunction = (e) => {
          console.log(e);
          dispatch(changeMembershipThunk(e.id, auth.sessionToken));
          closeSelectPlan();
     };

     return (
          <>
               <div className=" w-full h-fit flex md:flex-row flex-col gap-4 p-4 mt-4  ">
                    <ProfileInformationLayout title={"Profile Information"}>
                         <div className="w-full h-full flex flex-col justify-start p-4 gap-4">
                              <h3 className=" text-[12px] text-[#8999AB] font-montserrat font-bold">
                                   {auth.username}
                              </h3>
                              <div className=" w-full flex flex-row gap-4">
                                   <GoogleSVG />
                                   <p className="  text-[12px]  font-montserrat text-[#555]">
                                        {auth.email}
                                   </p>
                              </div>
                         </div>
                    </ProfileInformationLayout>
                    <ProfileInformationLayout title={"Plan"}>
                         <div className="w-full h-full flex flex-col justify-between p-4 gap-4">
                              <div className=" w-full h-fit flex flex-col gap-[0.5rem]">
                                   <div className=" w-full h-fit flex flex-row justify-between">
                                        <h1 className=" font-montserrat text-[14px] ">
                                             {plan?.currentPlan}
                                        </h1>
                                        <h2 className=" text-[#17173A] flex flex-row gap-[0.5rem] font-montserrat font-extrabold text-[14px]">
                                             <h3 className="text-[#8999AB]">
                                                  {plan.paymentMessage}
                                             </h3>
                                             {plan?.cost}
                                        </h2>
                                   </div>
                                   <div className=" w-full h-fit flex flex-row justify-between">
                                        <h3 className=" text-[12px] text-[#8999AB] font-montserrat font-bold">
                                             {plan?.message}
                                        </h3>
                                   </div>
                              </div>

                              <div className=" w-full h-fit flex flex-row justify-between">
                                   {plan.isUpdateAvailable ? (
                                        <ButtonComponent
                                             onClick={updatePlanButtonFunction}
                                             className=" text-[12px] font-montserrat font-bold text-[#fff] w-[109px] h-[29px] bg-mainRectangeGredient rounded-[8px] "
                                        >
                                             Update Plan
                                        </ButtonComponent>
                                   ) : (
                                        <div></div>
                                   )}
                                   {plan.isPaid ? (
                                        <></>
                                   ) : (
                                        <ButtonComponent
                                             onClick={payButtonFunction}
                                             className=" text-[12px] font-montserrat font-bold text-[#fff] w-[109px] h-[29px] bg-mainRectangeGredient rounded-[8px] "
                                        >
                                             Pay
                                        </ButtonComponent>
                                   )}
                              </div>
                         </div>
                    </ProfileInformationLayout>

                    {dashboardViewMode === dashbaordViewModeObj.plan && (
                         <SelectPlanUIComponent
                              onClick={selectPlanComponentFunction}
                              selectedId={auth.type}
                              closeOnClick={closeSelectPlan}
                         />
                    )}
               </div>
          </>
     );
};

export default ProfileInformation;
