import { asyncThunkCreator, createSlice, current } from "@reduxjs/toolkit";
import {
     emailValid,
     mobilenumberChecker,
     passwordChecker,
     setLoginAuthLocalStorage,
} from "./loginSlice";
import {
     baseURl_User_Module,
     createUser,
     getbills,
     userMembershipChange,
} from "../../API-utils/APIConfig";
import { PostAPI } from "../../API-utils/PostAPI";
import { GetAPI, status } from "../../API-utils/GetAPI";
import { routes } from "../../config/mainConfig";
import { masterSliceActions } from "../masterSlice";
import { navBarSliceActions } from "../DashboardSlices/NavbarSlice";
import { SelectPlanFoundWithId } from "../../config/SelectPlanConfig";

const initialState = {
     content: {
          email: {
               value: "",
               valid: "",
               touched: "",
               message: "email includes @",
          },
          username: {
               value: "",
               valid: false,
               touched: false,
               message: "Username cannot be empty",
               taken_username: ["User"],
               taken_username_message: "User name is already taken",
          },

          mobilenumber: {
               value: "",
               valid: false,
               touched: false,
               message: "please enter vaild mobile number ",
          },

          password: {
               value: "",
               valid: false,
               touched: false,
               message: "Password Should contain 6 characters",
          },

          plan: {
               id: "",
               name: "",
               touched: false,
          },
     },

     ui: {
          formButtonIsLoading: false,
          isSelectPlanOn: false,
     },
};

export const check_Taken_username = (currentUserName, taken_username_array) => {
     let count = taken_username_array.length;
     for (let i = 0; i < count; i++) {
          if (taken_username_array[i] === currentUserName) {
               return true;
          }
     }
     return false;
};

const checker = (val) => {
     if (val.trim().length) return true;
     return false;
};

const userRegisterSlice = createSlice({
     name: "User register state management ",
     initialState,

     reducers: {
          //modify the isSelectplanON

          modifyIsSelectPlanOn(state, action) {
               return {
                    ...state,
                    ui: {
                         ...state.ui,
                         isSelectPlanOn: action.payload,
                    },
               };
          },

          //User name
          setUsernameValue(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         username: {
                              ...state.content.username,
                              value: action.payload,
                              valid: checker(action.payload),
                         },
                    },
               };
          },
          setUsernameBlur(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         username: {
                              ...state.content.username,
                              touched: true,
                         },
                    },
               };
          },

          addTakenUserNameArray(state, action) {
               let currentState = current(state);

               let taken_username =
                    currentState.content.username.taken_username;
               console.log(taken_username);
               taken_username = [...taken_username];

               taken_username.push(action.payload);

               return {
                    ...state,
                    content: {
                         ...state.content,
                         username: {
                              ...state.content.username,
                              taken_username: taken_username,
                         },
                    },
               };
          },

          // email
          setEmailValue(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         email: {
                              ...state.content.email,
                              value: action.payload,
                              valid: emailValid(action.payload),
                         },
                    },
               };
          },
          setEmailBlur(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         email: {
                              ...state.content.email,
                              touched: true,
                         },
                    },
               };
          },

          setMobilenumberValue(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         mobilenumber: {
                              ...state.content.mobilenumber,
                              value: action.payload,
                              valid: mobilenumberChecker(action.payload),
                         },
                    },
               };
          },

          setMobilenumberBlur(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         mobilenumber: {
                              ...state.content.mobilenumber,
                              touched: true,
                         },
                    },
               };
          },

          setPasswordValue(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         password: {
                              ...state.content.password,
                              value: action.payload,
                              valid: passwordChecker(action.payload),
                         },
                    },
               };
          },

          setPasswordBlur(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         password: {
                              ...state.content.password,
                              touched: true,
                         },
                    },
               };
          },

          //password state management
          //newenter password
          setNewValue(state, action) {
               return {
                    ...state,
                    password: {
                         ...state.password,
                         newenter: {
                              ...state.password.newenter,
                              value: action.payload,
                              valid: passwordChecker(action.payload),
                         },
                    },
               };
          },
          setNewBlur(state, action) {
               return {
                    ...state,
                    password: {
                         ...state.password,
                         newenter: {
                              ...state.password.newenter,
                              touched: true,
                         },
                    },
               };
          },

          //reenter
          setReenterValue(state, action) {
               return {
                    ...state,
                    password: {
                         ...state.password,
                         reenter: {
                              ...state.password.reenter,
                              value: action.payload,
                         },
                    },
               };
          },
          setReenterBlur(state, action) {
               return {
                    ...state,
                    password: {
                         ...state.password,
                         reenter: {
                              ...state.password.reenter,
                              touched: true,
                         },
                    },
               };
          },

          setReenterValid(state, action) {
               return {
                    ...state,
                    password: {
                         ...state.password,
                         reenter: {
                              ...state.password.reenter,
                              valid: action.payload,
                         },
                    },
               };
          },

          blurContentPlan(state, action) {
               return {
                    ...state,
                    content: {
                         ...state.content,
                         plan: {
                              ...state.content.plan,
                              touched: true,
                         },
                    },
               };
          },

          setContentPlan(state, action) {
               console.log(action.payload);
               return {
                    ...state,
                    content: {
                         ...state.content,
                         plan: {
                              ...state.content.plan,
                              id: action.payload.id,
                              name: action.payload.name,
                         },
                    },
               };
          },

          resetState(state, action) {
               return {
                    ...state,
                    content: initialState.content,
               };
          },

          modifyFormButtonIsLoading(state, action) {
               return {
                    ...state,
                    ui: {
                         ...state.ui,
                         formButtonIsLoading: action.payload,
                    },
               };
          },
     },
});

export const userRegisterSliceActions = userRegisterSlice.actions;
export default userRegisterSlice;

export const userRegisterAPIThunk = (inputObj, navigate) => {
     return async (dispatch) => {
          console.log(inputObj);

          dispatch(userRegisterSliceActions.modifyFormButtonIsLoading(true));

          let api = new URL(baseURl_User_Module + createUser);

          console.log(api.href);

          let obj = {
               username: inputObj.username.value,
               email: inputObj.email.value,

               password: inputObj.password.value,
               mobile: inputObj.mobilenumber.value,
               role: "basic",
          };

          let response = await PostAPI(api, obj, {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
               "Access-Control-Allow-Methods": [
                    "POST",
                    "GET",
                    "OPTIONS",
                    "DELETE",
                    "PUT",
               ],
               // "Access-Control-Allow-Headers": [
               //      "append",
               //      "delete",
               //      "entries",
               //      "foreach",
               //      "get",
               //      "has",
               //      "keys",
               //      "set",
               //      "values",
               //      "Authorization",
               // ],
               "Access-Control-Allow-Headers":
                    "Origin, X-Requested-With, Content-Type, Accept",
               // redirect: "follow",
          });

          if (response.status === status.successful) {
               console.log(response);
               let responseData = response.responsedata;
               let data = responseData?.user;
               if (data) {
                    console.log(data);

                    navigate(routes.signin);
                    dispatch(userRegisterSliceActions.resetState());
               } else if (responseData?.error) {
                    let error = responseData?.error;

                    dispatch(
                         userRegisterSliceActions.addTakenUserNameArray(
                              obj.username
                         )
                    );
                    console.log(error);
               }
          } else if (response.status === status.error) {
               console.log(response);
          }

          dispatch(userRegisterSliceActions.modifyFormButtonIsLoading(false));
     };
};

export const changeMembershipThunk = (id, userToken) => {
     return async (dispatch) => {
          let api = new URL(baseURl_User_Module + userMembershipChange);

          console.log(api.href);

          let obj = {
               member_type: id,
          };

          let response = await PostAPI(api, obj, {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
               "Access-Control-Allow-Methods": [
                    "POST",
                    "GET",
                    "OPTIONS",
                    "DELETE",
                    "PUT",
               ],
               // "Access-Control-Allow-Headers": [
               //      "append",
               //      "delete",
               //      "entries",
               //      "foreach",
               //      "get",
               //      "has",
               //      "keys",
               //      "set",
               //      "values",
               //      "Authorization",
               // ],
               "Access-Control-Allow-Headers":
                    "Origin, X-Requested-With, Content-Type, Accept",
               // redirect: "follow",

               Authorization: userToken,
          });

          if (response.status === status.successful) {
               let responsedata = response.responsedata;
               console.log(responsedata);

               if (responsedata?.user) {
                    let data = responsedata.user;
                    console.log(data);
                    const obj = {
                         email: data?.email,
                         username: data?.username,
                         mobile: data?.mobile,
                         session_token: userToken,
                         id: data?._id,
                         type: data?.type,
                    };

                    console.log(obj);
                    setLoginAuthLocalStorage(userToken);
                    dispatch(
                         navBarSliceActions.modifyNavPlan({
                              currentPlan: SelectPlanFoundWithId(obj.type)
                                   .title,
                         })
                    );
                    dispatch(masterSliceActions.updateAuthentication(obj));
               }
          }
          if (response.status === status.error) {
               console.log(response);
          }
     };
};

export const billsFetchingThunk = (userToken) => {
     return async (dispatch) => {
          let api = new URL(baseURl_User_Module + getbills);

          console.log(api.href);
          console.log(userToken);

          let response = await GetAPI(api, {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
               "Access-Control-Allow-Methods": [
                    "POST",
                    "GET",
                    "OPTIONS",
                    "DELETE",
                    "PUT",
               ],
               "Access-Control-Allow-Headers": [
                    "append",
                    "delete",
                    "entries",
                    "foreach",
                    "get",
                    "has",
                    "keys",
                    "set",
                    "values",
                    "Authorization",
               ],
               "Access-Control-Allow-Headers":
                    "Origin, X-Requested-With, Content-Type, Accept",
               redirect: "follow",

               Authorization: userToken,
          });

          let responseObj = response.response;

          if (responseObj.ok) {
               console.log(response.responsedata);
               let data = response.responsedata.data;
               console.log(data);
               dispatch(invoiceObjThunk(data));
               // if (data.length === 0) {
               //      dispatch(
               //           navBarSliceActions.modifyNavPlan({
               //                paymentMessage: "Paid",
               //                isPaid: true,
               //           })
               //      );
               // } else if (data.length >= 1) {
               //      // Please clear your pending dues to reactivate
               //      // Account suspended
               //      let dataObj = data[0];
               //      let paymentLink = dataObj.payment_link;
               //      let status = dataObj.status;
               //      if (status === "unpaid") {
               //           dispatch(
               //                navBarSliceActions.modifyNavPlan({
               //                     cost: dataObj.amount,
               //                     isPaid: false,
               //                     paymentLink: paymentLink,
               //                })
               //           );
               //      }
               // }
          }
     };
};

export const invoiceObjThunk = (invoiceArray) => {
     return async (dispatch) => {
          // console.log(invoiceObj);

          if (invoiceArray.length === 0) {
               dispatch(
                    navBarSliceActions.modifyNavPlan({
                         paymentMessage: "Paid",
                         isPaid: true,
                    })
               );
               return;
          }

          console.log("Invoice", invoiceArray);
          let invoiceObj = invoiceArray[0];

          let amount = invoiceObj?.amount;
          let payment = invoiceObj?.payment;
          let paymentLink = payment?.payment_link;

          dispatch(
               navBarSliceActions.modifyNavPlan({
                    currentPlan: SelectPlanFoundWithId(invoiceObj.user.role)
                         .title,
                    cost: payment.currency + " " + amount,
                    paymentMessage: "",
                    isPaid: false,
                    paymentLink: paymentLink,
                    isUpdateAvailable: true,
               })
          );
     };
};
