export const contactFormModes = {
     form: {
          view: "view",
          edit: "edit",
     },

     delete: {
          none: "none",
          deleting: "deleting",
          successfullyDeleted: "deleted",
     },
};
