export const CalenderMonths = [
     "Jan",
     "Feb",
     "Mar",
     "Apr",
     "May",
     "Jun",
     "Jul",
     "Aug",
     "Sep",
     "Oct",
     "Nov",
     "Dec",
];

export const CalenderDay = [
     "Sunday",
     "Monday",
     "Tuesday",
     "Wednesday",
     "Thursday",
     "Friday",
     "Saturday",
];

export const minutesArray = [
     "00",
     "01",
     "02",
     "03",
     "04",
     "05",
     "06",
     "07",
     "08",
     "09",
     "10",
     "11",
     "12",
     "13",
     "14",
     "15",
     "16",
     "17",
     "18",
     "19",
     "20",
     "21",
     "22",
     "23",
     "24",
     "25",
     "26",
     "27",
     "28",
     "29",
     "30",
     "31",
     "32",
     "33",
     "34",
     "35",
     "36",
     "37",
     "38",
     "39",
     "40",
     "41",
     "42",
     "43",
     "44",
     "45",
     "46",
     "47",
     "48",
     "49",
     "50",
     "51",
     "52",
     "53",
     "54",
     "55",
     "56",
     "57",
     "58",
     "59",
];

export const hoursArray = [
     "12",
     "01",
     "02",
     "03",
     "04",
     "05",
     "06",
     "07",
     "08",
     "09",
     "10",
     "11",
];

export const zoneArray = ["AM", "PM"];

export const sevenPairDates = (dates, no) => {
     let arr = [];

     for (let i = no * 7; i < no * 7 + 7; i++) {
          arr.push(dates[i]);
     }

     return arr;
};

export const datesDefault = [
     {
          date: 27,
          isSelected: false,
          selectedAble: false,
     },
     {
          date: 28,
          isSelected: false,
          selectedAble: false,
     },
     {
          date: 29,
          isSelected: false,
          selectedAble: false,
     },
     {
          date: 30,
          isSelected: false,
          selectedAble: false,
     },
     {
          date: 1,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 2,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 3,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 4,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 5,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 6,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 7,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 8,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 9,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 10,
          isSeleted: false,
          selectedAble: true,
     },
     {
          date: 11,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 12,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 13,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 14,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 15,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 16,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 17,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 18,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 19,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 20,
          isSeleted: false,
          selectedAble: true,
     },
     {
          date: 21,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 22,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 23,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 24,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 25,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 26,
          isSelected: true,
          selectedAble: true,
     },
     {
          date: 27,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 28,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 29,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 30,
          isSeleted: false,
          selectedAble: true,
     },
     {
          date: 31,
          isSelected: false,
          selectedAble: true,
     },
     {
          date: 1,
          isSelected: false,
          selectedAble: false,
     },
     {
          date: 2,
          isSelected: false,
          selectedAble: false,
     },
     {
          date: 3,
          isSelected: false,
          selectedAble: false,
     },
     {
          date: 4,
          isSelected: false,
          selectedAble: false,
     },
     {
          date: 5,
          isSelected: false,
          selectedAble: false,
     },
     {
          date: 6,
          isSelected: false,
          selectedAble: false,
     },
     {
          date: 7,
          isSelected: false,
          selectedAble: false,
     },
];

export const getDateText = (datedd) => {
     let date = new Date(datedd);
     let minute = date.getMinutes();
     let hours = date.getHours();
     let amorPm = hours > 12 ? "PM" : "AM";
     hours = hours > 12 ? hours - 12 : hours;
     let month = CalenderMonths[date.getMonth()];
     let dat = date.getDate();
     dat = dat < 9 ? "0" + dat : dat;
     minute = minute < 9 ? "0" + minute : minute;
     hours = hours < 9 ? "0" + hours : hours;
     let year = date.getFullYear();

     const dateText = `${hours} : ${minute} ${amorPm}  ${month} ${dat}, ${year} `;
     return dateText;
};

export function scheduler_formatDate(date) {
     //  Return value is  based GMT time format
     const year = date.getUTCFullYear();
     const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
     const day = String(date.getUTCDate()).padStart(2, "0");
     const hours = String(date.getUTCHours()).padStart(2, "0");
     const minutes = String(date.getUTCMinutes()).padStart(2, "0");
     const seconds = String(date.getUTCSeconds()).padStart(2, "0");

     return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function returnWithSetTime(date, hourStr, minuteStr, period) {
     // Create a new Date object to avoid mutating the original date
     const newDate = new Date(date);

     // Ensure the hours and minutes are integers
     let hours = parseInt(hourStr, 10);
     let minutes = parseInt(minuteStr, 10);

     // Convert 12 AM to 0 hours and 12 PM to 12 hours
     if (period.toUpperCase() === "AM" && hours === 12) {
          hours = 0;
     } else if (period.toUpperCase() === "PM" && hours < 12) {
          hours += 12;
     }

     // Set the hours and minutes on the new date object
     newDate.setHours(hours, minutes, 0, 0);

     return newDate;
}
