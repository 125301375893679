export const feePlan = {
     title: "Free",
     id: "basic",
     cost: "0",
     features: [
          {
               text: "Users: 1",
               value: true,
          },
          {
               text: "Facebook  Review",
               value: true,
          },
          {
               text: "Instagram Review",
               value: true,
          },
          {
               text: "Google Review",
               value: true,
          },
          {
               text: "Yelp Review",
               value: false,
          },
          {
               text: "Trip Advisor Review",
               value: false,
          },
          {
               text: "Bing Review",
               value: false,
          },
          {
               text: "Facebook Scheduling",
               value: true,
          },
          {
               text: "Instagram Scheduling",
               value: true,
          },
          {
               text: "Google Scheduling",
               value: true,
          },
          {
               text: "Linkedin Scheduling",
               value: true,
          },
          {
               text: "TikTok Scheduling",
               value: true,
          },
          {
               text: "Twitter Scheduling",
               value: false,
          },
          {
               text: "Video Integration: NA",
               value: false,
          },
          {
               text: "Form Integration: NA",
               value: false,
          },
     ],
};

export const professionalPlan = {
     title: "Professional",
     id: "premium",
     cost: "$699",
     features: [
          {
               text: "Users: 2",
               value: true,
          },
          {
               text: "Facebook  Review",
               value: true,
          },
          {
               text: "Instagram Review",
               value: true,
          },
          {
               text: "Google Review",
               value: true,
          },
          {
               text: "Yelp Review",
               value: true,
          },
          {
               text: "Trip Advisor Review",
               value: true,
          },
          {
               text: "Bing Review",
               value: true,
          },
          {
               text: "Facebook Scheduling",
               value: true,
          },
          {
               text: "Instagram Scheduling",
               value: true,
          },
          {
               text: "Google Scheduling",
               value: true,
          },
          {
               text: "Linkedin Scheduling",
               value: true,
          },
          {
               text: "TikTok Scheduling",
               value: true,
          },
          {
               text: "Twitter Scheduling",
               value: true,
          },
          {
               text: "Video Integration: 1 Domain",
               value: true,
          },
          {
               text: "Form Integration: 1 Domain",
               value: true,
          },
     ],
};

export const enterPrisePlan = {
     title: "EnterPrise",
     id: "enterprise",
     cost: "custom",
     features: [
          {
               text: "Users: Unlimited",
               value: true,
          },
          {
               text: "Facebook  Review",
               value: true,
          },
          {
               text: "Instagram Review",
               value: true,
          },
          {
               text: "Google Review",
               value: true,
          },
          {
               text: "Yelp Review",
               value: true,
          },
          {
               text: "Trip Advisor Review",
               value: true,
          },
          {
               text: "Bing Review",
               value: true,
          },
          {
               text: "Facebook Scheduling",
               value: true,
          },
          {
               text: "Instagram Scheduling",
               value: true,
          },
          {
               text: "Google Scheduling",
               value: true,
          },
          {
               text: "Linkedin Scheduling",
               value: true,
          },
          {
               text: "TikTok Scheduling",
               value: true,
          },
          {
               text: "Twitter Scheduling",
               value: true,
          },
          {
               text: "Video Integration: Unlimited Domains",
               value: true,
          },
          {
               text: "Form Integration: Unlimited Domains",
               value: true,
          },
     ],
};

export const blocked = {
     title: "Account is suspended",
     id: "blocked",
     cost: "####",
};

export const SelectPlanFoundWithId = (state) => {
     if (state === feePlan.id) return feePlan;
     if (state === professionalPlan.id) return professionalPlan;
     if (state === enterPrisePlan.id) return enterPrisePlan;
     if (state === blocked.id) return blocked;

     return {title:"Unknown"};
};
