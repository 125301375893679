import React from "react";
import AuthenticationTemplate from "./utils/AuthenticationTemplate";
import { useDispatch, useSelector } from "react-redux";
import {
     check_Taken_username,
     userRegisterAPIThunk,
     userRegisterSliceActions,
} from "../../store/AuthenticationSlices/UserRegisterationSlice";
import LoginFooter from "./utils/LoginFooter";
import ButtonComponent from "../../basicultils/ButtonComponent";
import { useNavigate } from "react-router-dom";

const SignupLayout = () => {
     const navigate = useNavigate();
     const { email, username, password, mobilenumber, plan } = useSelector(
          (state) => state.userregister.content
     );
     const { formButtonIsLoading } = useSelector(
          (state) => state.userregister.ui
     );

     const dispatch = useDispatch();

     const submitHandler = (event) => {
          event.preventDefault();

          if (formButtonIsLoading) {
               return;
          }

          dispatch(
               userRegisterSliceActions.setUsernameValue(username.value.trim())
          );
          //preventing from refresh

          if (
               username.valid &&
               email.valid &&
               mobilenumber.valid &&
               password.valid
               // plan.id !== ""
          ) {
               //navigate("/createpassword");

               dispatch(
                    userRegisterAPIThunk(
                         {
                              username,
                              email,
                              mobilenumber,
                              password,
                              plan,
                         },
                         navigate
                    )
               );
          }

          emailBlurHandler();
          userBlurHandler();
          passwordBlurHandler();
          mobilenumberBlurHandler();
          //navigate("/createpassword");
     };

     const emailChangeHandler = (event) => {
          dispatch(userRegisterSliceActions.setEmailValue(event.target.value));
     };
     const emailBlurHandler = () => {
          dispatch(userRegisterSliceActions.setEmailBlur());
     };

     const userChangeHandler = (event) => {
          dispatch(
               userRegisterSliceActions.setUsernameValue(event.target.value)
          );
     };
     const userBlurHandler = () => {
          dispatch(userRegisterSliceActions.setUsernameBlur());
     };

     const passwordChanceHandler = (event) => {
          dispatch(
               userRegisterSliceActions.setPasswordValue(event.target.value)
          );
     };
     const passwordBlurHandler = () => {
          dispatch(userRegisterSliceActions.setPasswordBlur());
     };

     const mobileNumberChangeHandler = (event) => {
          dispatch(
               userRegisterSliceActions.setMobilenumberValue(event.target.value)
          );
     };

     const mobilenumberBlurHandler = () => {
          dispatch(userRegisterSliceActions.setMobilenumberBlur());
     };

     return (
          <AuthenticationTemplate>
               <div className=" w-fit h-fit ">
                    <h1 className=" text-2xl font-Roboto font-[700] bg-clip-text bg-mainRectangeGredient text-transparent mb-3">
                         {/* Header */}
                         Get started!
                    </h1>

                    <form
                         className=" flex flex-col gap-4 font-Roboto"
                         onSubmit={submitHandler}
                    >
                         {/* All input streams is here */}
                         <div>
                              <input
                                   type="text"
                                   name="Name"
                                   placeholder="Username"
                                   // required
                                   value={username.value}
                                   onChange={userChangeHandler}
                                   onBlur={userBlurHandler}
                                   className={`w-[364px] h-12 ps-3 focus:border   border border-inputBorderColor rounded text-sm font-semibold placeholder:font-Roboto placeholder:text-xs placeholder:text-inputPlaceholderColor ${
                                        username.touched && !username.valid
                                             ? "bg-headerAndButtonColor bg-opacity-25"
                                             : ""
                                   }`}
                              />
                              {username.touched && !username.valid && (
                                   <p className=" text-[10px] text-headerAndButtonColor ps-2">
                                        {username.message}
                                   </p>
                              )}
                              {username.valid &&
                                   check_Taken_username(
                                        username.value.trim(),
                                        username.taken_username
                                   ) && (
                                        <p className=" text-[10px] text-headerAndButtonColor ps-2">
                                             {username.taken_username_message}
                                        </p>
                                   )}
                         </div>
                         <div>
                              <input
                                   type="text"
                                   value={mobilenumber.value}
                                   onChange={mobileNumberChangeHandler}
                                   onBlur={mobilenumberBlurHandler}
                                   name="Phone"
                                   placeholder="Phone"
                                   className={`w-[364px] h-12 ps-3  border border-inputBorderColor rounded text-sm font-semibold placeholder:font-Roboto placeholder:text-xs placeholder:text-inputPlaceholderColor ${
                                        mobilenumber.touched &&
                                        !mobilenumber.valid
                                             ? "bg-headerAndButtonColor bg-opacity-25"
                                             : ""
                                   }`}
                              />
                              {mobilenumber.touched && !mobilenumber.valid && (
                                   <p className=" text-[10px] text-headerAndButtonColor ps-2">
                                        {mobilenumber.message}
                                   </p>
                              )}
                         </div>

                         <div>
                              <input
                                   type="email"
                                   name="email"
                                   // required
                                   value={email.value}
                                   onChange={emailChangeHandler}
                                   onBlur={emailBlurHandler}
                                   placeholder="Email address"
                                   className={`w-[364px] h-12 ps-3 focus:border   border border-inputBorderColor rounded text-sm font-semibold placeholder:font-Roboto placeholder:text-xs placeholder:text-inputPlaceholderColor ${
                                        email.touched && !email.valid
                                             ? "bg-headerAndButtonColor bg-opacity-25"
                                             : ""
                                   }`}
                              />
                              {email.touched && !email.valid && (
                                   <p className=" text-[10px] text-headerAndButtonColor ps-2">
                                        {email.message}
                                   </p>
                              )}
                         </div>

                         <div>
                              <input
                                   type={"password"}
                                   name={"password"}
                                   // required
                                   value={password.value}
                                   onChange={passwordChanceHandler}
                                   onBlur={passwordBlurHandler}
                                   placeholder="Password"
                                   className={`w-[368px] h-12 ps-3  border border-inputBorderColor rounded text-sm font-semibold placeholder:font-Roboto placeholder:text-xs placeholder:text-inputPlaceholderColor ${
                                        password.touched && !password.valid
                                             ? " bg-headerAndButtonColor bg-opacity-25 text-black"
                                             : ""
                                   }`}
                              />
                              {password.touched && !password.valid && (
                                   <p className=" text-[10px] text-headerAndButtonColor ps-2">
                                        {password.message}
                                   </p>
                              )}
                         </div>

                         {/* <div className="flex flex-row items-start mt-2"> */}
                         {/* CheckBox input stream */}
                         {/* <input
                                   type="checkbox"
                                   className="ms-1 me-2 mt-1  "
                                   required
                              /> */}
                         {/* Links to privacy and Terms of service pages / instruction */}
                         {/* <p className=" inline-block text-alterLoginColor text-xs break-words align-baseline font-Roboto">
                                   By signing up, you agree to our{" "}
                                   <Link className=" font-bold hover:text-linkColor">
                                        Terms of Service
                                   </Link>{" "}
                                   and <br /> acknowledge our{" "}
                                   <Link className=" font-bold hover:text-linkColor">
                                        Privacy Policy
                                   </Link>
                                   .
                              </p> */}
                         {/* </div> */}
                         {/* <div>
                              <div className=" w-fit h-fit flex flex-row items-center gap-[1.5rem]">
                                   <div
                                        onClick={() => {
                                             dispatch(
                                                  userRegisterSliceActions.blurContentPlan()
                                             );
                                             dispatch(
                                                  userRegisterSliceActions.modifyIsSelectPlanOn(
                                                       true
                                                  )
                                             );
                                        }}
                                        className=" w-[171px] h-[49px]  rounded-[20px] flex flex-row justify-center items-center cursor-pointer bg-[#E1E7ED] text-[#E91050] text-[14px] font-semibold font-poppins"
                                   >
                                        Select your Plan
                                   </div>
                                   <div className=" font-poppins text-[18px] font-[500] text-[#000]">
                                        {plan?.name}
                                   </div>
                              </div>

                              {password.touched && !password.valid && (
                                   <p className=" text-[10px] text-headerAndButtonColor ps-2">
                                        {"Select the plan"}
                                   </p>
                              )}
                         </div> */}
                         <div className="mt-2">
                              {/* Submit Button for form */}
                              <ButtonComponent
                                   isLoading={formButtonIsLoading}
                                   className=" font-bold uppercase w-[180px] h-12 text-center bg-mainRectangeGredient text-xs text-white rounded font-Roboto"
                              >
                                   Sign up
                              </ButtonComponent>
                         </div>
                    </form>

                    {/* isUser props is boolean true - signin false - signup */}
                    <LoginFooter isUser={false} />
               </div>
          </AuthenticationTemplate>
     );
};

export default SignupLayout;
