import React from "react";

//Layout and UI
import ButtonComponent from "../../../../basicultils/ButtonComponent";

const VideoComDocs = () => {
     return (
          <div className=" w-full h-full p-4 overflow-auto">
               <h1 className=" text-[20px] font-montserrat font-bold text-[#172B70]">
                    Steps to Connect your Website with Ranblitz Account for
                    Videos
               </h1>

               <p className=" font-montserrat text-[14px] text-[#2F407C] mt-[4rem]">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam non lacinia dolor, sed efficitur risus. Praesent ante
                    ex, dictum vel nisl at, rhoncus gravida ligula. Nunc
                    facilisis consequat elit eget dignissim. Curabitur quis
                    efficitur odio. In sodales quis nibh eu maximus. Etiam eget
                    consequat nulla. Nam quis nulla ut tortor convallis cursus
                    at at leo. Etiam efficitur augue sed metus cursus, at semper
                    turpis finibus. In fermentum, elit eu facilisis condimentum,
                    leo odio aliquet quam, at sagittis ex magna sed odio. Mauris
                    eget eros non lacus viverra lacinia id et enim. Vivamus
                    vitae ante sit amet mauris viverra lacinia eu vel ligula.
               </p>

               <div className=" mt-[2.5rem] w-[500px] h-[150px] rounded-[8px] border-2 p-4 bg-[#2E3B43] relative">
                    <ButtonComponent className=" right-3 top-[70%] absolute w-[100px] h-[30px] rounded-[10px] bg-mainRectangeGredient text-[14px] text-[#fff] font-[600]  ">
                         Copy
                    </ButtonComponent>

                    <div className=" w-full h-fit flex flex-col gap-[0.75rem]">
                         <div className=" flex flex-row gap-[1rem] font-[600] text-[#fff]">
                              <span>Curl</span>
                              <span className="text-[#A5A6F6] font-[600]">
                                   --Request GET \
                              </span>
                         </div>

                         <div className=" ps-[4rem] flex flex-row gap-[1rem]">
                              <h1 className="text-[#A5A6F6] font-[600]">
                                   --Url
                              </h1>
                              <h4 className="font-[600] text-[#fff]">
                                   'http://13.51.197.126:8083/api/v1/forms/fetch'
                              </h4>
                         </div>
                         <div className=" ps-[4rem] flex flex-row gap-[1rem]">
                              <h1 className="text-[#A5A6F6] font-[600]">
                                   --Header
                              </h1>
                              <h4 className="font-[600] text-[#FFCA00]">
                                   'accept: Application/Json'
                              </h4>
                         </div>
                    </div>
               </div>
          </div>
     );
};

export default VideoComDocs;
