export const status = {
     successful: "good",
     error: "error_in_API_response",
};

export const GetAPI = async (
     api,
     headers = {
          "Content-Type": "application/json",
     },
     method = "GET"
) => {
     let response = null;
     let responsedata = null;
     try {
          response = await fetch(api, {
               method: method,
               headers: headers,
          });
          responsedata = await response.json();
          return {
               status: status.successful,
               response,
               responsedata,
          };
     } catch (e) {
          //   responsedate = response.data();
          // console.log(e.message);
          // console.log(e.name);
          // console.log(e.status);

          return {
               status: status.error,
               e,
          };
          // console.log(response, responsedate);
     }
};
