import React from "react";

const LeadFormEmptyFormsPageSvg = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="53"
               height="59"
               viewBox="0 0 53 59"
               fill="none"
          >
               <path
                    d="M39.2337 58.1561H4.51811C2.57144 58.1561 0.949219 56.5339 0.949219 54.5872V9.97611C0.949219 8.02945 2.57144 6.40723 4.51811 6.40723H7.92477C8.57366 6.40723 9.14144 6.975 9.14144 7.62389C9.14144 8.27278 8.57366 8.84056 7.92477 8.84056H4.51811C3.86922 8.84056 3.38255 9.32723 3.38255 9.97611V54.5872C3.38255 55.2361 3.86922 55.7228 4.51811 55.7228H39.2337C39.8825 55.7228 40.3692 55.2361 40.3692 54.5872V39.1761C40.3692 38.5272 40.937 37.9594 41.5859 37.9594C42.2348 37.9594 42.8025 38.5272 42.8025 39.1761V54.5872C42.8025 56.615 41.2614 58.1561 39.2337 58.1561Z"
                    fill="#FF6161"
               />
               <path
                    d="M41.5849 14.4372C40.936 14.4372 40.3683 13.8695 40.3683 13.2206V9.97612C40.3683 9.32723 39.8816 8.84056 39.2327 8.84056H35.826C35.1772 8.84056 34.6094 8.27278 34.6094 7.62389C34.6094 6.975 35.1772 6.40723 35.826 6.40723H39.2327C41.1794 6.40723 42.8016 8.02945 42.8016 9.97612V13.2206C42.8016 13.8695 42.3149 14.4372 41.5849 14.4372Z"
                    fill="#FF6161"
               />
               <path
                    d="M29.8251 13.7889H13.9274C12.7107 13.7889 11.6562 12.8156 11.6562 11.5178V5.51555C11.6562 4.29889 12.6296 3.24444 13.9274 3.24444H17.5774C18.1451 1.37889 19.8485 0 21.8762 0C23.904 0 25.6885 1.37889 26.1751 3.24444H29.744C30.9607 3.24444 32.0151 4.21778 32.0151 5.51555V11.5178C32.0962 12.8156 31.1229 13.7889 29.8251 13.7889ZM14.1707 11.3556H29.6629V5.67778H25.2018C24.5529 5.67778 23.9851 5.11 23.9851 4.46111C23.9851 3.32556 23.0929 2.43333 21.9574 2.43333C20.8218 2.43333 19.9296 3.32556 19.9296 4.46111C19.9296 5.11 19.3618 5.67778 18.7129 5.67778H14.1707V11.3556Z"
                    fill="#FF6161"
               />
               <path
                    d="M16.9286 38.5282H8.73633V30.3359H16.9286V38.5282ZM11.1697 36.0948H14.4952V32.7693H11.1697V36.0948Z"
                    fill="#FF6161"
               />
               <path
                    d="M26.9039 35.6072H21.7128C21.0639 35.6072 20.4961 35.0394 20.4961 34.3905C20.4961 33.7416 21.0639 33.1738 21.7128 33.1738H26.9039C27.5528 33.1738 28.1205 33.7416 28.1205 34.3905C28.1205 35.0394 27.5528 35.6072 26.9039 35.6072Z"
                    fill="#FF6161"
               />
               <path
                    d="M16.9286 50.6121H8.73633V42.4199H16.9286V50.6121ZM11.1697 48.1788H14.4952V44.8533H11.1697V48.1788Z"
                    fill="#FF6161"
               />
               <path
                    d="M16.9286 26.4422H8.73633V18.25H16.9286V26.4422ZM11.1697 24.0089H14.4952V20.6833H11.1697V24.0089Z"
                    fill="#FF6161"
               />
               <path
                    d="M33.7993 21.1697H21.6327C20.9838 21.1697 20.416 20.6019 20.416 19.953C20.416 19.3041 20.9838 18.7363 21.6327 18.7363H33.7993C34.4482 18.7363 35.016 19.3041 35.016 19.953C35.016 20.6019 34.5293 21.1697 33.7993 21.1697Z"
                    fill="#FF6161"
               />
               <path
                    d="M30.635 25.8738H21.7128C21.0639 25.8738 20.4961 25.306 20.4961 24.6571C20.4961 24.0082 21.0639 23.4404 21.7128 23.4404H30.7161C31.365 23.4404 31.9328 24.0082 31.9328 24.6571C31.9328 25.306 31.365 25.8738 30.635 25.8738Z"
                    fill="#FF6161"
               />
               <path
                    d="M27.0672 48.7481C26.8239 48.7481 26.5806 48.667 26.4183 48.5048C26.0128 48.2614 25.7694 47.6937 25.9317 47.207L27.3917 41.0425C27.3917 40.8803 27.4728 40.7181 27.5539 40.637L42.5595 18.6559C42.965 18.0881 43.695 17.9259 44.2628 18.3314C44.8306 18.737 44.9928 19.467 44.5872 20.0348L29.5817 41.9348L28.9328 44.7737L31.3661 43.1514L47.5883 19.3859L44.8306 17.5203C44.5872 17.3581 44.3439 17.0337 44.3439 16.7092C44.2628 16.3848 44.3439 16.0603 44.5061 15.817L46.0472 13.4648C46.4528 12.897 47.1828 12.7348 47.7506 13.1403L51.4817 15.7359C52.0495 16.1414 52.2117 16.8714 51.8061 17.4392C51.4006 18.007 50.6706 18.1692 50.1028 17.7637L47.345 15.8981L47.1828 16.1414L49.9406 18.007C50.1839 18.1692 50.4272 18.4937 50.4272 18.8181C50.5083 19.1425 50.4272 19.467 50.265 19.7103L33.2317 44.6114C33.1506 44.7737 33.0695 44.8548 32.9072 44.9359L27.7161 48.5859C27.5539 48.667 27.3106 48.7481 27.0672 48.7481Z"
                    fill="#FF6161"
               />
          </svg>
     );
};

export default LeadFormEmptyFormsPageSvg;
