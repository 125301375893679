import React from "react";

const LocationLogoSVg = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="25"
               height="25"
               viewBox="0 0 25 25"
               fill="none"
          >
               <g clip-path="url(#clip0_1757_9219)">
                    <path
                         d="M12.5007 2.08334C8.4694 2.08334 5.20898 5.34376 5.20898 9.37501C5.20898 14.8438 12.5007 22.9167 12.5007 22.9167C12.5007 22.9167 19.7923 14.8438 19.7923 9.37501C19.7923 5.34376 16.5319 2.08334 12.5007 2.08334ZM12.5007 11.9792C11.0632 11.9792 9.89648 10.8125 9.89648 9.37501C9.89648 7.93751 11.0632 6.77084 12.5007 6.77084C13.9382 6.77084 15.1048 7.93751 15.1048 9.37501C15.1048 10.8125 13.9382 11.9792 12.5007 11.9792Z"
                         fill="#FF6161"
                    />
               </g>
               <defs>
                    <clipPath id="clip0_1757_9219">
                         <rect width="25" height="25" fill="white" />
                    </clipPath>
               </defs>
          </svg>
     );
};

export default LocationLogoSVg;
