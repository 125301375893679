import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLoginAuthLocalStorageThunk } from "../store/AuthenticationSlices/loginSlice";
import { updateYelpMasterSliceAuthStatus } from "../Api-Calling/yelpAPICalling";
import { updateTwitterMasterSliceAuthStatus } from "../Api-Calling/twitterAPICaliing";
import { updatelinkedinMasterSliceAuthStatus } from "../Api-Calling/linkedinAPICalling";
import { updateTripAdvisorMasterSliceAuthSlice } from "../Api-Calling/tripAdvisorAPICalling";
import { updateTiktokMasterSliceAuthStatusThunk } from "../Api-Calling/tiktokAPICalling";

const UseAuthenticationOperations = () => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const { auth } = useSelector((state) => {
          return state.master;
     });
     useEffect(() => {
          if (!auth.isAuth) {
               console.log("IS_AUTHENTTCATION");
               dispatch(getLoginAuthLocalStorageThunk(navigate));
          }

          if (auth.isAuth) {
               dispatch(updateYelpMasterSliceAuthStatus());
               dispatch(updateTwitterMasterSliceAuthStatus());
               dispatch(updateTripAdvisorMasterSliceAuthSlice());
               dispatch(updateTiktokMasterSliceAuthStatusThunk());
               dispatch(updatelinkedinMasterSliceAuthStatus(auth));
          }
     }, [auth]);

     const isAuthenticated = () => {};

     return [isAuthenticated];
};

export default UseAuthenticationOperations;
