import React from "react";
import HeartSvg from "../../templates/Svg/HeartSvg";

const LikeAndDateCom = ({ likeno = 121, date = "12:50 PM Jan 16, 2021" }) => {
     return (
          <div className="w-full h-full flex flex-row items-center gap-4 font-montserrat   ">
               <span className=" flex flex-row  items-center gap-1">
                    <HeartSvg isLiked={false} />
                    <p className=" text-[11px] ">{likeno}</p>
               </span>
               <span className=" text-[10px]">{date}</span>
          </div>
     );
};

export default LikeAndDateCom;
