import React, { useEffect } from "react";

// Components
import SchedulerListViewHeader from "./SchedulerListViewHeader";
import SchedulerFullSelect from "./SchedulerListListingComponents/SchedulerFullSelect";
import SchedulerListingLayout from "./SchedulerListListingComponents/SchedulerListingLayout";

// Custom Hooks
import UseGetSchedulerList from "../../../customHooks/UseGetSchedulerList";

//  hooks
import { useSelector } from "react-redux";

const SchedulerListViewLayout = () => {
     // Hook
     const [fetchSchdulerList] = UseGetSchedulerList();
     const { auth } = useSelector((state) => {
          return state.master;
     });
     const { selectedSocialMedia } = useSelector((state) => {
          return state.scheduler;
     });
     useEffect(() => {
          fetchSchdulerList();
     }, [auth, selectedSocialMedia]);

     return (
          <div className=" w-full h-full flex flex-col  ">
               <SchedulerListViewHeader />
               <SchedulerFullSelect />
               <div className=" flex-1 overflow-hidden border-2">
                    <SchedulerListingLayout />
               </div>
          </div>
     );
};

export default SchedulerListViewLayout;
