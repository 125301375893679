import React from "react";

// Layout and UI
import ButtonComponent from "../../../../basicultils/ButtonComponent";
import HeaderLeadForms from "../../HeaderLeadForms";

// Hooks
import { useNavigate } from "react-router-dom";

// Config
import { routes } from "../../../../config/mainConfig";

// Component
import VideoComDocs from "./VideoComDocs";

const VideoComDocsLayout = () => {
     const navigate = useNavigate();
     return (
          <div className=" w-full h-full flex flex-col ">
               <HeaderLeadForms title="Video">
                    <ButtonComponent
                         onClick={() => {
                              navigate(routes.leadvideo);
                         }}
                         className="  w-[100px] h-[30px] rounded-[10px] bg-mainRectangeGredient text-[14px] text-[#fff] font-[600]  "
                    >
                         Go to Video
                    </ButtonComponent>
               </HeaderLeadForms>
               <div className=" flex-1 overflow-hidden">
                    <VideoComDocs />
               </div>
          </div>
     );
};

export default VideoComDocsLayout;
