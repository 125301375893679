import React from "react";
import CalenderTopSection from "./CalenderTopSection";
import CalenderBodySection from "./CalenderBodySection";
import CalenderTimeSection from "./CalenderTimeSection";

const CalenderLayout = () => {
     return (
          <div className=" lg:w-[385px] w-full h-[595px] border-2 flex flex-col justify-center gap-4 items-center rounded-[5px] bg-[#FFE6E6] mt-4 ">
               <div className=" w-[90%] h-[420px] flex flex-col   border-2 rounded-[10px] bg-[#fff]">
                    <CalenderTopSection />
                    <div className=" flex-1 ">
                         <CalenderBodySection />
                    </div>
               </div>
               <div className=" flex-1  ">
                    <CalenderTimeSection />
               </div>
          </div>
     );
};

export default CalenderLayout;
