import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../config/mainConfig";
import ButtonComponent from "../../../basicultils/ButtonComponent";
import ModalComponent from "../../../basicultils/ModalComponent";
import InputComponent from "../../../basicultils/InputComponent";
import { useDispatch, useSelector } from "react-redux";

import toast from "react-hot-toast";
import {
     baseURL_Scheduler,
     schedulersAPIroute,
} from "../../../API-utils/APIConfig";
import { GetAPI } from "../../../API-utils/GetAPI";
import { masterSliceActions } from "../../../store/masterSlice";
import { PostAPI } from "../../../API-utils/PostAPI";
import { setLocalStorage_twitter } from "../../../Api-Calling/twitterAPICaliing";

// Please enter code

const SchedulerTwitterAddAccountDetails = () => {
     const navigate = useNavigate();
     const dispatch = useDispatch();

     const { auth } = useSelector((state) => {
          return state.master;
     });

     // Local States
     const [isLinkOpened, setIsLinkOpened] = useState(false);
     const [authenticateButtonIsLoading, setAuthenticateButtonIsLoading] =
          useState(false);
     const [addAccountButtonIsLoading, setAddAccountButtonIsLoading] =
          useState(false);
     const [twitterAuthenticationKeys, setTwitterAuthenticationKeys] = useState(
          {}
     );
     const [code, setCode] = useState("");

     // Toaster

     const customToast = (message) => {
          toast.custom(
               (t) => (
                    <div
                         className={` bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                    >
                         <div className="flex-1 p-4">
                              <div className="ml-3 flex-1  ">
                                   <p className="text-sm font-medium text-gray-900">
                                        {message}
                                   </p>
                              </div>
                         </div>
                         <div className="flex border-l border-gray-200">
                              <button
                                   onClick={() => {
                                        authenticatewithTwitterButtonHandler();
                                        toast.dismiss(t.id);
                                   }}
                                   className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-500"
                              >
                                   Authenticate
                              </button>
                         </div>
                    </div>
               ),
               {
                    position: "bottom-right",
                    duration: 20000,
               }
          );
     };

     // Handlers
     const authenticatewithTwitterButtonHandler = async () => {
          if (authenticateButtonIsLoading) {
               // if Button is already clicked, then state is changed to isloading : true
               return;
          }
          // Adding Try and catch block for safe purpose
          try {
               setAuthenticateButtonIsLoading(true);
               let responsedata = await twitterOpenAuthenticateTab(auth);

               if (responsedata?.authorization_url) {
                    // Warning Message - Toaster
                    toast("Don't close the tab or modal", {
                         icon: "⚠️",
                         duration: 20000,
                         position: "bottom-right",
                    });
                    // State updation
                    setTwitterAuthenticationKeys(responsedata);
                    // Toaster Message
                    toast(
                         "Authenticate Ranblitz with your twitter account and Enter a code",
                         {
                              position: "top-right",
                              icon: "⏳",
                              duration: 8000,
                         }
                    );

                    // Opening the Link and updating the state
                    window.open(responsedata?.authorization_url, "_blank");
                    setIsLinkOpened(true);
               } else {
                    // Error message toaster
                    let message = responsedata?.message;
                    toast.error(message ? message : "Something went wrong ", {
                         duration: 5000,
                    });
               }
          } catch (e) {
               // Something went wrong message - Toaster
               toast.error("Something went wrong ", {
                    duration: 5000,
                    position: "top-right",
               });
               customToast("Authenticate Again");
          }
          setAuthenticateButtonIsLoading(false);
     };

     // Add account
     const addAccountButtonHandler = async () => {
          if (!twitterAuthenticationKeys || addAccountButtonIsLoading) {
               // If twitterAuthenticationkeys is empty
               // Button is already clicked
               return;
          }

          if (code === "") {
               customToast("Please enter a code");
               return;
          }

          try {
               setAddAccountButtonIsLoading(true);

               let responsedata = await authenticateTwitterAccount(
                    twitterAuthenticationKeys,
                    code,
                    auth
               );

               if (responsedata?.oauth_token_secret) {
                    // Success toast message ->
                    toast.success(
                         `Your ${responsedata?.screen_name} account is authenticated with this ${auth.username} Ranblitz Account`,
                         {
                              duration: 10000,
                         }
                    );
                    // Global State updation
                    setLocalStorage_twitter(responsedata); // set Local Storage storage
                    dispatch(
                         masterSliceActions.updateTwiierAccountObj(responsedata)
                    ); // Master slice updation
                    // Navigate to Grid Layout
                    navigate(
                         routes.scheduler +
                              "/twitter/" +
                              routes.schedulersocialmediagrid
                    );

                    // Reset the code state
                    setCode("");
               } else {
                    let error = responsedata?.error;
                    toast.error(error ? error : "Something Went wrong", {
                         duration: 5000,
                    });
               }
          } catch (e) {
               toast.error("Something Went wrong", {
                    duration: 5000,
               });
               customToast("Authenticate Again");
          }
          // Off the loading spinner
          setAddAccountButtonIsLoading(false);
     };

     return (
          <ModalComponent>
               <div className=" w-full h-full bg-black bg-opacity-20 absolute  flex flex-row justify-center items-center ">
                    <div className=" max-w-[524px] w-[80%] h-fit min-h-[210px] bg-[#fff] flex flex-col  p-3 rounded-[8px]">
                         {isLinkOpened ? (
                              <>
                                   <div className=" w-full flex flex-row justify-center">
                                        <div className="  md:w-[75%] w-[90%] mt-[1rem] flex flex-row justify-start md:text-[16px] text-[14px] text-[#577496] font-lato ps-2 pe-2">
                                             Authenticate and enter the code :
                                        </div>
                                   </div>
                                   <div className=" w-full h-fit flex flex-row justify-center mt-[1rem]">
                                        <InputComponent
                                             type="text"
                                             placeholder={"Enter a Code"}
                                             value={code}
                                             onChange={(e) => {
                                                  setCode(e.target.value);
                                             }}
                                             className=" md:w-[75%] w-[90%] h-[45px] border-[#577496] border-2 text-[18px]  font-lato  rounded-[8px]  ps-5 "
                                        />
                                   </div>
                                   <div className=" w-full h-fit flex flex-row justify-center gap-[1rem] mt-[1.75rem] ">
                                        <ButtonComponent
                                             onClick={() => {
                                                  navigate(routes.scheduler);
                                             }}
                                             className=" w-[122px] h-[40px] text-[#577496] text-[16px] font-lato border-[#577496] border-2 rounded-[6px]"
                                        >
                                             Close
                                        </ButtonComponent>

                                        <ButtonComponent
                                             onClick={addAccountButtonHandler}
                                             isLoading={
                                                  addAccountButtonIsLoading
                                             }
                                             className=" w-[122px] h-[40px]  text-[16px] text-[#fff] font-lato  border-2 rounded-[6px] bg-mainRectangeGredient"
                                        >
                                             Add Account
                                        </ButtonComponent>
                                   </div>
                              </>
                         ) : (
                              <>
                                   <div className=" w-full pt-[1rem] h-full flex flex-col justify-center items-center md:text-[16px] text-[14px] gap-[1rem] text-[#577496] font-lato ps-2 pe-2 mt-[1rem]">
                                        <div className=" w-fit ">
                                             Click Authenticate Tiktok button to
                                             login your Twitter Account
                                        </div>

                                        <div className=" w-full h-fit flex flex-row justify-center gap-[1rem] mt-[1.75rem] ">
                                             <ButtonComponent
                                                  onClick={() => {
                                                       navigate(
                                                            routes.scheduler
                                                       );
                                                  }}
                                                  className="  w-[200px] h-[50px] text-[#577496] text-[16px] font-lato border-[#577496] border-2 rounded-[6px]"
                                             >
                                                  Close
                                             </ButtonComponent>

                                             <ButtonComponent
                                                  onClick={
                                                       authenticatewithTwitterButtonHandler
                                                  }
                                                  isLoading={
                                                       authenticateButtonIsLoading
                                                  }
                                                  className=" w-[200px] h-[50px] text-[16px] text-[#fff] font-lato  border-2 rounded-[6px] bg-mainRectangeGredient"
                                             >
                                                  Authenticate With Twitter
                                             </ButtonComponent>
                                        </div>
                                   </div>
                              </>
                         )}
                    </div>
               </div>
          </ModalComponent>
     );
};

export default SchedulerTwitterAddAccountDetails;

// Authenticate Functions and Thunks

export const twitterOpenAuthenticateTab = async (auth) => {
     let api = new URL(
          baseURL_Scheduler + schedulersAPIroute + "/twitter/oauth_url"
     );

     let response = await GetAPI(api, {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": [
               "POST",
               "GET",
               "OPTIONS",
               "DELETE",
               "PUT",
          ],
          "Access-Control-Allow-Headers": [
               "append",
               "delete",
               "entries",
               "foreach",
               "get",
               "has",
               "keys",
               "set",
               "values",
               "Authorization",
          ],

          Authorization: auth?.sessionToken,

          redirect: "follow",
     });

     if (response.response.ok) {
          let responsedata = response.responsedata;
          let data = responsedata?.data;

          console.log(data);

          if (data) {
               const obj = {
                    authorization_url: data?.authorization_url,
                    resource_owner_key: data?.resource_owner_key,
                    resource_owner_secret: data?.resource_owner_secret,
               };

               return obj;
          }
     } else {
          return response?.responsedata;
     }
};

// Add account -> Authenticate twitter account

const authenticateTwitterAccount = async (
     twitterAuthenticationKeys,
     code,
     auth
) => {
     let api = new URL(
          baseURL_Scheduler +
               schedulersAPIroute +
               "/twitter/authenticated_token"
     );

     console.log(api.href);

     const obj = {
          resource_owner_key: twitterAuthenticationKeys?.resource_owner_key,
          resource_owner_secret:
               twitterAuthenticationKeys?.resource_owner_secret,
          verifier: code,
     };

     console.log(obj);

     let response = await PostAPI(api, obj, {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": [
               "POST",
               "GET",
               "OPTIONS",
               "DELETE",
               "PUT",
          ],
          "Access-Control-Allow-Headers": [
               "append",
               "delete",
               "entries",
               "foreach",
               "get",
               "has",
               "keys",
               "set",
               "values",
               "Authorization",
          ],

          Authorization: auth?.sessionToken,

          redirect: "follow",
     });

     if (response?.response?.ok) {
          return response.responsedata;
     } else {
          return response.responsedata;
     }
};
