import React from "react";

import eyecatcher from "../../assest/landingpageimages/seventhsectioneyecatcher.png";
import FeatureSectionLayoutType2 from "../utils/FeatureSectionComponents/FeatureSectionLayoutType2";

const content = {
     heading: "Features-4",
     body: "Build a solid social media strategy by listening to the conversations about your brand across the channels.",
     image: eyecatcher,
     featureList: [
          {
               heading: "Listen and Monitor",
               body: "Monitor and respond to your brand mentions and comments without missing a single one across social channels.",
          },
          {
               heading: "Engage with Audience",
               body: "Maximize your social media presence and get attention by constantly engaging with your audience.",
          },
          {
               heading: "Actionable Insights",
               body: "Get actionable insights from your audience at fingertips by listening to them on all social media platforms.",
          },
     ],
};

const SeventhSection = () => {
     return <FeatureSectionLayoutType2 content={content} />;
};

export default SeventhSection;
