import React from "react";
import CurveLineSVG from "../../components/templates/Svg/Landingpagesvgs/CurveLineSVG";
const HeadLineText = () => {
     return (
          <div className=" xl:w-[70%] md:w-[90%] w-full bg-transparent">
               <div className=" w-fit h-fit font-poppins  text-[#1C1F25] font-[700] lg:text-[48px] md:text-[40px] sm:text-[55px] text-[40px] bg-transparent ">
                    Empower Your <br /> Business with <br />{" "}
                    <p className="  bg-clip-text text-transparent bg-mainRectangeGredient2">
                         Ranblitz Together
                    </p>
               </div>

               <div className=" md:w-fit sm:w-[50%] w-[75%] h-fit flex flex-row justify-center mt-6 ">
                    <CurveLineSVG />
               </div>
          </div>
     );
};

export default HeadLineText;
