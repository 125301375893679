import React from "react";

const SchedulerMonthGridHeaderColumnComponent = ({ day = "Sunday" }) => {
     return (
          <div className=" min-w-[205px]  h-[78px] border-2 flex flex-row justify-center items-center">
               <h1 className=" font-poppins text-[16px] text-[#000]">{day}</h1>
          </div>
     );
};

const SchedulerMonthGridHeader = () => {
     return (
          <div className=" w-fit h-fit flex flex-row ">
               <SchedulerMonthGridHeaderColumnComponent day={"Sunday"} />
               <SchedulerMonthGridHeaderColumnComponent day={"Monday"} />
               <SchedulerMonthGridHeaderColumnComponent day={"Tuesday"} />
               <SchedulerMonthGridHeaderColumnComponent day={"Wednesday"} />
               <SchedulerMonthGridHeaderColumnComponent day={"Thursday"} />
               <SchedulerMonthGridHeaderColumnComponent day={"Friday"} />
               <SchedulerMonthGridHeaderColumnComponent day={"Saturday"} />
          </div>
     );
};

export default SchedulerMonthGridHeader;
