import React from "react";

const VideoSVG = ({ color = "#D9D9D9", height = "24", width = "28" }) => {
     return (
          <svg
               width={width}
               height={height}
               viewBox="0 0 72 48"
               fill="none"
               xmlns="http://www.w3.org/2000/svg"
          >
               <path
                    d="M56 18V4C56 1.8 54.2 0 52 0H4C1.8 0 0 1.8 0 4V44C0 46.2 1.8 48 4 48H52C54.2 48 56 46.2 56 44V30L72 46V2L56 18ZM44 28H32V40H24V28H12V20H24V8H32V20H44V28Z"
                    fill={color}
               />
          </svg>
     );
};

export default VideoSVG;
// 28 24
