import React, { useState } from "react";
import SchedulerHeaderText from "../Utils/SchedulerHeaderText";
import SelectComponent from "../../../basicultils/SelectComponent";

import SchedulerNewPostButton from "../Utils/SchedulerNewPostButton";
import SchedulerNavLink from "../Utils/SchedulerNavLink";

const optionarr = [
     {
          id: "1",
          content: "10 Dec 2023 - 31 Dec 2023",
     },
     {
          id: "2",
          content: "12 Dec 2023 - 31 Dec 2023",
     },
     {
          id: "3",
          content: "Instagram",
     },

     {
          id: "4",
          content: "15 Dec 2023 - 31 Dec 2023",
     },
];

const SchedulerListViewHeader = () => {
     const [selected, setSelected] = useState(
          optionarr[0]
               ? optionarr[0]
               : { id: "none", content: "10 Dec 2023 - 31 Dec 2023" }
     );

     const updateSelected = (option) => {
          setSelected(option);
     };

     return (
          <div className=" w-full lg:h-[75px] h-[100px] pb-2 lg:pb-0 border-2 flex lg:flex-row flex-col justify-between ps-2 pe-2">
               <div className=" w-fit h-full flex flex-col justify-center">
                    <SchedulerHeaderText
                         className={`text-[20px] font-montserrat text-mentionButtoncolor font-bold`}
                         title={"Scheduler"}
                    />
               </div>
               <div className=" lg:w-fit w-full justify-between h-full flex flex-row gap-8">
                    <div className=" w-fit h-full flex flex-col justify-center">
                         <SelectComponent
                              className=" w-[270px] h-[36px] "
                              textClassName="text-[16px] font-poppins text-[#555] font-[500]"
                              optionarr={optionarr}
                              updateFunction={updateSelected}
                              value={selected}
                         />
                    </div>
                    <div className=" w-fit h-full flex flex-row gap-8">
                         <div className=" w-fit h-full flex flex-col justify-center">
                              <SchedulerNewPostButton />
                         </div>
                         <SchedulerNavLink />
                    </div>
               </div>
          </div>
     );
};

export default SchedulerListViewHeader;
