export const routes = {
     home: "/",
     benefits: "#benefits",
     features: "#features",
     faq: "#faq",
     ourPricingPlan: "#pricing",

     signin: "/signin",
     signup: "/signup",

     privacypolicy: "/privacy-policy",
     termsofservice: "/terms-of-service",

     onlydashboard: "/dashboard",
     socialmedialist: "/dashboard/socialmedia",

     dynamcisocialmedia: "/dashboard/socialmedia/:socialmedianame",
     dynamcisocialmediaaddaccount: "addaccount",

     socialmediastat: "/dashboard/socialmediastat",

     leadforms: "/dashboard/leadforms",
     contactforms: "/dashboard/leadforms/contactforms",

     //Lead video docs
     leadformsdocs: "/dashboard/leadforms/docs",

     leadvideo: "/dashboard/leadvideo",
     videoforms: "/dashboard/leadvideo/videoforms",

     // Lead form docs
     leadvideodocs: "/dashboard/leadvideo/docs",

     scheduler: "/dashboard/scheduler",
     schedulerdynamicsocialmedia: "/dashboard/scheduler/:socialmedianame",
     schedulersocialmediagrid: "grid",
     schedulersocialmedialist: "list",
     schedulersocialmediaaddpost: "addpost",
     schedulersocialmediaaddaccount: "addaccount",

     schedulerview: "/dashboard/scheduler/view/",
     schedulergridview: "/dashboard/scheduler/view/grid",
     schedulerlistview: "/dashboard/scheduler/view/list",
     scheduleraddpost: "/dashboard/scheduler/addpost",

     profile: "/dashboard/profile",

     accountsetting: "/dashboard/accountsetting",
};
