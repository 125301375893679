import React, { useState } from "react";
import SocialMediaLogo from "../../../basicultils/SocialMediaLogo";

import SelectComponent from "../../../basicultils/SelectComponent";
import ButtonComponent from "../../../basicultils/ButtonComponent";

import { useDispatch, useSelector } from "react-redux";
import {
     dynamicSocialMediaSliceActions,
     dynamicState,
} from "../../../store/DynamicSocialMediaSlices/DynamicSocialMediaSlices";
// not correct size

const optionarr = [
     {
          id: "1",
          content: "Balajitheblankde@gmail.com",
     },
     {
          id: "2",
          content: "mithun@gmail.com",
     },
     {
          id: "3",
          content: "blajitheblandke@gmail.cok",
     },

     {
          id: "4",
          content: "mithun@gmail.com",
     },
     {
          id: "5",
          content: "dfasfasf",
     },
     {
          id: "6",
          content: "sadfas",
     },
     {
          id: "7",
          content: "sadfas",
     },
     {
          id: "8",
          content: "sadfas",
     },
];
const DynamicSocialMediaSmallScreenHeader = () => {
     const dispatch = useDispatch();
     const selectedSocialMedia = useSelector((state) => {
          return state.dynamicsocialmedia.selectedSocialMedia;
     });
     const [selected, setSelected] = useState(
          optionarr[0] ? optionarr[0] : { id: "none", content: "jar" }
     );
     const smallScreenMode = useSelector((state) => {
          return state.dynamicsocialmedia.smallScreenMode;
     });

     const updateSelected = (option) => {
          setSelected(option);
     };
     return (
          <div className=" w-full h-fit flex flex-col mb-[1rem] ">
               <div className=" w-full h-fit flex flex-row items-center p-4 gap-[3vw]">
                    <SocialMediaLogo
                         maincss={"w-[40px] h-[40px] rounded-[50%] "}
                         content={selectedSocialMedia}
                    />
                    <h1 className=" text-[18px] font-poppins font-[600] text-[#00]">
                         {selectedSocialMedia.name} Review
                    </h1>
               </div>

               <div className=" w-full h-fit flex flex-row justify-between ps-2 pe-2">
                    <div className=" w-[50%] h-fit flex flex-row justify-evenly">
                         <ButtonComponent
                              onClick={() => {
                                   dispatch(
                                        dynamicSocialMediaSliceActions.modifySmallScreenMode(
                                             dynamicState.mention
                                        )
                                   );
                              }}
                              className={` w-[40%] h-[35px] rounded-[6px]  duration-200 text-[#fff] ${
                                   dynamicState.mention === smallScreenMode
                                        ? " bg-mainRectangeGredient"
                                        : "bg-[#E1E7ED]"
                              }`}
                         >
                              All Reviews
                         </ButtonComponent>
                         <ButtonComponent
                              onClick={() => {
                                   dispatch(
                                        dynamicSocialMediaSliceActions.modifySmallScreenMode(
                                             dynamicState.wall
                                        )
                                   );
                              }}
                              className={` w-[40%] h-[35px] rounded-[6px] duration-200  text-[#fff] ${
                                   dynamicState.wall === smallScreenMode
                                        ? " bg-mainRectangeGredient"
                                        : "bg-[#E1E7ED]"
                              } `}
                         >
                              Pinned Reviews
                         </ButtonComponent>
                    </div>
                    <div className=" w-[50%] h-fit ">
                         <SelectComponent
                              textClassName=" text-[12px] font-montserrat"
                              className=" w-[90%] h-[33px] rounded-[12px] "
                              optionarr={optionarr}
                              updateFunction={updateSelected}
                              value={selected}
                         />
                    </div>
               </div>

               {/* <div className=" w-full h-fit flex flex-row justify-center">
                    <SearchBar
                         searchbarcss={
                              "w-[90%] h-[33px] relative overflow-hidden   flex flex-row justify-between font-montserrat"
                         }
                         isLogoAtFront={true}
                    />
               </div> */}
          </div>
     );
};

export default DynamicSocialMediaSmallScreenHeader;
