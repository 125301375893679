import React from "react";

const CalenderSVG = ({ color = "white" }) => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="25"
               height="25"
               viewBox="0 0 25 25"
               fill="none"
          >
               <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.72917 0C6.30446 0 6.77083 0.46637 6.77083 1.04167V2.08333H14.0625V1.04167C14.0625 0.46637 14.5289 0 15.1042 0C15.6795 0 16.1458 0.46637 16.1458 1.04167V2.10905C18.7778 2.37036 20.8333 4.59096 20.8333 7.29167V16.6667C20.8333 19.5431 18.5015 21.875 15.625 21.875H5.20833C2.33185 21.875 0 19.5431 0 16.6667V7.29167C0 4.59096 2.05556 2.37036 4.6875 2.10905V1.04167C4.6875 0.46637 5.15387 0 5.72917 0ZM2.26115 6.25H18.5722C18.1432 5.03626 16.9856 4.16667 15.625 4.16667H5.20833C3.84769 4.16667 2.69015 5.03626 2.26115 6.25ZM18.75 8.33333H2.08333V16.6667C2.08333 18.3926 3.48244 19.7917 5.20833 19.7917H15.625C17.3509 19.7917 18.75 18.3926 18.75 16.6667V8.33333ZM4.16667 11.4583C4.16667 10.883 4.63304 10.4167 5.20833 10.4167H9.375C9.9503 10.4167 10.4167 10.883 10.4167 11.4583V15.625C10.4167 16.2003 9.9503 16.6667 9.375 16.6667H5.20833C4.63304 16.6667 4.16667 16.2003 4.16667 15.625V11.4583ZM8.33333 12.5H6.25V14.5833H8.33333V12.5Z"
                    fill={color}
               />
          </svg>
     );
};

export default CalenderSVG;
