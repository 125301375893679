import React from "react";
import ButtonComponent from "../../basicultils/ButtonComponent";
import logo from "../../assest/image/logoimagewhite.png";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaGithub } from "react-icons/fa6";
import { routes } from "../../config/mainConfig";

const content = [
     {
          title: "Legal",
          list: [
               {
                    name: "Agreements",
                    link: "",
               },
               {
                    name: "Terms and Service",
                    link: routes.termsofservice,
               },
               {
                    name: "Privacy Policy",
                    link: routes.privacypolicy,
               },
               {
                    name: "Cookie Policy",
                    link: "",
               },
          ],
     },
     {
          title: "Resources",
          list: [
               {
                    name: "Blog",
                    link: "",
               },
               {
                    name: "Knowledge Base",
                    link: "",
               },
               {
                    name: "Feedback",
                    link: "",
               },
               {
                    name: "Service Status",
                    link: "",
               },
          ],
     },
     {
          title: "Use Cases",
          list: [
               {
                    name: "User Generated Content",
                    link: "",
               },
               {
                    name: "Reputation Management",
                    link: "",
               },
               {
                    name: "Social Proof",
                    link: "",
               },
          ],
     },
];

const ColumnComponent = ({ title = " ", list = [] }) => {
     return (
          <div className=" lg:w-fit w-[45%] h-fit flex flex-col gap-[1rem]">
               <h1 className=" font-poppins text-[18px] text-[#91929B] font-[600]">
                    {title}
               </h1>
               {list.map((item) => {
                    return (
                         <a
                              href={item.link}
                              // rel="noreferrer"
                              // target="_blank"
                              className=" font-poppins text-[#fff] text-[14px] hover:underline hover:text-[#91929B]"
                         >
                              {item.name}
                         </a>
                    );
               })}
          </div>
     );
};

const TenthSection = () => {
     return (
          <div className=" w-full lg:h-[75vh] h-fit flex flex-col justify-between lg:gap-[0rem] gap-[2rem] bg-[#111013] ps-[7.5vw] pe-[7.5vw] pb-[2rem]">
               <div className=" w-full h-[45%]  flex lg:flex-row flex-col lg:p-0 pt-8 pb-8 lg:gap-[0rem] gap-[2rem] justify-between">
                    <div className=" lg:w-[60%] md:w-[80%] h-full flex flex-col justify-center text-[#fff] font-poppins lg:text-[40px] md:text-[32px] text-[22px] font-[600] leading-[150%]">
                         Ready to Amplify Your Social Presence? Reach out to Us
                         Today and Let's Start Listening Together!
                    </div>

                    <div className=" w-fit h-full flex flex-col justify-center">
                         <ButtonComponent className=" w-[180px] h-[50px] bg-mainRectangeGredient font-poppins md:text-[16px] text-[14px] font-[600] text-[#fff] rounded-[6px] ">
                              Contact us
                         </ButtonComponent>
                    </div>
               </div>
               <div className=" w-full h-[1px] bg-[#60606B]"></div>

               <div className=" w-full h-[45%] flex lg:flex-row flex-col justify-between  ">
                    <div className=" lg:w-[40%] w-full h-full flex flex-col justify-center gap-[2.5rem]">
                         <div className=" w-[200px] h-[50px]">
                              <img
                                   src={logo}
                                   className=" w-full h-full "
                                   alt=""
                              />
                         </div>
                         <div className=" lg:w-[65%] w-[90%] h-fit font-inter text-[#fff] text-[14px] leading-[22px] ps-6 ">
                              Elevating Your Presence, Empowering Your Team,
                              Building Success Together, One Insight at a Time.
                         </div>

                         <div className=" w-[65%] h-fit flex flex-row lg:justify-between  lg:gap-[0rem] gap-[10vw] ">
                              <ButtonComponent>
                                   <FaTwitter color={"#91929B"} size={"24px"} />
                              </ButtonComponent>
                              <ButtonComponent>
                                   <FaInstagram
                                        color={"#91929B"}
                                        size={"24px"}
                                   />
                              </ButtonComponent>
                              <ButtonComponent>
                                   <FaFacebookF
                                        color={"#91929B"}
                                        size={"24px"}
                                   />
                              </ButtonComponent>
                              <ButtonComponent>
                                   <FaGithub color={"#91929B"} size={"24px"} />
                              </ButtonComponent>
                         </div>
                    </div>

                    <div className=" lg:w-[60%] w-full h-fit  pt-[2rem] flex flex-row flex-wrap flex-shrink-0 lg:gap-0 gap-[2rem] justify-between">
                         {/* <div className=" w-fit h-fit flex flex-col gap-[1rem]">
                              <h1 className=" font-poppins text-[18px] text-[#91929B] font-[600]">
                                   Legal
                              </h1>
                              <p className=" font-poppins text-[#fff] text-[14px]">
                                   Agreements{" "}
                              </p>
                         </div> */}
                         <ColumnComponent
                              title={content[0].title}
                              list={content[0].list}
                         />
                         <ColumnComponent
                              title={content[1].title}
                              list={content[1].list}
                         />
                         <ColumnComponent
                              title={content[2].title}
                              list={content[2].list}
                         />
                    </div>
               </div>
          </div>
     );
};

export default TenthSection;
