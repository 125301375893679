import React from "react";
import SchedulerAddPostHeader from "./SchedulerAddPostHeader";
import SchedulerForm from "./SchedulerForm";

const SchedulerAddPostCom = () => {
     return (
          <div className=" w-full h-full  flex flex-col ">
               <SchedulerAddPostHeader />
               <div className="  flex-1 overflow-hidden">
                    <SchedulerForm />
               </div>
          </div>
     );
};

export default SchedulerAddPostCom;
