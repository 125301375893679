import React from "react";
import ContactBodyDeleteCard from "./utils/ContactBodyDeleteCard";
import ModalComponent from "../../../../../basicultils/ModalComponent";
import DeleteLogoComponent from "./utils/DeleteLogoComponent";
import { useDispatch } from "react-redux";
import { contactFormSliceActions } from "../../../../../store/contactFormSlice";
import { contactFormModes } from "../../../../../config/ContactFormConfig";

const ContactBodySuccessfullDelete = () => {
     const dispatch = useDispatch();
     return (
          <ModalComponent
               onClick={() => {
                    dispatch(
                         contactFormSliceActions.updateModeDelete(
                              contactFormModes.delete.none
                         )
                    );
               }}
          >
               <ContactBodyDeleteCard>
                    <div className=" w-full h-full flex flex-col justify-evenly ">
                         <DeleteLogoComponent />
                         <h1 className="  text-center text-[16px] text-deleteContactTextColor font-lato ">
                              Successfull Deleted
                         </h1>
                    </div>
               </ContactBodyDeleteCard>
          </ModalComponent>
     );
};

export default ContactBodySuccessfullDelete;
