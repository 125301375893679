import React from "react";

const CustomerSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="30"
               height="30"
               viewBox="0 0 30 30"
               fill="none"
          >
               <path
                    d="M18.4623 15.5C18.6498 15.5875 18.8623 15.625 19.0623 15.625C19.4748 15.625 19.8623 15.4625 20.1623 15.175L22.8873 12.5H24.0623C25.9623 12.5 27.4998 10.9625 27.4998 9.0625V5.9375C27.4998 4.0375 25.9623 2.5 24.0623 2.5H18.4373C16.5373 2.5 14.9998 4.0375 14.9998 5.9375V9.0625C14.9998 10.6375 16.0623 11.9625 17.4998 12.375V14.0625C17.4998 14.6875 17.8873 15.25 18.4623 15.5ZM16.8748 5.9375C16.8748 5.075 17.5748 4.375 18.4373 4.375H24.0623C24.9248 4.375 25.6248 5.075 25.6248 5.9375V9.0625C25.6248 9.925 24.9248 10.625 24.0623 10.625H22.1123L19.3748 13.3125V10.625H18.4373C17.5748 10.625 16.8748 9.925 16.8748 9.0625V5.9375ZM9.99976 16.875C7.58726 16.875 5.62476 14.9125 5.62476 12.5C5.62476 10.0875 7.58726 8.125 9.99976 8.125C12.4123 8.125 14.3748 10.0875 14.3748 12.5C14.3748 14.9125 12.4123 16.875 9.99976 16.875ZM9.99976 10C8.62476 10 7.49976 11.125 7.49976 12.5C7.49976 13.875 8.62476 15 9.99976 15C11.3748 15 12.4998 13.875 12.4998 12.5C12.4998 11.125 11.3748 10 9.99976 10ZM9.99976 27.5C7.42476 27.5 5.44976 26.8 4.12476 25.4125C2.45476 23.6575 2.49601 21.445 2.49976 21.2162V21.2C2.49976 19.8625 3.62476 18.75 4.99976 18.75H14.9998C16.3748 18.75 17.4998 19.875 17.4998 21.25L17.501 21.2575C17.5048 21.4163 17.5573 23.645 15.876 25.4125C14.5498 26.8 12.5748 27.5 9.99976 27.5ZM4.99976 20.625C4.64976 20.625 4.37476 20.9 4.37476 21.25V21.2562C4.37476 21.375 4.35351 22.9412 5.49976 24.1375C6.44976 25.125 7.96226 25.625 9.99976 25.625C12.0373 25.625 13.5623 25.1125 14.5123 24.1125C15.6535 22.9225 15.6273 21.3725 15.6248 21.29V21.2875C15.6248 20.9 15.3373 20.6125 14.9998 20.6125H4.99976V20.625Z"
                    fill="white"
               />
          </svg>
     );
};

export default CustomerSVG;
