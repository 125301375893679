import React from "react";
import SchedulerHeaderText from "../Utils/SchedulerHeaderText";


const SchedulerAddPostHeader = () => {
     return (
          <div className="w-full h-[50px] flex flex-row ps-4 ">
               <div className=" w-fit h-full flex flex-col justify-center">
                    <SchedulerHeaderText
                         className={`text-[20px] font-montserrat text-mentionButtoncolor font-bold`}
                         title="Scheduler Post"
                    />
               </div>
          </div>
     );
};

export default SchedulerAddPostHeader;
