import React from "react";
import ListingCom from "../components/socialmediaListingCom/ListingCom";

const SocialMediaListingPage = () => {
     return (
          <div className=" w-full h-full ">
               <ListingCom />
          </div>
     );
};

export default SocialMediaListingPage;
