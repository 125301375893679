import React from "react";
import { Outlet } from "react-router-dom";

const VideoFormsLayout = () => {
     return (
          <div className=" w-full h-full flex flex-col ">
               <div className=" flex-1 overflow-hidden">
                    <Outlet />
               </div>
          </div>
     );
};

export default VideoFormsLayout;
