import React from "react";

// Layout and UI
import ButtonComponent from "../../../basicultils/ButtonComponent";
import LeadFormEmptyFormsPageSvg from "../../templates/Svg/LeadFormEmptyFormsPageSvg";

const EmptyWallLayout = ({
     mainText = " ",
     buttonText = "",
     onClick = () => {},
}) => {
     return (
          <div className=" w-full h-full flex flex-col justify-center items-center gap-[0.5rem] text-center">
               <div className="w-full h-min flex flex-col items-center gap-[0.5rem] ">
                    <div className=" w-full h-min flex flex-row justify-center ">
                         <LeadFormEmptyFormsPageSvg />
                    </div>
                    <h1 className=" lg:text-[18px] text-[14px] font-montserrat font-[600] capitalize text-mentioncolor">
                         {mainText}
                    </h1>

                    <ButtonComponent
                         onClick={onClick}
                         className="  w-[132px] h-[40px] rounded-[10px] bg-mainRectangeGredient text-[16px] text-[#fff] font-[600]  "
                    >
                         {buttonText}
                    </ButtonComponent>
               </div>
          </div>
     );
};

export default EmptyWallLayout;
