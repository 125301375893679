import React from "react";

const LearnMoreSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="18"
               height="18"
               viewBox="0 0 18 18"
               fill="none"
          >
               <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.18825 10.4141L14.0614 10.4141L8.43203 16.0462C8.21185 16.2559 8.08723 16.5466 8.08723 16.8507C8.08723 17.1547 8.21185 17.4455 8.43203 17.6552C8.64172 17.8754 8.93249 18 9.23655 18C9.5406 18 9.83137 17.8754 10.0411 17.6552L17.5101 10.1844C17.9706 9.65796 17.9706 8.87204 17.5101 8.34562L10.0393 0.874802C9.82958 0.654619 9.53881 0.530001 9.23475 0.530001C8.9307 0.530001 8.63993 0.654619 8.43024 0.874802H8.43024C8.21005 1.08449 8.08543 1.37526 8.08543 1.67932C8.08543 1.98337 8.21005 2.27414 8.43024 2.48383L14.0614 8.11499L1.18825 8.11499C0.877987 8.09618 0.574533 8.21124 0.35474 8.43104C0.134945 8.65083 0.0198803 8.95428 0.0386925 9.26455C0.0198803 9.57482 0.134945 9.87827 0.354736 10.0981C0.574532 10.3179 0.877983 10.4329 1.18825 10.4141Z"
                    fill="#E91050"
               />
          </svg>
     );
};

export default LearnMoreSVG;
