import React from "react";
import BoxWallSectionSVG from "../../templates/Svg/BoxWallSectionSVG";
import ButtonComponent from "../../../basicultils/ButtonComponent";


const EmptyWallContent = ({ h1 = " ", p = "" }) => {
     return (
          <div className=" w-full h-full flex flex-col justify-center items-center text-center">
               <div className="w-full h-min flex flex-col gap-[0.2rem] ">
                    <div className=" w-full h-min flex flex-row justify-center ">
                         <BoxWallSectionSVG />
                    </div>
                    <h1 className=" lg:text-[18px] text-[14px] font-montserrat font-[600] capitalize text-mentioncolor">
                         {h1}
                    </h1>

                    <ButtonComponent
                         onClick={() => {}}
                         className=" cursor-pointer text-[#6476B5] font-montserrat lg:text-[12px] text-[10px] capitalize font-thin"
                    >
                         {p}
                    </ButtonComponent>
               </div>
          </div>
     );
};

export default EmptyWallContent;
