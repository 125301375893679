import React, { useState } from "react";
import LogoSection from "./Utils/LogoSection";
import SearchBar from "../../../basicultils/SearchBar";
import IconContainer from "../../../basicultils/IconContainer";
import { IoMdNotificationsOutline } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import searchicon from "../../../assest/iconpngs/search.png";
import ButtonComponent from "../../../basicultils/ButtonComponent";
import { IoMdMenu } from "react-icons/io";

const HeaderSection = ({
     isBigscreen = true,
     onClickHamHandler = () => {},
}) => {
     const [smallScreenSearchBar, setSmallScreenSearchBar] = useState(false);
     return (
          <>
               <div
                    className={` w-full h-fit duration-200 ${
                         isBigscreen
                              ? "bg-[#fff]"
                              : " bg-mainRectangeGredient bg-opacity-75"
                    }`}
               >
                    <div
                         className={` w-full   flex flex-row justify-between  ${
                              isBigscreen
                                   ? " h-[73px]  ps-[5vh] pe-[5vh] border-b-2"
                                   : "  h-[73px] ps-[2vh] pe-[2vh]"
                         }  `}
                    >
                         <div
                              className={` w-fit h-full flex flex-row   gap-[2vw]`}
                         >
                              <ButtonComponent
                                   onClick={() => {
                                        onClickHamHandler();
                                   }}
                                   className=" w-fit h-full flex flex-col justify-center "
                              >
                                   <IconContainer>
                                        <IoMdMenu
                                             size={"50px"}
                                             color={"white"}
                                        />
                                   </IconContainer>
                              </ButtonComponent>
                              <div className=" w-fit h-full flex flex-col justify-center">
                                   <LogoSection isBigscreen={isBigscreen} />
                              </div>
                         </div>
                         {isBigscreen && (
                              // <div className=" h-full flex-col justify-center flex  ">
                              //      <SearchBar
                              //           searchbarcss={
                              //                " w-[380px] lg:w-[455px] h-[35px]  overflow-hidden   flex flex-row justify-between relative font-montserrat"
                              //           }
                              //           isLogoAtFront={false}
                              //      />
                              // </div>
                              <div></div>
                         )}
                         <div className=" h-full flex flex-col justify-center items-center gap-[2vh]">
                              <div className=" flex flex-row gap-[5vw]">
                                   {/* <IconContainer>
                                        <IoMdNotificationsOutline
                                             size={"25px"}
                                        />
                                        <div className=" w-[7px] h-[7px] rounded-[50%]  absolute bg-red-500 top-0 left-[70%]"></div>
                                   </IconContainer> */}
                                   {/* <IconContainer>
                                        <CgProfile
                                             size={"25px"}
                                             color={
                                                  !isBigscreen
                                                       ? "white"
                                                       : "black"
                                             }
                                        />
                                   </IconContainer> */}
                                   {/* {!isBigscreen && (
                                        <ButtonComponent
                                             onClick={() => {
                                                  setSmallScreenSearchBar(
                                                       (state) => {
                                                            return !state;
                                                       }
                                                  );
                                             }}
                                             className=" w-[25px] h-[25px] bg-[#fff] rounded-[10px] flex flex-row justify-center items-center"
                                        >
                                             <img
                                                  src={searchicon}
                                                  alt="search bar"
                                                  className=" w-[60%] h-[60%]"
                                             />
                                        </ButtonComponent>
                                   )} */}
                              </div>
                         </div>
                    </div>
                    {!isBigscreen && smallScreenSearchBar && (
                         // <div className=" w-full h-[50px] flex-row  items-center justify-center md:hidden flex ">
                         //      <SearchBar
                         //           searchbarcss={
                         //                " w-[85%] h-[35px]  overflow-hidden   flex flex-row justify-between relative font-montserrat"
                         //           }
                         //           isLogoAtFront={false}
                         //      />
                         // </div>
                         <div></div>
                    )}
               </div>
          </>
     );
};

export default HeaderSection;
