import React from "react";
import FeatureSectionHeader from "./FeatureSectionHeader";
import FeatureSectionListLayout from "./FeatureSectionListLayout";


const FeatureSectionLayoutType1 = ({ content }) => {
     return (
          <>
               <div className=" w-full h-fit mt-[5rem] bg-[#FFF5F5]  md:flex flex-row  hidden ">
                    <div className=" w-[50%] h-full lg:ps-12 ps-4">
                         <FeatureSectionHeader
                              heading={content.heading}
                              body={content.body}
                         />
                         <FeatureSectionListLayout
                              featureList={content.featureList}
                         />
                    </div>
                    <div className=" w-[50%]  flex flex-row justify-center items-center ">
                         <div className=" xl:w-[600px] xl:h-[600px] lg:w-[450px] lg:h-[450px]   ">
                              <img
                                   src={content.image}
                                   className=" w-full h-full object-contain"
                                   alt="feature-1"
                              />
                         </div>
                    </div>
               </div>

               <div className=" w-full h-fit  flex flex-col gap-[2rem] bg-[#FFF5F5] md:hidden mt-[5rem] pt-[2rem] pb-[2rem] ">
                    <FeatureSectionHeader
                         heading={content.heading}
                         body={content.body}
                    />
                    <div className=" w-[100%]  flex flex-row justify-center  items-center ">
                         <div className="">
                              <img
                                   src={content.image}
                                   className=" w-full h-full object-contain"
                                   alt="feature-1"
                              />
                         </div>
                    </div>
                    <FeatureSectionListLayout
                         featureList={content.featureList}
                    />
               </div>
          </>
     );
};

export default FeatureSectionLayoutType1;
