import { useSelector } from "react-redux";
import { SelectPlanFoundWithId, blocked } from "../config/SelectPlanConfig";

const UseRoleAuthenticationOperation = () => {
     const master = useSelector((state) => {
          return state.master;
     });

     const auth = master?.auth;

     // Takes no argument and return the boolean value that represent whether the user role is allowed to use the feature or not
     const checkAccessAllowed = () => {
          let type = auth.type;

          if (!type) return false;
          let typeObj = SelectPlanFoundWithId(type);

          if (typeObj.id === blocked.id) {
               return false;
          }

          return true;
     };

     return [checkAccessAllowed];
};

export default UseRoleAuthenticationOperation;
