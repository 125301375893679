import React from "react";
import InputComponent from "../../../basicultils/InputComponent";
import ButtonComponent from "../../../basicultils/ButtonComponent";
import ModalComponent from "../../../basicultils/ModalComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dynamicSocialMediaSliceActions } from "../../../store/DynamicSocialMediaSlices/DynamicSocialMediaSlices";
import { searchBussinessThunk } from "../../../store/masterSlice";
import { socialMedia_searchType } from "../../../config/socialmediaConfig";

// Not a correct size
const DynamicSocialMediaAddAccountLayout = () => {
     const [searchParams] = useSearchParams();

     const navigate = useNavigate();
     const dispatch = useDispatch();
     const { socialmedia, addAccount, ui, selectedSocialMedia } = useSelector(
          (state) => {
               return state.dynamicsocialmedia;
          }
     );

     //console.log(socialmedia, selectedSocialMedia);

     let isLoading = ui.addAccountButtonIsLoading;
     //  console.log(socialmedia, addAccount);

     //default values
     let headerText = `Enter the Mobile Number used to create your
     ${socialmedia}
     account`;

     let placeholder = "Mobile Number";

     let type = searchParams.get("type");

     if (type === socialMedia_searchType.name) {
          headerText = `Enter the Bussiness name used to create your
     ${socialmedia}
     account`;
          placeholder = "Business name ";
     } else if (type === socialMedia_searchType.phoneno) {
          headerText = `Enter the Mobile Number used to create your
          ${socialmedia}
          account`;

          placeholder = "Mobile Number";
     } else if (type === socialMedia_searchType.verficationCode) {
          headerText = `Enter the Verfication code to create your
          ${socialmedia}
          account`;

          placeholder = "Vertification code";
     }

     return (
          <ModalComponent>
               <div className=" w-full h-full bg-black bg-opacity-40 absolute  flex flex-row justify-center items-center ">
                    <div className=" max-w-[524px] w-[80%] h-fit min-h-[243px] bg-[#fff] flex flex-col p-3 md:pt-[3rem] pt-[2rem] ">
                         <div className=" w-full  flex flex-row justify-center md:text-[16px] text-[14px] text-[#577496] font-lato ps-2 pe-2 ">
                              {/* {`Enter the Mobile Number used to create your
                            ${socialmedia}
                            account`} */}{" "}
                              {headerText}
                         </div>
                         <div className=" w-full h-fit flex flex-row justify-center mt-[1rem]">
                              <InputComponent
                                   type="text"
                                   placeholder={placeholder}
                                   value={addAccount.phoneno}
                                   className=" md:w-[75%] w-[90%] h-[45px] border-[#577496] border-2 text-[18px]  font-lato  rounded-[8px]  ps-5 "
                                   onChange={(event) => {
                                        dispatch(
                                             dynamicSocialMediaSliceActions.updatePhoneNo(
                                                  event.target.value
                                             )
                                        );
                                   }}
                              />
                         </div>

                         <div className=" w-full h-fit flex flex-row justify-center gap-[1rem] mt-[1.75rem] ">
                              <ButtonComponent
                                   onClick={() => {
                                        navigate("..");
                                   }}
                                   className=" w-[122px] h-[40px] text-[#577496] text-[16px] font-lato border-[#577496] border-2 rounded-[6px]"
                              >
                                   Cancel
                              </ButtonComponent>
                              <ButtonComponent
                                   isLoading={isLoading}
                                   onClick={() => {
                                        dispatch(
                                             searchBussinessThunk(
                                                  selectedSocialMedia?.api,
                                                  addAccount.phoneno?.trim(),
                                                  type,
                                                  navigate
                                             )
                                        );
                                   }}
                                   className=" w-[122px] h-[40px]  text-[16px] text-[#fff] font-lato  border-2 rounded-[6px] bg-mainRectangeGredient"
                              >
                                   Add Account
                              </ButtonComponent>
                         </div>
                    </div>
               </div>
          </ModalComponent>
     );
};

export default DynamicSocialMediaAddAccountLayout;
