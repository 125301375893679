import React from "react";

const HeartSvg = ({ isLiked = false }) => {
     return (
          <div>
               <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="10"
                    viewBox="0 0 11 10"
                    fill={isLiked ? "red" : "none"}
               >
                    <path
                         d="M9.37643 1.66346C8.41316 0.842581 6.98057 0.990234 6.09641 1.90252L5.75013 2.25935L5.40385 1.90252C4.52144 0.990234 3.08709 0.842581 2.12383 1.66346C1.01995 2.60563 0.96194 4.29662 1.94981 5.31789L5.35111 8.82994C5.57084 9.05669 5.92767 9.05669 6.14739 8.82994L9.54869 5.31789C10.5383 4.29662 10.4803 2.60563 9.37643 1.66346Z"
                         stroke="#B8CADE"
                         stroke-width="1.2"
                    />
               </svg>
          </div>
     );
};

export default HeartSvg;
