import React from "react";
import SocialMediaLogo from "../../../../../../basicultils/SocialMediaLogo";
import { socialmedia } from "../../../../../../config/socialmediaConfig";

const SchedulerWeekGridRowTemplate = () => {
     return (
          <div className=" w-[235px] h-[200px] border-2 flex flex-row justify-center items-center">
               <div className=" w-[180px] h-[170px] rounded-[4px] bg-[#FFC9C9] flex flex-col justify-center items-center p-4  gap-2">
                    <div className=" w-full h-[30px] flex flex-col justify-end">
                         6:00 AM
                    </div>
                    <div className=" w-full h-[120px] bg-green-500 rounded-[4px] relative ">
                         <img
                              src={
                                   "/c/Users/balaj/OneDrive/Pictures/Screenshots"
                              }
                              className=" w-full h-full object-cover"
                              alt="responsive pic"
                         />

                         <div className=" absolute bottom-0 left-0 w-[40px] h-[40px]  flex flex-row justify-center items-center">
                              <SocialMediaLogo
                                   content={socialmedia.yelp}
                                   maincss="w-[25px] h-[25px] "
                              />
                         </div>
                    </div>
               </div>
          </div>
     );
};

const SchedulerWeekGridEachRow = () => {
     return (
          <div className=" w-fit h-fit  flex flex-row ">
               <div className=" w-[235px] h-[200px] border-2 flex flex-row justify-center items-center">
                    <h2 className="  font-poppins text-[18px]">6:00 AM</h2>
               </div>
               <SchedulerWeekGridRowTemplate />
               <SchedulerWeekGridRowTemplate />
               <SchedulerWeekGridRowTemplate />
               <SchedulerWeekGridRowTemplate />
               <SchedulerWeekGridRowTemplate />
               <SchedulerWeekGridRowTemplate />
               <SchedulerWeekGridRowTemplate />
          </div>
     );
};

export default SchedulerWeekGridEachRow;
