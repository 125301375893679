import React from "react";

const FourCircleSvg = ({ color = "white" }) => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
          >
               <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.032 3H16.968C16.5294 2.99999 16.1509 2.99998 15.8376 3.02135C15.5078 3.04386 15.1779 3.09336 14.852 3.22836C14.1169 3.53285 13.5328 4.11687 13.2284 4.85195C13.0934 5.17788 13.0439 5.50779 13.0214 5.83762C13 6.15087 13 6.52934 13 6.96797V7.03198C13 7.47062 13 7.84914 13.0214 8.16238C13.0439 8.49221 13.0934 8.82212 13.2284 9.14805C13.5328 9.88314 14.1169 10.4672 14.852 10.7716C15.1779 10.9066 15.5078 10.9561 15.8376 10.9787C16.1509 11 16.5293 11 16.968 11H17.032C17.4706 11 17.8491 11 18.1624 10.9787C18.4922 10.9561 18.8221 10.9066 19.1481 10.7716C19.8831 10.4672 20.4672 9.88314 20.7716 9.14805C20.9066 8.82212 20.9561 8.49221 20.9787 8.16238C21 7.84913 21 7.47064 21 7.03199V6.96801C21 6.52936 21 6.15088 20.9787 5.83762C20.9561 5.50779 20.9066 5.17788 20.7716 4.85195C20.4672 4.11687 19.8831 3.53285 19.1481 3.22836C18.8221 3.09336 18.4922 3.04386 18.1624 3.02135C17.8491 2.99998 17.4706 2.99999 17.032 3ZM15.6173 5.07612C15.6589 5.05889 15.7458 5.03227 15.9738 5.01671C16.2107 5.00054 16.5204 5 17 5C17.4796 5 17.7893 5.00054 18.0262 5.01671C18.2542 5.03227 18.3411 5.05889 18.3827 5.07612C18.6277 5.17762 18.8224 5.37229 18.9239 5.61732C18.9411 5.65893 18.9677 5.74576 18.9833 5.97376C18.9995 6.21074 19 6.52038 19 7C19 7.47963 18.9995 7.78926 18.9833 8.02624C18.9677 8.25424 18.9411 8.34107 18.9239 8.38268C18.8224 8.62771 18.6277 8.82239 18.3827 8.92388C18.3411 8.94112 18.2542 8.96773 18.0262 8.98329C17.7893 8.99946 17.4796 9 17 9C16.5204 9 16.2107 8.99946 15.9738 8.98329C15.7458 8.96773 15.6589 8.94112 15.6173 8.92388C15.3723 8.82239 15.1776 8.62771 15.0761 8.38268C15.0589 8.34107 15.0323 8.25424 15.0167 8.02624C15.0005 7.78926 15 7.47963 15 7C15 6.52038 15.0005 6.21074 15.0167 5.97376C15.0323 5.74576 15.0589 5.65893 15.0761 5.61732C15.1776 5.37229 15.3723 5.17762 15.6173 5.07612Z"
                    fill={color}
               />
               <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.96802 13H7.03199C7.47062 13 7.84914 13 8.16238 13.0214C8.49221 13.0439 8.82212 13.0934 9.14805 13.2284C9.88314 13.5328 10.4672 14.1169 10.7716 14.852C10.9066 15.1779 10.9561 15.5078 10.9787 15.8376C11 16.1509 11 16.5293 11 16.968V17.032C11 17.4706 11 17.8491 10.9787 18.1624C10.9561 18.4922 10.9066 18.8221 10.7716 19.1481C10.4672 19.8831 9.88314 20.4672 9.14805 20.7716C8.82212 20.9066 8.49221 20.9561 8.16238 20.9787C7.84913 21 7.47064 21 7.03199 21H6.96801C6.52936 21 6.15088 21 5.83762 20.9787C5.50779 20.9561 5.17788 20.9066 4.85195 20.7716C4.11687 20.4672 3.53285 19.8831 3.22836 19.1481C3.09336 18.8221 3.04386 18.4922 3.02135 18.1624C2.99998 17.8491 2.99999 17.4707 3 17.032V16.968C2.99999 16.5294 2.99998 16.1509 3.02135 15.8376C3.04386 15.5078 3.09336 15.1779 3.22836 14.852C3.53285 14.1169 4.11687 13.5328 4.85195 13.2284C5.17788 13.0934 5.50779 13.0439 5.83762 13.0214C6.15087 13 6.52939 13 6.96802 13ZM5.97376 15.0167C5.74576 15.0323 5.65893 15.0589 5.61732 15.0761C5.37229 15.1776 5.17762 15.3723 5.07612 15.6173C5.05889 15.6589 5.03227 15.7458 5.01671 15.9738C5.00054 16.2107 5 16.5204 5 17C5 17.4796 5.00054 17.7893 5.01671 18.0262C5.03227 18.2542 5.05889 18.3411 5.07612 18.3827C5.17762 18.6277 5.37229 18.8224 5.61732 18.9239C5.65893 18.9411 5.74576 18.9677 5.97376 18.9833C6.21074 18.9995 6.52038 19 7 19C7.47963 19 7.78926 18.9995 8.02624 18.9833C8.25424 18.9677 8.34107 18.9411 8.38269 18.9239C8.62771 18.8224 8.82239 18.6277 8.92388 18.3827C8.94112 18.3411 8.96774 18.2542 8.98329 18.0262C8.99946 17.7893 9 17.4796 9 17C9 16.5204 8.99946 16.2107 8.98329 15.9738C8.96774 15.7458 8.94112 15.6589 8.92388 15.6173C8.82239 15.3723 8.62771 15.1776 8.38269 15.0761C8.34107 15.0589 8.25424 15.0323 8.02624 15.0167C7.78926 15.0005 7.47963 15 7 15C6.52038 15 6.21074 15.0005 5.97376 15.0167Z"
                    fill={color}
               />
               <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.968 13H17.032C17.4706 13 17.8491 13 18.1624 13.0214C18.4922 13.0439 18.8221 13.0934 19.1481 13.2284C19.8831 13.5328 20.4672 14.1169 20.7716 14.852C20.9066 15.1779 20.9561 15.5078 20.9787 15.8376C21 16.1509 21 16.5294 21 16.968V17.032C21 17.4706 21 17.8491 20.9787 18.1624C20.9561 18.4922 20.9066 18.8221 20.7716 19.1481C20.4672 19.8831 19.8831 20.4672 19.1481 20.7716C18.8221 20.9066 18.4922 20.9561 18.1624 20.9787C17.8491 21 17.4706 21 17.032 21H16.968C16.5294 21 16.1509 21 15.8376 20.9787C15.5078 20.9561 15.1779 20.9066 14.852 20.7716C14.1169 20.4672 13.5328 19.8831 13.2284 19.1481C13.0934 18.8221 13.0439 18.4922 13.0214 18.1624C13 17.8491 13 17.4707 13 17.032V16.968C13 16.5294 13 16.1509 13.0214 15.8376C13.0439 15.5078 13.0934 15.1779 13.2284 14.852C13.5328 14.1169 14.1169 13.5328 14.852 13.2284C15.1779 13.0934 15.5078 13.0439 15.8376 13.0214C16.1509 13 16.5294 13 16.968 13ZM15.9738 15.0167C15.7458 15.0323 15.6589 15.0589 15.6173 15.0761C15.3723 15.1776 15.1776 15.3723 15.0761 15.6173C15.0589 15.6589 15.0323 15.7458 15.0167 15.9738C15.0005 16.2107 15 16.5204 15 17C15 17.4796 15.0005 17.7893 15.0167 18.0262C15.0323 18.2542 15.0589 18.3411 15.0761 18.3827C15.1776 18.6277 15.3723 18.8224 15.6173 18.9239C15.6589 18.9411 15.7458 18.9677 15.9738 18.9833C16.2107 18.9995 16.5204 19 17 19C17.4796 19 17.7893 18.9995 18.0262 18.9833C18.2542 18.9677 18.3411 18.9411 18.3827 18.9239C18.6277 18.8224 18.8224 18.6277 18.9239 18.3827C18.9411 18.3411 18.9677 18.2542 18.9833 18.0262C18.9995 17.7893 19 17.4796 19 17C19 16.5204 18.9995 16.2107 18.9833 15.9738C18.9677 15.7458 18.9411 15.6589 18.9239 15.6173C18.8224 15.3723 18.6277 15.1776 18.3827 15.0761C18.3411 15.0589 18.2542 15.0323 18.0262 15.0167C17.7893 15.0005 17.4796 15 17 15C16.5204 15 16.2107 15.0005 15.9738 15.0167Z"
                    fill={color}
               />
               <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.96802 3H7.03198C7.47064 2.99999 7.84913 2.99998 8.16238 3.02135C8.49221 3.04386 8.82212 3.09336 9.14805 3.22836C9.88314 3.53284 10.4672 4.11687 10.7716 4.85195C10.9066 5.17788 10.9561 5.50779 10.9787 5.83762C11 6.15088 11 6.52936 11 6.96801V7.03199C11 7.47064 11 7.84913 10.9787 8.16238C10.9561 8.49221 10.9066 8.82212 10.7716 9.14805C10.4672 9.88314 9.88314 10.4672 9.14805 10.7716C8.82212 10.9066 8.49221 10.9561 8.16238 10.9787C7.84913 11 7.47064 11 7.03199 11H6.96801C6.52936 11 6.15088 11 5.83762 10.9787C5.50779 10.9561 5.17788 10.9066 4.85195 10.7716C4.11687 10.4672 3.53284 9.88314 3.22836 9.14805C3.09336 8.82212 3.04386 8.49221 3.02135 8.16238C2.99998 7.84913 2.99999 7.47064 3 7.03198V6.96802C2.99999 6.52937 2.99998 6.15088 3.02135 5.83762C3.04386 5.50779 3.09336 5.17788 3.22836 4.85195C3.53284 4.11687 4.11687 3.53284 4.85195 3.22836C5.17788 3.09336 5.50779 3.04386 5.83762 3.02135C6.15088 2.99998 6.52937 2.99999 6.96802 3ZM5.97376 5.01671C5.74576 5.03227 5.65893 5.05888 5.61732 5.07612C5.37229 5.17762 5.17762 5.37229 5.07612 5.61732C5.05888 5.65893 5.03227 5.74576 5.01671 5.97376C5.00054 6.21074 5 6.52038 5 7C5 7.47963 5.00054 7.78926 5.01671 8.02624C5.03227 8.25424 5.05888 8.34107 5.07612 8.38268C5.17762 8.62771 5.37229 8.82239 5.61732 8.92388C5.65893 8.94112 5.74576 8.96773 5.97376 8.98329C6.21074 8.99946 6.52038 9 7 9C7.47963 9 7.78926 8.99946 8.02624 8.98329C8.25424 8.96773 8.34107 8.94112 8.38268 8.92388C8.62771 8.82239 8.82239 8.62771 8.92388 8.38268C8.94112 8.34107 8.96773 8.25424 8.98329 8.02624C8.99946 7.78926 9 7.47963 9 7C9 6.52038 8.99946 6.21074 8.98329 5.97376C8.96773 5.74576 8.94112 5.65893 8.92388 5.61732C8.82239 5.37229 8.62771 5.17762 8.38268 5.07612C8.34107 5.05888 8.25424 5.03227 8.02624 5.01671C7.78926 5.00054 7.47963 5 7 5C6.52038 5 6.21074 5.00054 5.97376 5.01671Z"
                    fill={color}
               />
          </svg>
     );
};

export default FourCircleSvg;
