import React from "react";
import { contactRigthColumns } from "../../../../../config/ContactConfig";
import ContactRigthListingEachColumnSection from "./Utils/ContactRigthListingEachColumnSection";

const ContactRightListHeader = () => {
     return (
          <div className=" min-w-full w-fit h-[50px] flex flex-row border-b-2 ps-4 justify-between mt-[2rem] flex-shrink-0 ">
               {contactRigthColumns.map((column) => {
                    return (
                         <ContactRigthListingEachColumnSection
                              width={column.width}
                         >
                              <h1 className=" text-[12px]  font-montserrat font-[600] text-mentionButtoncolor">
                                   {column.columnHeader}
                              </h1>
                         </ContactRigthListingEachColumnSection>
                    );
               })}
          </div>
     );
};

export default ContactRightListHeader;
