import React from "react";

// Component
import IndivContactRigthItem from "./IndivContactRigthItem";

// Layout and UI
import ContactRightListHeader from "./ContactRightListHeader";
import EmptyWallLayout from "../../../UI/EmptyWallLayout";

// Hooks
import { useNavigate } from "react-router-dom";

// Config
import { routes } from "../../../../../config/mainConfig";

const ContactRigthListing = ({ content = [] }) => {
     const navigate = useNavigate();
     return (
          <>
               {content?.length === 0 ? (
                    <EmptyWallLayout
                         mainText="You don't have any response"
                         buttonText="Connect Forms"
                         onClick={() => {
                              navigate(routes?.leadformsdocs);
                         }}
                    />
               ) : (
                    <>
                         <ContactRightListHeader />
                         <div
                              className=" w-full h-full flex-1  overflow-y- auto "
                              key="listing component"
                         >
                              {content.map((ele, index) => {
                                   return (
                                        <IndivContactRigthItem
                                             index={index + 1}
                                             content={ele}
                                        />
                                   );
                              })}
                         </div>
                    </>
               )}
               <div className=" w-full h-[10rem]"></div>
          </>
     );
};

export default ContactRigthListing;
