import React, { useEffect } from "react";
import LeftArrowSVG from "../../../templates/Svg/ContactForm/LeftArrowSVG";
import ButtonComponent from "../../../../basicultils/ButtonComponent";
import { useDispatch, useSelector } from "react-redux";
import { calenderSliceActions } from "./calenderStore/calenderSlice";
import { CalenderMonths } from "../../../../config/CalenderConfig";

const CalenderTopSection = () => {
     const currentSlide = new Date(
          useSelector((state) => state.calender.currentSlide)
     );
     const dispatch = useDispatch();

     const prevButtonHandler = () => {
          dispatch(calenderSliceActions.prevMonth());
     };

     const nextButtonHandler = () => {
          dispatch(calenderSliceActions.nextMonth());
     };
     useEffect(() => {
          nextButtonHandler();
          prevButtonHandler();
     }, []);

     return (
          <div className="w-full h-[65px] flex flex-row justify-between ">
               <div className="w-[60px] h-full flex flex-row justify-center items-center ">
                    <ButtonComponent
                         onClick={prevButtonHandler}
                         className=" w-fit h-fit "
                    >
                         <LeftArrowSVG />
                    </ButtonComponent>
               </div>
               <div className=" flex-1 flex flex-row justify-center items-center ">
                    <div className=" flex flex-row gap-4 text-[18px]  font-inter ">
                         {CalenderMonths[currentSlide.getMonth()]},{" "}
                         {currentSlide.getFullYear()}
                    </div>
               </div>
               <div className="w-[60px] h-full flex flex-row justify-center items-center">
                    <ButtonComponent
                         onClick={nextButtonHandler}
                         className=" w-fit h-fit  rotate-[180deg] "
                    >
                         <LeftArrowSVG />
                    </ButtonComponent>
               </div>
          </div>
     );
};

export default CalenderTopSection;
