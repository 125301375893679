import React from "react";

const ModalComponent = ({ children, onClick = () => {} }) => {
     return (
          <div
               onClick={onClick}
               className="fixed w-[100vw] top-0 left-0  h-screen z-[2000] bg-[#000] bg-opacity-70"
          >
               {children}
          </div>
     );
};

export default ModalComponent;
