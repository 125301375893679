

export const contactRigthColumns = [
     {
          columnHeader: "No",
          width: "w-[50px] text-center ",
     },
     {
          columnHeader: "Email Id",
          width: "w-[200px]",
     },
     {
          columnHeader: "Form Name",
          width: "w-[125px]",
     },
     {
          columnHeader: "Date & Time",
          width: "w-[150px]",
     },
     {
          columnHeader: "Action IP",
          width: "w-[100px]",
     },
     {
          columnHeader: "Actions",
          width: "w-[100px]",
     },
];

export const dummyitem = {
     emailId: "mithun@growble.digital",
     formName: "Contact Form",
     dateAndTime: "12 June 2021 7:30PM",
     ActionsIp: "100.102.96.1",
};

// export const contactRightContentSetting = (
//      contactContent,
//      viewclick = () => {}
// ) => {
//      sno = 0;
//      returnarray = [];
//      for (let i = 0; i < contactContent.length; i++) {
//           indivarray = [
//                { ...contactRigthColumns[0], content: sno++ },
//                {
//                     ...contactRigthColumns[1],
//                     content: contactContent[i].emailId,
//                },
//                {
//                     ...contactRigthColumns[2],
//                     content: contactContent[i].formName,
//                },
//                {
//                     ...contactRigthColumns[3],
//                     content: contactContent[i].dateAndTime,
//                },
//                {
//                     ...contactRigthColumns[4],
//                     content: contactContent[i].ActionsIp,
//                },
//                {
//                     ...contactRigthColumns[5],
//                     content: <ButtonComponent>View All</ButtonComponent>,
//                },
//           ];

//           returnarray.push(indivarray);
//      }

//      return returnarray;
// };
