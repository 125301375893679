import React from "react";

const SocialListeningSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="30"
               height="30"
               viewBox="0 0 30 30"
               fill="none"
          >
               <path
                    d="M3.75 18.75V21C3.75 22.4 3.75 23.1 4.0225 23.635C4.26218 24.1054 4.64462 24.4878 5.115 24.7275C5.64875 25 6.34875 25 7.74625 25H26.25M3.75 18.75V6.25M3.75 18.75L8.56625 14.7375L8.57125 14.7337C9.4425 14.0075 9.87875 13.6437 10.3525 13.4962C10.9114 13.3215 11.5139 13.3485 12.055 13.5725C12.5138 13.7637 12.915 14.165 13.72 14.97L13.7275 14.9775C14.545 15.795 14.9538 16.205 15.42 16.3937C15.9718 16.6186 16.5858 16.639 17.1512 16.4513C17.63 16.2913 18.0675 15.9087 18.9425 15.1437L26.25 8.75"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
               />
          </svg>
     );
};

export default SocialListeningSVG;
