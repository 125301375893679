import React from "react";

import ButtonComponent from "../../basicultils/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { routes } from "../../config/mainConfig";

const NavBarList = () => {
     const navigate = useNavigate();
     return (
          <div className=" md:hidden w-full h-fit flex flex-col gap-[1rem] mt-[3vw] ps-8 duration-150 ">
               <a
                    href={"/"}
                    className=" w-full h-[50px] border-b-2 font-poppins text-[18px] font-[600] ps-[10vw]  hover:bg-[#9497A1] hover:text-[#fff] duration-150  flex flex-col justify-center "
               >
                    Home
               </a>
               <a
                    href={"#benefits"}
                    className=" w-full h-[50px] border-b-2 font-poppins text-[18px] font-[600] ps-[10vw]  hover:bg-[#9497A1] hover:text-[#fff] duration-150 flex flex-col justify-center "
               >
                    Benefits
               </a>
               <a
                    href={"#features"}
                    className=" w-full h-[50px] border-b-2 font-poppins text-[18px] font-[600] ps-[10vw]  hover:bg-[#9497A1] hover:text-[#fff] duration-150  flex flex-col justify-center "
               >
                    Features
               </a>
               <a
                    href={"#faq"}
                    className=" w-full h-[50px] border-b-2 font-poppins text-[18px] font-[600] ps-[10vw]  hover:bg-[#9497A1] hover:text-[#fff] duration-150  flex flex-col justify-center "
               >
                    FAQ
               </a>
               <div className=" w-full h-[50px] border-b-2 font-poppins text-[18px] font-[600] ps-[10vw]   duration-150 ">
                    <ButtonComponent
                         onClick={() => {
                              navigate(routes.profile);
                         }}
                         className=" lg:w-[133px] md:w-[100px] w-[100px] h-[40px] lg:h-[50px] md:h-[35px] bg-mainRectangeGredient rounded-[6px]  "
                    >
                         <h1 className=" font-poppins lg:text-[16px] md:text-[14px] text-[12px] text-[#fff] font-[600]">
                              Get Started
                         </h1>
                    </ButtonComponent>
               </div>
          </div>
     );
};

export default NavBarList;
