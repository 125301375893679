import React from "react";

const RightArrow = () => {
     return (
          <div>
               <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
               >
                    <path
                         d="M11.272 3.61676L8.28663 0.216763C8.03965 -0.0425511 7.64361 -0.0673112 7.38365 0.132327C7.12369 0.331965 7.08086 0.735466 7.29374 0.979262L9.41946 3.39801H1.38971C1.03641 3.39801 0.75 3.66664 0.75 3.998C0.75 4.32938 1.03641 4.598 1.38971 4.598H9.41946L7.29374 7.01676C7.08086 7.26054 7.12876 7.65838 7.38365 7.86368C7.64647 8.0754 8.07381 8.0231 8.28663 7.77926L11.272 4.37926C11.4663 4.12296 11.4497 3.8646 11.272 3.61676Z"
                         fill="#E91050"
                    />
               </svg>
          </div>
     );
};

export default RightArrow;
