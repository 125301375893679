import React from "react";

const CheckOn = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="21"
               height="20"
               viewBox="0 0 21 20"
               fill="none"
          >
               <rect
                    x="0.105469"
                    width="20"
                    height="20"
                    rx="3"
                    fill="#F2994A"
               />
               <path
                    d="M4.39852 10.7212C3.50544 9.68471 4.85585 8.09982 5.74878 9.13634L8.48977 12.3037L14.4557 5.34088C15.3487 4.29852 16.7065 5.88322 15.8134 6.92558L9.17201 14.6727C8.79908 15.1081 8.19421 15.1094 7.81988 14.6749L4.39852 10.7212Z"
                    fill="white"
               />
          </svg>
     );
};

export default CheckOn;
