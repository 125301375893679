import React from "react";
import ContactBodyInputSection from "./utlis/ContactBodyInputSection";
import ContactBodyViewContent from "./utlis/ContactBodyViewContent";

const ContactBodyViewComponent = () => {
     return (
          <div className=" w-full h-fit flex flex-col gap-[2rem]">
               <ContactBodyInputSection header={"Form Name"}>
                    <ContactBodyViewContent>
                         Contact Form
                    </ContactBodyViewContent>
               </ContactBodyInputSection>
               <ContactBodyInputSection header={"Email Id"}>
                    <ContactBodyViewContent>
                         Mithun@gmail.com
                    </ContactBodyViewContent>
               </ContactBodyInputSection>
               <ContactBodyInputSection header={"Message"}>
                    <ContactBodyViewContent>
                         Lorem Ipsum is simply dummy text of the printing and
                         typesetting industry. Lorem Ipsum has been the
                         industry's standard dummy text ever since the 1500s
                    </ContactBodyViewContent>
               </ContactBodyInputSection>
          </div>
     );
};

export default ContactBodyViewComponent;
