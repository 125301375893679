import React from "react";
import ProfileSocialConnectLayout from "./utils/ProfileSocialConnectLayout";
import { profilePageSocialMediaListing } from "../../config/socialmediaConfig";
import UseGetAccountDetails, {
     useGetAccountDetails_getDetailsFrom_modes,
} from "../../customHooks/UseGetAccountDetails";

const ProfileSocialConnect = () => {
     const [getDetailsFrom] = UseGetAccountDetails();

     console.log(profilePageSocialMediaListing);
     return (
          <div className=" w-full max-w-[850px] h-fit flex md:flex-row flex-col flex-wrap  md:justify-start justify-evenly p-4 gap-3 ">
               {profilePageSocialMediaListing.map((content) => {
                    let loginDetails = getDetailsFrom(
                         useGetAccountDetails_getDetailsFrom_modes.custom,
                         content?.link
                    );

                    if (loginDetails?.isAccounted) {
                         return (
                              <ProfileSocialConnectLayout content={content} />
                         );
                    }
                    return null;
               })}
            
          </div>
     );
};

export default ProfileSocialConnect;
