import React from "react";
import ContactFormHeader from "./ContactFormHeaderComponents/ContactFormHeader";
import ContactBody from "./ContactFormBodyComponents/ContactBody";

const ContactFormCom = () => {
     return (
          // <div className=" w-full h-full flex flex-col ">
          //      <ContactFormHeader />
          //      <div className="flex-1 overflow-hidden">
          //           <ContactBody />
          //      </div>
          // </div>
          <>
               <div className=" w-full h-full flex flex-col">
                    <ContactFormHeader />
                    <div className=" flex-1 overflow-hidden ">
                         <ContactBody />
                    </div>
               </div>
          </>
     );
};

export default ContactFormCom;
