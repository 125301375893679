import {
     baseURL_Scheduler,
     linkedin_getMyAccount_apiendpoints,
     linkedin_posting_apiendpoints,
} from "../API-utils/APIConfig";
import { GetAPI } from "../API-utils/GetAPI";
import { PostAPI } from "../API-utils/PostAPI";
import { scheduler_formatDate } from "../config/CalenderConfig";
import { routes } from "../config/mainConfig";
import { socialmedia } from "../config/socialmediaConfig";
import {
     schedulerSlideActions,
     updatePostListScheduler,
} from "../store/SchedulerSlices/schedulerSlide";
import { masterSliceActions } from "../store/masterSlice";

let defaultHeaders = {
     "Content-Type": "application/json",
     "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Methods": [
          "POST",
          "GET",
          "OPTIONS",
          "DELETE",
          "PUT",
     ],
     "Access-Control-Allow-Headers": [
          "append",
          "delete",
          "entries",
          "foreach",
          "get",
          "has",
          "keys",
          "set",
          "values",
          "Authorization",
     ],

     redirect: "follow",
};

// ------- post --------------

const linkedinPostAPI = async (content, schedule_time, auth) => {
     let url = new URL(baseURL_Scheduler + linkedin_posting_apiendpoints);
     let body = { content };

     if (schedule_time) {
          body = {
               ...body,
               schedule_time,
          };
     }

     let headers = {
          ...defaultHeaders,
          Authorization: auth?.sessionToken,
     };

     let response = await PostAPI(url, body, headers);

     if (response?.response?.ok) {
          return response;
     }

     return null;
};

export const linkedinPostThunk = (
     postObj,
     auth,
     schedule_time,
     { successToaster, navigate, toasterErrorFunction }
) => {
     return async (dispatch) => {
          if (schedule_time) {
               console.log("Schedule Time : ", schedule_time);
               schedule_time = scheduler_formatDate(new Date(schedule_time));
          }

          console.log("Content : ", postObj, auth.sessionToken);

          dispatch(schedulerSlideActions.modifyPostNowButtonIsLoading(true));

          let response = await linkedinPostAPI(
               postObj.description,
               schedule_time,
               auth
          );

          if (response) {
               console.log("Response Obj : ", response.responsedata);
               dispatch(linkedinSchedulerListUpdateThunk(auth));

               if (schedule_time) {
                    successToaster("Scheduled");
               } else {
                    successToaster("Posted");
               }
               navigate("/dashboard/scheduler/linkedin/grid");
          } else {
               toasterErrorFunction("Something went wrong ");
          }
          dispatch(schedulerSlideActions.modifyPostNowButtonIsLoading(false));
     };
};

//-------------------

// Local Storage

const key_linkedin = "linkedin_auth";

export const setLinkedinLocalStorage = (obj = "") => {
     console.log("Linkedin Local Storage set", obj);
     localStorage.setItem(key_linkedin, JSON.stringify(obj));
};

export const getLinkedinLocalStorage = () => {
     const response = JSON.parse(localStorage.getItem(key_linkedin));
     return response;
};

// Thunk to check and set the Linkedin Auth status
export const updatelinkedinMasterSliceAuthStatus = (auth) => {
     return async (dispatch) => {
          let response = getLinkedinLocalStorage();

          if (response) {
               dispatch(
                    masterSliceActions.updateLinkedAuthentication(response)
               );
          }

          if (true) {
               let getmyAccountResponse = await linkedinGetMyAccount(auth);
               console.log("My Account : ", getmyAccountResponse);
               if (getmyAccountResponse?.account) {
                    let obj = {
                         name: getmyAccountResponse?.account?.vanityName,
                    };

                    if (obj) {
                         dispatch(
                              masterSliceActions.updateLinkedAuthentication(obj)
                         );
                         setLinkedinLocalStorage(obj);
                    }
               }
          }
     };
};

// -------------  API -> Get My account

export const linkedinGetMyAccount = async (auth) => {
     let url = new URL(baseURL_Scheduler + linkedin_getMyAccount_apiendpoints);

     let headers = {
          ...defaultHeaders,
          Authorization: auth?.sessionToken,
     };

     let response = await GetAPI(url, headers);

     if (response?.response?.ok) {
          return response.responsedata;
     }

     return null;
};

// -- Schedule List API

const isPostedHelper = (date) => {
     const now = new Date();
     return date < now;
};

const scheduleLinkedinFormater = (array) => {
     if (array?.length == 0) return [];

     const returnArray = [];

     for (let i = 0; i < array?.length; i++) {
          let item = array[i];
          returnArray.push({
               content: item?.content,
               taskTime: new Date(item?.task_time),
               isPosted: isPostedHelper(new Date(item?.task_time)),
          });
     }

     return returnArray;
};

export const linkedinSchedulerListUpdateThunk = (auth) => {
     return async (dispatch) => {
          let getmyAccountResponse = await linkedinGetMyAccount(auth);

          if (getmyAccountResponse) {
               let postsList = getmyAccountResponse?.account?.posts;

               let formattedArray = scheduleLinkedinFormater(postsList);

               dispatch(updatePostListScheduler(formattedArray));
          }
     };
};
