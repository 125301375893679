import React from "react";

const IconHolder = ({
     children = <></>,
     onClick = () => {},
     href = undefined,
}) => {
     // if donot want to redirect then donot give any value to href props
     return (
          <a
               href={href}
               target="_blank" rel="noreferrer"
               onClick={onClick}
               className=" w-[27px] h-[27px] cursor-pointer rounded-[50%] border-1 border-[#D0CACA] shadow-iconholderBoxShadow  flex flex-row justify-center items-center"
          >
               {children}
          </a>
     );
};

export default IconHolder;
