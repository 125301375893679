import React from "react";
import ButtonComponent from "../../../../basicultils/ButtonComponent";
import LeftArrowSVG from "../../../templates/Svg/ContactForm/LeftArrowSVG";
import { useDispatch, useSelector } from "react-redux";
import { calenderSliceActions } from "./calenderStore/calenderSlice";

const CalenderTimeSection = () => {
     const dispatch = useDispatch();
     const time = useSelector((state) => state.calender.time);
     return (
          <div className="w-[254px] h-[134px] rounded-[6px] bg-[#fff] flex flex-row justify-between">
               <div className=" ps-4 w-[150px] h-full flex flex-row justify-evenly items-center gap-6">
                    <div className=" w-fit h-full flex flex-col justify-evenly items-center">
                         <ButtonComponent
                              onClick={() => {
                                   dispatch(
                                        calenderSliceActions.alterHours(-1)
                                   );
                              }}
                              className=" w-fit h-fit  rotate-[90deg]"
                         >
                              <LeftArrowSVG />
                         </ButtonComponent>
                         <div className=" text-[18px] font-inter">
                              {time.hours}
                         </div>
                         <ButtonComponent
                              onClick={() => {
                                   dispatch(calenderSliceActions.alterHours(1));
                              }}
                              className=" w-fit h-fit rotate-[270deg]"
                         >
                              <LeftArrowSVG />
                         </ButtonComponent>
                    </div>
                    <div className=" w-fit h-full  flex flex-col justify-center">
                         :
                    </div>
                    <div className=" w-fit h-full flex flex-col justify-evenly items-center">
                         <ButtonComponent
                              onClick={() => {
                                   dispatch(
                                        calenderSliceActions.alterMinutes(-1)
                                   );
                              }}
                              className=" w-fit h-fit  rotate-[90deg]"
                         >
                              <LeftArrowSVG />
                         </ButtonComponent>
                         <div className=" text-[18px] font-inter">
                              {time.minutes}
                         </div>
                         <ButtonComponent
                              onClick={() => {
                                   dispatch(
                                        calenderSliceActions.alterMinutes(1)
                                   );
                              }}
                              className=" w-fit h-fit rotate-[270deg]"
                         >
                              <LeftArrowSVG />
                         </ButtonComponent>
                    </div>
               </div>
               <div className=" flex-1 w-full flex flex-row justify-center">
                    <div className=" w-fit h-full flex flex-col justify-evenly items-center">
                         <ButtonComponent
                              onClick={() => {
                                   dispatch(calenderSliceActions.alterZone(-1));
                              }}
                              className=" w-fit h-fit  rotate-[90deg]"
                         >
                              <LeftArrowSVG />
                         </ButtonComponent>
                         <div className=" text-[18px] font-inter">
                              {time.zones}
                         </div>
                         <ButtonComponent
                              onClick={() => {
                                   dispatch(calenderSliceActions.alterZone(1));
                              }}
                              className=" w-fit h-fit rotate-[270deg]"
                         >
                              <LeftArrowSVG />
                         </ButtonComponent>
                    </div>
               </div>
          </div>
     );
};

export default CalenderTimeSection;
