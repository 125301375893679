import React, { useState } from "react";
import ContactBodyInputSection from "../utlis/ContactBodyInputSection";
import InputComponent from "../../../../../basicultils/InputComponent";
import ButtonComponent from "../../../../../basicultils/ButtonComponent";

const ContactBodyEditComponent = () => {
     const [formNameText, setFormNameText] = useState("Happy to have you");
     const [email, setEmail] = useState("Great to have");
     const [message, setMessage] = useState("Message here");

     return (
          <div className="w-full h-fit flex flex-col gap-[2rem] mb-[200px]">
               <ContactBodyInputSection header={"Form Name"}>
                    <InputComponent
                         type="text"
                         value={formNameText}
                         placeholder="Enter Something"
                         onChange={setFormNameText}
                         className=" w-full h-[40px] border-2 border-inputBorder ps-2  rounded-[4px] text-contactBodyContentTextColor  text-[14px] font-montserrat placeholder:text-contactBodyContentTextColor placeholder:text-[14px]"
                    />
               </ContactBodyInputSection>
               <ContactBodyInputSection header={"Email Id"}>
                    <InputComponent
                         type="text"
                         value={email}
                         placeholder="Enter Something"
                         onChange={setEmail}
                         className=" w-full h-[40px] border-2 border-inputBorder ps-2  rounded-[4px] text-contactBodyContentTextColor  text-[14px] font-montserrat placeholder:text-contactBodyContentTextColor placeholder:text-[14px]"
                    />
               </ContactBodyInputSection>
               <ContactBodyInputSection header={"Message"}>
                    <textarea
                         className=" w-full h-[108px] border-2 border-inputBorder p-2  rounded-[4px] text-contactBodyContentTextColor  text-[14px] font-montserrat"
                         value={message}
                         onChange={(e) => {
                              setMessage(e.target.value);
                         }}
                         placeholder="Enter Something"
                    />
               </ContactBodyInputSection>
               <div className=" w-full flex flex-row gap-4">
                    <ButtonComponent
                         isLoading={false}
                         onClick={() => {}}
                         className="  w-[125px] h-[40px] rounded-[4px] bg-mainRectangeGredient font-montserrat text-[14px] text-[#fff]"
                    >
                         Save
                    </ButtonComponent>
                    <ButtonComponent
                         isLoading={false}
                         onClick={() => {}}
                         className="w-[125px] h-[40px] rounded-[4px] border-2 border-mentionButtoncolor text-[14px]  font-montserrat text-mentionButtoncolor"
                    >
                         Cancel
                    </ButtonComponent>
               </div>
          </div>
     );
};

export default ContactBodyEditComponent;
