import React, { useState } from "react";

import LeftArrowSVG from "../components/templates/Svg/ContactForm/LeftArrowSVG";
import ButtonComponent from "./ButtonComponent";

const OptionComponent = ({ click = () => {}, opt }) => {
     const clickHandler = () => {
          click(opt);
     };

     return (
          <>
               <ButtonComponent
                    onClick={clickHandler}
                    isLoading={false}
                    className=" w-full h-[30px] border-0 truncate border-[#000] flex flex-col justify-center text-[12px] ps-2 hover:bg-gray-300   "
               >
                    {opt.content}
               </ButtonComponent>
          </>
     );
};

// Customized Select components
// Select list : [{ id : idvalue , content : value }]
// value : Selected Value
const SelectComponent = ({
     className = "w-[200px] h-[30px]",
     textClassName = "text-[12px] font-montserrat",
     optionarr = [],

     updateFunction = () => {},
     value = "",
     children = <></>,
}) => {
     // model display none to block
     const [isModelOpen, setIsModeOpen] = useState(false);
     const changeModel = () =>
          setIsModeOpen((state) => {
               return !state;
          });

     // value
     const selected = value;

     const clickHandler = (event) => {
          //    changeModel();
          updateFunction(event);
     };

     return (
          <>
               <div className="relative w-full h-fit flex flex-row justify-center">
                    <ButtonComponent
                         onClick={changeModel}
                         className={` ${
                              textClassName + className
                         } relative  flex flex-row justify-between items-center border-[#777] rounded-[3px] border-[2px] ps-2 pe-2 cursor-pointer`}
                    >
                         <h1 className=" flex-1 truncate text-start">
                              {selected.content}
                         </h1>
                         <div
                              style={{
                                   rotate: "270deg",
                              }}
                              className="w-fit h-fit"
                         >
                              <div
                                   className={` w-fit h-full  ${
                                        isModelOpen ? "rotate-180" : "rotate-0"
                                   } `}
                              >
                                   <LeftArrowSVG />
                              </div>
                         </div>
                         {children}

                         {isModelOpen && (
                              <div
                                   className={
                                        " absolute w-[100%] h-fit  max-h-[200px] border-2 top-[105%] left-0 overflow-auto  shadow-selectOptionShadow z-50 bg-[#fff] rounded-[5px] " +
                                        textClassName
                                   }
                              >
                                   {optionarr.map((opt) => {
                                        return (
                                             <OptionComponent
                                                  click={clickHandler.bind(
                                                       opt.id
                                                  )}
                                                  opt={opt}
                                             />
                                        );
                                   })}
                              </div>
                         )}
                    </ButtonComponent>
               </div>
          </>
          //   <select className={className}>
          //        {optionarr.map((opt) => {
          //             return <option className=" w-full p-2" value={opt}>{opt}</option>;
          //        })}
          //   </select>
     );
};

export default SelectComponent;

// const SelectComponent = ({
//      className = "w-[200px] h-[50px]",
//      optionarr = [],
// }) => {
//      const onChange = (event) => {
//           console.log(event);
//           const { selectedIndex } = event.target;
//           console.log(event.options[selectedIndex]);
//      };

//      return (
//           <select onChange={onChange} className={className}>
//                {optionarr.map((opt, index) => {
//                     return (
//                          <option id={index} value={opt}>
//                               {opt}
//                          </option>
//                     );
//                })}
//           </select>
//      );
// };

// export default SelectComponent;
