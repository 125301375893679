import React, { useEffect } from "react";
import VideoContentLeftComLayout from "./VideoContentLeftCom.js/VideoContentLeftComLayout";
import VideoContentRightComLayout from "./VideoContentRightCom.js/VideoContentRightComLayout";

//Thunk and actions
import { videoFetchListingThunk } from "../../../../store/contactFormSlice";

// Hooks
import { useDispatch, useSelector } from "react-redux";

const VideoContentComLayout = () => {
     const dispatch = useDispatch();
     const { auth } = useSelector((state) => {
          return state.master;
     });

     useEffect(() => {
          dispatch(videoFetchListingThunk(auth));
     }, [auth]);

     return (
          <>
               <div className=" w-full h-full  flex-row md:flex hidden   ">
                    <div className=" xl:w-[35%] lg:w-[40%] w-[40%] min-w-[300px] h-full border-e-2 overflow-auto flex flex-col">
                         <VideoContentLeftComLayout />
                    </div>
                    <div className=" flex-1  overflow-hidden ">
                         <VideoContentRightComLayout />
                    </div>
               </div>
               <div className=" w-full h-full flex md:hidden flex-col items-center">
                    {/* {smallScreenMode === formsState.left && <ContactLeft />}
                    {smallScreenMode === formsState.right && <ContactRigth />} */}
                    <VideoContentLeftComLayout />
               </div>
          </>
     );
};

export default VideoContentComLayout;
