import React from "react";

// Layout And UI
import HeaderLeadForms from "../../../HeaderLeadForms";
import ButtonComponent from "../../../../../basicultils/ButtonComponent";

// Components
import FormsContactDocsLayout from "./FormsContactDocsLayout";

// Hooks
import { useNavigate } from "react-router-dom";

//Config
import { routes } from "../../../../../config/mainConfig";

const FormDocsLayout = () => {
     const navigate = useNavigate();
     return (
          <div className=" w-full h-full flex flex-col ">
               <HeaderLeadForms title="Forms">
                    <ButtonComponent
                         onClick={() => {
                              navigate(routes.leadforms);
                         }}
                         className="  w-[100px] h-[30px] rounded-[10px] bg-mainRectangeGredient text-[14px] text-[#fff] font-[600]  "
                    >
                         Go to forms
                    </ButtonComponent>
               </HeaderLeadForms>
               <div className=" flex-1 overflow-hidden">
                    <FormsContactDocsLayout />
               </div>
          </div>
     );
};

export default FormDocsLayout;
