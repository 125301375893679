import React from "react";

const ScheduledSVG = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="21"
               height="20"
               viewBox="0 0 21 20"
               fill="none"
          >
               <path
                    d="M10.1055 6.66667V10L12.6055 12.5M17.6055 10C17.6055 14.1421 14.2476 17.5 10.1055 17.5C5.96333 17.5 2.60547 14.1421 2.60547 10C2.60547 5.85786 5.96333 2.5 10.1055 2.5C14.2476 2.5 17.6055 5.85786 17.6055 10Z"
                    stroke="#F2994A"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
               />
          </svg>
     );
};

export default ScheduledSVG;
