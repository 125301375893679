import React from "react";

const CalenderEachLetterLayout = ({
     children,
     className = " text-[#737373]",
}) => {
     return (
          <div
               className={`w-[36px] h-[36px] flex flex-row justify-center items-center  text-[14px] font-inter ${className} `}
          >
               {children}
          </div>
     );
};

export default CalenderEachLetterLayout;
