// import ajio from "../assest/socialmediaassest/ajio.png";
// import alibaba from "../assest/socialmediaassest/alibaba.png";
// import careem from "../assest/socialmediaassest/careem.png";
// import ebay from "../assest/socialmediaassest/ebay.png";
// import etsy from "../assest/socialmediaassest/etsy.png";
// import flipkart from "../assest/socialmediaassest/flipkart.png";
// import foursquare from "../assest/socialmediaassest/foursquare.png";
// import noon from "../assest/socialmediaassest/noon.png";
// import shopify from "../assest/socialmediaassest/shopify.png";
// import swiggy from "../assest/socialmediaassest/swiggy.png";
// import talabat from "../assest/socialmediaassest/talabat.png";
// import ubereats from "../assest/socialmediaassest/ubereats.png";
// import urbanspoon from "../assest/socialmediaassest/urbanspoon.png";
// import wallmart from "../assest/socialmediaassest/wallmart.png";
// import woocommerce from "../assest/socialmediaassest/woocommerce.png";
// import yellowpage from "../assest/socialmediaassest/yellowpages.png";
// import zomato from "../assest/socialmediaassest/zomato.png";
import yelp from "../assest/socialmediaassest/yelp.png";
import tripadvisor from "../assest/socialmediaassest/tripadvisor.png";
import facebookimage from "../assest/socialmediaassest/facebook.png";
import instagramimage from "../assest/socialmediaassest/instagram.png";
import googlemybusiness from "../assest/socialmediaassest/googlemybusiness.png";
import trustpliot from "../assest/socialmediaassest/trustpliot.png";
import bingplaces from "../assest/socialmediaassest/bingplaces.png";
import twitter from "../assest/socialmediaassest/twitter.png";
import linkedin from "../assest/socialmediaassest/linkedin.png";
import titok from "../assest/socialmediaassest/tiktok.png";

export const socialMedia_searchType = {
     phoneno: "phoneon",
     name: "name",
     verficationCode: "verficationcode",
};

export const socialmedia = {
     // ajio: {
     //      image: ajio,
     //      name: "Ajio",
     //      link: "ajio",
     // },
     // alibaba: {
     //      image: alibaba,
     //      name: "Alibaba",
     //      link: "alibaba",
     // },
     // careem: {
     //      image: careem,
     //      name: "Careem",
     //      link: "careem",
     // },

     // ebay: {
     //      image: ebay,
     //      name: "Ebay",
     //      link: "ebay",
     // },
     // etsy: {
     //      image: etsy,
     //      name: "Etsy",
     //      link: "etsy",
     // },
     // flipkart: {
     //      image: flipkart,
     //      name: "Flipkart",
     //      link: "flipkart",
     // },
     // foursquare: {
     //      image: foursquare,
     //      name: "Foursquare",
     //      link: "foursquare",
     // },
     // noon: {
     //      image: noon,
     //      name: "Noon",
     //      link: "noon",
     // },
     // shopify: {
     //      image: shopify,
     //      name: "Shopify",
     //      link: "shopify",
     // },
     // swiggy: {
     //      image: swiggy,
     //      name: "Swiggy",
     //      link: "swiggy",
     // },
     // talabat: {
     //      image: talabat,
     //      name: "Talabat",
     //      link: "talabat",
     // },
     // ubereats: {
     //      image: ubereats,
     //      name: "Uber Eats",
     //      link: "ubereats",
     // },
     // urbanspoon: {
     //      image: urbanspoon,
     //      name: "Urban Spoon",
     //      link: "urbanspoon",
     // },
     // wallmart: {
     //      image: wallmart,
     //      name: "Wallmart",
     //      link: "wallmart",
     // },
     // woocommerce: {
     //      image: woocommerce,
     //      name: "Woo Commerce",
     //      link: "woocommerce",
     // },
     // yellowpage: {
     //      image: yellowpage,
     //      name: "Yellow Pages",
     //      link: "yellowpages",
     // },
     yelp: {
          image: yelp,
          name: "Yelp",
          link: "yelp",
          api: "yelp",
          searchType: socialMedia_searchType.phoneno,
     },
     tripadvisor: {
          image: tripadvisor,
          name: "Trip Advisor",
          link: "tripvisor",
          api: "tripvisor",
          searchType: socialMedia_searchType.name,
     },
     facebook: {
          image: facebookimage,
          name: "Facebook",
          link: "facebook",
     },
     instagram: {
          image: instagramimage,
          name: "Instagram",
          link: "instagram",
     },
     googlereviewmybusiness: {
          name: "Google My Business",
          image: googlemybusiness,
          link: "googlereviewmybusiness",
     },
     trustpliot: {
          image: trustpliot,
          name: "Trust Pliot",
          link: "trustpliot",
     },
     bingplaces: {
          image: bingplaces,
          name: "Bing Places",
          link: "bingplaces",
     },
     twitter: {
          image: twitter,
          name: "Twitter",
          link: "twitter",
     },
     linkedin: {
          image: linkedin,
          name: "LinkedIn",
          link: "linkedin",
     },
     tiktok: {
          image: titok,
          name: "TikTok",
          link: "tiktok",
     },
     // zomato: {
     //      image: zomato,
     //      name: "Zomato",
     //      link: "zomato",
     // },
};

export const profilePageSocialMediaListing = [
     socialmedia.yelp,
     socialmedia.bingplaces,
     socialmedia.facebook,
     socialmedia.instagram,
     socialmedia.trustpliot,
     socialmedia.tripadvisor,
     socialmedia.googlereviewmybusiness,
];

export const reviewPageSocialMediaListing = [
     socialmedia.yelp,
     socialmedia.tripadvisor,
     // socialmedia.bingplaces,
     socialmedia.facebook,
     socialmedia.instagram,
     socialmedia.googlereviewmybusiness,
];

export const schedulerPageSocialMediaListing = [
     socialmedia.facebook,
     socialmedia.instagram,
     socialmedia.twitter,
     socialmedia.tiktok,
     socialmedia.linkedin,
];

export const arrayOfSocialMedias = () => {
     let array = [];

     for (let media in socialmedia) {
          array.push(socialmedia[media]);
     }
     return array;
};

export const findSocialMediaByLinks = (links_name) => {
     for (let media in socialmedia) {
          const obj = socialmedia[media];

          if (obj.link === links_name) {
               return media;
          }
     }

     return null;
};
