import React from "react";
import FeatureSectionLayoutType1 from "../utils/FeatureSectionComponents/FeatureSectionLayoutType1";

import eyecatcher from "../../assest/landingpageimages/sixthsectioneyecatcher.png"

const content = {
     heading: "Features-3",
     body: "Collect feedback and listen to your customers to improve, before they badmouth your business.",
     image: eyecatcher,
     featureList: [
          {
               heading: "Collect",
               body: "Constantly collect feedback from your customers with feedback requests.",
          },
          {
               heading: "Analyze",
               body: "Analyze the feedback and understand where to improve for better customer experience.",
          },
          {
               heading: "Showcase",
               body: "Embed feedback widgets on your website with a single line of code",
          },
     ],
};
const SixthSection = () => {
     return <FeatureSectionLayoutType1 content={content} />;
};

export default SixthSection;
