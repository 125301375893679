import React from "react";

const LeftArrowLandingPagesvg = () => {
     return (
          <svg
               xmlns="http://www.w3.org/2000/svg"
               width="11"
               height="19"
               viewBox="0 0 11 19"
               fill="none"
          >
               <path
                    d="M2 16.7051L9 9.20508L2 1.70508"
                    stroke="#111013"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
               />
          </svg>
     );
};

export default LeftArrowLandingPagesvg;
