import { createSlice } from "@reduxjs/toolkit";
import { contactFormModes } from "../config/ContactFormConfig";
import {
     baseURL_Profolio,
     formsFetch,
     videoFetch,
     videoupload,
} from "../API-utils/APIConfig";
import { GetAPI } from "../API-utils/GetAPI";
import { PostAPI, PostAPIWithOutStringify } from "../API-utils/PostAPI";

export const uploadVideoPageMode = {
     uploading: "Upload",
     successfullUpload: "Successfull Upload",
};

const initialState = {
     view: {
          formName: "",
          email: "",
          message: "",
     },

     edit: {
          formName: "",
          email: "",
          message: "",
     },

     forms: {
          formsListingArray: [],
     },

     video: {
          copyText: "",
          videoListingArray: [],
     },

     mode: {
          form: contactFormModes.form.view,
          delete: contactFormModes.delete.none,

          uploadVideo: uploadVideoPageMode.uploading,
     },

     ui: {
          createVideoButtonIsLoading: false,
     },
};

const contactFormSlice = createSlice({
     name: "contact-Form-state-management",
     initialState,

     reducers: {
          // Uploading createVideoButtonIsLoading
          uploadingUiCreateVideoButtonIsLoading(state, action) {
               return {
                    ...state,
                    ui: {
                         ...state.ui,
                         createVideoButtonIsLoading: action.payload,
                    },
               };
          },

          // Upload Copy text

          uploadCopyText(state, action) {
               return {
                    ...state,
                    video: {
                         ...state.video,
                         copyText: action.payload,
                    },
               };
          },

          //Upload Video

          uploadVideoPageMode(state, action) {
               return {
                    ...state,
                    mode: {
                         ...state.mode,
                         uploadVideo: action.payload,
                    },
               };
          },

          // Update the FORMs Listing array

          updateFromsFromsListingArray(state, action) {
               return {
                    ...state,
                    forms: {
                         ...state.forms,
                         formsListingArray: action.payload,
                    },
               };
          },

          // Update The Video Listing array

          updateVideoVideoListingArray(state, action) {
               return {
                    ...state,
                    video: {
                         ...state.video,
                         videoListingArray: action.payload,
                    },
               };
          },

          updateModeForm(state, action) {
               return {
                    ...state,
                    mode: {
                         ...state.mode,
                         form: action.payload,
                    },
               };
          },
          updateModeDelete(state, action) {
               return {
                    ...state,
                    mode: {
                         ...state.mode,
                         delete: action.payload,
                    },
               };
          },

          setContactForm(state, action) {
               return {
                    ...state,
                    view: initialState.view,
                    mode: initialState.mode,
               };
          },
     },
});

export const contactFormSliceActions = contactFormSlice.actions;

export default contactFormSlice;

export const formsFetchAPIThunk = (auth) => {
     return async (dispatch) => {
          let url = new URL(
               "https://apps.ranblitz.com:9093/api/v1/forms/fetch"
          );

          console.log("AUTH", auth);

          let headers = {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
               "Access-Control-Allow-Methods": [
                    "POST",
                    "GET",
                    "OPTIONS",
                    "DELETE",
                    "PUT",
               ],
               "Access-Control-Allow-Headers": [
                    "append",
                    "delete",
                    "entries",
                    "foreach",
                    "get",
                    "has",
                    "keys",
                    "set",
                    "values",
                    "Authorization",
               ],

               Authorization: auth?.sessionToken,

               redirect: "follow",
          };

          const responseObj = await GetAPI(url, headers);

          if (responseObj?.response?.ok) {
               console.log("FORM FETCH ", responseObj.responsedata);
               // dispatch(
               //      contactFormSliceActions.updateFromsFromsListingArray(
               //           responseObj?.responsedata?.froms_list
               //      )
               // );

               let data = responseObj?.responsedata.data;
               let array = [];
               let froms = data?.froms;
               for (let item of froms) {
                    array.push({
                         id: item.id,
                         email: item?.from_data?.email,
                         name: item?.from_data?.name,
                         phone: item?.from_data?.phone,
                         formName: item?.from_name,
                         fromType: item?.from_type,
                         createdDate: item?.created_at,
                    });
               }

               dispatch(
                    contactFormSliceActions.updateFromsFromsListingArray(array)
               );
          }
     };
};

export const videoFetchListingThunk = (auth) => {
     return async (dispatch) => {
          let url = new URL(baseURL_Profolio + videoFetch);

          console.log("AUTH", auth);

          let headers = {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
               "Access-Control-Allow-Methods": [
                    "POST",
                    "GET",
                    "OPTIONS",
                    "DELETE",
                    "PUT",
               ],
               "Access-Control-Allow-Headers": [
                    "append",
                    "delete",
                    "entries",
                    "foreach",
                    "get",
                    "has",
                    "keys",
                    "set",
                    "values",
                    "Authorization",
               ],

               Authorization: auth?.sessionToken,

               redirect: "follow",
          };

          const responseObj = await GetAPI(url, headers);

          console.log(responseObj?.response);

          if (responseObj?.response?.ok) {
               console.log(responseObj.responsedata);

               let videoList = responseObj?.responsedata?.video_list;
               //console.log(videoList);
               let array = [];

               for (let i = 0; i < videoList?.length; i++) {
                    let indivVideoList = videoList[i];

                    let obj = {
                         id: indivVideoList?.id,
                         s3key: indivVideoList?.s3_key,
                         userId: indivVideoList.user_id,
                         videoFile: indivVideoList?.video_file,
                         videoUrl: indivVideoList?.video_url,
                         videoDetails: indivVideoList?.video_details,
                    };
                    array.push(obj);
               }

               // id: "661548639e3e6856366a78e0";
               // s3_key: "0a96ca05129d405783091be332e002c9";
               // user_id: "661142c0511f6dcc1f482945";
               // video_details: "sea videos";
               // video_file: "portfolio/0a96ca05129d405783091be332e002c9.mp4";
               // video_url: "http://13.51.197.126:8083/api/v1/videos/feed/0a96ca05129d405783091be332e002c9";
               dispatch(
                    contactFormSliceActions.updateVideoVideoListingArray(array)
               );
          }
     };
};

export const videoFileUploadThunk = (file, title, auth) => {
     return async (dispatch) => {
          let url = new URL(baseURL_Profolio + videoupload);

          let headers = {
               Authorization: auth?.sessionToken,
          };

          dispatch(
               contactFormSliceActions.uploadingUiCreateVideoButtonIsLoading(
                    true
               )
          );

          const body = new FormData();
          body?.append("video_details", title);
          body?.append("video", file);
          console.log(body);

          for (const entry of body.entries()) {
               console.log(entry);
          }

          const bodyObj = {
               video: file,
               title: title,
          };

          console.log(bodyObj, auth?.sessionToken);

          const response = await PostAPIWithOutStringify(url, body, headers);
          console.log(response);
          // const response1 = await PostAPI(url, body, headers);
          // console.log(response1);

          console.log(response?.response);

          if (response.response?.ok) {
               console.log("RESONSE DATE", response.responsedata);
               let video_details = response.responsedata?.video_details;

               if (video_details) {
                    dispatch(
                         contactFormSliceActions.uploadCopyText(
                              video_details?.video_url
                         )
                    );
                    dispatch(
                         contactFormSliceActions.uploadVideoPageMode(
                              uploadVideoPageMode?.successfullUpload
                         )
                    );
               }
          }

          console.log(response?.responsedata);

          dispatch(
               contactFormSliceActions.uploadingUiCreateVideoButtonIsLoading(
                    false
               )
          );
     };
};
