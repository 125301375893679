import React from "react";

const LeadFormsNavigationSVG = ({
     color = "white"
}) => {
     // let c ="#FFCA00"
     return (
          <svg
               width="25"
               height="25"
               viewBox="0 0 25 25"
               fill={color}
               xmlns="http://www.w3.org/2000/svg"
          >
               <path
                    d="M7.35294 13.1579C6.54076 13.1579 5.88235 12.5688 5.88235 11.8421C5.88235 11.1154 6.54076 10.5263 7.35294 10.5263H16.1765C16.9887 10.5263 17.6471 11.1154 17.6471 11.8421C17.6471 12.5688 16.9887 13.1579 16.1765 13.1579H7.35294ZM7.35294 18.4211C6.54076 18.4211 5.88235 17.832 5.88235 17.1053C5.88235 16.3786 6.54076 15.7895 7.35294 15.7895H13.2353C14.0475 15.7895 14.7059 16.3786 14.7059 17.1053C14.7059 17.832 14.0475 18.4211 13.2353 18.4211H7.35294ZM19.1176 21.8234L22.4895 18.8064C23.0638 18.2926 23.995 18.2926 24.5693 18.8064C25.1436 19.3203 25.1436 20.1534 24.5693 20.6672L20.1575 24.6146C19.5832 25.1285 18.6521 25.1285 18.0778 24.6146L15.1366 21.983C14.5623 21.4692 14.5623 20.6361 15.1366 20.1222C15.7109 19.6084 16.642 19.6084 17.2163 20.1222L19.1176 21.8234ZM10.2941 21.0526C11.1063 21.0526 11.7647 21.6417 11.7647 22.3684C11.7647 23.0951 11.1063 23.6842 10.2941 23.6842H4.41176C1.97521 23.6842 0 21.9169 0 19.7368V3.94737C0 1.7673 1.97521 0 4.41176 0H19.1176C21.5542 0 23.5294 1.7673 23.5294 3.94737V14.4737C23.5294 15.2004 22.871 15.7895 22.0588 15.7895C21.2466 15.7895 20.5882 15.2004 20.5882 14.4737V3.94737C20.5882 3.22068 19.9298 2.63158 19.1176 2.63158H4.41176C3.59958 2.63158 2.94118 3.22068 2.94118 3.94737V19.7368C2.94118 20.4635 3.59958 21.0526 4.41176 21.0526H10.2941ZM7.35294 7.89474C6.54076 7.89474 5.88235 7.30564 5.88235 6.57895C5.88235 5.85226 6.54076 5.26316 7.35294 5.26316H16.1765C16.9887 5.26316 17.6471 5.85226 17.6471 6.57895C17.6471 7.30564 16.9887 7.89474 16.1765 7.89474H7.35294Z"
                    fill={color}
               />
          </svg>
     );
};

export default LeadFormsNavigationSVG;
