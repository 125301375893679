import React from "react";
import FeatureListContentBox from "./FeatureListContentBox";

const FeatureSectionListLayout = ({ featureList = [] }) => {
     return (
          <div className=" md:p-12 p-6 flex flex-col md:gap-[2rem] gap-[1rem]">
               {featureList.map((item) => {
                    return (
                         <FeatureListContentBox
                              heading={item.heading}
                              body={item.body}
                         />
                    );
               })}
          </div>
     );
};

export default FeatureSectionListLayout;
