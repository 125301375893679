import React from "react";

const IndivDashboarditemCard = ({ isDummy = false, children }) => {
     return (
          <div
               className={` w-[205px] h-[327px] overflow-hidden ${
                    isDummy
                         ? ""
                         : " border-2 border-[#C0E6E9] rounded-[14px] flex flex-col justify-between"
               }  `}
          >
               {children}
          </div>
     );
};

export default IndivDashboarditemCard;
