import React from "react";
import FeatureSectionLayoutType1 from "../utils/FeatureSectionComponents/FeatureSectionLayoutType1";
import eyecatcher from "../../assest/landingpageimages/fourthsectioneyecatcher.png";

const content = {
     heading: "Features-1",
     body: "Capture video testimonials at scale without having the hassle of managing hosting, encoding, and streaming.",
     image: eyecatcher,
     featureList: [
          {
               heading: "Collect",
               body: "Automate the process of request and collection of video testimonials from your customers with an SMS or email.",
          },
          {
               heading: "Stream",
               body: "Manage recording, uploading, encoding, and streaming HD videos with a global content delivery network.",
          },
          {
               heading: "Showcase",
               body: "Maximize your conversion by showcasing the most impactful social proof to your prospects.",
          },
     ],
};

const FourthSection = () => {
     return (
          <>
               <FeatureSectionLayoutType1 content={content} />
          </>
     );
};

export default FourthSection;
