import React, { useState } from "react";

import logo from "../../assest/image/logoimage.png";
import NavBarLink from "./NavBarLink";
import ButtonComponent from "../../basicultils/ButtonComponent";
import { useNavigate } from "react-router-dom";
import { routes } from "../../config/mainConfig";
import HamburgerLandingpage from "../../components/templates/Svg/Landingpagesvgs/HamburgerLandingpage";
import NavBarList from "./NavBarList";
import { HiXMark } from "react-icons/hi2";

const src =
     "https://tse2.mm.bing.net/th?id=OIP.IoUmyjrf4VaXudyiVqv2WwHaII&pid=Api&P=0&h=180";

const TextComponent = ({ children, to = "/" }) => {
     return (
          <a
               href={to}
               className=" text-[#4B5162] lg:text-[16px] md:text-[14px] font-poppins font-[500]"
          >
               {children}
          </a>
     );
};

const NavBarLandingPage = () => {
     const [isOpen, setIsOpen] = useState(false);
     const navigate = useNavigate();

     return (
          <>
               {/* tab / laptop  */}
               <div className=" w-full h-[100px]  flex-row  justify-between md:flex hidden bg-transparent  z-10 ">
                    <div className="w-[30%] h-full ">
                         <div className=" lg:w-[250px] md:w-[200px] h-full flex flex-row justify-center items-center ">
                              <img
                                   src={logo}
                                   className=" object-cover"
                                   alt="ranblitz"
                              />
                         </div>
                    </div>

                    <div className="w-[60%] h-full  flex flex-row justify-evenly">
                         <NavBarLink>
                              <TextComponent to={routes.home}>
                                   Home
                              </TextComponent>
                         </NavBarLink>
                         <NavBarLink>
                              <TextComponent to={routes.benefits}>
                                   Benefits
                              </TextComponent>
                         </NavBarLink>
                         <NavBarLink>
                              <TextComponent to={routes.ourPricingPlan}>
                                   Pricing
                              </TextComponent>
                         </NavBarLink>
                         <NavBarLink>
                              <TextComponent to={routes.features}>
                                   Features
                              </TextComponent>
                         </NavBarLink>
                         <NavBarLink>
                              <TextComponent to={routes.faq}>FAQ</TextComponent>
                         </NavBarLink>

                         <NavBarLink>
                              <ButtonComponent
                                   onClick={() => {
                                        navigate(routes.signup);
                                   }}
                                   className=" lg:w-[133px] md:w-[100px] lg:h-[50px] md:h-[35px] bg-mainRectangeGredient rounded-[6px]  "
                              >
                                   <h1 className=" font-poppins lg:text-[16px] md:text-[14px] text-[#fff] font-[600]">
                                        Get Started
                                   </h1>
                              </ButtonComponent>
                         </NavBarLink>
                    </div>
               </div>

               {/* small screen */}

               <div className=" w-full h-[100px] border-2 flex flex-row justify-between md:hidden bg-transparent ">
                    <div className=" w-[65px] h-full flex flex-row justify-center items-center duration-200">
                         <ButtonComponent
                              className=" w-fit h-fit"
                              onClick={() => {
                                   setIsOpen((state) => {
                                        return !state;
                                   });
                              }}
                         >
                              {isOpen ? (
                                   <HiXMark size={"32px"} />
                              ) : (
                                   <HamburgerLandingpage size={32} />
                              )}
                         </ButtonComponent>
                    </div>
                    <div className=" w-[200px] h-full flex flex-row justify-center items-center">
                         <img
                              src={logo}
                              className=" object-cover"
                              alt="ranblitz"
                         />
                    </div>
                    <div className=" w-[65px] h-full flex flex-col justify-center">
                         <ButtonComponent className=" sm:w-[45px] sm:h-[45px] h-[35px] w-[35px] rounded-[50%] border-2 overflow-hidden ">
                              <img
                                   // src={image}
                                   src={src}
                                   className=" w-full h-full object-cover"
                                   alt=""
                              />
                         </ButtonComponent>
                    </div>
               </div>

               {isOpen && <NavBarList />}
          </>
     );
};

export default NavBarLandingPage;
