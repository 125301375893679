import React, { useEffect, useState } from "react";
import AuthenticationTemplate from "./utils/AuthenticationTemplate";
import LoginFooter from "./utils/LoginFooter";
import { useDispatch, useSelector } from "react-redux";
import {
     loginAPIThunk,
     loginSliceActions,
} from "../../store/AuthenticationSlices/loginSlice";
import ButtonComponent from "../../basicultils/ButtonComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GoogleLogin, googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const SigninLayout = () => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const [searchParams, setSearchParams] = useSearchParams();

     const { email, password } = useSelector((state) => state.login.content);
     const { formButtonIsLoading } = useSelector((state) => state.login.ui);

     // console.log(searchParams.get("redirect_url"));

     const submitHandler = (event) => {
          event.preventDefault();

          if (formButtonIsLoading) {
               return;
          }

          if (email.valid && password.valid) {
               //    dispatch(
               //         loginAuthenticationThunk({ email, password }, id, navigate)
               //    );

               if (searchParams.get("redirect_url")) {
                    dispatch(
                         loginAPIThunk(
                              { email, password },
                              navigate,
                              searchParams.get("redirect_url")
                         )
                    );
               } else {
                    dispatch(loginAPIThunk({ email, password }, navigate));
               }
          }
          emailBlurHandler();
          passwordBlurHandler();
     };

     const emailchanceHandler = (event) => {
          dispatch(loginSliceActions.setEmailValue(event.target.value));
     };
     const emailBlurHandler = () => {
          dispatch(loginSliceActions.setEmailBlur());
     };

     const passwordChanceHandler = (event) => {
          dispatch(loginSliceActions.setPasswordValue(event.target.value));
     };
     const passwordBlurHandler = () => {
          dispatch(loginSliceActions.setPasswordBlur());
     };

     ///-----------------------------------------------
     // const responseMessage = (response) => {
     //      console.log(response);
     // };
     // const errorMessage = (error) => {
     //      console.log(error);
     // };

     // useEffect(() => {
     //      if (user) {
     //           axios.get(
     //                `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user?.access_token}`,
     //                {
     //                     headers: {
     //                          Authorization: `Bearer ${user.access_token}`,
     //                          Accept: "application/json",
     //                     },
     //                }
     //           )
     //                .then((res) => {})
     //                .catch((err) => console.log(err));
     //      }
     // }, [user]);

     // log out function to log the user out of google and set the profile array to null
     // const logOut = () => {
     //      googleLogout();
     //      // setProfile(null);
     // };

     return (
          <AuthenticationTemplate>
               <div className=" w-fit h-fit">
                    {/* <GoogleLogin
                         onSuccess={responseMessage}
                         onError={errorMessage}
                    /> */}

                    <h1 className=" text-2xl font-semibold  bg-clip-text bg-mainRectangeGredient text-transparent mb-2">
                         {/* Header */}
                         Welcome Back!
                    </h1>
                    <h1 className="text-3xl font-light font-Lato">Sign In</h1>

                    <form
                         className="flex flex-col gap-3 mt-6"
                         onSubmit={submitHandler}
                    >
                         {/* All input stream  */}
                         <div>
                              <input
                                   type="text"
                                   name="email"
                                   // required
                                   value={email.value}
                                   onChange={emailchanceHandler}
                                   onBlur={emailBlurHandler}
                                   placeholder="User name"
                                   className={`w-[368px] h-12 ps-3  border border-inputBorderColor rounded text-sm font-semibold placeholder:font-Roboto placeholder:text-xs placeholder:text-inputPlaceholderColor ${
                                        email.touched && !email.valid
                                             ? " bg-headerAndButtonColor bg-opacity-25 text-black"
                                             : ""
                                   }`}
                              />
                              {email.touched && !email.valid && (
                                   <p className=" text-[10px] text-headerAndButtonColor ps-2">
                                        {email.message}
                                   </p>
                              )}
                         </div>
                         <div>
                              <input
                                   type={"password"}
                                   name={"password"}
                                   // required
                                   value={password.value}
                                   onChange={passwordChanceHandler}
                                   onBlur={passwordBlurHandler}
                                   placeholder="Password"
                                   className={`w-[368px] h-12 ps-3  border border-inputBorderColor rounded text-sm font-semibold placeholder:font-Roboto placeholder:text-xs placeholder:text-inputPlaceholderColor ${
                                        password.touched && !password.valid
                                             ? " bg-headerAndButtonColor bg-opacity-25 text-black"
                                             : ""
                                   }`}
                              />
                              {password.touched && !password.valid && (
                                   <p className=" text-[10px] text-headerAndButtonColor ps-2">
                                        {password.message}
                                   </p>
                              )}
                         </div>

                         <div className="mt-4 flex flex-row justify-between">
                              {/* Submit button for form */}
                              <ButtonComponent
                                   isLoading={formButtonIsLoading}
                                   className=" font-bold uppercase w-[180px] h-12 text-cente bg-mainRectangeGredient text-xs text-white rounded font-Roboto"
                              >
                                   Sign in
                              </ButtonComponent>
                              {/* trouble shooting code */}
                              <span></span>
                              {/* <Link
                                        to="/forgetpassword"
                                        className="pt-2 text-linkColor"
                                   >
                                        Trouble signing in?
                                   </Link> */}
                         </div>
                         {/* Footer  */}
                    </form>
                    <LoginFooter isUser={true} />
               </div>
          </AuthenticationTemplate>
     );
};

export default SigninLayout;
