import React from "react";

// Config
import { socialmedia } from "../config/socialmediaConfig";

// Hooks
import { useDispatch, useSelector } from "react-redux";
import { linkedinSchedulerListUpdateThunk } from "../Api-Calling/linkedinAPICalling";
import { twitter_scheduler_list_fetch_thunk } from "../Api-Calling/twitterAPICaliing";
import { tiktok_list_fetch_thunk } from "../Api-Calling/tiktokAPICalling";

// This Hook is used to get / fetch the scheduler Posts in the account

// Returns an array

// Index - 0 ; fetchSchdulerList function have logic and call of thunk of particular application
const UseGetSchedulerList = () => {
     const dispatch = useDispatch();
     const scheduler = useSelector((state) => {
          return state.scheduler;
     });
     let selectedSchedulerPlatform = scheduler.selectedSocialMedia;
     const { auth } = useSelector((state) => {
          return state.master;
     });

     const fetchSchedulerList = () => {
          console.log("Schdeuler list fetch");
          if (selectedSchedulerPlatform.link === socialmedia?.linkedin?.link) {
               console.log("Linkedin");
               dispatch(linkedinSchedulerListUpdateThunk(auth));
          }
          if (selectedSchedulerPlatform.link === socialmedia?.twitter?.link) {
               console.log("Twitter");
               dispatch(twitter_scheduler_list_fetch_thunk(auth));
          }
          if (selectedSchedulerPlatform.link === socialmedia?.tiktok?.link) {
               console.log("Tiktok");
               dispatch(tiktok_list_fetch_thunk(auth));
          }
     };

     return [fetchSchedulerList];
};

export default UseGetSchedulerList;
