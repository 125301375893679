import React from "react";
import ContactFormCom from "../../components/LeadFormsComp/ContactFormCom/ContactFormCom";

const ContactForm = () => {
     return (
          <div className=" w-full h-full">
               <ContactFormCom />
          </div>
     );
};

export default ContactForm;
