import React from "react";
import IndivDashboarditem from "./IndivDashboarditem";
import IndivDashboarditemCard from "./utils/IndivDashboarditemCard";

const ListingDashboardItems = ({ content = [] }) => {
     return (
          <div className=" w-full h-full flex flex-row flex-wrap lg:gap-[20px] gap-[10px] p-2 justify-evenly overflow-auto">
               <IndivDashboarditem />
               <IndivDashboarditem />
               <IndivDashboarditem />
               <IndivDashboarditem />
               <IndivDashboarditem />
               <IndivDashboarditem />

               <IndivDashboarditemCard isDummy={true}></IndivDashboarditemCard>
               <IndivDashboarditemCard isDummy={true}></IndivDashboarditemCard>
               <IndivDashboarditemCard isDummy={true}></IndivDashboarditemCard>
               <IndivDashboarditemCard isDummy={true}></IndivDashboarditemCard>
               <IndivDashboarditemCard isDummy={true}></IndivDashboarditemCard>
          </div>
     );
};

export default ListingDashboardItems;
