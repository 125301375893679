import React from "react";
import { routes } from "../../../config/mainConfig";

import { useNavigate } from "react-router-dom";
import IndivSocialMediaCard from "./IndivSocialMediaCard";
import ButtonComponent from "../../../basicultils/ButtonComponent";
import { useDispatch } from "react-redux";
import { navBarSliceActions } from "../../../store/DashboardSlices/NavbarSlice";
import { dynamicSocialMediaSliceActions } from "../../../store/DynamicSocialMediaSlices/DynamicSocialMediaSlices";

// Single card of social media
const IndivSocialMediaCom = ({ media = {} }) => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     let image = media?.image;
     let mediaName = media.name;
     let link = media?.link;

     return (
          <IndivSocialMediaCard>
               <ButtonComponent
                    // to={routes.socialmedialist + "/" + link}
                    onClick={() => {
                         dispatch(
                              navBarSliceActions.modifyReviewNavLink(media)
                         );
                         dispatch(
                              dynamicSocialMediaSliceActions.updateSelectedMedia(
                                   media
                              )
                         );
                         navigate(routes.socialmedialist + "/" + link);
                    }}
                    className="w-full h-full flex flex-col justify-center items-center gap-[1rem]"
               >
                    <div className="w-[50px] h-[50px]">
                         <img
                              src={image}
                              alt={mediaName}
                              className="object-cover"
                         />
                    </div>
                    <div className=" w-full text-center font-[600] text-[16px] text-socailmedialistingcolor font-manrope">
                         {mediaName}
                    </div>
               </ButtonComponent>
          </IndivSocialMediaCard>
     );
};

export default IndivSocialMediaCom;
