import React, { useState } from "react";
import SchedulerHeaderText from "../../Utils/SchedulerHeaderText";
import SelectComponent from "../../../../basicultils/SelectComponent";
import LocationLogoSVg from "../../../templates/Svg/ContactForm/LocationLogoSVg";
import InputComponent from "../../../../basicultils/InputComponent";
import CounterElement from "./utils/CounterElement";
import { useDispatch, useSelector } from "react-redux";
import { schedulerSlideActions } from "../../../../store/SchedulerSlices/schedulerSlide";
const optionarr = [
     {
          id: "1",
          content: "fackbook",
     },
     {
          id: "2",
          content: "fackbook",
     },
     {
          id: "3",
          content: "Instagram",
     },

     {
          id: "4",
          content: "fackbook",
     },
];

const SchedulerInputComponents = () => {
     const dispatch = useDispatch();
     const { selectedSocialMedia, addPost } = useSelector((state) => {
          return state.scheduler;
     });
     const [selected, setSelected] = useState(
          [{ id: 1, content: selectedSocialMedia?.name }]
               ? { id: 1, content: selectedSocialMedia?.name }
               : { id: "none", content: "jar" }
     );

     const updateSelected = (option) => {
          setSelected(option);
     };

     return (
          <div className=" w-full h-fit flex flex-col ">
               <div className=" w-full h-[45px] flex flex-col justify-center ">
                    <SchedulerHeaderText
                         title={"Creat Post"}
                         className={`text-[16px] font-montserrat text-mentionButtoncolor font-bold`}
                    />
               </div>
               <div className=" w-full h-fit lg:ps-8 ps-4 gap-2 ">
                    <div className=" w-full h-fit mb-6 ">
                         <SelectComponent
                              className=" w-[234px] h-[30px]"
                              textClassName="text-[12px] font-montserrat text-[#555] font-[500]"
                              optionarr={optionarr}
                              updateFunction={updateSelected}
                              value={selected}
                         />
                    </div>
                    <div className=" w-full h-fit ">
                         <h2 className=" text-[16px]  font-poppins text-[#2F407C] font-[400]">
                              Check in
                         </h2>
                         <SelectComponent
                              className=" w-[242px] h-[42px] ps-[40px]"
                              textClassName="text-[12px] font-montserrat text-[#8999AB] font-[400]"
                              optionarr={optionarr}
                              updateFunction={updateSelected}
                              value={selected}
                         >
                              <div className=" w-[35px] h-full  absolute left-0 flex flex-row justify-center items-center">
                                   <LocationLogoSVg />
                              </div>
                         </SelectComponent>
                    </div>

                    <div className="w-full h-fit flex flex-col mt-2  ">
                         <h2 className=" text-[16px]  font-poppins text-[#2F407C] font-[400]">
                              Title :
                         </h2>
                         <div className="max-w-[588px]  w-full relative">
                              <InputComponent
                                   value={addPost.title}
                                   onChange={(event) => {
                                        dispatch(
                                             schedulerSlideActions.updateAddPostTitle(
                                                  event.target.value
                                             )
                                        );
                                   }}
                                   className=" max-w-[588px]  w-full h-[42px] border-2 border-[#B8CADE] rounded-[4px] shadow-schedulerInputShadow text-[12px] font-montserrat text-[#8999AB] font-[400] ps-3"
                                   type="text"
                                   placeholder="Enter Something"
                              />
                              <CounterElement length={addPost.title?.length} />
                         </div>
                    </div>
                    <div className="w-full h-fit flex flex-col mt-2  ">
                         <h2 className=" text-[16px]  font-poppins text-[#2F407C] font-[400]">
                              Description :
                         </h2>
                         <div className=" relative max-w-[588px] w-full">
                              <textarea
                                   className=" max-w-[588px] w-full h-[134px] border-2 border-[#B8CADE] p-3  rounded-[4px] shadow-schedulerInputShadow  text-[12px] font-montserrat text-[#8999AB] font-[400]"
                                   value={addPost.description}
                                   onChange={(e) => {
                                        dispatch(
                                             schedulerSlideActions.updateAddPostDescription(
                                                  e.target.value
                                             )
                                        );
                                   }}
                                   placeholder="Enter Something"
                              />
                              <CounterElement
                                   length={addPost.description?.length}
                              />
                         </div>
                    </div>
               </div>
          </div>
     );
};

export default SchedulerInputComponents;
