import React from "react";
import SocialMediaLogo from "../../../basicultils/SocialMediaLogo";
import ButtonComponent from "../../../basicultils/ButtonComponent";
import UseGetAccountDetails, {
     useGetAccountDetails_getDetailsFrom_modes,
} from "../../../customHooks/UseGetAccountDetails";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../config/mainConfig";

const ProfileSocialConnectLayout = ({ content }) => {
     const navigate = useNavigate();
     // Calling Hooks to find whether the account is connected or not
     const [getDetailsFrom] = UseGetAccountDetails();
     let loginDetails = getDetailsFrom(
          useGetAccountDetails_getDetailsFrom_modes.custom,
          content?.link
     );

     // Button Text
     let buttonText = ` ${
          loginDetails?.isAccounted ? "Connected" : "Connect your"
     } ${content.name} account`;
     return (
          <div className=" md:w-[250px] w-full h-[130px] border-[#E1F2FF] border-2 rounded-[8px] p-4 flex flex-col justify-between">
               <div className=" w-full h-fit flex flex-row items-center gap-4 ">
                    <SocialMediaLogo
                         maincss={"w-[30px] h-[30px]"}
                         content={content}
                    />
                    <h1 className=" text-[14px] font-montserrat text-[#17173A] capitalize font-bold truncate">
                         {content.name}
                    </h1>
               </div>

               <div className="w-full h-fit">
                    <ButtonComponent
                         onClick={() => {
                              navigate(
                                   routes.socialmedialist + "/" + content?.link
                              );
                         }}
                         className=" pt-2 pb-2 ps-2 pe-2 rounded-[3px] bg-[#FFF5F5] font-[500]  text-[#E91050]  font-montserrat text-[12px] capitalize"
                    >
                         {buttonText}
                    </ButtonComponent>
               </div>
          </div>
     );
};

export default ProfileSocialConnectLayout;
