import React from "react";

const CounterElement = ({ length = "0" }) => {
     return (
          <div className=" w-[70px] h-full max-h-[45px] absolute right-0  top-0 flex flex-row justify-center items-center">
               <p className=" text-[12px] font-montserrat text-[#8999AB] font-[400]">
                    {length}
               </p>
          </div>
     );
};

export default CounterElement;
