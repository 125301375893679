import React from "react";
import ModalComponent from "../../basicultils/ModalComponent";
import ButtonComponent from "../../basicultils/ButtonComponent";
import PlanUI from "./Utils/PlanUI";
import {
     enterPrisePlan,
     feePlan,
     professionalPlan,
} from "../../config/SelectPlanConfig";
import { HiOutlineXMark } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { userRegisterSliceActions } from "../../store/AuthenticationSlices/UserRegisterationSlice";

const SelectPlanUIComponent = ({
     onClick = () => {},
     closeOnClick = () => {},
     selectedId = "",
}) => {
     const dispatch = useDispatch();

     const clickhandler = (e) => {
          onClick(e);
          dispatch(userRegisterSliceActions.modifyIsSelectPlanOn(false));
     };
     return (
          <ModalComponent>
               <div className=" w-full h-full flex flex-row overflow-y-auto md:overflow-hidden relative justify-center md:items-center items-start">
                    <ButtonComponent
                         onClick={closeOnClick}
                         className=" w-[25px] h-[25px] text-white absolute right-6 top-6 hover:scale-125 "
                    >
                         <HiOutlineXMark size={"white"} />
                    </ButtonComponent>
                    <div className="w-full md:h-full h-fit flex md:flex-row flex-col gap-[1rem] md:justify-center justify-start items-center  md:mt-[2rem] mt-[3rem] mb-[2rem]">
                         {/* ---------------------------- */}
                         <PlanUI
                              content={feePlan}
                              onClick={clickhandler}
                              isSelected={selectedId === feePlan.id}
                         />

                         <PlanUI
                              content={professionalPlan}
                              onClick={clickhandler}
                              isSelected={selectedId === professionalPlan.id}
                         />
                         <PlanUI
                              content={enterPrisePlan}
                              onClick={clickhandler}
                              isSelected={selectedId === enterPrisePlan.id}
                         />
                         {/* ---------------------------- */}
                    </div>
               </div>
          </ModalComponent>
     );
};

export default SelectPlanUIComponent;
