import React, { useState } from "react";
import SocialMediaLogo from "../../basicultils/SocialMediaLogo";
import { socialmedia } from "../../config/socialmediaConfig";
import ButtonComponent from "../../basicultils/ButtonComponent";
import FollowSvg from "../templates/Svg/FollowSvg";
import IndivDashboarditemCard from "./utils/IndivDashboarditemCard";

const IndivDashboarditem = () => {
     const [isHover, setIsHover] = useState(false);

     const onHover = () => {
          setIsHover(true);
     };

     const offHover = () => {
          setIsHover(false);
     };

     return (
          <IndivDashboarditemCard>
               <div
                    className={` w-full h-full flex flex-col justify-between  duration-250 ${
                         isHover
                              ? "bg-dashboardpagehover text-[#fff] "
                              : "bg-[#fff] text-[#000]"
                    }`}
                    onMouseEnter={onHover}
                    onMouseLeave={offHover}
               >
                    <div
                         className={` w-full h-[100px] ps-6 flex flex-col justify-end   `}
                    >
                         <SocialMediaLogo
                              maincss={"w-[49px] h-[49px] rounded-[50%]"}
                              content={socialmedia.yelp}
                         />
                    </div>
                    <div className=" w-full h-[75px] flex flex-col ps-8 pt-8 md:gap-[14px] gap-[10px]">
                         <h1 className=" font-poppins text-[14px] font-bold">
                              {socialmedia.yelp.name}
                         </h1>
                         <p
                              className={` w-full text-[12px]  font-poppins ${
                                   isHover
                                        ? "text-[#fff]"
                                        : "text-dashboardpara"
                              }`}
                         >
                              {" "}
                              Orders that requires approval
                         </p>
                    </div>
                    <div className=" w-full h-[100px] ps-6 pe-3 flex flex-row justify-between items-center font-poppins">
                         <p
                              className={` text-[28px]  font-bold  ${
                                   isHover ? "text-[#fff]" : "text-[#425853]"
                              }`}
                         >
                              28
                         </p>
                         <ButtonComponent
                              isLoading={false}
                              onClick={() => {}}
                              className=" w-min h-min "
                         >
                              <FollowSvg />
                         </ButtonComponent>
                    </div>
               </div>
          </IndivDashboarditemCard>
     );
};

export default IndivDashboarditem;
