import React from "react";
import IndivReviewSchedulerSocialMediaCard from "./IndivReviewSchedulerSocialMediaCard";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { navBarSliceActions } from "../../../store/DashboardSlices/NavbarSlice";
import ButtonComponent from "../../../basicultils/ButtonComponent";
import { routes } from "../../../config/mainConfig";
import {
     schedulerSlideActions,
     updateSelectedSocialMediaMiddleware,
} from "../../../store/SchedulerSlices/schedulerSlide";
import UseGetAccountDetails, {
     useGetAccountDetails_getDetailsFrom_modes,
} from "../../../customHooks/UseGetAccountDetails";

const IndivSchedulerSocialMediaCom = ({ media }) => {
     const navigate = useNavigate();
     const dispatch = useDispatch();
     const [getDetailsFrom] = UseGetAccountDetails();
     let image = media?.image;
     let mediaName = media.name;
     let link = media?.link;  

     return (
          <IndivReviewSchedulerSocialMediaCard>
               <ButtonComponent
                    // to={routes.socialmedialist + "/" + link}
                    onClick={() => {
                         // Updating Selected Media in scheduler dashboard
                         dispatch(updateSelectedSocialMediaMiddleware(media));

                         let loginDetails = getDetailsFrom(
                              useGetAccountDetails_getDetailsFrom_modes.custom,
                              media?.link
                         );
                         console.log(loginDetails);

                         if (!loginDetails?.isAccounted) {
                              dispatch(schedulerSlideActions.resetContent());
                              navigate(
                                   routes.scheduler +
                                        "/" +
                                        media?.link +
                                        "/" +
                                        routes.schedulersocialmediaaddaccount
                              );
                         } else {
                              navigate(
                                   routes.scheduler +
                                        "/" +
                                        link +
                                        "/" +
                                        routes.schedulersocialmediagrid
                              );
                         }
                    }}
                    className="w-full h-full flex flex-col justify-center items-center gap-[1rem]"
               >
                    <div className="w-[50px] h-[50px]">
                         <img
                              src={image}
                              alt={mediaName}
                              className="object-cover"
                         />
                    </div>
                    <div className=" w-full text-center font-[600] text-[16px] text-socailmedialistingcolor font-manrope">
                         {mediaName}
                    </div>
               </ButtonComponent>
          </IndivReviewSchedulerSocialMediaCard>
     );
};

export default IndivSchedulerSocialMediaCom;
