// Import Actions -> slices : master
import { masterSliceActions } from "../store/masterSlice";

// API config
import { baseURL, yelp_reviewFetch } from "../API-utils/APIConfig";
import { GetAPI } from "../API-utils/GetAPI";
import { dynamicSocialMediaSliceActions } from "../store/DynamicSocialMediaSlices/DynamicSocialMediaSlices";

// Default Header
const header = {
     "Content-Type": "application/json",
};

//------------  Review Fetching   ----------------------
const fetchReviews_yelpSocialMedia = async (alias) => {
     const url = new URL(baseURL + yelp_reviewFetch + "/" + alias);
     let response = await GetAPI(url, header);
     return response;
};

// Thunk
export const updateYelpReviewListThunk = (
     socialmediaAuthObj,
     navigate = () => {}
) => {
     return async (dispatch) => {
          let response = await fetchReviews_yelpSocialMedia(
               socialmediaAuthObj?.alias
          );

          // Checking the data
          if (response?.response?.ok) {
               // data or array of reviews
               let data = response.responsedata.data.reviews;
               if (!data) return;
               // Updating the reviews
               dispatch(
                    dynamicSocialMediaSliceActions.updateAllReviewList(data)
               );
          }
     };
};

//-------------------------------------------
// Local Storage
// Setting and getting Local Storage for Yelp
const key_yelp_localstorage = "yelp_auth";

export const setYelpLocalStorage = (obj = "") => {
     localStorage.setItem(key_yelp_localstorage, JSON.stringify(obj));
};

export const getYelpLocalStorage = () => {
     // Getting local storage of yelp
     let obj = JSON.parse(localStorage.getItem(key_yelp_localstorage));
     console.log("Yelp : ", obj);
     return obj;
};

// Thunk to update the master

export const updateYelpMasterSliceAuthStatus = (yelp_obj) => {
     return (dispatch) => {
          // Getting yelp auth from local storage
          let localStorage_yelpObj = getYelpLocalStorage();

          if (!localStorage_yelpObj) return;

          // Setting in the master slice
          dispatch(masterSliceActions.updateYelp(localStorage_yelpObj));
     };
};

//----------------------------------
