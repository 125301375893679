import React from "react";
import SocialMediaLogo from "../../../../../../../basicultils/SocialMediaLogo";
import { socialmedia } from "../../../../../../../config/socialmediaConfig";
import { useSelector } from "react-redux";

function formatTime(date) {
     // Get the hours and minutes from the date object
     let hours = date.getHours();
     let minutes = date.getMinutes();
     let period = hours >= 12 ? "PM" : "AM";

     // Convert hours from 24-hour to 12-hour format
     hours = hours % 12;
     hours = hours ? hours : 12; // the hour '0' should be '12'

     // Add leading zero to minutes if necessary
     minutes = minutes < 10 ? "0" + minutes : minutes;

     // Construct the formatted time string
     let formattedTime = hours + ":" + minutes + " " + period;

     return formattedTime;
}

const SchedulerMonthGridContentBox = ({ postContent }) => {
     const scheduler = useSelector((state) => {
          return state.scheduler;
     });

     return (
          <div className="w-full h-fit flex flex-row justify-center">
               <div
                    className={` w-[90%] h-[40px]  p-2 flex flex-row justify-between items-center rounded-[3px] ${
                         postContent?.isPosted
                              ? " bg-[#02A443] opacity-80"
                              : " bg-[#F2994A] opacity-80"
                    }`}
               >
                    <h1 className="  text-[14px]  font-poppins font-[600]   ">
                         {formatTime(new Date(postContent.taskTime))}
                    </h1>
                    <SocialMediaLogo
                         content={scheduler?.selectedSocialMedia}
                         maincss={"w-[25px] h-[25px]"}
                    />
               </div>
          </div>
     );
};

export default SchedulerMonthGridContentBox;
