import React from "react";
import searchicon from "../assest/iconpngs/search.png";

// Search Bar Component
const SearchBar = ({ searchbarcss, isLogoAtFront = false }) => {
     return (
          <div className={searchbarcss}>
               <input
                    type="text"
                    placeholder="Search"
                    className={` text-greyText text-[12px] placeholder:text-[12px] ps-[10%] w-[100%] placeholder:text-greyText rounded-[5px] border-2  border-[#DDD] ${
                         isLogoAtFront ? "ps-[10%]" : "ps-[2%]"
                    }`}
               />
               <div
                    className={` absolute  w-[10%]  h-full flex flex-row justify-center items-center ${
                         isLogoAtFront ? "" : "right-0"
                    } `}
               >
                    <div className=" w-[65%] h-[65%] ">
                         <img
                              alt="search bar"
                              src={searchicon}
                              className=" w-full h-full  object-contain"
                         />
                    </div>
               </div>
          </div>
     );
};

export default SearchBar;
