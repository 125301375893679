import React from "react";
import ThirdSectionContextBox from "./ThirdSectionContextBox";
import VideoSVG from "../../components/templates/Svg/Landingpagesvgs/VideoSVG";
import CustomerSVG from "../../components/templates/Svg/Landingpagesvgs/CustomerSVG";
import ReviewSVG from "../../components/templates/Svg/Landingpagesvgs/ReviewSVG";
import SocialListeningSVG from "../../components/templates/Svg/Landingpagesvgs/SocialListeningSVG";

const content = [
     {
          title: "Video Testimonials",
          body: "Captivate your audience with authentic video testimonials, harnessing the power of visual storytelling to build trust and credibility.",
          learnMoreLink: "",
     },
     {
          title: "Customer Feedback",
          body: "Gain invaluable insights into customer satisfaction and preferences through direct feedback, empowering you to tailor your products and services to exceed expectations.",
          learnMoreLink: "",
     },
     {
          title: "Review Management",
          body: "Effortlessly manage and respond to reviews across all platforms from a centralized dashboard, ensuring timely engagement and nurturing positive relationships with your audience.",
          learnMoreLink: "",
     },
     {
          title: "Social Listening",
          body: "Stay attuned to conversations about your brand across social media platforms, enabling you to understand sentiment, identify trends, and proactively address concerns to enhance your online reputation.",
          learnMoreLink: "",
     },
];

const ThirdSection = () => {
     return (
          <div className=" w-full h-fit mt-[4rem]" id={"features"}>
               <div className=" w-full h-fit flex flex-col items-center gap-[1.5rem] p-6">
                    <h1 className=" w-[100%] flex flex-row md:justify-center justify-start font-poppins text-[20px] font-[600] bg-clip-text text-transparent bg-mainRectangeGredient2 ">
                         FEATURES
                    </h1>
                    <p className=" lg:w-[50%] md:w-[65%] w-[100%]  md:text-center text-start font-poppins text-[36px] leading-[38px] font-[600] text-[#111013]">
                         Powerful Features for Enhanced Social Engagement
                    </p>
                    <p className=" md:w-[75%] w-[100%] md:text-center text-start font-poppins text-[20px] leading-[24px] font-[500] text-[#9497A1] ">
                         Explore the Features That Elevate Your Social Listening
                         and Review Management Experience.
                    </p>
               </div>

               <div className=" w-full h-fit flex flex-row  xl:justify-evenly justify-start flex-shrink-0 gap-[5rem] overflow-x-auto pt-8 pb-8 ps-8">
                    <ThirdSectionContextBox
                         title={content[0].title}
                         body={content[0].body}
                         learnMoreLink={content[0].learnMoreLink}
                    >
                         <VideoSVG />
                    </ThirdSectionContextBox>
                    <ThirdSectionContextBox
                         title={content[1].title}
                         body={content[1].body}
                         learnMoreLink={content[1].learnMoreLink}
                    >
                         <CustomerSVG />
                    </ThirdSectionContextBox>
                    <ThirdSectionContextBox
                         title={content[2].title}
                         body={content[2].body}
                         learnMoreLink={content[2].learnMoreLink}
                    >
                         <ReviewSVG />
                    </ThirdSectionContextBox>
                    <ThirdSectionContextBox
                         title={content[3].title}
                         body={content[3].body}
                         learnMoreLink={content[3].learnMoreLink}
                    >
                         <SocialListeningSVG />
                    </ThirdSectionContextBox>
               </div>
          </div>
     );
};

export default ThirdSection;
