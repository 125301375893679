import React, { useState } from "react";
import SelectComponent from "../../../../basicultils/SelectComponent";
import ContactFormPageScrollSection from "./ContactFormPageScrollSection";

const optionarr = [
     {
          id: "1",
          content: "Balajitheblankde@gmail.com",
     },
     {
          id: "2",
          content: "mithun@gmail.com",
     },
     {
          id: "3",
          content: "blajitheblandke@gmail.cok",
     },

     {
          id: "4",
          content: "mithun@gmail.com",
     },
     {
          id: "5",
          content: "dfasfasf",
     },
     {
          id: "6",
          content: "sadfas",
     },
     {
          id: "7",
          content: "sadfas",
     },
     {
          id: "8",
          content: "sadfas",
     },
];

const ContactFormHeaderEmailSection = () => {
     const [selected, setSelected] = useState(
          optionarr[0] ? optionarr[0] : { id: "none", content: "jar" }
     );

     const updateSelected = (option) => {
          setSelected(option);
     };

     return (
          <div className=" sm:w-fit w-full h-full flex flex-row   items-center sm:ps-8 sm:pe-8 ps-4 pe-4 gap-8 sm:mb-0 mb-[1.5rem]">
               <SelectComponent
                    textClassName=" text-[12px] font-montserrat"
                    className=" w-[200px] h-[30px] "
                    optionarr={optionarr}
                    updateFunction={updateSelected}
                    value={selected}
               />

               <ContactFormPageScrollSection />
          </div>
     );
};

export default ContactFormHeaderEmailSection;
