import React, { useEffect } from "react";
import DynamicSocialMediaLayout from "../../components/DynamicSocialmediaCOm/DynamicSocialMediaLayout";
import { Outlet, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dynamicSocialMediaSliceActions } from "../../store/DynamicSocialMediaSlices/DynamicSocialMediaSlices";
import {
     findSocialMediaByLinks,
     socialmedia,
} from "../../config/socialmediaConfig";
import { navBarSliceActions } from "../../store/DashboardSlices/NavbarSlice";

const DynamicSocialMediaPage = () => {
     const parmas = useParams();
     const dispatch = useDispatch();
     const socialmedianame = parmas.socialmedianame;
     const socialmediaState = useSelector((state) => {
          return state?.dynamicsocialmedia?.socialmedia;
     });

     useEffect(() => {
          if (socialmedianame !== socialmediaState) {
               let media = findSocialMediaByLinks(socialmedianame);
               if (media) {
                    let socialObj = socialmedia[media];
                    dispatch(navBarSliceActions.modifyReviewNavLink(socialObj));
                    dispatch(
                         dynamicSocialMediaSliceActions.updateSelectedMedia(
                              socialObj
                         )
                    );

                    dispatch(
                         dynamicSocialMediaSliceActions.resetAllReviewAndPinnedReviews()
                    );

                    console.log(media, socialmedianame);
               }
          }
     }, [dispatch, socialmediaState, socialmedianame]);

     return (
          <div className=" w-full h-full relative">
               <Outlet />
               <DynamicSocialMediaLayout />
          </div>
     );
};

export default DynamicSocialMediaPage;
