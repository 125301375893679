import React from "react";

const ContactBodyInputSection = ({ children, header }) => {
     return (
          <div className=" w-full h-fit flex flex-col gap-1 ">
               <h1 className=" text-contactFormBackTextColor font-[400] font-montserrat text-[12px]">
                    {header}
               </h1>
               <div className="w-full h-fit">{children}</div>
          </div>
     );
};

export default ContactBodyInputSection;
