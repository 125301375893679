import React from "react";
import SchedulerHeaderText from "../Utils/SchedulerHeaderText";

import SchedulerNewPostButton from "../Utils/SchedulerNewPostButton";
import SchedulerNavLink from "../Utils/SchedulerNavLink";

const SchedulerGridHeader = () => {
     return (
          <div className=" w-full h-[75px]   flex flex-row justify-between  ps-6 pe-6">
               <div className=" w-fit h-full flex flex-col justify-center">
                    <SchedulerHeaderText
                         className={`text-[20px] font-montserrat text-mentionButtoncolor font-bold`}
                         title={"Scheduler"}
                    />
               </div>

               <div className=" w-fit h-full flex flex-row items-center gap-8">
                    <SchedulerNewPostButton />
                    <SchedulerNavLink />
               </div>
          </div>
     );
};

export default SchedulerGridHeader;
