export const schedulerRigthColumns = [
     //  {
     //       columnHeader: "No",
     //       width: "w-[50px] text-center ",
     //  },
     {
          columnHeader: "Date",
          width: "w-[116px]",
     },
     {
          columnHeader: "Time",
          width: "w-[116px]",
     },
     {
          columnHeader: "Platform",
          width: "w-[116px]",
     },
     {
          columnHeader: "Post",
          width: "w-[300px]",
     },
     {
          columnHeader: "Status",
          width: "w-[166px]",
     },
];

export const schedulerModes = {
     week: "week",
     month: "month",
};
