import React from "react";

const FeatureListContentBox = ({ heading = "Collect", body = "" }) => {
     return (
          <div className=" w-full h-fit flex flex-col gap-[1rem]">
               <h1 className=" font-poppins  text-[16px] font-[600] text-[#1C1F25]">
                    {heading}
               </h1>

               <p className=" text-ellipsis  font-poppins text-[16px] font-[400] text-[#4B5162]">
                    {body}
               </p>
          </div>
     );
};

export default FeatureListContentBox;
