import React from "react";
import ModalComponent from "../../../basicultils/ModalComponent";
import ButtonComponent from "../../../basicultils/ButtonComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";

const SchedulerFaceBookAddAccountDetails = () => {
     const [searchParams] = useSearchParams();
   
     const navigate = useNavigate();
     const handleClickLoginButton = () => {
       window.FB.login(
         function (response) {
           console.log(response);
           if (response.authResponse) {
             console.log("Welcome!  Fetching your information.... ");
             window.FB.api("/me", function (response) {
               console.log("Good to see you, " + response.name + ".");
             });
           } else {
             console.log("User cancelled login or did not fully authorize.");
           }
         },
         {
           config_id: "500562739060410",
           response_type: "code",
           override_default_response_type: true,
         }
       );
       //     const checkLoginState = () => {
       //       window.FB.getLoginStatus(function (response) {
       //         statusChangeCallback(response);
       //       });
       //     };
       //     window.FB.login((response) => {
       //       if (response.authResponse) {
       //         console.log("Welcome!  Fetching your information.... ");
       //         window.FB.api("/me", function (response) {
       //           console.log("Good to see you, " + response.name + ".");
       //         });
       //       } else {
       //         console.log("User cancelled login or did not fully authorize.");
       //       }
       //     });
     };
   
     const initFacebookLogin = () => {
       window.FB.init({
         appId: "1797356007408770",
         configId: "500562739060410",
         cookie: true,
         xfbml: true,
         version: "v20.0",
       });
     };
   
     useEffect(() => {
       if (!document.getElementById("facebook-sdk")) {
         const script = document.createElement("script");
         script.id = "facebook-sdk";
         script.async = true;
         script.defer = true;
         script.crossOrigin = "anonymous";
         script.src = "https://connect.facebook.net/en_US/sdk.js";
         script.onload = initFacebookLogin;
         document.body.appendChild(script);
       }
     }, []);
     return (
       <ModalComponent>
         <div className=" w-full h-full flex flex-row justify-center items-center">
           <div className=" w-[500px] h-[250px] bg-[#fff]">
             <div className=" w-full h-fit flex flex-row justify-center mt-[5rem]">
               {/* <button onClick={handleClickLoginButton}>CLICK HERE</button> */}
               <div className=" w-full h-fit flex flex-row justify-center gap-[1rem] mt-[0.25rem] ">
                 <ButtonComponent
                   onClick={() => {
                     handleClickLoginButton();
                   }}
                   className=" w-[122px] h-[40px] text-[#577496] text-[16px] font-lato border-[#577496] border-2 rounded-[6px]"
                 >
                   Login
                 </ButtonComponent>
               </div>
               {/* <LoginSocialFacebook
                 appId="1797356007408770"
                 config_id="500562739060410"
                 onResolve={(response) => {
                   console.log(response);
                   //  setProfile(response.data);
                 }}
                 //     onLoginStart={checkLoginState}
                 onReject={(error) => {
                   console.log(error);
                 }}
               >
                 <FacebookLoginButton />
               </LoginSocialFacebook>
               <div
                 className="fb-login-button"
                 data-width=""
                 data-size="large"
                 data-button-type="login_with"
                 data-layout="default"
                 data-auto-logout-link="false"
                 data-use-continue-as="true"
                 // data-onlogin="checkLoginState();"
                 data-config_id="500562739060410"
               ></div> */}
   
               {/* <ButtonComponent
                 onClick={() => {
                   handleClickLoginButton();
                 }}
                 className=" w-[122px] h-[40px] text-[#577496] text-[16px] font-lato border-[#577496] border-2 rounded-[6px]"
               >
                 Login
               </ButtonComponent> */}
             </div>
             <div className=" w-full h-fit flex flex-row justify-center gap-[1rem] mt-[1.75rem] ">
               <ButtonComponent
                 onClick={() => {
                   navigate("..");
                 }}
                 className=" w-[122px] h-[40px] text-[#577496] text-[16px] font-lato border-[#577496] border-2 rounded-[6px]"
               >
                 Cancel
               </ButtonComponent>
             </div>
           </div>
         </div>
       </ModalComponent>
     );
   };

export default SchedulerFaceBookAddAccountDetails;
