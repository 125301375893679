import React from "react";
import SchedulerMonthGridHeader from "./SchedulerMonthGridHeader";
import SchedulerMonthBodyDate from "./SchedulerMonthBodyDate";

const SchedulerMonthLayout = () => {
     return (
          <div className=" w-fit h-full  p-4 ">
               <SchedulerMonthGridHeader />
               <SchedulerMonthBodyDate />
          </div>
     );
};

export default SchedulerMonthLayout;
