import React from "react";
import ButtonComponent from "../../../../basicultils/ButtonComponent";
import { useSelector } from "react-redux";
const VideoFormSuccessfullUploadLayout = () => {
     const video = useSelector((state) => {
          return state.contactform.video;
     });

     return (
          <div className=" w-full h-full flex flex-row justify-center items-center">
               <div className=" w-fit h-fit flex flex-col items-center gap-[3rem] ">
                    <h1 className=" text-[#E91050] font-inter text-[20px] font-[600] ">
                         Video Uploaded Successfully ✅
                    </h1>

                    <div className=" relative w-[585px] h-[76px]   rounded-[40px] flex flex-col justify-center   text-[#FF6161] shadow-md ps-8 pe-8   truncate">
                         {video.copyText}
                         <ButtonComponent
                              onClick={() => {
                                   navigator.clipboard.writeText(
                                        video.copyText
                                   );
                              }}
                              className=" absolute top-[50%] -translate-y-[50%] right-[4%] w-[150px] h-[46px] rounded-[25px]  bg-mainRectangeGredient text-[16px] font-inter text-[#fff] "
                         >
                              Copy
                         </ButtonComponent>
                    </div>
               </div>
          </div>
     );
};

export default VideoFormSuccessfullUploadLayout;
