import React from "react";
import ProfileImageComponent from "../../../basicultils/ProfileImageComponent";

import SocialMediaLogo from "../../../basicultils/SocialMediaLogo";
import HeartSvg from "../../templates/Svg/HeartSvg";
import LikeAndDateCom from "../utils/LikeAndDateCom";
import IconHolder from "../utils/IconHolder";
import ShareSVG from "../../templates/Svg/ShareSVG";
import { useDispatch, useSelector } from "react-redux";
import { getDateText } from "../../../config/CalenderConfig";
import FillButtonMention from "./utils/FillButtonMention";
import UnFillButtonMention from "./utils/UnFillButtonMention";
import { dynamicSocialMediaSliceActions } from "../../../store/DynamicSocialMediaSlices/DynamicSocialMediaSlices";
const src1 =
     "https://preview.keenthemes.com/metronic-v4/theme/assets/pages/media/profile/profile_user.jpg";

const IndivMentionContent = ({
     username = "John Doe",
     email = "@John Doe",
     src = src1,

     content = {},
}) => {
     const dispatch = useDispatch();
     const selectedSocialMedia = useSelector((state) => {
          return state.dynamicsocialmedia.selectedSocialMedia;
     });

     let user = content?.user;
     let dateText = getDateText(content?.time_created);

     return (
          <div className=" w-[95%] h-fit min-h-[320px] max-h-[400px] flex flex-col flex-shrink-0  rounded-[8px] justify-between   border-[#E1F2FF] border-2">
               {/* Header section */}
               <div className=" w-full h-[75px] flex flex-row justify-between  lg:p-2 p-1">
                    {/* Profile Photo section */}
                    <div className=" lg:w-[55px] w-[44px] h-full flex flex-col justify-center items-center ">
                         <ProfileImageComponent
                              image={user?.image_url}
                              size={
                                   " lg:w-[48px] lg:h-[48px] w-[36px] h-[36px]"
                              }
                         />
                    </div>
                    {/* profile username section */}
                    <div className=" flex-1 flex flex-col justify-center overflow-hidden lg:ps-2 ps-1 ">
                         <div className=" w-fit h-min  font-montserrat  lg:text-[12px] text-[10px]  ">
                              <h1 className=" text-mentioncolor font-bold truncate">
                                   {user?.name}
                              </h1>
                              <p className="  font-[500]  text-profileGreyTextColor">
                                   {user?.email}
                              </p>
                         </div>
                    </div>
                    {/* social media section  */}
                    <div className=" lg:w-[90px] w-[60px] h-full  flex flex-row justify-center items-center">
                         <SocialMediaLogo
                              content={selectedSocialMedia}
                              maincss="lg:w-[30px] lg:h-[30px] w-[25px] h-[25px]"
                         />
                    </div>
               </div>

               {/* Body section */}
               <div className=" w-full flex flex-row overflow-hidden lg:ps-2 ps-1 lg:mb-4 mb-2  ">
                    <div className="lg:w-[55px] w-[44px]"></div>
                    <div className=" flex-1   font-montserrat text-mentionparaTextColor lg:text-[11px] text-[10px] font-[400] lg:p-2 p-1  overflow-auto">
                         {content?.text}
                    </div>
               </div>
               {/* Like and date section  */}

               <div className="w-full h-[30px]   border-b-2 border-[#E1F2FF] flex flex-row">
                    <div className="lg:w-[55px] w-[44px]"></div>
                    <div className="flex-1">
                         <LikeAndDateCom
                              likeno={content?.rating}
                              date={dateText}
                         />
                    </div>
               </div>

               <div className=" w-full h-min  flex lg:flex-row flex-col gap-4 items-center lg:ps-[40px] ps-[30px] lg:pb-5 pb-3 lg:pt-5 pt-3">
                    <div className=" lg:w-min w-full h-full flex flex-row gap-4 lg:items-center items-start">
                         <IconHolder
                              onClick={() => {
                                   console.log(content.id);
                              }}
                         >
                              <HeartSvg isLiked={false} />
                         </IconHolder>
                         <IconHolder onClick={() => {}} href={content?.url}>
                              <ShareSVG />
                         </IconHolder>
                    </div>

                    <div className=" flex-1 lg:w-full w-full h-full  flex flex-row lg:justify-end  items-start pe-4 lg:gap-4 gap-5">
                         <UnFillButtonMention>Ignore</UnFillButtonMention>
                         {content?.pinned ? (
                              <FillButtonMention
                                   onClick={() => {
                                        dispatch(
                                             dynamicSocialMediaSliceActions.removingFromWall(
                                                  content?.id
                                             )
                                        );
                                   }}
                              >
                                   Pinned
                              </FillButtonMention>
                         ) : (
                              <UnFillButtonMention
                                   onClick={() => {
                                        console.log(content?.id);

                                        dispatch(
                                             dynamicSocialMediaSliceActions.addingToWall(
                                                  content?.id
                                             )
                                        );
                                   }}
                              >
                                   Pin to wall
                              </UnFillButtonMention>
                         )}
                    </div>
               </div>
          </div>
     );
};

export default IndivMentionContent;
