import React from "react";
import ProfileInformation from "./ProfileInformation";
import ProfileSocialConnect from "./ProfileSocialConnect";

const ProfileLayout = () => {
     return (
          <div className=" w-full h-full  ">
               <div className=" w-full h-[50px] flex flex-col justify-center ps-8">
                    <h1 className=" font-montserrat text-[18px] font-bold">
                         Account Settings
                    </h1>
               </div>
               <div className=" w-full h-fit ">
                    <ProfileInformation />
               </div>
               <div className=" w-full h-fit ">
                    <ProfileSocialConnect />
               </div>
               {/* <div className=" w-full h-fit ">
                    <ProfileAPI />
               </div> */}
          </div>
     );
};

export default ProfileLayout;
