import React from "react";
import ModalComponent from "../../../basicultils/ModalComponent";
import ButtonComponent from "../../../basicultils/ButtonComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { masterSliceActions } from "../../../store/masterSlice";
import UseGetAccountDetails, {
  useGetAccountDetails_getDetailsFrom_modes,
} from "../../../customHooks/UseGetAccountDetails";

const DynamicFacebookAddAccount = () => {
  const [searchParams] = useSearchParams();
  const [uid, setUid] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const dispatch = useDispatch();
  const accDetails = UseGetAccountDetails()[0];

  const navigate = useNavigate();

  const statusChangeCallback = (response) => {
    console.log("Login status:", response);
    if (response.status === "connected") {
      setUid(response.authResponse.userID);
      setAccessToken(response.authResponse.accessToken);
      console.log("uid", uid);
      console.log("accessToken", accessToken);
    }
    dispatch(
      masterSliceActions.updateFacebookAuthentication(response.authResponse)
    );
    // console.log("Account Details:", accDetails);
    setTimeout(() => {
      accDetails(useGetAccountDetails_getDetailsFrom_modes.review);
      console.log(accDetails(useGetAccountDetails_getDetailsFrom_modes.review));
    }, 2000);
  };

  // Check login state
  const checkLoginState = () => {
    window.FB.getLoginStatus(function (response) {
      console.log("At checkLoginState:", response);
      statusChangeCallback(response);
    });
  };

  // const checkLoginState = () => {
  //   window.FB.getLoginStatus(function (response) {
  //     if (response.status === "connected") {
  //       setUid(response.authResponse.userID);
  //       setAccessToken(response.authResponse.accessToken);
  //       console.log("uid", uid);
  //       console.log("accessToken", accessToken);
  //     }
  //     dispatch(
  //       masterSliceActions.updateFacebookAuthentication(response.authResponse)
  //     );
  //     // console.log("Account Details:", accDetails);
  //     setTimeout(() => {
  //       accDetails(useGetAccountDetails_getDetailsFrom_modes.review);
  //       console.log(
  //         accDetails(useGetAccountDetails_getDetailsFrom_modes.review)
  //       );
  //     }, 2000);
  //   });
  // };

  const handleClickLoginButton = async() => {
    window.FB.login(
      function (response) {
        console.log(response);
        if (response.status === "connected") {
          const code = response.authResponse.code;
          // Exchange the code for an access token
          fetch(
            `https://graph.facebook.com/v20.0/oauth/access_token?client_id=1797356007408770&client_secret=ca1f95dfba1ec0bc7fa0f8445a786fb3&code=${code}`
          )
            .then((res) => res.json())
            .then(async (data) => {
              console.log("data", data);
              // console.log("Access Token:", data.access_token);
              setAccessToken(data.access_token);
              console.log("Access Token:", accessToken);
              const userData = await fetch(`https://graph.facebook.com/me?access_token=${accessToken}`);
              setUid(userData.id);
              console.log("USER DATA", userData);
            })
            .catch((error) => {
              console.error("Error exchanging code for access token:", error);
            });
          window.FB.getLoginStatus(function (response) {
            console.log("At checkLoginState:", response);
            statusChangeCallback(response);
          });
        } else {
          console.error("User login failed:", response);
        }
      },
      {
        config_id: "500562739060410", // Replace with your Configuration ID
        response_type: "code",
        override_default_response_type: true,
      }
    );

    // window.FB.login(
    //   function (response) {
    //     console.log(response);

    //     window.FB.getLoginStatus(function (response) {
    //       if (response.status === "connected") {
    //         setUid(response.authResponse.userID);
    //         setAccessToken(response.authResponse.accessToken);
    //         console.log("uid", uid);
    //         console.log("accessToken", accessToken);
    //       }
    //       dispatch(
    //         masterSliceActions.updateFacebookAuthentication(
    //           response.authResponse
    //         )
    //       );
    //       // console.log("Account Details:", accDetails);
    //       setTimeout(() => {
    //         accDetails(useGetAccountDetails_getDetailsFrom_modes.review);
    //         console.log(
    //           accDetails(useGetAccountDetails_getDetailsFrom_modes.review)
    //         );
    //       }, 2000);
    //     });
    //     if (response.authResponse) {
    //       console.log("Welcome!  Fetching your information.... ");

    //       window.FB.api("/me", function (response) {
    //         console.log("Good to see you, " + response.name + ".");
    //       });
    //     } else {
    //       console.log("User cancelled login or did not fully authorize.");
    //     }
    //   },
    //   {
    //     config_id: "500562739060410",
    //     response_type: "code",
    //     override_default_response_type: true,
    //   }
    // );

    //     const checkLoginState = () => {
    //       window.FB.getLoginStatus(function (response) {
    //         statusChangeCallback(response);
    //       });
    //     };
    //     window.FB.login((response) => {
    //       if (response.authResponse) {
    //         console.log("Welcome!  Fetching your information.... ");
    //         window.FB.api("/me", function (response) {
    //           console.log("Good to see you, " + response.name + ".");
    //         });
    //       } else {
    //         console.log("User cancelled login or did not fully authorize.");
    //       }
    //     });
  };

  const initFacebookLogin = () => {
    window.FB.init({
      appId: "1797356007408770",
      configId: "500562739060410",
      cookie: true,
      xfbml: true,
      version: "v20.0",
    });
    window.FB.AppEvents.logPageView();
  };

  useEffect(() => {
    if (!document.getElementById("facebook-sdk")) {
      const script = document.createElement("script");
      script.id = "facebook-sdk";
      script.async = true;
      script.defer = true;
      script.crossOrigin = "anonymous";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.onload = initFacebookLogin;
      document.body.appendChild(script);
    }
  }, []);
  return (
    <ModalComponent>
      <div className=" w-full h-full flex flex-row justify-center items-center">
        <div className=" w-[500px] h-[250px] bg-[#fff]">
          <div className=" w-full h-fit flex flex-row justify-center mt-[5rem]">
            {/* <button onClick={handleClickLoginButton}>CLICK HERE</button> */}
            <div className=" w-full h-fit flex flex-row justify-center gap-[1rem] mt-[0.25rem] ">
              <ButtonComponent
                onClick={() => {
                  handleClickLoginButton();
                  // checkLoginState();
                }}
                className=" w-[122px] h-[40px] text-[#577496] text-[16px] font-lato border-[#577496] border-2 rounded-[6px]"
              >
                Login Here!
              </ButtonComponent>
            </div>
            {/* <LoginSocialFacebook
              appId="1797356007408770"
              config_id="500562739060410"
              onResolve={(response) => {
                console.log(response);
                //  setProfile(response.data);
              }}
              //     onLoginStart={checkLoginState}
              onReject={(error) => {
                console.log(error);
              }}
            >
              <FacebookLoginButton />
            </LoginSocialFacebook>
            <div
              className="fb-login-button"
              data-width=""
              data-size="large"
              data-button-type="login_with"
              data-layout="default"
              data-auto-logout-link="false"
              data-use-continue-as="true"
              // data-onlogin="checkLoginState();"
              data-config_id="500562739060410"
            ></div> */}

            {/* <ButtonComponent
              onClick={() => {
                handleClickLoginButton();
              }}
              className=" w-[122px] h-[40px] text-[#577496] text-[16px] font-lato border-[#577496] border-2 rounded-[6px]"
            >
              Login
            </ButtonComponent> */}
          </div>
          <div className=" w-full h-fit flex flex-row justify-center gap-[1rem] mt-[1.75rem] ">
            <ButtonComponent
              onClick={() => {
                navigate("..");
              }}
              className=" w-[122px] h-[40px] text-[#577496] text-[16px] font-lato border-[#577496] border-2 rounded-[6px]"
            >
              Cancel
            </ButtonComponent>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default DynamicFacebookAddAccount;
