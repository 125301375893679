import { createSlice, current } from "@reduxjs/toolkit";

// Helper functions
function getFirstDayOfMonth(date) {
     // Create a new Date object based on the input date, set to the first day of the month
     let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
     return firstDay;
}

function getLastDayOfLastMonth(date) {
     // Create a new Date object set to the first day of the current month
     let firstDayOfCurrentMonth = new Date(
          date.getFullYear(),
          date.getMonth(),
          1
     );

     // Subtract one day from the first day of the current month to get the last day of the previous month
     let lastDayOfLastMonth = new Date(firstDayOfCurrentMonth);
     lastDayOfLastMonth.setDate(firstDayOfCurrentMonth.getDate() - 1);

     return lastDayOfLastMonth;
}

const monthListFormat = (date) => {
     let array = [];
     let curDate = getFirstDayOfMonth(new Date(date));
     // Prev Month Date s
     let dayNumber = curDate.getDay();
     let reverseDate = new Date(getLastDayOfLastMonth(new Date(curDate)));
     for (let i = 0; i < dayNumber; i++) {
          array.unshift({
               date: new Date(reverseDate),
               postArray: [],
          });
          reverseDate = new Date(
               reverseDate.setDate(reverseDate.getDate() - 1)
          );
     }

     // Current Month Date s
     for (let i = 0; i < 42 - dayNumber; i++) {
          curDate = new Date(curDate);
          array.push({
               date: new Date(curDate),
               postArray: [],
          });
          curDate = new Date(curDate.setDate(curDate.getDate() + 1));
     }

     return array;
};

const initialState = {
     month: {
          dateList: monthListFormat(new Date()),
          currentMonthDateObj: new Date(),
     },
     week: {},
     postList: [],
};

// Helper function of listing post list according to their date format
function isSameDay(currentDate, postDate) {
     return (
          currentDate.getFullYear() === postDate.getFullYear() &&
          currentDate.getMonth() === postDate.getMonth() &&
          currentDate.getDate() === postDate.getDate()
     );
}

function currentPostList(currentDate, postList) {
     currentDate = new Date(currentDate);
     let array = [];

     for (let i = 0; i < postList?.length; i++) {
          if (
               isSameDay(new Date(currentDate), new Date(postList[i].taskTime))
          ) {
               array.push(postList[i]);
          }
     }

     return array;
}

function updateDateListArrayWithPostList(dateList, postList) {
     let array = [];
     for (let i = 0; i < dateList.length; i++) {
          let currentDate = dateList[i];
          let postArray = currentPostList(currentDate?.date, postList);

          array.push({
               ...currentDate,
               postArray: postArray,
          });
     }

     return array;
}

const schedulerMonthWeekSlice = createSlice({
     name: "SCHEDULER_MONTH_WEEK_SLICE_ACTION",
     initialState,
     reducers: {
          // Month

          updatePostList(state, action) {
               let postList = action.payload;
               let currentState = current(state);
               currentState = { ...currentState };
               let dateList = currentState.month.dateList;

               let array = updateDateListArrayWithPostList(dateList, postList);

               return {
                    ...state,
                    postList: postList,

                    month: {
                         ...state.month,
                         dateList: array,
                    },
               };
          },

          // Month update
          updateMonth(state, action) {
               let currentState = current(state);
               currentState = { ...currentState };
               let postList = currentState.postList;

               // Update Month Date Obj
               let month = new Date(action.payload);
               // Getting Date List Format
               let dateList = monthListFormat(month);
               // Embedding PostList with date List format
               let array = updateDateListArrayWithPostList(dateList, postList);
               return {
                    ...state,
                    month: {
                         ...state.month,
                         dateList: array,
                         currentMonthDateObj: new Date(month),
                    },
               };
          },
     },
});

export const schedulerMonthWeekSliceActions = schedulerMonthWeekSlice.actions;
export default schedulerMonthWeekSlice;
