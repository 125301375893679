import React from "react";
import IndivSocialMediaCom from "./utils/IndivSocialMediaCom";
import { reviewPageSocialMediaListing } from "../../config/socialmediaConfig";
import IndivSocialMediaCard from "./utils/IndivSocialMediaCard";
import DiscountBoardLayout from "../OtherComponents/DiscountBoard/DiscountBoardLayout";

// Having a logic of listing all components

const ListingCom = () => {
     return (
          <div className="w-full h-full flex flex-col ">
               <div className=" w-full h-fit flex flex-row justify-center pt-[1.5em] mb-[0.5rem] ">
                    <DiscountBoardLayout />
               </div>
               <div className=" w-full h-fit flex flex-row  justify-evenly overflow-auto  flex-wrap gap-[20px] lg:p-[20px] p-[10px] pt-[1rem] ">
                    {reviewPageSocialMediaListing.map((media) => {
                         return <IndivSocialMediaCom media={media} />;
                    })}

                    {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => {
                         return (
                              <IndivSocialMediaCard
                                   isDummy={true}
                              ></IndivSocialMediaCard>
                         );
                    })}
               </div>
          </div>
     );
};

export default ListingCom;
