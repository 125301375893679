import { createSlice, current } from "@reduxjs/toolkit";
import { socialmedia } from "../../config/socialmediaConfig";
import { GetAPI, status } from "../../API-utils/GetAPI";
import { reviewRoute } from "../../API-utils/APIConfig";

// Review Array Format
// [{
//      "id" : "review-id",
//      "rating": number,
//      "text": "String",
//      "time_created": "2024-04-03 13:25:53",
//      "url": "URL-link"
//      "user": {
//        "id": "user-id",
//        "image_url": "URL-link",
//        "name": "String",
//        "profile_url": "URL-link"
//      }
//    }]

export const dynamicState = {
     wall: "wall",
     mention: "mention",
};

const initialState = {
     socialmedia: "",
     smallScreenMode: dynamicState.wall,
     selectedSocialMedia: {
          image: "",
          name: "",
          link: "",
     },
     allReviewList: [],
     pinnedReviewList: [],

     addAccount: {
          phoneno: "",
     },
     ui: {
          addAccountButtonIsLoading: false,
     },
};

const dynamicSocialMediaSlice = createSlice({
     name: "Dynamic-state-management",
     initialState,
     reducers: {
          updateAllReviewList(state, action) {
               let reviewArray = action.payload;
               let reviewReturnArray = [];
               // adding ignore and pinned key for handling their logic
               for (let i = 0; i < reviewArray.length; i++) {
                    let obj = reviewArray[i];
                    obj = {
                         ...obj,
                         ignore: false,
                         pinned: false,
                    };
                    reviewReturnArray.push(obj);
               }

               return {
                    ...state,
                    allReviewList: reviewReturnArray,
               };
          },

          modifySmallScreenMode(state, action) {
               return {
                    ...state,
                    smallScreenMode: action.payload,
               };
          },

          updateSelectedMedia(state, action) {
               console.log(action.payload);

               return {
                    ...state,
                    socialmedia: action.payload.link,
                    selectedSocialMedia: action.payload,
               };
          },

          updatePhoneNo(state, action) {
               const phoneno = action.payload;
               return {
                    ...state,
                    addAccount: {
                         ...state.addAccount,
                         phoneno: phoneno,
                    },
               };
          },

          //--- UI Reducers

          modifyAddAccountButtonIsLoading(state, action) {
               return {
                    ...state,
                    ui: {
                         ...state.ui,
                         addAccountButtonIsLoading: action.payload,
                    },
               };
          },

          resetAllReviewAndPinnedReviews(state, action) {
               return {
                    ...state,
                    allReviewList: [],
                    pinnedReviewList: [],
               };
          },

          // Adding to wall
          addingToWall(state, action) {
               const id = action.payload;

               let allReview = current(state).allReviewList;
               let pinnedReview = current(state).pinnedReviewList;

               let selectedReview = allReview.filter((item) => {
                    return id === item.id;
               });

               let isPinnedReviewId = pinnedReview.filter((item) => {
                    return id === item.id;
               });

               if (isPinnedReviewId.length > 0) {
                    return {
                         ...state,
                    };
               }

               let returnAllreview = [];

               for (let i = 0; i < allReview.length; i++) {
                    if (allReview[i].id === id) {
                         let allReviewObj = {
                              ...allReview[i],
                         };
                         allReviewObj.pinned = true;

                         returnAllreview.push(allReviewObj);
                    } else {
                         returnAllreview.push(allReview[i]);
                    }
               }

               pinnedReview = [...pinnedReview, ...selectedReview];

               // console.log(selectedReview, pinnedReview);

               return {
                    ...state,
                    allReviewList: returnAllreview,
                    pinnedReviewList: pinnedReview,
               };
          },

          removingFromWall(state, action) {
               const id = action.payload;

               let allReview = current(state).allReviewList;
               let pinnedReview = current(state).pinnedReviewList;

               let returnPinnedReview = pinnedReview.filter((item) => {
                    return id !== item.id;
               });

               console.log(returnPinnedReview, id);

               let returnAllreview = [];

               for (let i = 0; i < allReview.length; i++) {
                    if (allReview[i].id === id) {
                         let allReviewObj = {
                              ...allReview[i],
                         };
                         allReviewObj.pinned = false;

                         returnAllreview.push(allReviewObj);
                    } else {
                         returnAllreview.push(allReview[i]);
                    }
               }

               return {
                    ...state,
                    allReviewList: returnAllreview,
                    pinnedReviewList: returnPinnedReview,
               };
          },
     },
});

export const dynamicSocialMediaSliceActions = dynamicSocialMediaSlice.actions;

export default dynamicSocialMediaSlice;

export const fetchGetReviewAPIThunk = (
     base,
     header,
     account,
     alias,
     limit,
     navigate = () => {}
) => {
     return async (dispatch) => {
          let reviewq = "";
          if (account === socialmedia.yelp.api) {
               reviewq = reviewRoute;
          } else if (account === socialmedia.tripadvisor.api) {
               reviewq = "/review/fetch";
          }

          let api = new URL(base + "/" + account + reviewq + "/" + alias);
          console.log(api.href);
          const response = await GetAPI(api, header);
          console.log(response);

          if (response.status === status.successful) {
               let data = response.responsedata;

               console.log(data);

               let review = data?.reviews;
               //console.log(data, review);

               if (account === socialmedia.yelp.api) {
                    review = data.data.reviews;
                    console.log(data, review);
               }

               if (account === socialmedia.tripadvisor.api) {
                    let uploadreview = [];
                    review = review?.data;
                    //  console.log(review.length);
                    for (let i = 0; i < review?.length; i++) {
                         //console.log(review[i]);

                         let obj = {
                              id: review[i]?.id,
                              rating: review[i]?.rating,
                              text: review[i].text,
                              time_created: review[i]?.published_date,

                              user: {
                                   id: 0,
                                   name: review[i].user.username,
                                   profile_url: 0,
                                   image_url:
                                        review[i]?.user?.avatar?.thumbnail,
                              },
                         };

                         uploadreview.push(obj);
                    }

                    review = uploadreview;
               }
               dispatch(
                    dynamicSocialMediaSliceActions.updateAllReviewList(review)
               );
          } else if (response.status === status.error) {
               console.log(response.e?.message);
          }
     };
};
