import {
     baseURL_Scheduler,
     tiktokCallBack,
     tiktokDraftPost,
     tiktokPublishPostAPIENDPOINT,
} from "../../API-utils/APIConfig";
import { GetAPI } from "../../API-utils/GetAPI";
import { PostAPI, PostAPIWithOutStringify } from "../../API-utils/PostAPI";
import { setTikTokLocalStorage } from "../../Api-Calling/tiktokAPICalling";
import { routes } from "../../config/mainConfig";
import { socialmedia } from "../../config/socialmediaConfig";
import { masterSliceActions } from "../masterSlice";
import { schedulerSlideActions } from "./schedulerSlide";

export const tiktokOpenAuthentiationTab = async (auth) => {
     console.log(auth);
     let url = new URL(baseURL_Scheduler + "/schedulers/tiktok/auth/code");

     let headers = {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": [
               "POST",
               "GET",
               "OPTIONS",
               "DELETE",
               "PUT",
          ],
          "Access-Control-Allow-Headers": [
               "append",
               "delete",
               "entries",
               "foreach",
               "get",
               "has",
               "keys",
               "set",
               "values",
               "Authorization",
          ],

          Authorization: auth?.sessionToken,

          redirect: "follow",
     };

     const responseObj = await GetAPI(url, headers);
     let response = await responseObj.response;

     console.log(response);

     if (response?.ok) {
          console.log(responseObj?.responsedata);

          let url = new URL(responseObj?.responsedata?.auth_url);
          // let redirectLink = new URL(
          //      "https://ranblitz.com" +
          //           routes.scheduler +
          //           "/" +
          //           socialmedia.tiktok?.link +
          //           "addaccount"
          // );
          // url.searchParams.set("redirect_uri", redirectLink.href);
          // console.log(url.href, redirectLink);
          // window.open(url);
          window.location.href = url;
     }
};

export const tiktokCallBackAuthentication = (code, auth, navigate) => {
     return async (dispatch) => {
          let url = new URL(baseURL_Scheduler + tiktokCallBack);

          let bodyObj = {
               code: code,
          };
          let headers = {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
               "Access-Control-Allow-Methods": [
                    "POST",
                    "GET",
                    "OPTIONS",
                    "DELETE",
                    "PUT",
               ],
               "Access-Control-Allow-Headers": [
                    "append",
                    "delete",
                    "entries",
                    "foreach",
                    "get",
                    "has",
                    "keys",
                    "set",
                    "values",
                    "Authorization",
               ],

               Authorization: auth?.sessionToken,

               redirect: "follow",
          };

          let response = await PostAPI(url, bodyObj, headers);

          if (response?.response?.ok) {
               console.log(response.responsedata);

               dispatch(
                    masterSliceActions.updateTiktokAuthentication(
                         response.responsedata?.access_token
                    )
               );

               setTikTokLocalStorage(response.responsedata?.access_token);

               navigate(
                    routes.scheduler +
                         "/" +
                         socialmedia.tiktok?.link +
                         "/" +
                         routes.schedulersocialmediagrid
               );
          }
     };
};

export const tiktokPublishPostFunction = async (id, token) => {
     let url = new URL(baseURL_Scheduler + tiktokPublishPostAPIENDPOINT);

     let headers = {
          Authorization: token,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": [
               "POST",
               "GET",
               "OPTIONS",
               "DELETE",
               "PUT",
          ],
          "Access-Control-Allow-Headers": [
               "append",
               "delete",
               "entries",
               "foreach",
               "get",
               "has",
               "keys",
               "set",
               "values",
               "Authorization",
          ],
     };

     let bodyObj = {
          post_id: id,
     };

     let response = await PostAPI(url, bodyObj, headers);

     console.log(bodyObj);

     if (response.response.ok) {
          console.log(response.responsedata);
          return response.responsedata;
     }
};

export const tiktokDraftPostThunk = (obj, auth, scheduleTime, navigate) => {
     return async (dispatch) => {
          console.log(obj);
          if (!obj?.title || !obj?.file) {
               return;
          }

          dispatch(schedulerSlideActions.modifyPostNowButtonIsLoading(true));

          let url = new URL(baseURL_Scheduler + tiktokDraftPost);

          let headers = {
               Authorization: auth?.sessionToken,

               "Access-Control-Allow-Origin": "*",
               "Access-Control-Allow-Methods": [
                    "POST",
                    "GET",
                    "OPTIONS",
                    "DELETE",
                    "PUT",
               ],
               "Access-Control-Allow-Headers": [
                    "append",
                    "delete",
                    "entries",
                    "foreach",
                    "get",
                    "has",
                    "keys",
                    "set",
                    "values",
                    "Authorization",
               ],
          };

          const body = new FormData();

          body?.append("video", obj.file);
          body?.append("title", obj?.title);
          body?.append("schedule_time", scheduleTime);
          console.log(url, body, headers);

          for (const entry of body.entries()) {
               console.log(entry);
          }

          console.log(auth?.sessionToken);

          const response = await PostAPIWithOutStringify(url, body, headers);
          console.log(response);

          if (response.response.ok) {
               console.log(response.responsedata);
               let video = response.responsedata.video;

               let publishResponse = await tiktokPublishPostFunction(
                    video._id,
                    auth?.sessionToken
               );

               console.log(publishResponse);
          }

          dispatch(schedulerSlideActions.modifyPostNowButtonIsLoading(false));
          dispatch(schedulerSlideActions.resetAddPost());
     };
};
