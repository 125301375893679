import React, { useEffect } from "react";
import ContactRigthHeader from "./ContactRigthHeader";
import ContactRigthListing from "./ContactRigthListing";
import { useSelector, useDispatch } from "react-redux";
import { formsFetchAPIThunk } from "../../../../../store/contactFormSlice";

const ContactRigth = () => {
     const dispatch = useDispatch();
     const auth = useSelector((state) => {
          return state.master.auth;
     });
     const { formsListingArray } = useSelector((state) => {
          return state.contactform.forms;
     });
     useEffect(() => {
          dispatch(formsFetchAPIThunk(auth));
     }, [auth]);

     return (
          <div className=" w-full h-full   flex flex-col justify-start">
               <ContactRigthHeader />
               <div className=" h-fit ">
                    <div className=" w-full h-full overflow-auto ">
                         <ContactRigthListing content={formsListingArray} />
                    </div>
               </div>
          </div>
     );
};

export default ContactRigth;
