import React, { useEffect } from "react";
import NavBarLandingPage from "../landingPage/NavBar/NavBarLandingPage";
import FirstSection from "../landingPage/FirstSectionLandingpage/FirstSection";
import SecondSection from "../landingPage/SecondSectionLandingPage/SecondSection";
import ThirdSection from "../landingPage/ThirdSectionLandingpage/ThirdSection";
import FourthSection from "../landingPage/FourthSectionLandingpage/FourthSection";
import FivthSection from "../landingPage/FivethSectionLandingpage/FivthSection";
import SixthSection from "../landingPage/SixthSectionLandingpage/SixthSection";
import SeventhSection from "../landingPage/SeventhSectinLandingpage/SeventhSection";
import EightSection from "../landingPage/EighthSectionLandingpage/EightSection";
import TenthSection from "../landingPage/TenthSectionLandingpage/TenthSection";
import NinethSection from "../landingPage/NinethSectionLandingpage/NinethSection";
import { useSearchParams, useNavigate } from "react-router-dom";
import { socialmedia } from "../config/socialmediaConfig";
import { routes } from "../config/mainConfig";
import SelectPlanSectionLandingPage from "../landingPage/SelectPlanSectionLandingPage.js/SelectPlanSectionLandingPage";

const Home = () => {
     const [searchParams, setSearchParams] = useSearchParams();
     const navigate = useNavigate();

     useEffect(() => {
          console.log(searchParams.get("code"));
          if (searchParams.get("code")) {
               navigate(
                    routes.scheduler +
                         "/" +
                         socialmedia.tiktok?.link +
                         "/" +
                         "addaccount" +
                         "?" +
                         "code=" +
                         searchParams.get("code")
               );
          }
     }, []);

     return (
          <>
               <NavBarLandingPage />
               <FirstSection />
               <SecondSection />

               <ThirdSection />

               <FourthSection />

               <FivthSection />

               <SixthSection />
               <SeventhSection />

               <EightSection />

               <SelectPlanSectionLandingPage />
               <NinethSection />

               <TenthSection />
          </>
     );
};

export default Home;
