import { createSlice } from "@reduxjs/toolkit";
import { schedulerModes } from "../../config/SchedulerConfig";
import {
     baseURL_Scheduler,
     schedulersAPIroute,
} from "../../API-utils/APIConfig";

import { PostAPI } from "../../API-utils/PostAPI";
import { navBarSliceActions } from "../DashboardSlices/NavbarSlice";
import { schedulerMonthWeekSliceActions } from "./SchedulerMonthWeekSlice";

export const buttonMode_Scheduler = {
     addaccount: "addaccount",
     linkaccount: "linkaccount",
};

//scheduler format
// {
//      content : "" ,
//      taskTime : "" ,
//       isPosted : false
// }
const initialState = {
     mode: schedulerModes.month,

     selectedSocialMedia: {
          image: "",
          name: "",
          link: "",
     },

     addAccount: {
          inputValue: "",
     },

     addPost: {
          title: "",
          description: "",

          file: false,
     },

     scheduler: {
          list: [],
     },

     ui: {
          buttonmode: buttonMode_Scheduler.linkaccount,
          addAccountButtonIsLoading: false,
          linkAccountButtonIsLoading: false,
          postNowButtonIsLoading: false,
     },
};

const schedulerSlice = createSlice({
     name: "scheduler-Slice state management",
     initialState,
     reducers: {
          updateSelectedSocialMedia(state, action) {
               return {
                    ...state,
                    selectedSocialMedia: action.payload,
               };
          },

          updateMode(state, action) {
               return {
                    ...state,
                    mode: action.payload,
               };
          },

          updateInputValue(state, action) {
               return {
                    ...state,
                    addAccount: {
                         ...state.addAccount,
                         inputValue: action.payload,
                    },
               };
          },

          updateAddPostTitle(state, action) {
               return {
                    ...state,
                    addPost: {
                         ...state.addPost,
                         title: action.payload,
                    },
               };
          },
          updateAddPostDescription(state, action) {
               return {
                    ...state,
                    addPost: {
                         ...state.addPost,
                         description: action.payload,
                    },
               };
          },

          updateAddPostFile(state, action) {
               return {
                    ...state,
                    addPost: {
                         ...state.addPost,
                         file: action.payload,
                    },
               };
          },

          updateUIButtonMode(state, action) {
               return {
                    ...state,
                    ui: {
                         ...state.ui,
                         buttonmode: action.payload,
                    },
               };
          },

          modifyAddAccountButtonIsLoading(state, action) {
               return {
                    ...state,
                    ui: {
                         ...state.ui,
                         addAccountButtonIsLoading: action.payload,
                    },
               };
          },
          modifyPostNowButtonIsLoading(state, action) {
               return {
                    ...state,
                    ui: {
                         ...state.ui,
                         postNowButtonIsLoading: action.payload,
                    },
               };
          },

          modifyLinkAccountButtonIsLoading(state, action) {
               return {
                    ...state,
                    ui: {
                         ...state.ui,
                         linkAccountButtonIsLoading: action.payload,
                    },
               };
          },

          resetAddAccountInputValue(state, action) {
               return {
                    ...state,
                    addAccount: {
                         ...state.addAccount,
                         inputValue: initialState.addAccount.inputValue,
                    },
               };
          },

          resetAddPost(state, action) {
               return {
                    ...state,
                    addPost: initialState?.addPost,
               };
          },

          resetContent(state, action) {
               return {
                    ...state,

                    addPost: initialState.addPost,
                    ui: initialState.ui,
               };
          },

          // Updating Scheduler LIst :

          updateSchedulerList(state, action) {
               return {
                    ...state,
                    scheduler: {
                         ...state.scheduler,
                         list: action.payload,
                    },
               };
          },
     },
});

export const schedulerSlideActions = schedulerSlice.actions;

export default schedulerSlice;

// Update Selected social media MiddleWare
export const updateSelectedSocialMediaMiddleware = (media) => {
     return (dispatch) => {
          dispatch(schedulerSlideActions.updateSelectedSocialMedia(media));
          dispatch(navBarSliceActions.modifySchedulerNavLink(media));
          dispatch(updatePostListScheduler([]));
     };
};

// update Post List
export const updatePostListScheduler = (list) => {
     return async (dispatch) => {
          dispatch(schedulerMonthWeekSliceActions.updatePostList(list));
          dispatch(schedulerSlideActions.updateSchedulerList(list));
     };
};

export const postTwitterPostThunk = (obj, auth, schedulerTime, navigate) => {
     return async (dispatch) => {
          dispatch(schedulerSlideActions.modifyPostNowButtonIsLoading(true));

          let api = new URL(
               baseURL_Scheduler + schedulersAPIroute + "/twitter/post"
          );

          let bodyObj = { ...obj };

          if (schedulerTime) {
               bodyObj = {
                    ...obj,
                    schedule_time: schedulerTime,
               };
          }

          let headers = {
               "Content-Type": "application/json",
               "Access-Control-Allow-Origin": "*",
               "Access-Control-Allow-Methods": [
                    "POST",
                    "GET",
                    "OPTIONS",
                    "DELETE",
                    "PUT",
               ],
               "Access-Control-Allow-Headers": [
                    "append",
                    "delete",
                    "entries",
                    "foreach",
                    "get",
                    "has",
                    "keys",
                    "set",
                    "values",
                    "Authorization",
               ],

               Authorization: auth?.sessionToken,

               redirect: "follow",
          };

          console.log(api.href, bodyObj, headers, auth);

          let response = await PostAPI(api, bodyObj, headers);

          console.log(response);

          if (response.response?.ok) {
               dispatch(schedulerSlideActions.resetAddPost());
          }

          dispatch(schedulerSlideActions.modifyPostNowButtonIsLoading(false));
     };
};
