import { configureStore } from "@reduxjs/toolkit";
import calenderSlice from "../components/Scheduler/SchedulerAddPost/CalenderComponents/calenderStore/calenderSlice";
import contactFormSlice from "./contactFormSlice";

import navBarSlice from "./DashboardSlices/NavbarSlice";
import dynamicSocialMediaSlice from "./DynamicSocialMediaSlices/DynamicSocialMediaSlices";
import masterSlice from "./masterSlice";
import LeadformsSlice from "./LeadformsSlices/LeadformSlice";
import userRegisterSlice from "./AuthenticationSlices/UserRegisterationSlice";
import loginSlice from "./AuthenticationSlices/loginSlice";
import schedulerSlice from "./SchedulerSlices/schedulerSlide";
import SchedulerAuthenticationSocialMediaSlice from "./SchedulerSlices/schedulerAuthenticationSocialMediaSlice";
import schedulerMonthWeekSlice from "./SchedulerSlices/SchedulerMonthWeekSlice";

const store = configureStore({
     reducer: {
          calender: calenderSlice.reducer,
          contactform: contactFormSlice.reducer,

          scheduler: schedulerSlice.reducer,
          schedulerMonthWeek: schedulerMonthWeekSlice.reducer,
          schedulerAuthenticationSocialMedia:
               SchedulerAuthenticationSocialMediaSlice.reducer,

          navbar: navBarSlice.reducer,
          dynamicsocialmedia: dynamicSocialMediaSlice.reducer,
          master: masterSlice.reducer,
          leadforms: LeadformsSlice.reducer,

          userregister: userRegisterSlice.reducer,
          login: loginSlice.reducer,
     },
});

export default store;
