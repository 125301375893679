import React from "react";

// Hook
import { useParams } from "react-router-dom";

// Config
import { socialmedia } from "../../config/socialmediaConfig";

// Components
import DynamicSocialMediaAddAccountLayout from "../../components/DynamicSocialmediaCOm/AddAccount_socialMedia/DynamicSocialMediaAddAccountLayout";
import DynamicFacebookAddAccount from "../../components/DynamicSocialmediaCOm/AddAccount_socialMedia/DynamicFacebookAddAccount";
import DynamicInstagramAddAccount from "../../components/DynamicSocialmediaCOm/AddAccount_socialMedia/DynamicInstagramAddAccount";

const DynamicSocialMediaAddAcount = () => {
     const parmas = useParams();

     const socialmedianame = parmas.socialmedianame;
     return (
          <>
               {socialmedianame === socialmedia.facebook.link ? (
                    <DynamicFacebookAddAccount />
               ) : (
                    <></>
               )}
               {socialmedianame === socialmedia.instagram.link && (
                    <DynamicInstagramAddAccount />
               )}

               {socialmedianame === socialmedia.yelp.link ||
               socialmedianame === socialmedia.tripadvisor.link ? (
                    <DynamicSocialMediaAddAccountLayout />
               ) : (
                    <></>
               )}
          </>
     );
};

export default DynamicSocialMediaAddAcount;
